/* eslint-disable no-underscore-dangle */
import {
  Backdrop,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  Chip,
  Container,
  Modal
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { EventObject } from '@toast-ui/calendar';
import { useState } from 'react';
import { ModalContainer } from '../modals/GlobalConfirmModal';
import {
  TaskIterationStatusStatusEnum,
  TimeFormatEnum
} from '../../api-client/generated/api';
import {
  mainColors,
  successColors,
  warningColors
} from '../../theme/themeConfig';
import { getFormatedTimeByTimeZone } from '../../utils/general';
import useAuthStore from '../../store/zustand/auth';

interface Props {
  detailPopUpData: EventObject;
  isOpen: boolean;
  selectedView: string;
  setOpenDetailPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTaskDetail: React.Dispatch<React.SetStateAction<boolean>>;
}

function CalendarTechnicianDetailPopup({
  isOpen,
  detailPopUpData,
  selectedView,
  setOpenDetailPopup,
  setOpenTaskDetail
}: Props) {
  const { title } = detailPopUpData;
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const userState = useAuthStore((state) => state);
  const dateObj = new Date(detailPopUpData.eventDate);
  const offset = -new Date().getTimezoneOffset();

  const formatEventDate = dateObj.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
    timeZone: 'UTC',
    weekday: 'long'
  });

  return (
    <Container sx={{ alignItems: 'center', margin: '40px', width: 'auto' }}>
      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        className="scrollbar"
        onClose={() => {
          setOpenDetailPopup(false);
          setAuxManualClose(false);
        }}
        open={auxManualClose}
      >
        <ModalContainer sx={{ minWidth: '90vw', padding: '20px' }}>
          <Box
            key="calendar-detail-popup-header"
            sx={{
              alignItems: 'flex-star',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
              width: '100%'
            }}
          >
            <Chip
              color={
                // eslint-disable-next-line no-nested-ternary
                detailPopUpData.status ===
                `${TaskIterationStatusStatusEnum.Completed}`
                  ? 'success'
                  : detailPopUpData.status ===
                    `${TaskIterationStatusStatusEnum.InProgress}`
                  ? 'warning'
                  : 'primary'
              }
              label={
                <Box
                  sx={{
                    alignItems: 'left',
                    justifyContent: 'left'
                  }}
                >
                  <Typography
                    sx={{
                      color:
                        // eslint-disable-next-line no-nested-ternary
                        detailPopUpData.status ===
                        TaskIterationStatusStatusEnum.InProgress
                          ? warningColors.ERROR
                          : detailPopUpData.status ===
                            `${TaskIterationStatusStatusEnum.Completed}`
                          ? `${successColors.SUCCESS}`
                          : `${mainColors.MAIN}`
                    }}
                    variant="button_100"
                  >
                    {
                      // eslint-disable-next-line no-nested-ternary
                      detailPopUpData.status ===
                      TaskIterationStatusStatusEnum.Completed
                        ? 'Completed'
                        : detailPopUpData.status ===
                          TaskIterationStatusStatusEnum.InProgress
                        ? 'In progress'
                        : 'Incoming'
                    }
                  </Typography>
                </Box>
              }
              size="small"
              sx={{
                backgroundColor:
                  // eslint-disable-next-line no-nested-ternary
                  detailPopUpData.status ===
                  TaskIterationStatusStatusEnum.InProgress
                    ? `${warningColors.LIGHT_ERROR} !important`
                    : detailPopUpData.status ===
                      TaskIterationStatusStatusEnum.Completed
                    ? `${successColors.SUCCESS_LIGHT} !important`
                    : `${mainColors.MAIN_LIGHT} !important`
              }}
              variant="outlined"
            />
            <HighlightOffOutlinedIcon
              onClick={() => {
                setOpenDetailPopup(false);
                setAuxManualClose(false);
              }}
              sx={{ color: 'mainText.main', cursor: 'pointer' }}
            />
          </Box>
          <Box
            key="calendar-detail-popup-header-title"
            sx={{
              alignItems: 'flex-star',
              borderBottom: 'groove',
              display: 'flex',
              marginTop: '20px',
              width: '100%'
            }}
          >
            <Typography
              color="primary.main"
              fontSize="24px"
              marginBottom="1rem"
              variant="title"
            >
              {title}
            </Typography>
          </Box>
          <Box
            key="calendar-detail-popup-attendees"
            sx={{
              alignItems: 'flex-star',
              display: 'flex',
              marginTop: '20px',
              width: '100%'
            }}
          >
            <Typography
              alignItems="center"
              display="flex"
              fontSize="14px"
              gap="0.5rem"
              marginBottom="0.5rem"
            >
              <AccountCircleOutlinedIcon />
              <Typography fontSize="14px">
                {detailPopUpData.attendees.join(', ')}
              </Typography>
            </Typography>
          </Box>
          <Box
            key="calendar-detail-popup-location"
            sx={{
              alignItems: 'flex-star',
              display: 'flex',
              width: '100%'
            }}
          >
            <Typography
              alignItems="flex-start"
              display="flex"
              fontSize="14px"
              gap="0.5rem"
              marginBottom="0.5rem"
              textAlign="start"
            >
              <LocationOnOutlinedIcon />
              {detailPopUpData.location}
            </Typography>
          </Box>
          <Box
            key="calendar-detail-popup-phone"
            sx={{
              alignItems: 'flex-star',
              borderBottom: 'groove',
              display: 'flex',
              width: '100%'
            }}
          >
            <Typography
              alignItems="flex-start"
              display="flex"
              fontSize="14px"
              gap="0.5rem"
              marginBottom="1.5rem"
              textAlign="start"
            >
              <LocalPhoneOutlinedIcon />
              {detailPopUpData.phone}
            </Typography>
          </Box>
          <Box
            key="calendar-detail-popup-time"
            sx={{
              alignItems: 'flex-star',
              display: 'flex',
              width: '100%'
            }}
          >
            <Typography
              alignItems="flex-start"
              display="flex"
              fontSize="18px"
              gap="0.5rem"
              textAlign="start"
              variant="title"
            >
              Time
            </Typography>
          </Box>
          <Box
            key="calendar-detail-popup-attendees-time-range"
            sx={{
              alignItems: 'flex-star',
              display: 'flex',
              width: '100%'
            }}
          >
            <Typography
              alignItems="flex-start"
              display="flex"
              fontSize="14px"
              gap="0.5rem"
              textAlign="start"
            >
              {formatEventDate} |{' '}
              {getFormatedTimeByTimeZone(
                detailPopUpData?.startDate,
                -new Date().getTimezoneOffset(),
                userState?.timeFormat === TimeFormatEnum._24h
              )}
              {' - '}
              {getFormatedTimeByTimeZone(
                detailPopUpData?.endDate,
                -new Date().getTimezoneOffset(),
                userState?.timeFormat === TimeFormatEnum._24h
              )}
            </Typography>
          </Box>
          <Box
            key="calendar-detail-popup-frequency"
            sx={{
              alignItems: 'flex-star',
              borderBottom: 'groove',
              display: 'flex',
              width: '100%'
            }}
          >
            <Typography
              alignItems="flex-start"
              display="flex"
              fontSize="14px"
              gap="0.5rem"
              marginBottom="1.5rem"
              textAlign="start"
            >
              {detailPopUpData.frequency} {' on'}
              {detailPopUpData.frequencyDays.join(', ')}
            </Typography>
          </Box>
          <Button
            fullWidth
            onClick={() => {
              setOpenDetailPopup(false);
              setOpenTaskDetail(true);
            }}
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '1rem',
              marginTop: '1.5rem'
            }}
            variant="primary"
          >
            Task details
          </Button>
        </ModalContainer>
      </Modal>
    </Container>
  );
}

export default CalendarTechnicianDetailPopup;
