/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  Typography,
  Box,
  Container,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import CustomRadio from '../../../../../components/form/CustomRadio';
import Filter from '../../../../../components/list/Filter';
import { weekDays } from '../../../../../helpers/weekDays';
import DaysOfTheWeek from '../../../../../components/input/DaysOfTheWeek';
import { getCurrency } from '../../../../../utils/general';
import Loader from '../../../../../components/shared/Loader';

interface Props {
  clientView?: boolean;
  deleteServiceTime: () => void;
  resetValues: () => void;
  serviceTime: any;
  setRecalculateApi: () => void;
  setServiceTime: (a: any) => void;
}

const CounterOfferCard: React.FC<Props> = function CounterOfferCard({
  serviceTime,
  resetValues,
  deleteServiceTime,
  setServiceTime,
  setRecalculateApi,
  clientView
}) {
  const { palette } = useTheme();
  const weekdays = useMemo(() => {
    return weekDays.map((day: any) => {
      return day.name;
    });
  }, []);
  const { t } = useTranslation();
  const readOnly = !clientView;
  const theme = useTheme();

  const getCounterOfferTrend = () => {
    switch (serviceTime?.actionToCounterOffer) {
      case 'discount':
        return (
          <Typography
            color="success.main"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <TrendingDown />
          </Typography>
        );
      case 'penalization':
        return (
          <Typography
            color="error.main"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <TrendingUp />
          </Typography>
        );
      case 'notApplicable':
        return (
          <Typography
            color="secondary.main"
            sx={{ alignItems: 'center', display: 'flex' }}
          >
            <TrendingFlat />
          </Typography>
        );
      default:
        break;
    }
    return <Box />;
  };

  if (!(serviceTime?.id || serviceTime?.serviceTimeId)) {
    return <Loader customColor="primary" customSize={24} />;
  }

  console.log('serviceTime', serviceTime);

  return (
    <Box
      sx={{
        mb: 4,
        mt: 2
      }}
    >
      <Box
        sx={{
          border: 1,
          borderColor: palette.divider,
          borderRadius: 4,
          p: 2
        }}
      >
        {serviceTime?.services && (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={6}
          >
            <Typography
              color="primary"
              fontWeight={700}
              sx={{ width: '30%' }}
              variant="subtitle2"
            >
              {t('LEAD.CREATE.SERVICES.SUMMARY.service')}:
            </Typography>
            <Typography
              color="secondary.main"
              sx={{ textAlign: 'left', width: '70%' }}
              variant="body_300"
            >
              {serviceTime?.services[0]?.name}
            </Typography>
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          mb={6}
        >
          <Typography
            color="primary"
            fontWeight={700}
            sx={{ width: '30%' }}
            variant="subtitle2"
          >
            {t('LEAD.CREATE.SERVICES.SUMMARY.frequency')}:
          </Typography>
          <FormControl
            disabled={readOnly}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              justifyContent: 'flex-start',
              textAlign: 'right',
              width: '70%'
            }}
          >
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event: any) => {
                setServiceTime({
                  ...serviceTime,
                  frequency: event.target.value
                });
              }}
              sx={{ alignSelf: 'left', display: 'flex' }}
            >
              <FormControlLabel
                checked={serviceTime?.frequency === 'Weekly'}
                control={<CustomRadio />}
                label={
                  <Typography variant="primary">
                    {t('LEAD.CREATE.SERVICES.weekly')}
                  </Typography>
                }
                value="Weekly"
              />
              <FormControlLabel
                checked={serviceTime?.frequency === 'Biweekly'}
                control={<CustomRadio />}
                label={
                  <Typography variant="primary">
                    {t('LEAD.CREATE.SERVICES.biweekly')}
                  </Typography>
                }
                value="Biweekly"
              />
              <FormControlLabel
                checked={serviceTime?.frequency === 'Monthly'}
                control={<CustomRadio />}
                label={
                  <Typography variant="primary">
                    {t('LEAD.CREATE.SERVICES.monthly')}
                  </Typography>
                }
                value="Monthly"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {serviceTime?.frequency && serviceTime?.frequency === 'Monthly' && (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={6}
          >
            <Typography
              color="primary"
              fontWeight={700}
              sx={{ width: '30%' }}
              variant="subtitle2"
            >
              {t('LEAD.CREATE.SERVICES.frequencyWeek')}:
            </Typography>
            <Box
              sx={{
                alignItems: 'left',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                width: '70%'
              }}
            >
              <Filter
                arrayInput={['First', 'Second', 'Third', 'Fourth']}
                disabled={readOnly}
                selectInput={serviceTime?.frequencyWeek}
                setSelectInput={(frequencyWeek) => {
                  setServiceTime({ ...serviceTime, frequencyWeek });
                }}
                title="Week"
              />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 2,
                  width: isMobile ? '100%' : '65%'
                }}
              >
                <Filter
                  arrayInput={weekdays}
                  disabled={readOnly}
                  selectInput={serviceTime?.frequencyDays?.[0]}
                  setSelectInput={(values) => {
                    setServiceTime({
                      ...serviceTime,
                      frequencyDays: [values]
                    });
                  }}
                  title="Day"
                />
                <Typography variant="primary">
                  {t('LEAD.CREATE.SERVICES.frequencyWeek2')}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {serviceTime?.frequency !== 'Monthly' && (
          <Box
            alignItems="flex-start"
            display="flex"
            justifyContent="space-between"
            mb={6}
          >
            <Typography
              color="primary"
              fontWeight={700}
              sx={{ maxWidth: '30%', width: '20%' }}
              variant="subtitle2"
            >
              {t('LEAD.CREATE.SERVICES.daysOfTheWeek')}:
            </Typography>
            <Box display="flex" sx={{ minWidth: '70%', width: '80%' }}>
              <Button
                disabled={readOnly}
                onClick={() => {
                  if (serviceTime?.frequencyDays.length === 7) {
                    setServiceTime({ ...serviceTime, frequencyDays: [] });
                  } else {
                    setServiceTime({
                      ...serviceTime,
                      frequencyDays: weekDays.map((day) => day.name)
                    });
                  }
                }}
              >
                {t('LEAD.CREATE.SERVICES.allDays')}
              </Button>
              <DaysOfTheWeek
                disabled={readOnly}
                setValues={(frequencyDays: any) => {
                  setServiceTime({ ...serviceTime, frequencyDays });
                }}
                type="square"
                values={serviceTime?.frequencyDays || []}
              />
            </Box>
          </Box>
        )}
        <Box
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          mb={6}
        >
          <Typography
            color="primary"
            fontWeight={700}
            sx={{ width: '30%' }}
            variant="subtitle2"
          >
            {t('LEAD.CREATE.SERVICES.timePeriod')}:
          </Typography>
          <Box sx={{ alignItems: 'start', display: 'flex' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={readOnly}
                label={`${t('LEAD.CREATE.startDate')}`}
                maxDate={dayjs(serviceTime?.projectEnd) || undefined}
                minDate={dayjs(serviceTime?.projectStart) || undefined}
                onChange={(startDate) => {
                  setServiceTime({ ...serviceTime, startDate });
                }}
                renderInput={(params) => (
                  <TextField
                    error={false}
                    {...params}
                    sx={{
                      backgroundColor: 'transparent !important'
                    }}
                  />
                )}
                value={serviceTime?.startDate || serviceTime?.projectStart}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={readOnly}
                label={t('LEAD.CREATE.endDate')}
                maxDate={dayjs(serviceTime?.projectEnd) || undefined}
                minDate={dayjs(serviceTime?.projectStart) || undefined}
                onChange={(endDate) => {
                  setServiceTime({ ...serviceTime, endDate });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ backgroundColor: 'transparent !important' }}
                  />
                )}
                value={dayjs(serviceTime?.endDate || serviceTime?.projectEnd)}
              />
            </LocalizationProvider>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 1,
            width: '100%'
          }}
        >
          {clientView && (
            <Button
              color="error"
              onClick={deleteServiceTime}
              sx={{ gap: 1, width: 200 }}
              variant="text"
            >
              <i className="custom-icon icon-delete" />
              Delete Service
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          border: 1,
          borderColor: palette.divider,
          borderRadius: 4,
          mt: 2,
          p: 2
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            justifyContent: clientView ? 'space-between' : 'center'
          }}
        >
          {clientView && (
            <Button
              onClick={setRecalculateApi}
              sx={{
                backgroundColor: 'transparent',
                width: isMobile ? '100%' : 150
              }}
              variant="tertiary-outlined"
            >
              {t('GENERAL.recalculateButton')}
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              justifyContent: 'space-between',
              width: isMobile ? '100%' : '55%'
            }}
          >
            {getCounterOfferTrend()}
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography color="primary" fontWeight={600} variant="subtitle2">
                {t('LEAD.CREATE.PROPOSAL.visits')}:{' '}
              </Typography>
              <Typography
                color="success.main"
                fontWeight={800}
                variant="subtitle2"
              >
                {serviceTime?.availability?.generalInfo?.daysOfServiceCount ||
                  serviceTime?.numberOfVisits ||
                  0}
              </Typography>
            </Box>
            <Box
              sx={{
                border: 1,
                borderColor: palette.divider,
                height: '100%',
                mx: 1
              }}
            />
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography color="primary" fontWeight={600} variant="subtitle2">
                {t('LEAD.CREATE.PROPOSAL.totalPerVisit')}:{' '}
              </Typography>
              <Typography
                color="success.main"
                fontWeight={800}
                variant="subtitle2"
              >
                {getCurrency(
                  serviceTime?.sellPrice || serviceTime?.pricePerVisit
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CounterOfferCard.defaultProps = { clientView: false };

export default CounterOfferCard;
