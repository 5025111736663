/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';

interface Props {
  colorFont: string;
  elementsArray: string[];
  style: any;
  unlimited?: boolean;
}

const ArrayChips: React.FC<Props> = function ArrayChips({
  colorFont,
  elementsArray,
  style,
  unlimited
}) {
  const elementsValue = (lengthValue: number) => {
    return elementsArray ? (
      <Stack direction="row" flexWrap='wrap' gap={1} spacing={1}>
        {elementsArray?.slice(0, lengthValue)?.map((ele: string) => (
          <Chip
            key={`array-chips-chip-${ele}`}
            label={
              <Typography color={colorFont} variant="button_100">
                {ele}
              </Typography>
            }
            size="small"
            sx={style}
          />
        ))}
        {elementsArray.length - lengthValue !== 0 && (
          <Tooltip title={`${elementsArray.toString().replaceAll(',', ', ')}`}>
            <Chip
              label={
                <Typography color={colorFont} variant="button_100">
                  + {elementsArray.length - lengthValue}
                </Typography>
              }
              size="small"
              sx={style}
            />
          </Tooltip>
        )}
      </Stack>
    ) : null;
  };

  if (unlimited) {
    return elementsValue(elementsArray.length);
  }

  if (elementsArray[0] && elementsArray[1] && elementsArray[2]) {
    const totalLength =
      elementsArray[0].length +
      elementsArray[1].length +
      elementsArray[2].length;
    if (totalLength < 16) {
      return elementsValue(3);
    }
  }
  if (elementsArray[0] && elementsArray[1]) {
    const totalLength = elementsArray[0].length + elementsArray[1].length;
    if (totalLength < 16) {
      return elementsValue(2);
    }
  }
  if (elementsArray[0] && elementsArray[1]) {
    const totalLength = elementsArray[0].length + elementsArray[1].length;
    if (totalLength >= 16) {
      return elementsValue(1);
    }
  }
  if (elementsArray[0]) {
    const totalLength = elementsArray[0].length;
    if (totalLength < 16) {
      return elementsValue(1);
    }
  }

  if (elementsArray[0]) {
    const totalLength = elementsArray[0].length;
    if (totalLength >= 16) {
      return elementsValue(1);
    }
  }

  return <>{elementsArray.toString().replaceAll(',', ', ')}</>;
};

ArrayChips.defaultProps = {
  unlimited: false
};

export default ArrayChips;
