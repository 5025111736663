import { TextField, TextFieldProps, styled } from '@mui/material';

import {
  themeFont,
  themePalette,
  secondaryColors
} from '../../../../theme/themeConfig';

const DatePickerTextField = styled(TextField)<TextFieldProps>(() => ({
  '& .MuiButtonBase-root': {
    '&:focus': {
      outline: 'none' // Remove button focus outline
    }
  },
  '& .MuiInputAdornment-root': {
    '& svg': {
      height: '24px',
      width: '24px'
    }
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${secondaryColors.SECONDARY_1}`
  },
  backgroundColor: themePalette.BACKGROUND,
  display: 'flex',
  input: {
    color: secondaryColors.SECONDARY_3,
    fontFamily: themeFont.FONT_SECONDARY,
    fontSize: '16px',
    fontWeight: 400,
    height: 40,
    lineHeight: 1.25,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingTop: 8
  },
  paddingLeft: 0,
  paddingRight: 0
}));

export default DatePickerTextField;
