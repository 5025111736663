import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Container,
  Modal,
  Typography,
  SliderProps,
  TextField,
  styled,
  FormControl,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { isMobile } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';
import CustomRadio from '../form/CustomRadio';
import {
  ButtonContainerModal,
  DrawerButton,
  ModalContainer,
  ModalContainerMobile
} from './GlobalConfirmModal';
import bblogo from '../../assets/images/Bluberry_Logo.png';
import Api from '../../api';

interface Props {
  allowCounterOffers?: boolean;
  onSubmitCounterOffer?: () => void;
  setRejected: React.Dispatch<React.SetStateAction<boolean>>;
  setRejectedProposal?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  setShowRejectModal: React.Dispatch<React.SetStateAction<any>>;
  setWantNewProposal?: React.Dispatch<React.SetStateAction<any>> | undefined;
  view: any;
}

const getProportion = (baseSize: number, proportion: number) =>
  isMobile ? baseSize * proportion : baseSize;

const RejectProposalModal: React.FC<Props> = function RejectProposalModal({
  setRejected,
  setShowRejectModal,
  setRejectedProposal,
  setWantNewProposal,
  view,
  onSubmitCounterOffer,
  allowCounterOffers
}) {
  const rejectProposalAction = Api.ProposalApi.rejectProposal;
  const { t } = useTranslation();
  const [auxManualClose, setAuxManualClose] = useState<boolean>(true);
  const [sendReject, setSendReject] = useState<boolean>(false);
  const tokenParam = window.location.pathname.split('/')[2];
  const [rejectForm, setRejectForm] = useState<any>({
    getUpdatedProposal: true,
    rejectMessage: ''
  });

  const { data: rejectedProposalResponse, error: rejectedProposalError } =
    useSWR(
      sendReject
        ? {
            message: rejectForm.rejectMessage,
            requestUpdatedProposal: rejectForm.getUpdatedProposal,
            token: tokenParam
          }
        : null,
      rejectProposalAction
    );

  useEffect(() => {
    if (rejectedProposalResponse && !rejectedProposalError) {
      setRejected(true);
      if (view === 'client') {
        setSendReject(false);
      }
      setAuxManualClose(false);
      setShowRejectModal(false);
      if (view === 'client' && setRejectedProposal) {
        setRejectedProposal(true);
      }
      if (view === 'client' && setWantNewProposal) {
        setWantNewProposal(rejectForm.getUpdatedProposal);
      }
    }
  }, [rejectedProposalResponse]);

  return (
    <Container
      sx={{
        alignItems: 'normal',
        margin: '40px'
        // padding: '40px'
      }}
    >
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowRejectModal(false);
          setAuxManualClose(false);
        }}
        open={auxManualClose}
        sx={{
          alignItems: 'center'
        }}
      >
        <Box
          component={isMobile ? ModalContainerMobile : ModalContainer}
          sx={{
            ...(isMobile
              ? {
                  alignItems: 'center',
                  padding: 2,
                  transform: allowCounterOffers
                    ? 'translate(50%, -26%)'
                    : 'translate(50%, -4%)',
                  width: '100%'
                }
              : {
                  alignItems: 'center',
                  padding: 4,
                  position: 'fixed',
                  width: 'auto'
                })
          }}
        >
          <Box
            justifyContent="space-between"
            sx={{
              display: 'flex',
              mt: 2,
              width: '100%'
            }}
          >
            <img
              alt="bluberry logo"
              src={bblogo}
              style={{
                height: getProportion(40, 0.8),
                width: getProportion(140, 0.8)
              }}
            />
            <IconButton
              onClick={() => {
                setShowRejectModal(false);
                setAuxManualClose(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            mt={4}
            sx={{
              alignItems: 'flex-start',
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <Typography mb={2} variant="body_300">
              {t('LEAD.CREATE.PROPOSAL.rejectProposalModalText')}
            </Typography>
            <TextField
              multiline
              onChange={(value) => {
                setRejectForm({
                  ...rejectForm,
                  rejectMessage: value.target.value
                });
              }}
              sx={{
                '& fieldset': { border: 'none' },
                minHeight: '112px',
                width: '100%'
              }}
              value={rejectForm?.rejectMessage || ''}
            />
          </Box>
          <Box mt={2} sx={{ display: 'flex', width: '100%' }}>
            <Box mt={2}>
              <Typography variant="body_300">
                {t('LEAD.CREATE.PROPOSAL.rejectProposalModalOptionText')}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              marginBottom: 2,
              width: '100%'
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event: any) => {
                  setRejectForm({
                    ...rejectForm,
                    getUpdatedProposal: event.target.value === 'true'
                  });
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <FormControlLabel
                  value
                  checked={rejectForm?.getUpdatedProposal}
                  control={<CustomRadio />}
                  label={
                    <Typography variant="primary">
                      {t('MODAL.confirModalYes')}
                    </Typography>
                  }
                />
                <FormControlLabel
                  checked={!rejectForm?.getUpdatedProposal}
                  control={<CustomRadio />}
                  label={
                    <Typography variant="primary">
                      {t('MODAL.confirmModalNo')}
                    </Typography>
                  }
                  value={false}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {onSubmitCounterOffer && allowCounterOffers && (
            <Box
              alignItems="center"
              bgcolor="surface.300"
              borderRadius={2}
              display="flex"
              justifyContent="space-between"
              marginTop={2}
              padding={2}
            >
              <Box
                alignItems="flex-start"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                maxWidth="70%"
              >
                <Typography fontWeight="bold" variant="subtitle2">
                  Submit a Counter Offer
                </Typography>
                <Typography
                  color="secondary.main"
                  fontSize="14px"
                  textAlign="left"
                  variant="body_500"
                >
                  If you'd prefer to continue negotiations, you can suggest
                  alternative terms for a new proposal that better meets your
                  needs.
                </Typography>
              </Box>
              <Box alignSelf="flex-end">
                <Button
                  color="primary"
                  onClick={() => {
                    onSubmitCounterOffer();
                  }}
                  variant="text"
                >
                  Submit
                </Button>
              </Box>
            </Box>
          )}
          <ButtonContainerModal
            sx={{
              alignItems: 'center',
              marginTop: 2,
              width: '100%'
            }}
          >
            <DrawerButton
              onClick={() => {
                setSendReject(true);
              }}
              sx={{ height: 46 }}
              variant="cancel"
            >
              <Typography>Reject Proposal</Typography>
            </DrawerButton>
          </ButtonContainerModal>
        </Box>
      </Modal>
    </Container>
  );
};
RejectProposalModal.defaultProps = {
  allowCounterOffers: false,
  onSubmitCounterOffer: () => {},
  setRejectedProposal: undefined,
  setWantNewProposal: undefined
};
export default RejectProposalModal;
