import React, { useEffect, useRef, useState } from 'react';
import '../../style/App.scss';
import useSWR from 'swr';
import { Button } from '@mui/material';
import Api from '../../api';
import InviteModal from '../../components/modals/InviteModal';
import DeleteModal from '../../components/modals/DeleteModal';
import ListingPage from '../../components/list/ListingPage';
import DeleteButton from '../../components/list/DeleteButton';
import ResendButton from '../../components/list/ResendButton';
import Filter from '../../components/list/Filter';
import AutoCompleteSearch from '../../components/input/AutoCompleteSearch';
import useDebouncedSearch from '../../hooks/useDebounceSearch';
import ToastNotification from '../../components/shared/ToastNotification';
import { type toastNotification } from '../../utils/notificationHelper';

const Invitations: React.FC = function Invitations() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [selectInput, setSelectInput] = useState<string>('');
  const [resendItem, setResend] = useState<string | null>(null);
  const [deleteItem, setDelete] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fireInvitationAPI, setFireInvitationAPI] = useState<boolean>(true);
  const [fireInvitationByIdAPI, setFireInvitationByIdAPI] =
    useState<boolean>(true);
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const pageNumber = useRef<number>(1);
  const [options, setOptions] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const debouncedSearchQuery = useDebouncedSearch(search, 1000);
  const [requestSearch, setRequestSearch] = useState<boolean>(false);
  const [valueSearch, setValueSearched] = useState<any>('');
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();

  // ===================== Fetch
  const deleteAction = Api.CompanyInvitationApi.deleteCompanyInvitation;
  const resendAction = Api.CompanyInvitationApi.resendCompanyInvitation;
  const invitationAction =
    Api.CompanyInvitationApi.getCompanyInvitationsPaginated;

  const params = {
    items: 20,
    page: pageNumber.current,
    url: 'api/resources'
  };

  const {
    data: mainResponse,
    error: mainError,
    mutate
  } = useSWR(fireInvitationAPI ? params : null, invitationAction);

  const { data: resendData, error: resendError } = useSWR(
    resendItem,
    resendAction
  );

  const { data: invitationIdResponse } = useSWR(
    fireInvitationByIdAPI ? { id: valueSearch?.id } : null,
    Api.CompanyInvitationApi.getCompanyInvitations
  );

  const { data: searchResponse } = useSWR(
    requestSearch ? { q: debouncedSearchQuery } : null,
    Api.SearchApi.getSearch
  );

  const loadMoreItems = () => {
    pageNumber.current += 1;
    setFireInvitationAPI(true);
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => setOpenModal(false);

  useEffect(() => {
    if (mainError) {
      setData([]);
    }
  }, [mainError]);

  useEffect(() => {
    if (searchResponse) {
      setOptions(searchResponse);
      setRequestSearch(false);
    }
  }, [searchResponse]);

  useEffect(() => {
    if (debouncedSearchQuery !== '') {
      setRequestSearch(true);
    }
  }, [debouncedSearchQuery]);

  const setDataFunction = () => {
    if (mainResponse) {
      setEmptyState(mainResponse.length === 0);
      setData(
        mainResponse.map((item: any) => {
          return {
            ...item,
            delete: (
              <DeleteButton
                deleteFn={() => {
                  setDelete(item.id);
                  setShowDeleteModal(true);
                }}
              />
            ),
            resend: (
              <ResendButton
                resendFn={() => {
                  setResend(item.administratorEmail);
                }}
              />
            )
          };
        })
      );
    }
  };

  useEffect(() => {
    setDataFunction();
  }, [mainResponse]);

  const isItemLoaded = (index: any) => !!data[index];

  useEffect(() => {
    if (resendData) {
      setResend(null);
      mutate();
    }
  }, [resendData]);

  useEffect(() => {
    if (resendError) {
      setResend(null);
    }
  }, [resendError]);

  useEffect(() => {
    if (valueSearch?.id) {
      // eslint-disable-next-line no-console
      console.log('se hizo llamado valor', valueSearch.id);
      setFireInvitationByIdAPI(true);
    }
  }, [valueSearch]);

  useEffect(() => {
    if (data && data.length === 0 && !emptyState) {
      mutate();
    }
  }, [data]);

  useEffect(() => {
    if (invitationIdResponse) {
      // eslint-disable-next-line no-console
      console.log('aqui hacemos el set', invitationIdResponse);
    }
  }, [invitationIdResponse]);

  useEffect(() => {
    if (selectInput !== '') {
      const resultado = mainResponse?.filter(
        (invitations: any) => invitations.status === selectInput
      );
      setData(
        resultado?.map((item: any) => {
          return {
            ...item,
            delete: (
              <DeleteButton
                deleteFn={() => {
                  setDelete(item.id);
                  setShowDeleteModal(true);
                }}
              />
            ),
            resend: (
              <ResendButton
                resendFn={() => {
                  setResend(item.administratorEmail);
                }}
              />
            )
          };
        }) || []
      );
    } else {
      setDataFunction();
    }
  }, [selectInput]);

  const filters = () => {
    return (
      <>
        <Filter
          arrayInput={['Active', 'Expired', 'Pending']}
          selectInput={selectInput}
          setSelectInput={setSelectInput}
          title="Status"
        />
        <AutoCompleteSearch
          options={options?.services ?? []}
          setOptions={setOptions}
          setSearch={setSearch}
          setValueSearched={setValueSearched}
          value={valueSearch?.name ? valueSearch?.name : valueSearch}
        />
        <Button onClick={() => setDataFunction()}>Clear</Button>
      </>
    );
  };

  return (
    <ListingPage
      fullSize
      data={data}
      filters={filters}
      handleOpen={handleOpen}
      handleOpenDraw={() => {}}
      headKeys={[
        'administratorEmail',
        'companyName',
        'status',
        'resend',
        'delete'
      ]}
      isItemLoaded={isItemLoaded}
      itemCount={mainResponse ? 1 : 0}
      loadMoreItems={loadMoreItems}
      nameButton="create"
      setValueDrawer={() => {}}
      title="companyInvitations"
    >
      <>
        {openModal && (
          <InviteModal
            action={Api.CompanyInvitationApi.sendInvitation}
            handleClose={handleClose}
            mutateAction={mutate}
            openValue={openModal}
            setToastNotification={setToastNotification}
            type="company"
          />
        )}
        {showDeleteModal && (
          <DeleteModal
            action={deleteAction}
            id={deleteItem}
            mutate={mutate}
            setShowModal={setShowDeleteModal}
          />
        )}
        {toastNotification && (
          <ToastNotification
            onClose={() => {
              setToastNotification(undefined);
            }}
            text={toastNotification.text}
            type={toastNotification.type}
          />
        )}
      </>
    </ListingPage>
  );
};

export default Invitations;
