import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SignatureFieldProps {
  onChange: (value: string) => void;
  placeHolder?: string;
  valid?: boolean;
  value: string;
}

const SignatureField: React.FC<SignatureFieldProps> = function SignatureField({
  value,
  onChange,
  placeHolder,
  valid
}) {
  const { t } = useTranslation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        marginTop: '16px',
        padding: '10px 24px',
        width: '100%'
      }}
    >
      <Typography sx={{ marginBottom: '4px' }} variant="subtitle1">
        Signature
      </Typography>
      <Box width="60%">
        <TextField
          fullWidth
          hiddenLabel
          inputProps={{
            style: {
              borderBottom: '1px solid grey',
              fontSize: '1.5rem',
              fontWeight: 'bold',
              padding: '2px 20px 0px 6px'
            }
          }}
          onChange={handleInputChange}
          placeholder={placeHolder}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              borderStyle: 'hidden'
            },
            backgroundColor: 'transparent !important',
            border: 0,
            borderStyle: 'hidden'
          }}
          value={value}
        />
        {!valid && value !== '' && (
          <Typography color="error" sx={{ marginTop: '4px' }} variant="caption">
            {t('ERROR.signatureError')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

SignatureField.defaultProps = {
  placeHolder: '',
  valid: true
};

export default SignatureField;
