/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';
// import Calendar from '@toast-ui/react-calendar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { isMobile } from 'react-device-detect';
import { Container, Box, Typography, Modal, Backdrop } from '@mui/material';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { getFormatedTimeByTimeZone, getMonthString } from '../../utils/general';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import useCalendarStore from '../../store/zustand/calendar';
import CalendarTechnicianDetailPopup from '../../components/calendar/CalendarTechnicianDetailPopup';
import api from '../../api';
import { mainColors, surfaceColors } from '../../theme/themeConfig';
import { formatDate, formatDateSimple } from '../../utils/calendarHelper';
import TechnicianTaskDetails from '../dashboard/TechnicianTaskDetails';
import {
  TaskIterationStatusStatusEnum,
  TimeFormatEnum
} from '../../api-client/generated/api';
import { ModalContainerMobile } from '../../components/modals/GlobalConfirmModal';
import Notes from '../projects/Notes';
import useAuthStore from '../../store/zustand/auth';

const MobileStyle = {
  margin: '20px',
  width: 'auto'
};

const CalendarTechnicianView: React.FC = function CalendarTechnicianView() {
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [openTaskDetail, setOpenTaskDetail] = useState(false);
  const [getCalendarEvents] = useState(false);
  const [getCalendarEventsDay, setGetCalendarEventsDay] = useState(false);
  const [getCalendarEventsDots, setGetCalendarEventsDots] = useState(false);
  const [detailPopUpData, setDetailPopUpData] = useState<any>();
  const { selectedView } = useCalendarStore((state) => state);
  const calendarContainer = useRef<HTMLElement>(null);
  const pageContainer = useRef<HTMLDivElement>(null);
  const [notesProjectId, setNoteProjectId] = useState<String | null>(null);
  const [notesData, setNotesData] = useState<any>();
  const userState = useAuthStore((state) => state);
  const [value, setValue] = useState(new Date());
  const [selectedEvents, setSelectedEvents] = useState<any[]>([]);
  const [daysWithDot, setDaysWithDot] = useState<string[]>([]);
  const [auxDaysWithDot, setAuxDaysWithDot] = useState<string[]>([]);

  const { data: calendarEventsData, error: getCalendarEventsError } = useSWR(
    getCalendarEvents ? { url: 'api/CalendarEvents' } : null,
    api.CalendarApi.calendarGetCalendarEventsByTechnician
  );

  const { data: notesResponse } = useSWR(
    notesProjectId
      ? { id: notesProjectId, url: `projects/notes/${notesProjectId}` }
      : null,
    api.ProjectApi.getNotes
  );

  const {
    data: calendarEventsDayData,
    error: calendarEventsDayDataError,
    mutate: calendarEventsDataMutate
  } = useSWR(
    getCalendarEventsDay
      ? { day: formatDateSimple(value), timeFormat: userState?.timeFormat }
      : null,
    api.CalendarApi.calendarGetCalendarEventsByTechnicianByDate
  );

  const {
    data: calendarEventsDotsData,
    error: getCalendarEventsDotsError,
    isValidating: isValidatingDots
  } = useSWR(
    getCalendarEventsDots ? { url: 'api/CalendarEvents' } : null,
    api.CalendarApi.calendarEventsfindDotDaysByTechnician
  );

  //= ================= FUNCTIONS =============================
  const formatDateLocal = (dateString: any) => {
    const date = new Date(`${dateString} 00:00:00`);
    const auxMonts = getMonthString('short');
    const day = Math.floor(date.getDate());

    return { day, month: auxMonts[date.getMonth()] };
  };

  const tileContent = ({ date }: { date: Date }) => {
    if (
      daysWithDot.includes(formatDateSimple(date)) ||
      auxDaysWithDot.includes(formatDateSimple(date))
    ) {
      return (
        <div
          style={{
            background: 'blue',
            borderRadius: '50%',
            height: '5px',
            margin: 'auto',
            width: '5px'
          }}
        />
      );
    }

    return null;
  };

  const renderEventListItems = () => {
    if (selectedEvents.length > 0) {
      return selectedEvents.map((data) => {
        const { month, day } = formatDateLocal(data.date);
        let contTask = 0;

        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`${data.date}-${data.id}`}
            sx={{ alignItems: 'flex-start', display: 'flex', mb: 2 }}
          >
            <Box sx={{ mr: 1 }}>
              <Typography
                sx={{
                  color: 'primary.main',
                  display: 'block',
                  textAlign: 'center'
                }}
                variant="caption"
              >
                {month}
              </Typography>
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'primary.main',
                  borderRadius: '50%',
                  color: 'white',
                  display: 'flex',
                  fontWeight: 'bold',
                  height: '40px',
                  justifyContent: 'center',
                  width: '40px'
                }}
              >
                <Typography variant="caption">{day}</Typography>
              </Box>
            </Box>

            <Box sx={{ flex: 1, pl: 1 }}>
              <Typography
                sx={{
                  color: mainColors.MAIN_6,
                  fontSize: '0.875rem',
                  fontWeight: 'bold',
                  minWidth: '60px'
                }}
              >
                {getFormatedTimeByTimeZone(
                  data.startDate,
                  dayjs().utcOffset(),
                  userState?.timeFormat === TimeFormatEnum._24h
                )}
              </Typography>
              {data.events.map((event: any) => {
                contTask += 1;
                return (
                  <Box
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${event.time}-${data.id}-${event.leadServiceTaskId}-${contTask}`}
                    onClick={() => {
                      setOpenDetailPopup(true);
                      setDetailPopUpData({
                        ...event,
                        eventDate: data.date,
                        id: event.id
                      });
                    }}
                    sx={{
                      alignItems: 'center',
                      backgroundColor:
                        event?.statusColors?.backgroundColor ||
                        surfaceColors.SURFACE_5,
                      borderLeft: 'solid',
                      borderLeftColor:
                        event?.statusColors?.color || mainColors.MAIN_6,
                      borderLeftWidth: '4px',
                      borderRadius: '4px',
                      display: 'block',
                      mb: 1,
                      p: 1
                    }}
                  >
                    <Typography
                      sx={{
                        color: event?.statusColors?.color || mainColors.MAIN_6,
                        fontSize: '0.875rem'
                      }}
                    >
                      {event.title}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      });
    }
    return [];
  };

  //= ================ USEEFFECT ========================
  useEffect(() => {
    if (calendarEventsData && !getCalendarEventsError) {
      const mes = value.getMonth();

      const monDates = calendarEventsData.filter((event) => {
        const auxDate = new Date(event.date);
        return auxDate.getMonth() === mes;
      });
      const dayDotsList: any[] = [];

      monDates.forEach((monDate) => {
        const auxDate = new Date(monDate.date);
        dayDotsList.push(
          parseInt(
            auxDate.toLocaleDateString(undefined, {
              day: 'numeric'
            }),
            10
          ) + 1
        );
      });

      const eventsForDay = calendarEventsData.filter((event) => {
        const eventDate = new Date(event.date);
        eventDate.setDate(eventDate.getDate() + 1);

        return eventDate.toDateString() === value.toDateString();
      });

      setSelectedEvents(eventsForDay);
    }
  }, [calendarEventsData]);

  useEffect(() => {
    if (calendarEventsDotsData && !getCalendarEventsDotsError) {
      setGetCalendarEventsDots(false);

      const dayDotsList = calendarEventsDotsData.map((event: any) => {
        return event.start;
      });

      const daysWithDotsList = calendarEventsDotsData.map((event: any) => {
        return formatDateSimple(new Date(event.start));
      });

      setDaysWithDot(dayDotsList);
      setAuxDaysWithDot(daysWithDotsList);
    }
  }, [calendarEventsDotsData]);

  useEffect(() => {
    setGetCalendarEventsDots(true);
    setGetCalendarEventsDay(true);
  }, []);

  useEffect(() => {
    setGetCalendarEventsDay(true);
  }, [value]);

  useEffect(() => {
    if (calendarEventsDayData && !calendarEventsDayDataError) {
      setGetCalendarEventsDay(false);
      setSelectedEvents(calendarEventsDayData);
      setDetailPopUpData({
        ...detailPopUpData,
        calendarEventsDayData
      });
      setOpenTaskDetail(false);
    }
  }, [calendarEventsDayData]);

  const onChange = (nextValue: any) => {
    setValue(nextValue);
  };

  const onActiveChange = (nextValue: any) => {
    setValue(nextValue.activeStartDate);
  };

  useEffect(() => {
    if (notesResponse) {
      setNotesData(notesResponse);
    }
  }, [notesResponse]);

  return (
    <>
      {!openTaskDetail && !isValidatingDots && (
        <Container
          id="calendar-page-container"
          ref={pageContainer}
          sx={isMobile ? MobileStyle : null}
        >
          <Box id="calendar-container" ref={calendarContainer}>
            <Calendar
              calendarType="gregory"
              onActiveStartDateChange={onActiveChange}
              onChange={onChange}
              tileContent={tileContent}
              value={value}
            />
            <Box
              sx={{
                mt: 2,
                p: 0,
                width: '100%'
              }}
            >
              {renderEventListItems()}
            </Box>
          </Box>
          {openDetailPopup && (
            <CalendarTechnicianDetailPopup
              detailPopUpData={detailPopUpData}
              isOpen={openDetailPopup}
              selectedView={selectedView}
              setOpenDetailPopup={setOpenDetailPopup}
              setOpenTaskDetail={setOpenTaskDetail}
            />
          )}
        </Container>
      )}
      {openTaskDetail && detailPopUpData?.taskDetailFormated?.id && (
        <TechnicianTaskDetails
          manualClose
          calendarEventsDataMutate={calendarEventsDataMutate}
          editable={
            detailPopUpData?.taskDetailFormated?.status !==
            TaskIterationStatusStatusEnum.InProgress
          }
          setGetCalendarEventsMobile={setGetCalendarEventsDay}
          setNoteProjectId={setNoteProjectId}
          setTaskDetailsData={setOpenTaskDetail}
          taskDetails={{
            ...detailPopUpData?.taskDetailFormated?.taskDetails,
            date: formatDate(new Date(detailPopUpData?.eventDate)),
            projectId: detailPopUpData?.projectId
          }}
        />
      )}
      {notesProjectId && (
        <Modal
          closeAfterTransition
          open
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {
            setNoteProjectId(null);
            setNotesData(null);
          }}
        >
          <ModalContainerMobile sx={{ minWidth: '90%' }}>
            <Notes
              technicianView
              messages={notesData}
              onClose={() => {
                setNoteProjectId(null);
                setNotesData(null);
              }}
            />
          </ModalContainerMobile>
        </Modal>
      )}
    </>
  );
};

export default CalendarTechnicianView;
