import { AdvancedDashboardApi } from '../api-client/generated';

const apiCaller = new AdvancedDashboardApi();

const getAdvancedDashboards = async () => {
  const { data } = await apiCaller.advancedDashboardGetAll();
  const totalWidgets = data.reduce(
    (total, dashboard: any) => total + dashboard.widgets.length,
    0
  );
  return { data, totalWidgets };
};

const createDashboard = async (params: any) => {
  const { name, description, color, icon } = params;
  const response = await apiCaller.advancedDashboardCreate({
    createAdvancedDashboardDto: {
      color,
      description,
      icon,
      name
    }
  });
  return response.data;
};

const updateDashboard = async (params: any) => {
  const { values = {} } = params;
  const { id, name, description, color, icon } = values;
  const response = await apiCaller.advancedDashboardUpdate({
    id,
    updateAdvancedDashboardDto: {
      color,
      description,
      icon,
      name
    }
  });
  return response.data;
};

const getAdvanceDashboardById = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.advancedDashboardGetOneById({ id });
  return response.data;
};

const removeAdvanceDashboardById = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.advancedDashboardDelete({ id });
  return { status: response?.status };
};

const addWidgetToAdvancedDashboard = async (params: any) => {
  const { id, widgetId } = params;
  const response = await apiCaller.advancedDashboardAddWidget({
    createWidgetOnAdvanceDashboardDTO: {
      widgetId
    },
    id
  });
  return response.data;
};

const getWidgetDashboardData = async (params: any) => {
  const { id: widgetId, dashboardId } = params;
  const response = await apiCaller.advancedDashboardGetWidgetData({
    dashboardId,
    widgetId
  });
  return response.data as any;
};

const advancedDashboardDeleteWidget = async (params: any) => {
  const { id, widgetId } = params;
  const response = await apiCaller.advancedDashboardDeleteWidget({
    id,
    widgetId
  });
  return response.status;
};

const updateAdvanceDashboardWidgetOrder = async (params: any) => {
  const { id, widgetIds } = params;
  const response = await apiCaller.advancedDashboardUpdateWidgetOrder({
    id,
    updateWidgetOrderDTO: {
      widgetIds
    }
  });
  return response.status;
};

export default {
  addWidgetToAdvancedDashboard,
  advancedDashboardDeleteWidget,
  createDashboard,
  getAdvanceDashboardById,
  getAdvancedDashboards,
  getWidgetDashboardData,
  removeAdvanceDashboardById,
  updateDashboard,
  updateAdvanceDashboardWidgetOrder
};
