import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps,
  Modal,
  Backdrop,
  List,
  ListItemText
} from '@mui/material';
import useSWR from 'swr';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 254,
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 407
}));

const BoxCentered = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  justifyContent: 'center'
}));

const ButtonContainerModal = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '216px'
}));

const DrawerButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

const ModalContainerForProjectList = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 480,
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 607
}));

interface Props {
  action: (params: any) => Promise<any>;
  archived?: boolean;
  cleanRow?: () => void;
  id: any | null;
  mutate: () => void;
  setShouldMutate?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ArchiveModal: React.FC<Props> = function ArchiveModal({
  setShowModal,
  mutate,
  id,
  action,
  cleanRow,
  setShouldMutate,
  archived
}) {
  const { t } = useTranslation();
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [projectList, setProjectList] = useState<any>([]);

  const { data: deleteData, error: deleteError } = useSWR(
    deleteAction ? id : null,
    action
  );

  useEffect(() => {
    if (deleteData) {
      if (deleteData?.data?.length > 0) {
        setProjectList(deleteData.data);
      } else {
        setShowModal(false);
      }

      setDeleteAction(false);

      // This is to force the mutate of the list of clients with the useEffect
      if (setShouldMutate) setShouldMutate(true);
      if (cleanRow) cleanRow();
      // This mutate won't work because most of the SWR calls use a ternary operator with a state variable
      mutate();
    }
  }, [deleteData]);

  useEffect(() => {
    if (deleteError) {
      setDeleteAction(false);
      const message =
        deleteError?.response?.data?.message || t('ERROR.general');
      setError(message);
    }
  }, [deleteError]);

  useEffect(() => {}, []);

  // =================================================== COMPONENTS
  const getProjectList = () => {
    if (projectList.length === 0) return '';

    return (
      <Box
        sx={{
          height: 900,
          overflow: 'auto',
          mb: 5,
          width: '100%'
        }}
      >
        {projectList.map((project: any) => (
          <List key={project.id} sx={{ listStyleType: 'disc', p: 0 }}>
            <ListItemText sx={{ display: 'list-item' }}>
              <Typography
                color="primary.main"
                onClick={() => {
                  window.open(`/app/projects/detail/${project.id}`, '_blank');
                }}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  '&:hover': { color: 'secondary.400' }
                }}
                variant="modalTitle"
              >
                {project.title}
              </Typography>
            </ListItemText>
          </List>
        ))}
      </Box>
    );
  };

  const getContent = () => {
    if (projectList.length > 0) {
      return (
        <ModalContainerForProjectList>
          <BoxCentered>
            <Typography color="secondary.500" mb={0} variant="h5">
              {t('MODAL.archiveModalProjectListTitle')}
            </Typography>
            <Typography color="secondary.500" variant="body1">
              {t('MODAL.archiveModalProjectListDescription')}
            </Typography>
          </BoxCentered>
          {getProjectList()}
          <DrawerButton
            color="error"
            onClick={() => {
              setShowModal(false);
            }}
            sx={{ borderColor: 'error.error', height: 46, width: 114 }}
            variant="outlined"
          >
            <Typography color="error.main">
              {t('GENERAL.closeButton')}
            </Typography>
          </DrawerButton>
        </ModalContainerForProjectList>
      );
    }

    return (
      <ModalContainer>
        {error && (
          <BoxCentered>
            <Typography color="error.main" variant="heading_400">
              {t('ERROR.errorTitle')}
            </Typography>
            <Typography variant="primary">{error}</Typography>
          </BoxCentered>
        )}

        {!error && (
          <>
            <Typography color="secondary.400" variant="modalTitle">
              <i
                className={`custom-icon icon-${
                  archived ? 'unarchived' : 'archived'
                }`}
                style={{ fontSize: 40, marginBottom: 24 }}
              />
              <br />
              <br />
              {archived ? t('MODAL.unarchived') : t('MODAL.archived')}
            </Typography>
            <br />
            <ButtonContainerModal>
              <>
                <DrawerButton
                  onClick={() => {
                    setShowModal(false);
                  }}
                  sx={{ borderColor: 'primary.main', height: 46, width: 114 }}
                  variant="outlined"
                >
                  <Typography color="primary.main">
                    {t('MODAL.cancel')}
                  </Typography>
                </DrawerButton>
                <DrawerButton
                  color={`${archived ? 'primary' : 'error'}`}
                  onClick={() => {
                    setDeleteAction(true);
                  }}
                  sx={{ height: 46, width: 114 }}
                  variant="contained"
                >
                  <Typography>{t('GENERAL.confirmButton')}</Typography>
                </DrawerButton>
              </>
            </ButtonContainerModal>
          </>
        )}
      </ModalContainer>
    );
  };

  return (
    <Container sx={{ margin: '40px', width: 'auto' }}>
      <Modal
        open
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {getContent()}
      </Modal>
    </Container>
  );
};

ArchiveModal.defaultProps = {
  archived: false,
  cleanRow: () => {},
  setShouldMutate: () => {}
};

export default ArchiveModal;
