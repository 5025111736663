/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  styled,
  SliderProps,
  Modal,
  ButtonProps,
  IconButton,
  Button,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import CloseIcon from '@mui/icons-material/Close';
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import Api from '../../api';
import Loader from '../../components/shared/Loader';
import OpenDetailButton from '../../components/list/OpenDetailButton';
import { ListMainContainer } from '../../components/shared/container/containerParts';
import { ModalContainer } from '../../components/modals/GlobalConfirmModal';
import TableComponentEditable from '../../components/shared/TableComponentEditable';
import DeleteButton from '../../components/list/DeleteButton';
import DeleteModal from '../../components/modals/DeleteModal';
import ArchiveModal from '../../components/modals/ArchiveModal';
import CompanyConfigurationsModal from '../../components/modals/CompanyConfigurationsModal';

const BoxCentered = styled(Box)<SliderProps>(() => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%'
}));

const CloseButton = styled(IconButton)<ButtonProps>(({ theme }) => ({
  color: theme.palette.mainText?.main,
  width: 20
}));

function ButtonIcons(href: string, icon: string) {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <Button
        href={href}
        style={{ minWidth: '45px', padding: 0 }}
        variant="table"
      >
        <Typography variant="primary-bold">
          <i className={`custom-icon icon-${icon}`} />
        </Typography>
      </Button>
    </Box>
  );
}

interface Props {}

const CompanyList: React.FC<Props> = function CompanyList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<any>();
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false);
  const [currentAdmins, setCurrentAdmins] = useState<any>({});
  const [showUsersModal, setShowUsersModal] = useState<boolean>(false);
  const [currentCompany, setCurrentCompany] = useState<any>({});
  const [userImpersonate, setUserImpersonate] = useState<any>(null);
  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);
  const [configItem, setConfigItem] = useState<any>();

  // ========================================================================== SWR
  const getAction = Api.CompanyApi.getCompanies;

  const { data: mainResponse, mutate } = useSWR(
    { url: 'api/company' },
    getAction
  );

  const { data: usersData } = useSWR(
    currentCompany.id,
    Api.ImpersonateApi.getUsersByCompany
  );

  const { data: userImpersonated } = useSWR(
    userImpersonate,
    Api.ImpersonateApi.getTokenImpersonate
  );

  const getCompanyStatus = (item: any) => {
    if (item.archived) {
      return t('COMPANY_LIST.archived');
    }
    if (item.active) {
      return t('COMPANY_LIST.active');
    }
    return t('COMPANY_LIST.inactive');
  };

  // ========================================================================== LIFECYCLE
  useEffect(() => {
    if (mainResponse) {
      setLoading(false);
      if (mainResponse.length > 0) {
        setData(
          mainResponse.map((item: any) => {
            return {
              ...item,
              admins:
                item.admins?.length > 0 ? (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      width: '45px'
                    }}
                  >
                    <OpenDetailButton
                      handleOpenDraw={() => {
                        setShowAdminModal(true);
                        setCurrentAdmins({
                          admins: item.admins.map((item: any) => {
                            return {
                              ...item,
                              mailIcon: item.email
                                ? ButtonIcons(`mailto:${item.email}`, 'email')
                                : null,
                              name: `${item.firstName} ${item.lastName}`,
                              phoneIcon: item.phoneNumber
                                ? ButtonIcons(
                                    `tel:${item.phoneNumber}`,
                                    'classic-phone'
                                  )
                                : null
                            };
                          }),
                          company: item.companyName
                        });
                      }}
                      icon="users"
                    />
                  </Box>
                ) : null,
              config: (
                <Button
                  onClick={() => {
                    setConfigItem(item);
                    setShowConfigModal(true);
                  }}
                  style={{ minWidth: '45px', padding: 0 }}
                  variant="table"
                >
                  <Typography variant="primary-bold">
                    <Tooltip title="Company configurations">
                      <SettingsIcon />
                    </Tooltip>
                  </Typography>
                </Button>
              ),
              delete: (
                <DeleteButton
                  deleteFn={() => {
                    setDeleteItem(item);
                    setShowDeleteModal(true);
                  }}
                  icon={item.archived ? 'unarchived' : 'archived'}
                  tooltip={`${
                    item.archived
                      ? t('TOOLTIP.GENERAL.unarchive')
                      : t('TOOLTIP.GENERAL.archive')
                  }`}
                />
              ),
              description: item.companyDescription,
              impersonate: (
                <Button
                  onClick={() => {
                    setLoading(true);
                    setCurrentCompany({ id: item.id, name: item.companyName });
                  }}
                  style={{ minWidth: '45px', padding: 0 }}
                  variant="table"
                >
                  <Typography variant="primary-bold">
                    <Tooltip title="Impersonate this user">
                      <SafetyDividerIcon />
                    </Tooltip>
                  </Typography>
                </Button>
              ),
              resourcesQuantity: item.usersCount,
              status: getCompanyStatus(item)
            };
          })
        );
      }
    }
  }, [mainResponse]);

  useEffect(() => {
    if (usersData) {
      setLoading(false);
      if (usersData?.length > 0) {
        setCurrentCompany({
          ...currentCompany,
          users: usersData.map((item: any) => {
            return {
              email: item.email,
              impersonate: (
                <Button
                  onClick={() => {
                    setLoading(true);
                    setUserImpersonate(item.id);
                    setShowUsersModal(false);
                  }}
                  style={{ minWidth: '45px', padding: 0 }}
                  variant="table"
                >
                  <Typography variant="primary-bold">
                    <Tooltip title="Impersonate this user">
                      <SafetyDividerIcon />
                    </Tooltip>
                  </Typography>
                </Button>
              ),
              name: `${item.firstName} ${item.lastName}`,
              phoneNumber: item.phoneNumber,
              roles: item?.userRoles?.map(
                (itemRole: { role: { name: string } }) => {
                  return { name: itemRole?.role?.name };
                }
              )
            };
          })
        });
      }
      setShowUsersModal(true);
    }
  }, [usersData]);

  useEffect(() => {
    if (userImpersonated) {
      setLoading(false);
      setUserImpersonate(null);
      if (userImpersonated?.accessToken) {
        navigate('/app/impersonate', {
          state: {
            requestTwoFactor: userImpersonated?.requestTwoFactor,
            token: userImpersonated?.accessToken
          }
        });
      }
    }
  }, [userImpersonated]);

  // ================================================= RENDER

  if (loading) {
    return (
      <BoxCentered>
        <Loader customColor="primary" customSize={48} />
      </BoxCentered>
    );
  }

  return (
    <Box>
      <ListMainContainer>
        <>
          <Box sx={{ mb: '20px', mt: '8px', width: '70%' }}>
            <Typography variant="title">
              {t('COMPANY_LIST.companies')}
            </Typography>
          </Box>
          <TableComponentEditable
            disableAdd
            action={() => {}}
            headKeys={[
              'companyName',
              'description',
              'statusChip',
              'resourcesQuantity',
              'admins',
              'impersonate',
              'config',
              'delete'
            ]}
            onChange={() => {}}
            valuesArray={{ data } as unknown as { data: any[] }}
          />
          {showAdminModal && (
            <Modal
              onClose={() => {
                setShowAdminModal(false);
              }}
              open={showAdminModal}
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <ModalContainer
                sx={{
                  height: 'fit-content',
                  width: 'fit-content'
                }}
              >
                <Box flexDirection="row" marginBottom={4} width="100%">
                  <Typography color="primary" variant="heading_400">
                    {`${t('COMPANY_LIST.titleModalAdmins')} ${
                      currentAdmins.company
                    }`}
                  </Typography>
                  <Box position="absolute" right={20} top={32}>
                    <CloseButton
                      aria-label="menu"
                      color="inherit"
                      onClick={() => {
                        setShowAdminModal(false);
                      }}
                      size="medium"
                    >
                      <CloseIcon />
                    </CloseButton>
                  </Box>
                </Box>
                <Box height={500} overflow="scroll" paddingX={4} width="100%">
                  <TableComponentEditable
                    disableAdd
                    action={() => {}}
                    headKeys={[
                      'name',
                      'email',
                      'mailIcon',
                      'phoneNumber',
                      'phoneIcon'
                    ]}
                    onChange={() => {}}
                    valuesArray={
                      { data: currentAdmins.admins } as unknown as {
                        data: any[];
                      }
                    }
                  />
                </Box>
              </ModalContainer>
            </Modal>
          )}
          {showUsersModal && (
            <Modal
              onClose={() => {
                setShowUsersModal(false);
                setCurrentCompany({});
              }}
              open={showUsersModal}
              sx={{
                alignItems: 'center',
                alignSelf: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <ModalContainer
                className="scrollbar"
                sx={{
                  maxHeight: '90%',
                  width: '80%'
                }}
              >
                <Box flexDirection="row" marginBottom={4} width="80%">
                  <Typography color="primary" variant="heading_400">
                    {`${t('COMPANY_LIST.titleModalUsers')} ${
                      currentCompany.name
                    }`}
                  </Typography>
                  <Box position="absolute" right={20} top={32}>
                    <CloseButton
                      aria-label="menu"
                      color="inherit"
                      onClick={() => {
                        setShowUsersModal(false);
                        setCurrentCompany({});
                      }}
                      size="medium"
                    >
                      <CloseIcon />
                    </CloseButton>
                  </Box>
                </Box>
                <Box paddingX={4} width="100%">
                  {currentCompany.users ? (
                    <TableComponentEditable
                      disableAdd
                      action={() => {}}
                      headKeys={[
                        'name',
                        'email',
                        'phoneNumber',
                        'roles',
                        'impersonate'
                      ]}
                      onChange={() => {}}
                      valuesArray={
                        { data: currentCompany.users } as unknown as {
                          data: any[];
                        }
                      }
                    />
                  ) : (
                    <Typography>
                      {`${t('COMPANY_LIST.noUsersForImpersonate')}`}
                    </Typography>
                  )}
                </Box>
              </ModalContainer>
            </Modal>
          )}
        </>
      </ListMainContainer>
      {showDeleteModal && (
        <ArchiveModal
          action={
            deleteItem.archived
              ? Api.CompanyApi.companyUnarchiveCompany
              : Api.CompanyApi.companyArchiveCompany
          }
          archived={deleteItem.archived}
          id={{ id: deleteItem.id, url: `/archive-company-${deleteItem.id}` }}
          mutate={mutate}
          setShowModal={setShowDeleteModal}
        />
      )}
      {showConfigModal && (
        <CompanyConfigurationsModal
          closeModal={() => {
            setShowConfigModal(false);
            mutate();
          }}
          item={configItem}
          setShowModal={setShowConfigModal}
        />
      )}
    </Box>
  );
};

export default CompanyList;
