/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import useSWR from 'swr';
import dayjs from 'dayjs';
import Api from '../../../../api';
import CardHeader from '../CardHeader';
import ServiceByService from '../ServiceByService';
import CarrouselDates from '../CarrouseDates';
import ServicesSetup from '../ServicesSetup';
import {
  accentColors,
  alertColors,
  mainColors,
  warningColors
} from '../../../../theme/themeConfig';
import ServiceByServiceClientPage from '../ServiceByServiceClientPage';
import useAuthStore from '../../../../store/zustand/auth';

// Función para formatear la cadena de fecha
const formatDateString = (dateString: any) => {
  if (typeof dateString !== 'string') return null;

  const parts = dateString.split('/');
  const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
  return <>{dayjs(formattedDate).format('ddd DD')}</>;
};

function Weekly(props: any) {
  return (
    <Box
      style={{
        backgroundColor: '#F4FBFF',
        borderTop: 'solid #CBCCD3 1px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        marginTop: '-1px',
        width: '100%'
      }}
    >
      {props.days.map(
        (
          index:
            | string
            | {
                date: string;
                status?: {
                  bg: string;
                  bgNormal?: string;
                  txt?: string;
                };
                statusName?: string;
              },
          key: any
        ) => {
          const size = `${100 / props.days.length}%`;
          return (
            <Box
              key={key}
              minWidth={size}
              onClick={() => {
                if (typeof index !== 'string' && index.status) {
                  props.setDay(index.date);
                }
              }}
              style={{
                backgroundColor:
                  typeof index !== 'string' &&
                  index.status &&
                  index.date === props.selected
                    ? index.status.bgNormal
                    : typeof index !== 'string' && index.status
                    ? index.status.bg
                    : 'transparent',
                borderLeft: key !== 0 ? 'solid #CBCCD3 1px' : 'none',
                padding: '10px'
              }}
              width="100%"
            >
              {typeof index !== 'string' && index.date && index.status && (
                <Typography
                  align="left"
                  sx={{
                    color: index.status.txt,
                    fontSize: 12,
                    fontWeight: 700,
                    width: '100%'
                  }}
                >
                  {typeof index.date === 'string' &&
                    formatDateString(index.date)}
                </Typography>
              )}
            </Box>
          );
        }
      )}
    </Box>
  );
}

interface Props {
  count: any;
  data: any;
  dayChecked: string;
  detailProjectId?: any;
  projectType: string;
  type: string;
  typeView: any;
  utcUpadtedAt: string;
}

const CardContainerServices: React.FC<Props> = function CardContainerServices({
  data,
  count,
  type,
  detailProjectId,
  projectType,
  typeView,
  dayChecked,
  utcUpadtedAt
}) {
  const [scheduleDateLocal, setScheduleDateLocal] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(true);
  const [onlyTask, setOnlyTask] = useState<boolean>(false);
  const [dateRangeId, setDateRangeId] = useState<any>(null);
  const [daysList, setDaysList] = useState<any>([]);
  const [daySelected, setDaySelected] = useState<any>();
  const [localDetailProjectId, setLocalDetailProjectId] = useState<any>(null);
  const [calendarState, setCalendarState] = useState<any>(null);
  const [detailInfo, setDetailInfo] = useState<any>({});
  const [daytoSchedule, setDaytoSchedule] = useState<any>(null);
  const userState = useAuthStore((state) => state);
  const location = useLocation();
  const currentUrl = location.pathname;
  const isClientPage = currentUrl.includes('customer-page');

  let generateScheduleAction = Api.ProjectApi.generateSchedule;
  let getDetailByProjectAction = Api.ProjectApi.getDetailByProject;

  if (isClientPage) {
    generateScheduleAction = Api.ClientPageApi.generateSchedule;
    getDetailByProjectAction = Api.ClientPageApi.getDetailByProject;
  }

  const [cardData, seCardData] = useState<any>(null);
  const [taskIterations, setTaskIterations] = useState<any>([]);

  useEffect(() => {
    if (data && (!cardData || cardData.id !== data.id)) {
      seCardData(data);
      // Recorrer los servicios
      const auxTaskIterations: any[] = [];

      data.services.forEach((service: any) => {
        // Recorrer los trabajos (tasks) dentro de cada servicio
        service.tasks.forEach((task: any) => {
          // Verificar si hay iteraciones de tarea (taskIteration) en este trabajo
          if (task.taskIteration && task.taskIteration.length > 0) {
            // Recorrer las iteraciones de tarea (taskIteration) dentro de cada trabajo
            task.taskIteration.forEach((taskIteration: any) => {
              // Agregar el objeto taskIteration al array taskIterations
              auxTaskIterations.push(taskIteration);
            });
          }
        });
      });

      if (projectType === 'Ad-Hoc' && !daySelected) {
        const transfDate = dayjs(data.startDate).format('M/D/YYYY');
        const parts = transfDate?.split('/');
        const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
        setDaySelected(formattedDate);
      } else if (typeView === 'calendar' && dayChecked && !daySelected) {
        setDaySelected(dayChecked);
      }

      setTaskIterations(auxTaskIterations);
    }
  }, [data]);

  useEffect(() => {
    if (
      detailProjectId &&
      (!localDetailProjectId || localDetailProjectId.id !== detailProjectId.id)
    ) {
      setLocalDetailProjectId(detailProjectId);
    }
  }, [detailProjectId]);

  const parts = dayChecked.split('/');

  const formattedDay = `${parts[1]}/${parts[0]}/${parts[2]}`;
  const dayNotFrequency = dayjs(formattedDay).format('ddd DD');

  const { data: scheduleData, error: scheduleDataError } = useSWR(
    count > 0 &&
      cardData &&
      scheduleDateLocal.length === 0 &&
      localDetailProjectId &&
      isOpen === true
      ? [
          cardData?.startDate,
          cardData?.endDate,
          cardData?.frequency,
          cardData?.frequencyWeek,
          cardData?.frequencyDays,
          localDetailProjectId?.id,
          cardData.id
        ]
      : null,
    generateScheduleAction
  );

  useEffect(() => {
    if (scheduleData && !scheduleDataError) {
      setScheduleDateLocal(scheduleData);
    }
  }, [scheduleData]);

  let timePart = '';

  if (typeView === 'calendar') {
    const localDate = dayjs.utc(utcUpadtedAt).local();
    const utcUpadtedCalendar = localDate.format('YYYY-MM-DD HH:mm:ss.SSS');
    // eslint-disable-next-line no-unsafe-optional-chaining
    const timePartInner = utcUpadtedCalendar?.split(' ');
    timePart = timePartInner[1] || '00:00:00';
  }
  const {
    data: detailServicesData,
    error: detailServicesDataError,
    mutate
  } = useSWR(
    daytoSchedule !== null && localDetailProjectId && cardData
      ? [
          localDetailProjectId?.id,
          cardData.id,
          daytoSchedule,
          projectType === 'Ad-Hoc' ? data.startTime : timePart,
          daytoSchedule,
          userState?.timeFormat
        ]
      : null,
    getDetailByProjectAction
  );

  useEffect(() => {
    if (
      detailServicesData &&
      !detailServicesDataError &&
      Object.keys(detailServicesData).length !== 0
    ) {
      setDetailInfo(detailServicesData);
      setDaytoSchedule(null);
      setCalendarState(detailServicesData?.state);
    } else {
      setOnlyTask(true);
    }
  }, [detailServicesData, detailServicesDataError, daySelected, onlyTask]);

  useEffect(() => {
    if (dateRangeId) {
      const daysId = scheduleDateLocal.find(
        (obj: any) => obj.id === dateRangeId
      )?.id;
      if (daysId) {
        const res = scheduleDateLocal.find((d: any) => d.id === daysId);
        if (res) {
          const colorsByStatus = {
            Completed: {
              bg: accentColors.ACCENT_6,
              bgNormal: accentColors.ACCENT_1,
              txt: accentColors.ACCENT
            },
            Created: {
              bg: mainColors.MAIN_LIGHT,
              bgNormal: mainColors.MAIN_1,
              txt: mainColors.MAIN_4
            },
            Started: {
              bg: warningColors.LIGHT_ERROR,
              bgNormal: warningColors.ERROR_1,
              txt: warningColors.ERROR
            }
          };

          const daysWithStatus = res.stateDatesList.map((day: any) => {
            let statusColors;
            switch (day?.state) {
              case 'Completed':
                statusColors = colorsByStatus.Completed;
                break;
              case 'InProgress':
              case 'Started':
                statusColors = colorsByStatus.Started;
                break;
              case 'Awaiting':
              case 'Created':
                statusColors = colorsByStatus.Created;
                break;
              default:
                statusColors = colorsByStatus.Created;
                break;
            }
            return {
              date: day.formatted,
              status: statusColors,
              statusName: day?.state
            };
          });
          setDaysList(daysWithStatus);
        }
      }
    }
  }, [dateRangeId, scheduleDateLocal, detailInfo]);

  useEffect(() => {
    if (daySelected && (!daytoSchedule || daySelected !== daytoSchedule)) {
      setDaytoSchedule(daySelected);
    }
  }, [daySelected]);

  useEffect(() => {
    if (dayChecked && scheduleDateLocal) {
      const matchingDate = scheduleDateLocal.find((date: any) =>
        date.days.includes(dayChecked)
      );
      if (matchingDate) {
        setDateRangeId(matchingDate.id);
      } else {
        const parts = dayChecked.split('/');
        // eslint-disable-next-line radix
        const formattedDay = `${parseInt(parts[1])}/${parseInt(parts[0])}/${
          parts[2]
        }`;
        scheduleDateLocal.some((date: any) => {
          // Usa Array.some() para detener la búsqueda al encontrar la coincidencia
          const [start, end] = date.range.split(' - ');
          if (formattedDay >= start && formattedDay <= end) {
            setDateRangeId(date?.id);
            return true; // Detiene la iteración
          }
          return false; // Continúa la iteración
        });
      }
    }
  }, [dayChecked, scheduleDateLocal]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <Box
      style={{
        backgroundColor: 'white',
        border: 'solid #CBCCD3 1px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        overflow: 'hidden',
        width: '100%'
      }}
    >
      {projectType === 'Regular' && (
        <CardHeader
          componentType="service"
          isOpen={isOpen}
          projectType="projectType"
          propsType={type === 'serviceTime' ? data.frequency : ''}
          setIsOpen={setIsOpen}
          title={`Service Time #${count}`}
        />
      )}

      {isOpen && projectType === 'Regular' && (
        <Box>
          {type === 'serviceTime' && (
            <CarrouselDates
              calendarState={calendarState}
              dates={scheduleDateLocal}
              frequency={data.frequency}
              selected={dateRangeId}
              setDay={setDaySelected}
              setDetailInfo={setDetailInfo}
              setSelectedDayIndex={setDateRangeId}
            />
          )}
          {type === 'serviceTime' && dateRangeId !== null ? (
            <Weekly
              calendarState={detailInfo.state}
              days={daysList}
              selected={daySelected}
              setDay={setDaySelected}
              setDetailInfo={setDetailInfo}
            />
          ) : (
            dateRangeId === null &&
            type === 'serviceTime' && (
              <Typography
                color={`${alertColors.ALERT_2}`}
                fontSize={10}
                sx={{ marginLeft: '15px' }}
                variant="secondary"
              >
                {`${dayNotFrequency}
               it's not configured in the frequency of days `}
              </Typography>
            )
          )}

          {type === 'serviceSetup' && <ServicesSetup data={data} />}
          {type === 'serviceSetup' &&
            data.services.map((service: any, index: number) => (
              <>
                <br />
                {isClientPage ? (
                  <ServiceByServiceClientPage
                    detailInfo={data}
                    detailProjectId={detailProjectId}
                    key={index}
                    mutateAction={mutate}
                    onlyTask={false}
                    projectType=""
                    service={service}
                    servicesInfo={detailInfo?.serviceTime}
                    setOnlyTask={setOnlyTask}
                    type="serviceSetup"
                    typeView=""
                  />
                ) : (
                  <ServiceByService
                    detailInfo={data}
                    detailProjectId={detailProjectId}
                    key={index}
                    mutateAction={mutate}
                    onlyTask={false}
                    projectType=""
                    service={service}
                    servicesInfo={detailInfo?.serviceTime}
                    setOnlyTask={setOnlyTask}
                    type="serviceSetup"
                    typeView=""
                  />
                )}
              </>
            ))}

          {type === 'serviceTime' &&
            dateRangeId !== null &&
            detailInfo &&
            daySelected && (
              <>
                {detailInfo.serviceTime
                  ? detailInfo.serviceTime.services.map(
                      (service: any, key: number) => {
                        return (
                          <>
                            {isClientPage ? (
                              <ServiceByServiceClientPage
                                calendarEvenState={detailInfo.state}
                                detailInfo={{
                                  timezone: data?.timezone || null
                                }}
                                detailProjectId={[]}
                                key={key}
                                mutateAction={mutate}
                                onlyTask={false}
                                projectType={projectType}
                                service={service}
                                servicesInfo={detailInfo?.serviceTime}
                                setOnlyTask={setOnlyTask}
                                type="serviceTime"
                                typeView={typeView}
                              />
                            ) : (
                              <ServiceByService
                                calendarEvenState={detailInfo.state}
                                detailInfo={{
                                  timezone: data?.timezone || null
                                }}
                                detailProjectId={[]}
                                key={key}
                                mutateAction={mutate}
                                onlyTask={false}
                                projectType={projectType}
                                service={service}
                                servicesInfo={detailInfo?.serviceTime}
                                setOnlyTask={setOnlyTask}
                                type="serviceTime"
                                typeView={typeView}
                              />
                            )}
                          </>
                        );
                      }
                    )
                  : data.services.map((service: any) => {
                      return (
                        <>
                          {isClientPage ? (
                            <ServiceByServiceClientPage
                              calendarEvenState={detailInfo.state}
                              detailInfo={detailInfo}
                              detailProjectId={detailProjectId}
                              mutateAction={mutate}
                              onlyTask={onlyTask}
                              projectType={projectType}
                              service={service}
                              servicesInfo={data}
                              setOnlyTask={setOnlyTask}
                              type="serviceTime"
                              typeView={typeView}
                            />
                          ) : (
                            <ServiceByService
                              calendarEvenState={detailInfo.state}
                              detailInfo={detailInfo}
                              detailProjectId={detailProjectId}
                              mutateAction={mutate}
                              onlyTask={onlyTask}
                              projectType={projectType}
                              service={service}
                              servicesInfo={data}
                              setOnlyTask={setOnlyTask}
                              type="serviceTime"
                              typeView={typeView}
                            />
                          )}
                        </>
                      );
                    })}
              </>
            )}
        </Box>
      )}
      {projectType === 'Ad-Hoc' && (
        <Box>
          {detailInfo.serviceTime &&
          detailInfo.serviceTime.services &&
          detailInfo.serviceTime.services.length > 0
            ? detailInfo.serviceTime.services.map(
                (service: any, key: number) => (
                  <>
                    {isClientPage ? (
                      <ServiceByServiceClientPage
                        detailInfo={{}}
                        detailProjectId={[]}
                        key={key}
                        mutateAction={mutate}
                        onlyTask={false}
                        projectType={projectType}
                        service={service}
                        servicesInfo={detailInfo.serviceTime}
                        setOnlyTask={setOnlyTask}
                        type="serviceTime"
                        typeView={typeView}
                      />
                    ) : (
                      <ServiceByService
                        detailInfo={{}}
                        detailProjectId={[]}
                        key={key}
                        mutateAction={mutate}
                        onlyTask={false}
                        projectType={projectType}
                        service={service}
                        servicesInfo={detailInfo.serviceTime}
                        setOnlyTask={setOnlyTask}
                        type="serviceTime"
                        typeView={typeView}
                      />
                    )}
                  </>
                )
              )
            : data.services.map((service: any, key: number) => (
                <>
                  {isClientPage ? (
                    <ServiceByServiceClientPage
                      calendarEvenState={detailInfo.state}
                      detailInfo={detailInfo}
                      detailProjectId={detailProjectId}
                      key={key}
                      mutateAction={mutate}
                      onlyTask={onlyTask}
                      projectType={projectType}
                      service={service}
                      servicesInfo={detailInfo?.serviceTime}
                      setOnlyTask={setOnlyTask}
                      type="serviceTime"
                      typeView={typeView}
                    />
                  ) : (
                    <ServiceByService
                      calendarEvenState={detailInfo.state}
                      detailInfo={detailInfo}
                      detailProjectId={detailProjectId}
                      key={key}
                      mutateAction={mutate}
                      onlyTask={onlyTask}
                      projectType={projectType}
                      service={service}
                      servicesInfo={data}
                      setOnlyTask={setOnlyTask}
                      type="serviceTime"
                      typeView={typeView}
                    />
                  )}
                </>
              ))}
        </Box>
      )}
    </Box>
  );
};
export default CardContainerServices;
CardContainerServices.defaultProps = {
  detailProjectId: undefined
};
