/* eslint-disable import/prefer-default-export */
import {
  CommunicationMethodPropertiesDTOTypeEnum,
  CreateClientDTORelationshipEnum,
  CreateClientDTOStatusEnum,
  CreateClientDTOTypeEnum
} from '../api-client/generated';

export const customerType = [
  CreateClientDTOTypeEnum.Client,
  CreateClientDTOTypeEnum.Contact,
  CreateClientDTOTypeEnum.Prospect
];

export const customerRelationship = [
  CreateClientDTORelationshipEnum.B2B,
  CreateClientDTORelationshipEnum.B2C
];

export const customerStatus = [
  CreateClientDTOStatusEnum.Active,
  CreateClientDTOStatusEnum.Inactive
];

export const customerCommunicationMethods = [
  CommunicationMethodPropertiesDTOTypeEnum.Email,
  CommunicationMethodPropertiesDTOTypeEnum.Fax,
  CommunicationMethodPropertiesDTOTypeEnum.Phone,
  CommunicationMethodPropertiesDTOTypeEnum.WebAddress
];

export const calculateCanDelete = (extraData: any) => {
  if (!extraData) return false;
  return (
    Object.values(extraData.lead).every((val) => val === '0') &&
    Object.values(extraData.project).every((val) => val === '0') &&
    Object.values(extraData.proposal).every((val) => val === '0')
  );
};
