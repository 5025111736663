import React, { useEffect, useRef, useState } from 'react';
import '../../../style/App.scss';
import { Container, Typography, Box, Button } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import {
  FormLabel,
  WhiteBoxContainer,
  HeaderFormContainer,
  TitleFormBox
} from '../../../components/form/parts';
import RegularInput from '../../../components/input/RegularInput';
import { FormMainContainer } from '../../../components/shared/container/containerParts';
import api from '../../../api';
import ListingPage from '../../../components/list/ListingPage';
import EditButtonList from '../../../components/list/EditButton';
import SelecetClientModal from '../../../components/modals/SelectClientModal';

interface FormValues {
  clientId: string;
  clientSecret: string;
  webhookSecret: string;
}

function IntegrationForm() {
  const [formValues, setFormValues] = useState<FormValues>({
    clientId: '',
    clientSecret: '',
    webhookSecret: ''
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [customerSelected, setCustomerSelected] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const [fireAPIGetCustomer, setFireAPIGetCustomer] = useState<boolean>(false);

  const [fireAPIUpdateClient, setFireAPIUpdateClient] =
    useState<boolean>(false);

  const [fireAPIUpdateWebhookSecret, setFireAPIWebhookSecret] =
    useState<boolean>(false);

  const [fireAPIDeleteClient, setFireAPIDeleteClient] =
    useState<boolean>(false);

  const [fireAPISynchAccount, setFireAPISynchAccount] =
    useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: params } = useLocation();

  const savedInterval = useRef();

  const { data: dataUpdateClient, error: updateClientError } = useSWR(
    fireAPIUpdateClient ? formValues : null,
    api.IntegrationsApi.postConnectAccount
  );

  const { data: dataDeleteClient, error: deleteClientError } = useSWR(
    fireAPIDeleteClient ? formValues : null,
    api.IntegrationsApi.deleteIntegrations
  );

  const { data: dataUpdateWebhookSecret, error: dataUpdateWebhookSecretError } =
    useSWR(
      fireAPIUpdateWebhookSecret ? formValues : null,
      api.IntegrationsApi.postUpdateWebhook
    );

  const [isWindowClosed, setIsWindowClosed] = useState(false);
  const newWindowRef = useRef(null);

  // const { data: dataGetClient, error: dataClientError } = useSWR(
  //   'getClients',
  //   api.ClientApi.getClients
  // );

  // const {
  //   data: synchCustomer,
  //   error: synchCustomerError,
  //   mutate: synchCustomerMutate
  // } = useSWR(
  //   fireAPISynchAccount ? formValues : null,
  //   api.IntegrationsApi.postSynchCustomer
  // );

  const {
    data: getCustomers,
    error: getCustomersError,
    mutate: getCustomersMutate
  } = useSWR(fireAPIGetCustomer ? {} : null, api.IntegrationsApi.getCustomers);

  const {
    data: dataSynchAccount,
    error: dataSynchAccountError,
    mutate: dataSynchAccountMutate
  } = useSWR(
    fireAPISynchAccount ? formValues : null,
    api.IntegrationsApi.postSynchAccount
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // Handle form submission here
  };

  const onChange = (name: string, value: string | any[]) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleOpen = () => {
    getCustomersMutate();
  };

  const handleClose = () => {
    setCustomerSelected(false);
    setOpenModal(false);
  };

  const handleSetCustomerSelected = (item: any) => {
    setCustomerSelected(item);
  };

  const isEditActive = true;
  const FIELDS = [
    {
      label: 'clientId',
      name: 'clientId',
      pattern: /^[A-Za-z.\s_-]+$/,
      required: true,
      type: 'text',
      validationLabel: 'clientId is required'
    },
    {
      label: 'clientSecret',
      name: 'clientSecret',
      pattern: /^[A-Za-z.\s_-]+$/,
      required: true,
      type: 'text',
      validationLabel: 'clientSecret is required'
    }
  ];

  const errorFields: any = [];

  const handleConnect = () => {
    if (params.status === 'connected') {
      setFireAPIDeleteClient(true);
    } else {
      setFireAPIUpdateClient(true);
    }
  };

  const handleWebhookUpdate = () => {
    setFireAPIWebhookSecret(true);
  };

  const handleSynch = () => {
    setFireAPISynchAccount(true);
  };

  const getSelectedCustomer = () => {
    return customerSelected;
  };

  const handleGetCustomers = () => {
    getCustomersMutate();
    setFireAPIGetCustomer(true);
  };

  useEffect(() => {
    if (dataDeleteClient) {
      navigate(params.lastPage, { state: params });
    }
  }, [dataDeleteClient, fireAPIDeleteClient]);

  const isItemLoaded = (index: any) =>
    getCustomers ? !!getCustomers[index] : false;

  useEffect(() => {
    if (dataUpdateClient) {
      const parameters = 'location=1,width=800,height=650';
      // @ts-ignore
      // parameters += ",left=" + (screen.width - 800) / 2 + ",top=" + (screen.height - 650) / 2;
      newWindowRef.current = window.open(
        dataUpdateClient,
        'connectPopup',
        parameters
      ) as Window | null;

      const checkWindowClosed = setInterval(() => {
        // @ts-ignore
        if (newWindowRef.current && newWindowRef.current.closed) {
          clearInterval(checkWindowClosed);
          setIsWindowClosed(true);
          newWindowRef.current = null;
          navigate('/app/company-settings-integration', {
            state: { ...params, ...formValues, status: 'connected' }
          });
        }
      }, 1000); // Check every second
    }
  }, [dataUpdateClient]);

  useEffect(() => {
    // @ts-ignore
    if (getCustomers && getCustomers.length > 0) {
      const data: any = getCustomers;
      const dataResponse = data.map((item: any) => {
        return {
          ...item,
          edit: (
            <EditButtonList
              handleOpenDraw={() => {
                handleSetCustomerSelected(item);
                setOpenModal(true);
                getCustomersMutate();
                // navigate(`/app/clients/edit/${item.id}`);
              }}
            />
          )
        };
      });
      setData(dataResponse);
    }
  }, [getCustomers]);

  useEffect(() => {
    setFireAPIGetCustomer(true);
  }, []);

  useEffect(() => {
    if (dataSynchAccount) {
      getCustomersMutate();
    }
  }, [dataSynchAccount]);

  return (
    <Box>
      <FormMainContainer isMobile={isMobile}>
        <Container>
          <Box>
            <HeaderFormContainer isMobile={isMobile}>
              {/* ---------------- Header ---------------- */}
              <Box display="flex" flexDirection="row">
                {params?.lastPage && (
                  <Button
                    onClick={() => {
                      navigate(params.lastPage, { state: params });
                    }}
                  >
                    <i className="custom-icon icon-arrow-left"> </i>
                  </Button>
                )}
                <TitleFormBox isMobile={isMobile}>
                  <Typography sx={{ marginBottom: '4px' }} variant="title">
                    {/* {t('CUSTOMER.createClient')} */}
                    QuickBooks Integration
                  </Typography>
                </TitleFormBox>
              </Box>
            </HeaderFormContainer>
          </Box>

          <Box>
            <WhiteBoxContainer isMobile={isMobile}>
              <Typography color="mainText.main" variant="secondary">
                {/* {t('COMPANY_SETTINGS.remindersTitle')} */}
                Integration Credentials
              </Typography>

              <Box
                alignItems="center"
                display="flex"
                gap="16px"
                marginTop="16px"
              >
                <Box width="100%">
                  <FormLabel
                    error={errorFields.includes('invoices')}
                    // label={t('COMPANY_SETTINGS.invoices')}
                    label="Client ID"
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('invoices')}
                    onChange={(inputValue) => onChange('clientId', inputValue)}
                    type="text"
                    value={`${params.clientId || ''}`}
                  />
                </Box>
                <Box width="95%">
                  <FormLabel
                    error={errorFields.includes('upcomingProjects')}
                    // label={t('COMPANY_SETTINGS.upcomingProjects')}
                    label="Client Secret"
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('upcomingProjects')}
                    onChange={(inputValue) =>
                      onChange('clientSecret', inputValue)
                    }
                    type="text"
                    value={`${params.clientSecret || ''}`}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  alignItems: 'center',
                  display: isMobile ? 'block' : 'flex',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={() => handleConnect()}
                  sx={{
                    lineHeight: 'normal',
                    marginLeft: '10px',
                    width: '22%'
                  }}
                  // variant='cancel'
                  variant={`${
                    params.status === 'connected' ? 'cancel' : 'primary'
                  }`}
                >
                  {/* {t('CUSTOMER.addClientButton')} */}
                  {params.status === 'connected' ? 'Disconnect' : 'Connect'}
                </Button>
                {params.status === 'connected' && (
                  <Button
                    onClick={() => handleSynch()}
                    sx={{
                      lineHeight: 'normal',
                      marginLeft: '10px',
                      width: '22%'
                    }}
                    // variant='cancel'
                    variant="primary"
                  >
                    {/* {t('CUSTOMER.addClientButton')} */}
                    {/* {params.status === 'connected' ? 'Disconnect' : 'Connect'} */}
                    Synch
                  </Button>
                )}
              </Box>
            </WhiteBoxContainer>
          </Box>

          <Box>
            <WhiteBoxContainer isMobile={isMobile}>
              <Typography color="mainText.main" variant="secondary">
                {/* {t('COMPANY_SETTINGS.remindersTitle')} */}
                Webhoook
              </Typography>

              <Box
                alignItems="center"
                display="flex"
                gap="16px"
                marginTop="16px"
              >
                <Box width="100%">
                  <FormLabel
                    error={errorFields.includes('invoices')}
                    // label={t('COMPANY_SETTINGS.invoices')}
                    label="Webhook Secret"
                  />
                  <RegularInput
                    customStyle={{ width: '100%' }}
                    disabled={!isEditActive}
                    error={errorFields.includes('invoices')}
                    onChange={(inputValue) =>
                      onChange('webhookSecret', inputValue)
                    }
                    type="text"
                    value={`${params.webhookSecret || ''}`}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  alignItems: 'center',
                  display: isMobile ? 'block' : 'flex',
                  marginTop: '10px'
                }}
              >
                <Button
                  onClick={() => handleWebhookUpdate()}
                  sx={{
                    lineHeight: 'normal',
                    marginLeft: '10px',
                    width: '22%'
                  }}
                  // variant='cancel'
                  variant="secondary"
                >
                  {/* {t('CUSTOMER.addClientButton')} */}
                  Update Webhook
                </Button>
              </Box>
            </WhiteBoxContainer>
          </Box>
        </Container>
      </FormMainContainer>

      <Box>
        <Box>
          <WhiteBoxContainer
            isMobile={isMobile}
            sx={{
              width: '100%'
            }}
          >
            <Typography color="mainText.main" variant="secondary">
              {/* {t('COMPANY_SETTINGS.remindersTitle')} */}
              {/* Customers */}
            </Typography>

            <Box sx={{ width: '100%' }}>
              <Box>
                <ListingPage
                  data={data}
                  handleOpen={handleOpen}
                  // handleOpen={handleOpen}
                  // handleOpen={() => {}}
                  handleOpenDraw={() => {}}
                  headKeys={[
                    'customerDisplayName',
                    'customerEmail',
                    'clientName',
                    // 'phone',
                    'edit'
                    // 'delete'
                  ]}
                  isItemLoaded={isItemLoaded}
                  // itemCount={mainResponse?.pagination?.totalItems || 0}
                  itemCount={0}
                  // loadMoreItems={loadMoreItems}
                  loadMoreItems={() => {}}
                  nameButton="refresh"
                  setValueDrawer={() => {}}
                  title="customers"
                >
                  <Box>
                    {openModal && (
                      <SelecetClientModal
                        action={api.CompanyInvitationApi.sendInvitation}
                        // clients={dataGetClient || []}
                        getSelectedCustomer={getSelectedCustomer}
                        handleClose={handleClose}
                        mutateAction={handleGetCustomers}
                        openValue={openModal}
                        type="company"
                      />
                    )}
                  </Box>
                </ListingPage>
              </Box>
            </Box>
          </WhiteBoxContainer>
        </Box>
      </Box>
    </Box>
  );
}

export default IntegrationForm;
