/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Avatar, Tooltip, Typography } from '@mui/material';
import useSWR from 'swr';
import api from '../api';
import CustomTooltip from './shared/CustomTooltip';
import { theme } from '../theme/themeConfig';
import { resourseColors } from '../utils/general';

const SPACINGS = {
  large: '-2px',
  medium: '-6px',
  small: '-10px'
};

export interface User {
  firstName?: string;
  id: string;
  lastName?: string;
  name?: string;
}

interface UserPhoto {
  photo: string | null;
  user: User;
}

interface Props {
  max: number;
  sizeAvatar?: number;
  spacing?: 'medium' | 'small' | 'large';
  users: User[];
}

function ListExtraAvatars({ names }: { names: string[] }) {
  return (
    <Box display="flex" flexDirection="column" padding="0px 10px 10px 10px">
      {names.map((item: string) => (
        <Typography fontSize={14} variant="body1">
          {item}
        </Typography>
      ))}
    </Box>
  );
}

const AvatarGroup: React.FC<Props> = function AvatarGroup({
  max,
  users,
  spacing = 'medium',
  sizeAvatar = 40
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleAvatars, setVisibleAvatars] = useState(max);
  const [userPhotos, setUserPhotos] = useState<UserPhoto[]>([]);
  const [fireApi, setFireApi] = useState<boolean>(true);

  const { data: photoResponse } = useSWR(
    fireApi ? { url: 'photo/profile', users } : null,
    api.StorageApi.getProfilePhotoByIDS
  );

  useEffect(() => {
    if (photoResponse) {
      setFireApi(false);
      setUserPhotos(photoResponse);
    }
  }, [photoResponse]);

  const updateVisibleAvatars = useCallback(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const avatarSize = sizeAvatar;
    const maxAvatars = Math.floor(containerWidth / avatarSize);
    setVisibleAvatars(Math.min(maxAvatars, max));
  }, [max, sizeAvatar]);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const observer = new ResizeObserver(updateVisibleAvatars);
    observer.observe(element);

    return () => observer.unobserve(element);
  }, [containerRef, updateVisibleAvatars]);

  const clampedMax = Math.max(visibleAvatars, 2);
  const extraAvatars = Math.max(users.length - clampedMax, 0);
  const extraAvatarNames = users
    .slice(clampedMax)
    .map((user) => user.name || `${user.firstName} ${user.lastName}`.trim());

  const marginLeft = SPACINGS[spacing];

  const getAvatarContent = (
    user: User,
    index: number,
    photo: string | null
  ) => {
    const userName = user.name || `${user.firstName} ${user.lastName}`.trim();
    const initials = userName
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word[0]?.toUpperCase())
      .join('');

    return (
      <Tooltip arrow key={user.id} title={userName}>
        <Avatar
          alt={userName}
          src={photo || undefined}
          sx={{
            backgroundColor: resourseColors[index],
            cursor: 'pointer',
            height: sizeAvatar,
            marginLeft: index === 0 ? undefined : marginLeft,
            width: sizeAvatar,
            zIndex: users.length - index
          }}
        >
          {!photo && (
            <Typography sx={{ color: 'white' }} variant="body_300">
              {initials}
            </Typography>
          )}
        </Avatar>
      </Tooltip>
    );
  };

  return (
    <Box display="flex" flexDirection="row" ref={containerRef} width="100%">
      {userPhotos
        .slice(0, clampedMax)
        .map(({ user, photo }, index) => getAvatarContent(user, index, photo))}
      {extraAvatars > 0 && (
        <CustomTooltip
          backgroundColor={theme.palette.grey[700]}
          borderColor={theme.palette.grey[700]}
          component={<ListExtraAvatars names={extraAvatarNames} />}
        >
          <Avatar
            style={{
              backgroundColor: resourseColors[clampedMax],
              height: sizeAvatar,
              marginLeft,
              width: sizeAvatar,
              zIndex: 0
            }}
          >
            +{extraAvatars}
          </Avatar>
        </CustomTooltip>
      )}
    </Box>
  );
};

export default AvatarGroup;
