import React, { ReactElement } from 'react';
import { Box, styled, useTheme } from '@mui/system';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';

interface Props {
  backgroundColor?: string | number;
  borderColor?: string | number;
  borderHeight?: string;
  children: ReactElement<any, any>;
  component?: React.ReactNode;
  icon?: React.ReactNode;
  subtitle?: string;
  subtitleColor?: string;
  title?: string;
  titleColor?: string;
  width?: number | string;
  withoutBorder?: boolean;
}

const CustomTooltip: React.FC<Props> = function CustomTooltip({
  width,
  children,
  component,
  borderColor,
  backgroundColor,
  titleColor,
  title,
  subtitle,
  subtitleColor,
  withoutBorder,
  borderHeight,
  icon,
  ...props
}) {
  const theme = useTheme();
  const bgColor = backgroundColor || theme.palette.surface[100];
  const bColor = withoutBorder
    ? bgColor
    : borderColor || theme.palette.primary.main;
  const tColor = titleColor || theme.palette.primary.main;
  const stColor = subtitleColor || theme.palette.secondary.main;
  const bHeight = borderHeight || '10px';

  return (
    <Tooltip
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            '& .MuiTooltip-arrow': {
              color: bColor
            },
            bgcolor: bColor,
            border: `1px solid ${bColor}`,
            padding: 0,
            paddingBottom: 0,
            width
          }
        }
      }}
      title={
        <Box component="span" width={width}>
          <Box
            height={bHeight}
            sx={{
              backgroundColor: bColor,
              borderRadius: '10px'
            }}
            width="100%"
          />
          <Box
            border={`1px solid ${bColor}`}
            height="100%"
            sx={{ backgroundColor: bgColor, borderRadius: '4px' }}
            width="100%"
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              {icon && icon}
              {title && (
                <Typography
                  color={tColor}
                  mb={1}
                  mt={1}
                  mx={2}
                  variant="heading_400"
                >
                  {title}
                </Typography>
              )}
            </Box>
            {subtitle && (
              <Box alignItems="center" display="flex" justifyContent="center">
                <Typography color={stColor} mb={1} variant="body_100">
                  {subtitle}
                </Typography>
              </Box>
            )}
            {component && component}
          </Box>
        </Box>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.defaultProps = {
  backgroundColor: undefined,
  borderColor: undefined,
  borderHeight: undefined,
  component: undefined,
  icon: undefined,
  subtitle: undefined,
  subtitleColor: undefined,
  title: undefined,
  titleColor: undefined,
  width: 200,
  withoutBorder: false
};

export default CustomTooltip;
