import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  AUTHORIZED_VIEW_NAMES,
  DEFAULT_SCREENS,
  GLOBAL
} from '../helpers/routesConstants';
import AuthProcess from './AuthProcess';
import AuthByRole from '../routes/AuthByRole';
import { ROLES } from '../helpers/roles';
import ChatsView from './chats';
import ProjectsView from './projects';
import CompanySettingsPage from './companySettings/CompanySettingsPage';
import Companies from './companies';
import CalendarView from './calendar/CalendarView';
import CalendarTechnicianView from './calendar/CalendarTechnicianView';
import Timesheet from './timesheet/Timesheet';
import TimesheetTechnician from './timesheet/TimesheetTechnician';
import Leads from './leads';
import ScreenLoader from '../components/shared/ScreenLoader';
import useAuthStore from '../store/zustand/auth';
import AssignmentsView from './assignments/AssignmentsView';
import CompanyIntegrationPage from './integrations/CompanyIntegrationPage';
import AdminProcess from './AdminProcess';
import ImportClients from './clients/ImportClients';

const Catalog = lazy(() => import('./catalog'));
const Resources = lazy(() => import('./resources'));
const Clients = lazy(() => import('./clients'));
const DashboardView = lazy(() => import('./dashboard'));
const AdvancedDashboardView = lazy(() => import('./advancedDashboards'));
const LandingView = lazy(() => import('./landing'));
const ProfileView = lazy(() => import('./profile'));
const CompanyOnboarding = lazy(() => import('./companyOnboarding'));

function AppContainer() {
  const userState = useAuthStore((state) => state);
  const userRoles = [...userState.roles];

  const advancedDashboard = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <AdvancedDashboardView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.ADVANCED_DASHBOARDS}
    />
  );

  const dashboard = () => (
    <Route
      element={
        <AuthByRole allowedRoles={[ROLES.OPERATIONS, ROLES.TECHNICIAN]} />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <DashboardView />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.DASHBOARD}
      />
    </Route>
  );

  const landing = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <LandingView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.LANDING}
    />
  );

  const profile = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <ProfileView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.PROFILE}
    />
  );

  const resources = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.ADMINISTRATOR,
            ROLES.OPERATIONS,
            ROLES.SUPER_USER
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Resources />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.RESOURCES}
      />
    </Route>
  );

  const catalog = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.OPERATIONS,
            ROLES.SUPER_USER,
            ROLES.ADMINISTRATOR,
            ROLES.SALES
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Catalog />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.CATALOG}
      />
    </Route>
  );

  const company = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.SUPER_USER]} />}>
      <Route element={<Companies />} path={AUTHORIZED_VIEW_NAMES.COMPANIES} />
    </Route>
  );

  const companySettings = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.ADMINISTRATOR]} />}>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <CompanySettingsPage />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.COMPANY_SETTINGS}
      />
    </Route>
  );

  const companySettingsIntegration = () => (
    <Route element={<AuthByRole allowedRoles={[ROLES.ADMINISTRATOR]} />}>
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <CompanyIntegrationPage />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.COMPANY_SETTINGS_INTEGRATION}
      />
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <ImportClients />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.IMPORT_CUSTOMERS}
      />
    </Route>
  );

  const clients = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.OPERATIONS,
            ROLES.BUSINESS_DEVELOPMENT,
            ROLES.SALES,
            ROLES.FINANCE
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Clients />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.CUSTOMERS}
      />
    </Route>
  );

  const leads = () => (
    <Route
      element={<AuthByRole allowedRoles={[ROLES.OPERATIONS, ROLES.SALES]} />}
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <Leads />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.LEADS}
      />
    </Route>
  );

  const chats = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <ChatsView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.CHATS}
    />
  );

  const calendar = () => (
    <Route
      element={
        <AuthByRole allowedRoles={[ROLES.OPERATIONS, ROLES.TECHNICIAN]} />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            {isMobile ? <CalendarTechnicianView /> : <CalendarView />}
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.CALENDAR}
      />
    </Route>
  );

  const projects = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[
            ROLES.OPERATIONS,
            ROLES.FINANCE,
            ROLES.SUPERVISOR,
            ROLES.CUSTOMER
          ]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            <ProjectsView />
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.PROJECTS}
      />
    </Route>
  );

  const timesheet = () => (
    <Route
      element={
        <AuthByRole
          allowedRoles={[ROLES.OPERATIONS, ROLES.TECHNICIAN, ROLES.FINANCE]}
        />
      }
    >
      <Route
        element={
          <Suspense fallback={<ScreenLoader />}>
            {!userRoles.includes(ROLES.TECHNICIAN) ? (
              <Timesheet />
            ) : (
              <TimesheetTechnician />
            )}
          </Suspense>
        }
        path={AUTHORIZED_VIEW_NAMES.TIMESHEET}
      />
    </Route>
  );

  const assignments = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <AssignmentsView />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.ASSIGNMENTS}
    />
  );

  const companyOnboarding = () => (
    <Route
      element={
        <Suspense fallback={<ScreenLoader />}>
          <CompanyOnboarding />
        </Suspense>
      }
      path={AUTHORIZED_VIEW_NAMES.COMPANY_ONBOARDING}
    />
  );

  return (
    <Routes>
      <Route
        element={<Navigate to={DEFAULT_SCREENS.LANDING} />}
        path={AUTHORIZED_VIEW_NAMES.INVALID}
      />
      {dashboard()}
      {advancedDashboard()}
      {landing()}
      {profile()}
      {resources()}
      {company()}
      {companySettings()}
      {companySettingsIntegration()}
      {catalog()}
      {clients()}
      {leads()}
      {calendar()}
      {chats()}
      {projects()}
      {timesheet()}
      {assignments()}
      {companyOnboarding()}

      <Route
        element={<AdminProcess />}
        path={AUTHORIZED_VIEW_NAMES.ADMIN_PROCESS}
      />

      {/* --- AUTH PROCESS ---- */}
      {/* -- CHANGE PASSWORD -- */}
      <Route
        element={<AuthProcess startProcess="changePassword" />}
        path={GLOBAL.CHANGE_PASSWORD}
      />
      {/* -- RESET PASSWORD -- */}
      <Route
        element={<AuthProcess startProcess="resetPassword" />}
        path={GLOBAL.RESET_PASSWORD}
      >
        <Route
          element={<AuthProcess startProcess="resetPassword" />}
          path=":token"
        />
      </Route>
      {/* -- FORGOT PASSWORD -- */}
      <Route
        element={<AuthProcess startProcess="forgotPassword" />}
        path={GLOBAL.FORGOT_PASSWORD}
      >
        <Route
          element={<AuthProcess startProcess="forgotPassword" />}
          path=":token"
        />
      </Route>
      <Route
        element={<AuthProcess startProcess="impersonate" />}
        path={GLOBAL.IMPERSONATE}
      />
    </Routes>
  );
}

export default AppContainer;
