/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { useLocation, useParams } from 'react-router-dom';
import ClientForm from '../../components/client/ClientForm';
import InvoiceInformation from '../../components/client/InvoiceInformation';
import '../../style/App.scss';
import { FormMainContainer } from '../../components/shared/container/containerParts';
import useUserRole from '../../hooks/useUserRoles';
import { ROLES } from '../../helpers/roles';
import useAuthStore from '../../store/zustand/auth';
import NotesClient from '../../components/client/Notes';

interface TabPanelProps {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    'aria-controls': `simple-tabpanel-${index}`,
    id: `simple-tab-${index}`
  };
}

const EditClient = function EditClient() {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const isBusinessDevelopment = useUserRole([ROLES.BUSINESS_DEVELOPMENT]);
  const { clientId } = useParams();
  const isClient = useUserRole([ROLES.CUSTOMER]);
  const { id: profileId } = useAuthStore((state) => state);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <FormMainContainer isMobile={isMobile}>
      <Tabs
        aria-label="basic tabs example"
        onChange={handleChange}
        sx={{ marginBottom: '-8px', marginTop: '-8px' }}
        value={value}
      >
        <Tab
          label={t('CUSTOMER.customerInformation')}
          {...a11yProps(0)}
          value={0}
        />
        {!isClient && !isBusinessDevelopment && (
          <Tab
            label={t('CUSTOMER.invoiceInformation')}
            {...a11yProps(1)}
            value={1}
          />
        )}
        {!isClient && <Tab label="Notes" {...a11yProps(2)} value={2} />}
      </Tabs>
      <TabPanel index={0} value={value}>
        <ClientForm
          clientId={isClient ? profileId : clientId}
          isClient={isClient}
          type="summary"
        />
      </TabPanel>
      <TabPanel index={1} value={value}>
        {clientId && <InvoiceInformation id={clientId} />}
      </TabPanel>
      <TabPanel index={2} value={value}>
        {clientId && <NotesClient id={clientId} />}
      </TabPanel>
    </FormMainContainer>
  );
};

export default EditClient;
