import React, { useEffect, useState } from 'react';
import '../../style/App.scss';
import { Box, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import dayjs from 'dayjs';
import useNavigationStore from '../../store/zustand/navigation';
import Api from '../../api';
import Loader from '../../components/shared/Loader';
import { capitalizeString, formatDate } from '../../utils/general';
import TableComponentEditable from '../../components/shared/TableComponentEditable';
import OpenDetailButton from '../../components/list/OpenDetailButton';

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  justifyContent: 'left',
  padding: theme.spacing(4),
  width: '100%'
}));

const ProposalsClient: React.FC = function ProposalsClient() {
  const navigation = useNavigationStore((state) => state);
  const { t } = useTranslation();
  const { palette } = useTheme();

  // ====================================================== VARS

  const [proposalApi, setProposalApi] = useState<boolean>(false);
  const [proposalList, setProposalList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // ====================================================== API
  const { data: proposalData, error: proposalError } = useSWR(
    proposalApi ? { url: 'client-view/get-proposals' } : null,
    Api.ClientApi.getProposals
  );

  // ====================================================== FUNCTIONS

  // ====================================================== LIFECYCLE
  useEffect(() => {
    if (proposalData) {
      setProposalList(proposalData);
      setProposalApi(false);
      setLoading(false);
    }
  }, [proposalData]);

  useEffect(() => {
    if (proposalError) {
      setProposalApi(false);
    }
  }, [proposalError]);

  useEffect(() => {
    navigation.setCurrentStepScreen('client_proposals');
    setLoading(true);
    setProposalApi(true);
  }, []);

  if (loading) {
    return <Loader customColor="primary" customSize={48} />;
  }

  return (
    <MainContainer>
      <Typography color="primary" fontSize={32} variant="heading_400">
        {t('CUSTOMER.PROPOSAL.title')}
      </Typography>
      <Typography color="secondary" variant="primary">
        {t('CUSTOMER.PROPOSAL.subtitle')}
      </Typography>
      <TableComponentEditable
        disableAdd
        action={() => {}}
        handleOpenDraw={() => {}}
        headKeys={[
          'name',
          'type',
          'projectDuration',
          'allowCounterOffers',
          'status',
          'showDetail'
        ]}
        onChange={() => {}}
        valuesArray={{
          data:
            proposalList?.length > 0
              ? proposalList.map((proposal: any) => {
                  return {
                    allowCounterOffers: `${
                      proposal?.allowCounterOffers
                        ? t('GENERAL.yes')
                        : t('GENERAL.no')
                    }`,
                    name: proposal?.lead?.name || 'N/A',
                    projectDuration: `${formatDate(
                      proposal?.lead?.projectStartDate
                    )} - ${formatDate(proposal?.lead?.projectEndDate)}`,
                    showDetail: (
                      <OpenDetailButton
                        handleOpenDraw={() => {
                          const newURL = `/customer-proposal/${proposal?.token}`;
                          window.open(
                            newURL,
                            '_blank',
                            'rel=noopener noreferrer'
                          );
                        }}
                        icon="project"
                        tooltip={`${t('TOOLTIP.PROPOSAL.openDetailButton')}`}
                      />
                    ),
                    status: (
                      <Typography
                        style={{
                          color: palette.secondary.main,
                          display: 'block',
                          fontSize: '13px',
                          marginLeft: '-10px'
                        }}
                        variant="primary-bold"
                      >
                        {capitalizeString(
                          `${proposal.status} - ${dayjs(
                            proposal.updatedAt
                          ).format('MMM DD [at] h:mma')}`
                        )}
                      </Typography>
                    ),
                    type:
                      proposal?.lead?.projectType === 'Regular'
                        ? t('LEAD.CREATE.GENERAL_INFORMATION.regular')
                        : t('LEAD.CREATE.GENERAL_INFORMATION.adhoc')
                  };
                })
              : []
        }}
      />
    </MainContainer>
  );
};

export default ProposalsClient;
