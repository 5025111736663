import React, { useEffect, useState } from 'react';
import '../../style/App.scss';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useNavigationStore from '../../store/zustand/navigation';
import Api from '../../api';
import Loader from '../../components/shared/Loader';
import { capitalizeString, formatDate, getCurrency } from '../../utils/general';
import TableComponentEditable from '../../components/shared/TableComponentEditable';
import OpenDetailButton from '../../components/list/OpenDetailButton';
import { alertColors, successColors } from '../../theme/themeConfig';

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  justifyContent: 'left',
  padding: theme.spacing(4),
  width: '100%'
}));

const InvoicesClient: React.FC = function InvoicesClient() {
  const { setConfirmModal, setCurrentStepScreen } = useNavigationStore(
    (state) => state
  );
  const { t } = useTranslation();
  const { palette } = useTheme();

  // ====================================================== VARS

  const [invoiceApi, setInvoiceApi] = useState<boolean>(false);
  const [projectInvoiceApi, setProjectInvoiceApi] = useState<boolean>(false);
  const [projectApi, setProjectApi] = useState<boolean>(false);
  const [paymentApi, setPaymentApi] = useState<boolean>(false);
  const [invoiceList, setInvoiceList] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPayment, setLoadingPayment] = useState<string>('');
  const [projectId, setProjectId] = useState<string>();
  const [invoiceId, setInvoiceId] = useState<string>();
  const [projectList, setProjectList] = useState<any>();

  // ====================================================== API

  const { data: invoiceData, error: invoiceError } = useSWR(
    invoiceApi ? { url: 'client-view/get-invoices' } : null,
    Api.ClientApi.getInvoices
  );

  const { data: projectData, error: projectError } = useSWR(
    projectApi ? { url: 'client-view/get-invoices/get-projects' } : null,
    Api.ClientApi.getProjects
  );

  const { data: projectInvoicesData, error: projectInvoicesError } = useSWR(
    projectInvoiceApi
      ? { id: projectId, url: 'client-view/get-invoices-by-project' }
      : null,
    Api.ClientApi.getProjectInvoices
  );

  const { data: paymentData, error: paymentError } = useSWR(
    paymentApi ? { id: invoiceId, url: 'client-view/get-payment-link' } : null,
    Api.ClientApi.getInvoicePaymentLink
  );

  // ====================================================== FUNCTIONS

  // ====================================================== LIFECYCLE

  useEffect(() => {
    if (paymentData) {
      setLoadingPayment('');
      setPaymentApi(false);
      if (paymentData?.InvoiceLink) {
        const newURL = `${paymentData?.InvoiceLink}`;
        window.open(newURL, '_blank', 'rel=noopener noreferrer');
      } else {
        setConfirmModal(
          () => {
            console.log('');
          },
          `${t('GENERAL.closeButton')}`,
          () => {},
          '',
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              maxWidth: 500,
              padding: 1
            }}
          >
            <Typography color="error.main" fontSize={32} variant="heading_400">
              {t('CUSTOMER.INVOICE.paymentErrorTitle')}
            </Typography>
            <Typography variant="primary">
              {t('CUSTOMER.INVOICE.paymentErrorDescription')}
            </Typography>
          </Box>
        );
      }
    }
  }, [paymentData]);

  useEffect(() => {
    if (invoiceData) {
      setInvoiceList(invoiceData);
      setInvoiceApi(false);
      setLoading(false);
    }
  }, [invoiceData]);

  useEffect(() => {
    if (projectInvoicesData) {
      setInvoiceList(projectInvoicesData);
      setProjectInvoiceApi(false);
      setLoading(false);
    }
  }, [projectInvoicesData]);

  useEffect(() => {
    if (projectData) {
      setProjectList([
        {
          id: 'all',
          label: t('CUSTOMER.INVOICE.allInvoices'),
          name: t('CUSTOMER.INVOICE.allInvoices')
        },
        ...projectData.map((project: any) => {
          return {
            id: project.id,
            lable: project?.proposal?.lead?.name,
            name: project?.proposal?.lead?.name
          };
        })
      ]);
      setProjectApi(false);
      setLoading(false);
    }
  }, [projectData]);

  useEffect(() => {
    if (invoiceError) {
      setConfirmModal(
        () => {
          console.log('');
        },
        `${t('GENERAL.closeButton')}`,
        () => {},
        '',
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            maxWidth: 500,
            padding: 1
          }}
        >
          <Typography color="error.main" fontSize={32} variant="heading_400">
            {t('CUSTOMER.INVOICE.paymentErrorTitle')}
          </Typography>
          <Typography variant="primary">
            {t('CUSTOMER.INVOICE.paymentErrorDescription')}
          </Typography>
        </Box>
      );
      setInvoiceApi(false);
    }
  }, [invoiceError]);

  useEffect(() => {
    if (projectError) {
      setProjectApi(false);
    }
  }, [projectError]);

  useEffect(() => {
    if (projectInvoicesError) {
      setProjectInvoiceApi(false);
    }
  }, [projectInvoicesError]);

  useEffect(() => {
    if (paymentError) {
      setPaymentApi(false);
    }
  }, [paymentError]);

  useEffect(() => {
    setCurrentStepScreen('client_invoices');
    setLoading(true);
    setInvoiceApi(true);
    setProjectApi(true);
  }, []);

  return (
    <MainContainer>
      <Typography color="primary" fontSize={32} variant="heading_400">
        {t('CUSTOMER.INVOICE.title')}
      </Typography>
      <Typography color="secondary" variant="primary">
        {t('CUSTOMER.INVOICE.subtitle')}
      </Typography>
      {projectList?.length > 0 && (
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">
            {t('CUSTOMER.INVOICE.projectLabel')}
          </InputLabel> */}
          <Select
            id="demo-simple-select"
            label="Age"
            labelId="demo-simple-select-label"
            onChange={(value: any) => {
              const id = value?.target?.value;
              setLoading(true);
              if (id === 'all') {
                setInvoiceApi(true);
              } else {
                setProjectId(id);
                setProjectInvoiceApi(true);
              }
            }}
            placeholder="123"
            value={projectId}
          >
            {projectList?.map((project: any) => {
              return (
                <MenuItem value={project?.id}>
                  <Typography variant="primary">{`${project?.name}`}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {loading && <Loader customColor="primary" customSize={48} />}
      {!loading && (
        <TableComponentEditable
          disableAdd
          action={() => {}}
          handleOpenDraw={() => {}}
          headKeys={[
            'invoiceNumber',
            'dueDate',
            'dateRange',
            'amount',
            'status',
            'showDetail'
          ]}
          onChange={() => {}}
          valuesArray={{
            data:
              invoiceList?.length > 0
                ? invoiceList.map((invoice: any) => {
                    return {
                      amount: getCurrency(
                        (parseFloat(invoice?.amount) / 100).toFixed(2)
                      ),
                      dateRange: `${formatDate(
                        invoice?.rangeStartDate
                      )} - ${formatDate(invoice?.rangeEndDate)}`,
                      dueDate: invoice?.dueDate,
                      invoiceNumber: invoice?.invoiceNumber,
                      showDetail:
                        loadingPayment === invoice?.id ? (
                          <Loader customColor="primary" customSize={20} />
                        ) : (
                          <Box
                            alignItems="center"
                            display="flex"
                            justifyContent="center"
                          >
                            <OpenDetailButton
                              handleOpenDraw={() => {
                                setInvoiceId(invoice?.id);
                                setLoadingPayment(invoice?.id);
                                setPaymentApi(true);
                              }}
                              icon="payment"
                              tooltip={`${t('TOOLTIP.INVOICE.payment')}`}
                            />
                            <Box>
                              <Typography
                                style={{
                                  color: invoice?.financialIntegrationByInvoice
                                    ? successColors.SUCCESS
                                    : alertColors.ALERT
                                }}
                                variant="primary-bold"
                              >
                                <Tooltip
                                  title={`${
                                    invoice?.financialIntegrationByInvoice
                                      ? t(
                                          'TOOLTIP.INVOICE.paymentLinkSuccessWithReference'
                                        )
                                      : t(
                                          'TOOLTIP.INVOICE.paymentLinkErrorForNoReference'
                                        )
                                  }`}
                                >
                                  <i className="custom-icon icon-info" />
                                </Tooltip>
                              </Typography>
                            </Box>
                          </Box>
                        ),
                      status: (
                        <Typography
                          style={{
                            color: palette.secondary.main,
                            display: 'block',
                            fontSize: '13px',
                            marginLeft: '-10px'
                          }}
                          variant="primary-bold"
                        >
                          {capitalizeString(`${invoice.status}`)}
                        </Typography>
                      )
                    };
                  })
                : []
          }}
        />
      )}
      {!loading && invoiceList.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography color="primary" variant="heading_300">
            {t('ERROR.listingError', [t('CUSTOMER.INVOICE.title')])}
          </Typography>
        </Box>
      )}
    </MainContainer>
  );
};

export default InvoicesClient;
