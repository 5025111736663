export default {
  ADVANCED_DASHBOARD: {
    addDashboard: 'Add Dashboard',
    basicDashboard: 'Basic Dashboard',
    colorLabel: 'Color',
    createDashboard: 'Create Dashboard',
    descriptionLabel: 'Description',
    emptyState:
      'There is no dashboard available. Please create a new dashboard.',
    iconLabel: 'Icon',
    modalTitle: '{0} Advanced Dashboard',
    nameLabel: 'Name',
    numberOfWidgets: 'Number of Widgets: {0}',
    title: 'Advanced Dashboard',
    widgetsAvailable: 'Widgets Available: {0}'
  },
  BANNER: {
    LEAD: {
      differentTimezone:
        'This lead is located in a different timezone than where you are. Therefore, all times and dates provided will be in accordance with the timezone of that lead.',
      readOnly:
        'This lead cannot be edited, depends on the proposal status you can recall the proposal or create a new lead.'
    },
    TASK_ITERATION: {
      preEventStarted:
        'You have a task in progress on {0}.  Please go to the Calendar option in the menu to complete it.'
    }
  },
  CHATS: {
    LIST: {
      recentlyVisited: 'Recently Visited',
      title: 'Chats'
    },
    TEAM_PROJECT: {
      title: 'Team'
    }
  },
  COMPANY_LIST: {
    active: 'Active',
    archived: 'Archived',
    companies: 'Companies',
    inactive: 'Inactive',
    noUsersForImpersonate: `Sorry, there are no users available to impersonate at this moment.`,
    titleModalAdmins: 'Admins Company',
    titleModalUsers: 'Users Company'
  },
  COMPANY_ONBOARDING: {
    successful: 'Your data has been successfully saved. Thank you!',
    welcome: 'We need the company information and settings'
  },
  COMPANY_SETTINGS: {
    actualBrochure: 'Actual Brochure:',
    availableDays: 'Available Days',
    brochure: 'Brochure',
    cancelButton: 'Cancel',
    company: 'Company',
    companyDescription: 'Company Description',
    companyFinancialIntegration: 'Company Integration',
    companyInformation: 'Company Information',
    companyName: 'Company Name',
    companyProfile: 'Company Profile',
    companySettings: 'Company Settings',
    companyUpdateSuccesful: 'Company updated!',
    description: 'Description',
    directIndirectCostsTitle: 'Direct/Indirect costs',
    disableHours: 'Disable supervisor hours',
    documentsLabel: 'Documents',
    dragNDrop: 'Click or drag and drop here to upload.',
    editButton: 'Edit',
    enforceGeofencing: 'Enforce Geofencing',
    extraHourlyRateLabel: 'Overtime hourly rate (%)',
    filesTableLabel: 'Files',
    finishOnboarding: 'Finish Onboarding',
    finishOnboardingIntegrations: 'Go to Company Integrations',
    geofencingMeasure: 'Geofencing Measure',
    geofencingRadius: 'Geofencing Radius',
    geofencingTitle: 'Geofencing',
    inactiveTimeLimit: 'Inactive Time Limit (Days)',
    informationTitle: 'Information',
    invoices: 'Invoices  (Days)',
    locationHistoryRetentionDays: 'Days to retain location history',
    logo: 'Logo',
    maxHours: 'Supervisor-to-Technician hour ratio',
    minimumInvoice: 'Minimum Proposal Price (USD)',
    name: 'Name',
    officePhoneNumber: 'Office Phone',
    onboardingCompleted: 'Onboarding Completed',
    onboardingIncomplete: 'Onboarding incomplete',
    other: 'Other',
    pageSubtitle: ' Update details here.',
    paymentTermsLabel: 'Payment Terms  (Days)',
    paymentsLabel: 'Payments - TBD',
    pdf: 'PDF',
    proposals: 'Proposals (Days)',
    remindersTitle: 'Reminders',
    saveButton: 'Save',
    tax: 'Tax',
    termConditionLabel: 'Terms and conditions',
    termRenewalLabel: 'Term renewal increase (%)',
    timingsTitle: 'Timings',
    type: 'Type',
    upcomingProjects: 'Upcoming Projects (Days)',
    updateSuccessfulSubtitle:
      'Now you can connect others softwares like Quickbooks or finish the onboarding',
    updateSuccessfulTitle: 'Company update successfully',
    varianceDown: 'Variance Down',
    varianceUp: 'Variance Up',
    variantsTitle: 'Variances'
  },
  COMPANY_SETTINGS_MODAL: {
    description: 'Enter the amount of widgets allowed for this company',
    plan: 'Plan',
    title: 'Company Settings',
    widgetsAllowed: 'Widgets Allowed'
  },
  COMPANY_SETTINGS_VALIDATIONS: {
    companyDescription: 'This field is required',
    companyName: 'This field is required',
    maxHours: 'This field must be a number',
    minimumInvoice: 'This field must be a number'
  },
  CUSTOMER: {
    INVOICE: {
      allInvoices: 'All project inovoices',
      paymentErrorDescription:
        "There's somehting wrong getting your payment link, please contact to your provider ",
      paymentErrorTitle: 'Payment link error',
      projectLabel: 'Project',
      subtitle: 'You can find your invoices, check them out ',
      title: 'Invoices'
    },
    PROPOSAL: {
      subtitle: 'You can find your proposals, check them out ',
      title: 'Proposals'
    },
    STEPS: {
      b2bMainContact: {
        subtitle: 'Please provide the primary contact person for this partner.',
        title: 'Main Point of Contact (POC)'
      },
      b2cMainContact: {
        subtitle:
          'Please provide the main contact information for this partner.',
        title: 'Partner Information'
      },
      billingPreferences: {
        subtitle:
          "Specify the customer's billing preferences. Ensure this information is accurate to avoid any delays or errors in the billing process.",
        title: 'Set Up Billing Preferences'
      },
      businessType: {
        subtitle:
          'Choose the customer type to tailor their profile. Select B2B for businesses or B2C for individual consumers.',
        title: 'Business Type'
      },
      communicationMethods: {
        subtitle:
          'Please add client’s preferred communication method to ensure consistent communication with the client.',
        title: 'Communication Methods'
      },
      companyInfo: {
        subtitle: 'Tell us more about your company.',
        title: 'Company Information'
      },
      pointOfContact: {
        subtitle:
          'This contact will be the selected as POC when a new lead is created.',
        title: 'Service Point of Contact (POC)'
      },
      serviceAddress: {
        subtitle:
          'Specify the address where you would like to receive services.',
        title: 'Service Address'
      },
      watchers: {
        subtitle:
          'Watchers are individuals who maintain constant communication with clients. You can assign one Business Development or Sales Representative and multiple Operators for clients with multiple projects.',
        title: 'Watchers'
      }
    },
    SUMMARY: {
      subtitle: `Please verify the details you've submitted. Confirm they are accurate to complete process`,
      title: 'Review and Confirm Customers Information'
    },
    addClientButton: 'Add Client',
    addCustomerButton: 'Add Customer',
    address: 'Address',
    addressBodyValues: '["name", "phone", "address"]',
    addressN: 'Address #',
    addresses: 'Addresses',
    archived: 'Archived',
    b2b: 'B2B',
    billingAddress: 'Billing Address',
    billingEmail: 'Billing Email',
    billingInformation: 'Billing Preferences',
    businessToBusinessSubTitle: 'Companies or organizations as customers',
    businessToBusinessTitle: 'Business-to-Business (B2B)',
    businessToConsumerSubTitle: 'Individual customers for direct services.',
    businessToConsumerTitle: 'Business-to-Consumer (B2C)',
    clientInformation: 'Client Information',
    communicationMethod: 'Communication Method',
    communicationMethods: 'Communication Methods',
    companyInformation: 'Company Information',
    companyName: 'Company Name',
    contactN: 'Contact #',
    contactsLabel: 'Contacts',
    createClient: 'Create Partner',
    createSubtitle:
      'Follow the steps below to complete the form. Don’t worry if you don’t have all the information you can update it later!',
    customerInformation: 'Customer Information',
    customerType: 'Customer Type',
    dataUsedTitle: 'Some data depends on this {0}',
    downloadCSV: `To ensure completeness, we'll provide a CSV file containing the unprocessed fields for your reference.`,
    email: 'Email',
    emailAddress: 'Email Address',
    errorAnyFieldProcessed: `We've encountered an issue and couldn't process any fields. Below, you'll find the details of the errors encountered.`,
    errorUploadCsv: 'You have to upload a csv',
    firstName: 'First Name',
    home: 'Home',
    importClientsButton: 'Import clients',
    importCustomersButton: 'Import customers',
    invoiceInformation: 'Invoice Information',
    invoices: 'Invoices',
    jobTitle: 'Job Title',
    lastName: 'Last Name',
    modalMsgAllFiledRequired: 'All fields are required.',
    modalMsgSuccesfuly: 'Customer created successfully!',
    modalMsgUpdateSuccesfuly: 'Customer updated successfully!',
    more: 'More',
    msgFiledRequired: 'This field is required.',
    name: 'Bluberry',
    newOnboardingSubtitle:
      'Send an invitation to a client to start their onboarding process to get access to the client portal',
    newOnboardingTitle: 'Onboarding',
    nickname: 'Nickname',
    notes: 'Notes',
    operators: 'Operators',
    overdueInvoices: 'Overdue',
    owner: 'Business or sales representative',
    paidInvoices: 'Paid',
    pendingInvoices: 'Pending',
    personalInformation: 'Personal Information',
    phoneNumber: 'Phone Number',
    primaryHome: 'Primary Home',
    processingImport: 'Processing {0} of {1}',
    proposals: 'Proposals',
    relationship: 'Relationship',
    resendOnboardingSubtitle:
      'Do you want to send an email invitation to {0} {1} from {2}?',
    resendOnboardingSubtitleDefault:
      'Do you want to send an email invitation to {0}?',
    resendOnboardingTitle: 'Resend Onboarding Invitation',
    save: 'Save',
    searchByMonth:
      '["All months", "January", "February", "March", "April", "May", "June", "July", "August", "September", "November", "December"]',
    searchInvoicesInput: 'Search invoice',
    serviceAddress: 'Service Address',
    status: 'Status',
    subtitle: 'Update customer details here',
    subtitleImportClients: `Please upload a CSV file and specify the fields you'd like to include in our platform`,
    subtitleImportCustomers: `Please upload a CSV file, select the type of the relationship and specify the fields you'd like to include in our platform`,
    successfullyProcessed: `We've successfully processed {0} fields`,
    timeZone: 'Timezone',
    titleImportClients: 'Import clients',
    titleImportCustomers: 'Import customers',
    totalInvoices: 'Total invoiced',
    type: 'Type',
    unarchived: 'Unarchived',
    uploadCSV: 'Upload CSV',
    validWorkOrderLabel: 'Valid work order emails',
    value: 'Value'
  },
  CUSTOMER_PORTAL: {
    ONBOARDING: {
      successful: 'Your data has been successfully saved. Thank you!',
      welcome:
        'We need your information to create your profile and keep your data updated'
    }
  },
  DASHBOARD: {
    active: 'Active',
    adHoc: 'Project (One time)',
    advancedDashboard: 'Advanced Dashboard',
    billed: 'Billed',
    cancelled: 'Cancelled',
    collections: 'Collections',
    completed: 'Completed',
    converted: 'Converted',
    convertedPercent: 'Converted',
    dashboard: 'Dashboard',
    draft: 'Draft',
    hoursSheduled: 'Hours Scheduled',
    hoursWorked: 'Hours Worked',
    inProgress: 'In progress',
    inTransit: 'In transit',
    laborCost: 'Labor Cost',
    laborHours: 'Labor hours',
    lastDate: 'Last update:',
    overTimeCost: 'Over time cost',
    overTimeHours: 'Hours out of variance',
    overdue: 'Overdue',
    paid: 'Paid',
    partiallyPaid: 'Partially paid',
    pending: 'Pending',
    proposalLost: 'Proposal lost',
    proposalLostPercentage: 'Lost',
    proposalValue: 'Proposal value',
    regular: 'Contract (Re-ocurring)',
    rejected: 'Rejected',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    submitedProposal: 'Proposal submited',
    subtotal: 'Subtotal',
    taxes: 'Tax @ 20%',
    total: 'Total',
    travelTime: 'Travel time',
    travelTimeCost: 'Travel time cost',
    variance: 'Variance',
    workingLead: 'Working lead'
  },
  DAYS: {
    friday: 'Friday',
    fridayL: 'F',
    fridayShort: 'Fri',
    monday: 'Monday',
    mondayL: 'M',
    mondayShort: 'Mon',
    saturday: 'Saturday',
    saturdayL: 'S',
    saturdayShort: 'Sat',
    sunday: 'Sunday',
    sundayL: 'S',
    sundayShort: 'Sun',
    thursday: 'Thursday',
    thursdayL: 'T',
    thursdayShort: 'Thu',
    tuesday: 'Tuesday',
    tuesdayL: 'T',
    tuesdayShort: 'Tue',
    wednesday: 'Wednesday',
    wednesdayL: 'W',
    wednesdayShort: 'Wed'
  },
  ERROR: {
    checklistRequired: 'should be at least one checklist item',
    emailFormat: 'email should have the format example@mail.com',
    errorTitle: 'Error!',
    general: 'Something went wrong, please try it later',
    inputFieldRequired: '{0} is required',
    inputRequired: '(*) is required',
    listingError: 'There is no data about "{0}" at this moment.',
    materialCost: "Material cost can't be 0 or empty",
    measurePerHour: '{0} is required',
    minimumResources: "Minimum Resources can't be 0 or empty",
    noItems: 'There is no items ',
    numberInt: '{0} should be a positive number',
    reload: 'Try again',
    rolesRequired: 'at least one role should be selected',
    signatureError: `Your signature must match the client's name exactly, including case sensitivity`,
    skillsRequired: 'at least one skill should be selected',
    updateError: "{0} couldn't be updated, please check the form"
  },
  GENERAL: {
    add: 'Add',
    addButton: 'Add',
    addChecklistButton: 'Add Checklist',
    addResourceButton: 'Add Resource',
    addServiceButton: 'Add Service',
    addTaskButton: 'Add Task',
    and: 'and',
    assign: 'Assign',
    assignments: 'Assignments',
    backButton: 'Back',
    bluBerry: 'Bluberry',
    cancelButton: 'Cancel',
    cancelProject: 'Cancel Project',
    closeButton: 'Close',
    confirmButton: 'Confirm',
    create: 'Create',
    deleteButton: 'Delete',
    done: 'Done',
    downloadAllButton: 'Download All',
    dragNDrop: 'Click or drag and drop here to upload {0}',
    editButton: 'Edit',
    email: 'E-mail',
    finishButton: 'Finish',
    firstName: 'First Name',
    inputRequired: 'some values cannot be empty',
    lastName: 'Last Name',
    loading: 'Loading {0}',
    minutes: 'Minutes',
    needSupport: 'Need Support?',
    nextButton: 'Next',
    no: 'No',
    noData: 'No data to display',
    noImages: 'No images',
    noInfoDay: 'Please select a day.',
    noResources: 'No resources available for the required dates',
    payInvoice: 'Pay Invoice',
    phoneNumber: 'Phone Number',
    plus: '+',
    profilePicture: 'Profile picture',
    reassign: 'Reassign',
    reassignReazon: 'Reassign reason',
    recalculateButton: 'Recalculate',
    refresh: 'Refresh',
    resendInvoice: 'Resend Invoice',
    resetButton: 'Reset',
    saveButton: 'Save',
    saveScheduled: 'Save Resource Configuration',
    sendButton: 'Send',
    skipButton: 'Skip',
    sms: 'SMS',
    startConvertation: 'Start Convertation',
    stopImpersonating: 'Stop Impersonating',
    submitButton: 'Submit',
    summaryButton: 'Summary',
    supervisor: 'Supervisor',
    sync: 'Update',
    team: 'Team',
    unassign: 'Unassign',
    underMaintenance: 'We are under maintenance',
    updateButton: 'Update',
    verifyButton: 'Verify',
    welcome: 'Welcome',
    yes: 'Yes'
  },
  GEOFENCING: {
    checkingGeofencing: `Checking location...`,
    enforceApproved: `Location confirmed!`,
    enforceNotApproved: `It looks like you're currently outside the designated area for this service. Task actions are not allowed.`,
    locationConfirmed: 'Location confirmed',
    locationNotAllowed: 'Location not allowed',
    locationNotConfirmed: 'Location not confirmed',
    notEnforceNotApproved: `It looks like you're currently outside the designated area for this service, but you can continue!`
  },
  INSIGHTS: {
    PROFILE_CARD: {
      availableDays: 'Available Days',
      editUser: 'Edit user',
      endTime: 'End time',
      hourlyRate: 'Hourly rate',
      hoursScheduled: 'Hours scheduled',
      startTime: 'Start time',
      timezone: 'Timezone',
      twoFactorMethod: 'Two Factor Method'
    },
    PROJECT_CARD: {
      actual: 'Actual',
      assignments: 'Assignments',
      client: 'Client',
      date: 'Date',
      endDate: 'End Date',
      estimatedTime: 'Estimated Time',
      noDataCard: 'no data',
      noDataCardDescription: 'no data description',
      project: 'project',
      projectTitle: 'Projects Assigned: {0}',
      projectType: 'Project Type',
      review: 'Review',
      startDate: 'Start Date',
      status: 'Status',
      supervisor: 'Supervisor',
      task: 'Task',
      tasks: 'Tasks',
      team: 'Team'
    },
    ROUTES: {
      title: 'Task Routes'
    },
    SKILL_CARD: {
      addSkills: 'Add Skills',
      all: 'All',
      cardTitle: ' Skill Set:',
      dateSelect: 'Date',
      last7days: 'Last 7 days',
      last15days: 'Last 15 days',
      last30days: 'Last 30 days',
      last60days: 'Last 60 days',
      last90days: 'Last 90 days',
      lastYear: 'Last year',
      noData: 'No Skills Listed Yet',
      noDataDescription: `Once you add your skills, they will appear here along with how often they've been used`,
      skill: 'Skill',
      today: 'Today'
    },
    TIMESHEET: {
      viewAll: 'View all'
    },
    USER: {
      REPORT_DELAYS: {
        noData: 'No Late Arrivals',
        noDataDescription: 'All tasks are being handled on time.',
        title: 'Delays ({0})',
        viewAll: 'View all '
      },
      WEEKDASHBOARD: {
        title: 'Weekly Task Overview'
      },
      title: 'User Data Insights'
    }
  },
  INVITATION: {
    add: 'New Invitation',
    administrator: 'Administrator',
    client: 'Client',
    company: 'Company',
    companyDescription: 'Company Description',
    companyName: 'Company Name',
    customer: 'Customer',
    firstName: 'First Name',
    invitation: 'Invitation',
    jobTitle: 'Job Title',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    plan: 'Plan',
    rate: 'Hourly Rate',
    resendInvitationConfirmation: 'The Invitation was sent',
    roles: 'Roles',
    skills: 'Skills',
    user: 'User'
  },
  LEAD: {
    CREATE: {
      COST_CALCULATOR: {
        ADD_MATERIAL_MODAL: {
          autoCompletePlaceholder: 'Select a Material',
          cost: 'Cost',
          description:
            'You can add a material from Service Catalog or Create a new One',
          title: 'Add Material'
        },
        SUMMARY: {
          summaryCOGS: ' Cost of sale (COS)',
          summaryDescription:
            'The margin is what is left for your business after covering labor and direct costs to serve the project.\nThis is not Profit Margin yet, since you still have other expenses in your business such as admin, marketing, accounting, and overhead in general.\nThis percentage is known as Contribution Margin.',
          summaryDesireMargin: 'Desire Margin %',
          summaryHeader: 'Pricing And Margin',
          summaryMargin: 'Margin (%)',
          summaryMarkup: 'Markup',
          summaryMarkupPercent: 'Markup (%)',
          summarySalesTax: 'Collected sales tax',
          summarySuggestedPrice: 'Suggested project price',
          summaryTitle: 'What is this margin?',
          summaryTotalProjectCost: 'Total Project Costs',
          summarydesiredMargin: 'Desire margin %',
          summarytotal: 'Total Invoiced (including sales tax)'
        },
        add: 'Add +',
        goToProposalButton: 'Go to proposal',
        grandTotal: 'Total for Service during contract period',
        materialModalTitle:
          'Cost Breakdown for materials and supplies for task: {0}',
        otherCostTitle: 'Other Project-wide Cost',
        serviceFrequency: 'Service Frequency',
        serviceTime: 'Service Time',
        servicesCost: 'Services Cost',
        setupTimeCost: 'Setup Time Cost',
        supervisorTimeCost: 'Supervisor Time Cost',
        taskModalTitle: 'Cost Breakdown for task: {0}',
        teardownTimeCost: 'Teardown Time Cost',
        totalCostPerVisit: 'Total Cost per Service Visit',
        travelTimeCost: 'Travel Time Cost',
        visits: 'visits',
        visitsInPeriod: 'Visits in Period'
      },
      GENERAL_INFORMATION: {
        MODAL: {
          detailManualPricing:
            'You can add task or create them by yourself, task time, and resources',
          detailServiceCatalog: 'Select Services from Service Catalog',
          detailTitle: 'What is {0} for?',
          title:
            'Would you like to continue the process with manual pricing or with the service catalog?'
        },
        addClientFirst: 'You need to add a client first',
        address: 'Service Address',
        adhoc: 'Project (One time)',
        client: 'Client',
        contact: 'Contact',
        continue: 'Continue',
        description: 'General Information',
        dontHave: "This client doesn't have {0}",
        end: 'End',
        leadClientWarning:
          "Before creating a Lead, please ensure the client is added to the system. If the client isn't listed yet, you'll need to add them first to proceed",
        manualPricing: 'Manual Pricing',
        noClientSelected: 'You need to select a client first',
        noClients: 'There are no clients',
        overrideManualPricing:
          'The current lead has {0} configured, do you want to change it? This could make changes for the next steps',
        projectDescription: 'Project Description',
        projectName: 'Name of project',
        projectType: 'Project Type',
        regular: 'Contract (Re-ocurring)',
        select: 'select',
        serviceCatalog: 'Service Catalog',
        start: 'Start',
        uploadDocuments: 'Upload Project Documents'
      },
      PROPOSAL: {
        CHAT: {
          button: 'Send',
          clientTitle: 'Send us a message!',
          close: 'Close Chat',
          open: 'Open Chat',
          operatorTitle: 'Send a message to the client'
        },
        COUNTEROFFER_REVIEW: {
          adjustProposal: `Adjust the proposal and recalculate the total paid until it fits your budget. Once submitted, it will need Bluberry's approval before reaching the client.`,
          blockActionByCounterOfferSubtitle:
            'You cannot submit an action if a counter offer has been submited.',
          blockActionByCounterOfferTitle: 'Awaiting a counter offer response.',
          title: 'Review Counter Offer'
        },
        SERVICE_DETAIL: {
          description:
            'Review services and explore task details by expanding each task to view its step-by-step checklist.',
          title: 'Service Details'
        },
        acceptTerms: 'I accept the terms and conditions',
        acceptedProposalText:
          'All is set! We will contact you to confirm dates and start working together.',
        acceptedProposalTitle: 'We are in business!',
        addMessage: 'Add Message',
        approvalRequired: 'Approval required?',
        autoCompletePlaceholder: 'Select a Message',
        buttonAddCatalogMessage: 'Add a message from Catalog',
        buttonClientAccept: 'Accept',
        buttonClientDecline: 'Reject',
        buttonContinueEditing: 'Continue Editing Proposal',
        buttonContinueTermsConditions: 'Continue to read terms and conditions',
        buttonConvertProject: 'Convert to Project',
        buttonNext: 'Next',
        buttonReviewProposal: "Yes, let's review this proposal",
        buttonSendProposal: 'Send Proposal',
        confirmSendCounterOffer: 'Are you sure to send this counter offer?',
        counterOfferListTitle: 'Counter Offer',
        counterOfferSubmited:
          'A counter offer was submited, wait until you receive a response',
        counterOfferTitle: 'Make your own proposal',
        counterOfferToggle: 'Allow counter offer from customer?',
        discountPercent: 'Discount to apply (%)',
        discountSubtitle:
          'Add a variance to let the system know how many visits should increase to apply a discount and how much will be this one.',
        discountTitle: 'Discount configuration',
        discountVariance: 'Variance (%)',
        downloadProposalPDF: 'Download as PDF',
        generalInfoClient: 'Client: ',
        generalInfoContact: 'Contact: ',
        generalInfoDescription: 'Description: ',
        generalInfoDuration: 'Duration / Term: ',
        generalInfoProjectName: 'Project Name: ',
        generalInfoServiceAddress: 'Service Address: ',
        generalInfoTitle: 'General Information',
        invoiceConfigReminder: 'Reminder notification settings:',
        invoiceConfigSecurityLabel: 'Deposit amount:',
        invoiceConfigSecurityTitle: 'Security Deposit',
        invoiceConfigSelectDay: 'Day',
        invoiceConfigSelectEvery12: 'Every 12 hours',
        invoiceConfigSelectEvery24: 'Every 24 hours',
        invoiceConfigSelectEvery48: 'Every 48 hours',
        invoiceConfigSelectLapse: 'Payment Terms',
        invoiceConfigSelectPayment: 'Payment Terms',
        invoiceConfigSelectWeek: 'Week',
        invoiceConfigSendOn: 'Send on:',
        invoiceConfigSubTitle:
          'The system will run a billing calculation and send invoices with the following desired frequency:',
        invoiceConfigTimeLapse: 'Time lapse',
        invoiceConfigTitle: 'Invoicing and Billing Cycle',
        invoiceConfigWeek: 'Week of the month:',
        makeCounterOffer: 'Counter offer',
        messageTempleateTitle: 'Message Title',
        messageUs: 'Message us',
        minimumPriceErrorMessage:
          'Oops!! the total proposal price cannot be {0} $ {1}',
        pdfErrorMessage: `We couldn't load the document`,
        penalizationPercent: 'Apply a premium (%)',
        penalizationSubtitle:
          'Add a variance to let the system know how many visits should decrease to apply a premium and how much will be this one.',
        penalizationTitle: 'Premium configuration',
        penalizationVariance: 'Variance (%)',
        personalizedMsgSubtitle:
          'If you want to include a personalized message to be added in the proposal email text please add it here:',
        personalizedMsgTitle: 'Personalized message',
        pricingSummaryTable1: 'Billing Summary',
        pricingSummaryTable2: 'Total Proposal',
        proposalExpiredLinkFirstText:
          'You have already used this link or it has expired.',
        proposalExpiredLinkLastText: 'We will send a new link to your email',
        proposalExpiredLinkTitle: 'Proposal Link Expired',
        proposalModalAccept: 'I Accept all Bluberry Term And Conditions',
        proposalModalSign: 'Sing to accept proposal',
        proposalNotFoundTitle: 'Proposal Not Found',
        proposalTitle: 'Proposal Status',
        rejectProposalModalOptionText: 'Would you like an updated proposal?',
        rejectProposalModalText:
          'Please let us know what are the reasons to decline our proposal:',
        rejectedProposalText: 'We regret that we were unable to close the deal',
        rejectedProposalTitle: 'Thank you for your consideration',
        rejectedWantNewProposalText:
          'We are working on preparing a new proposal for you',
        rejectedWantNewProposalTitle: 'New proposal requested',
        requestNewLink: 'Request a new link',
        resendProposalLinkModalText: 'Check your email',
        resendProposalLinkModalTitle: 'New Proposal Link sent',
        reviewProposalSubTitle1:
          'Please review and make sure all details are ready to send. If all looks good press the Send Proposal button bellow.',
        reviewProposalSubTitle2:
          'If you are happy with the updated margin and adjusted pricing, you can proceed to sending the proposal.',
        reviewProposalTitle1: 'Ready to send?',
        reviewProposalTitle2: 'Are you ready to send this proposal?',
        serviceSummaryActualMargin: 'Actual markup per visit: ',
        serviceSummaryActualpersent: 'Actual markup percent per visit: ',
        serviceSummaryCostperVisit: 'Cost per visit: ',
        serviceSummaryMargin: 'Margin: ',
        serviceSummaryMarkup: 'Markup: ',
        serviceSummaryQuotedperVisit: 'Quoted price per visit: ',
        serviceSummarySuggestedPrice: 'Suggested Price per visit: ',
        serviceSummaryTotal: 'Total in {0} visits x {1} = {2}',
        subtitlePart1: 'Based on the desired margin of ',
        subtitlePart2:
          ' these are the prices you need to quote on your proposal for each Service time:',
        summaryScopeWork: 'Scope of work',
        tableCollectedSalesTax: 'Collected sales Tax',
        tableInvoiceCollectedTax: 'Collected sales tax',
        tableInvoicePart1: 'To be partially invoiced in',
        tableInvoicePart2: 'cycles',
        tableInvoiceSalesTaxable: 'Sales taxable items',
        tableInvoiceSecurityDeposit:
          'Security Deposit (applied toward the scheduled services)',
        tableInvoiceSecurityDepositAlternative: 'Services',
        tableInvoiceSubtotal: 'Sub-total',
        tableInvoiceTotal: 'TOTAL',
        tableInvoiceTotalMiscelaneouess: 'Total in Miscelaneoues items',
        tableMargin: 'Margin %',
        tableMarkup: 'Markup',
        tablePricingBillingService: 'Service Time',
        tableProjectCostsTitle: 'Other Project-wide Costs',
        tableQuotedPrice: 'Quoted Price',
        tableTitleUpdatedPricing: 'Updated Pricing and Margin',
        tableTotal: 'Total',
        tableTotalProjectCost: 'Cost of Sale (COS)',
        termsDescription:
          'To move forward with Bluberry cleaning services, we kindly ask you to review and accept our Terms and Conditions. Your acceptance is required to ensure you understand the service details, policies, and your rights as a customer. \n\n By accepting these Terms and Conditions, Bluberry will notify you of any important updates regarding your service, including schedule changes, special offers, and service reminders.',
        termsToProceed: 'Accept Terms to Proceed',
        titleCreateProposal: 'Create Proposal > ',
        titlePreview: 'Preview',
        titlePricing: 'Pricing',
        titleProposal: 'Proposal',
        titleTerms: 'Terms and Conditions',
        totalPerVisit: 'Total per visit',
        visits: 'visits'
      },
      SERVICES: {
        ADD_CHECKLIST_MODAL: {
          autoCompletePlaceholder: 'Select a Checklist',
          description: 'Checklist Description',
          name: 'Checklist Name',
          noData: "There's no data",
          tasks: 'Checklist Tasks',
          title: 'Add a Checklist'
        },
        ADD_RESOURCE_MODAL: {
          autoCompletePlaceholder: 'Select a Resource',
          description: 'Resource Description',
          name: 'Resource Name',
          noData: "There's no data",
          title: 'Add a Resource'
        },
        ADD_SERVICE_MODAL: {
          autoCompletePlaceholder: 'Select a Service',
          description: 'Service Description',
          name: 'Service Name',
          noData: "There's no data",
          tasks: 'Service Tasks',
          title: 'Add a Service'
        },
        ADD_TASK_MODAL: {
          addToCatalog: 'Add task to service catalog',
          autoCompletePlaceholder: 'Select a Task',
          autoCompletePlaceholderSkills: 'Select Skills',
          description: 'Description',
          modalTitle: 'Add a Task',
          name: 'Task Name',
          noData: "There's no data",
          skills: 'Skills',
          subTitle:
            'You have the option to either select from existing tasks or create a new one.'
        },
        MANUAL_PRICING: {
          address: 'Address',
          noData: "There's no data",
          phone: 'Phone',
          project: 'Project',
          subtitle: 'Description for Manual pricing',
          title: 'Manual Pricing Ad-hoc Project'
        },
        SCHEDULE: {
          arriveMsg1: 'Arrive between ',
          arriveMsg2: ' And ',
          assingResources: 'Assign resources (technicians)',
          assingSupervisor: 'Yes, assign supervisor',
          assingSupervisorAutocomplete: 'Assigned Supervisor',
          assingSupervisorHours: 'How many hours?',
          assingSupervisorTitle: 'Assign Supervisor',
          dontAssingSupervisor: `No, don't add supervisor`,
          finishing: 'Finishing ',
          moreHoursMsg:
            'With the current resources it is not possible to meet the end arrive time given in service window',
          scheduledTitle: ' Configure the future resources',
          serviceTimeTitle: 'Services time duration',
          serviceWindowTitle: 'Service Window: ',
          starting: 'Starting ',
          summaryBetween: ' between ',
          summaryHours: ' hours ',
          totalDurationMsg1:
            'Based on the assigned team, the total duration of each visit is: '
        },
        SUMMARY: {
          addServiceTimeButton: 'Add Service Time',
          adhocTasks: 'Additional Tasks',
          confirmDelete:
            'Are you sure that you want to delete the selected service time?',
          continueButton: 'Continue with Calculator',
          frequency: 'Frequency',
          noData: "There's no data",
          pending: 'Resources',
          scheduled: 'Configured',
          service: 'Service',
          serviceTime: 'Service Time',
          services: 'Services',
          subtitle:
            'These are the service times listed on your project. If you want to add more click on the “+ Add Service Time” button or if you are ready to assign resources click on “Continue”',
          title: 'Service Times',
          warning:
            'In order to proceed with Cost Calculator you need to complete the "Schedule Configuration” step for each of your Service Times'
        },
        SelectedDays: 'Selected Days',

        addService: 'Add Services',
        addTaksModalTitle: 'Add a existing task or add a line item',
        addTaskButton: '+ Add Tasks',
        adhocHeaderDescription: 'Description for Ad-hoc project',
        adhocHeaderTitle: 'Ad-hoc Service Time',
        adhocTasks: 'Individual Tasks',
        allDays: 'All days',
        biweekly: 'Biweekly',
        checklist: 'Checklist',
        daysOfTheWeek: 'Days of the week',
        finishButton: 'Finish',
        frequency: 'Frequency',
        frequencyWeek: 'Repeats on',
        frequencyWeek2: 'of the month.',
        generalService: 'General Service',
        hours: 'Hours',
        individualTask: 'Individual Tasks',
        lineItemTask: 'Line Item Task',
        manualPricing: 'Manual Pricing',
        minutes: 'Minutes',
        monthly: 'Monthly',
        nextDayWarning:
          'Based on the finishing time this service time will end the next day',
        roundTrip: 'Round Trip',
        serviceFrequency: 'Service Frequency',
        serviceTimeDescription:
          'Setup the frequency and days of the week that this service window needs to happen throughout the duration of the contract.',
        serviceTimeTitle: 'Service Time #{0}',
        services: 'Services',
        setupTime: 'Setup Time',
        tasks: 'Tasks',
        teardownTime: 'Teardown Time',
        timeAnd: 'and',
        timePeriod: 'Time Period',
        travelTime: 'Travel Time',
        weekly: 'Weekly'
      },
      TABS: {
        costCalculator: 'Cost Calculator',
        generalInformation: 'General Information',
        proposal: 'Proposal',
        resources: 'Resources',
        serviceTime: 'Service Time'
      },
      description: 'Add information to create a lead',
      endDate: 'End Date',
      saveProgress: 'Do you want to save the progress?',
      startDate: 'Start Date',
      timePerTask: 'Time per task',
      title: 'Lead Creation'
    },
    EDIT: {
      description: 'Change General Information to edit the Lead',
      title: 'Lead Edition'
    }
  },
  LISTING_PAGES: {
    LastSent: 'Last Sent',
    Recipent: 'Recipent',
    Recipient: 'Client',
    action: 'Action',
    activityLog: 'Activity logs',
    address: 'Address',
    adhocTime: 'Ad-Hoc time',
    administratorEmail: 'Admin Email',
    admins: 'Admins',
    allInvoices: 'All invoices',
    allowCounterOffers: 'Allow Counter Offer',
    amount: 'Amount',
    assigned: 'Assigned',
    assignmentStatus: 'Assignment',
    businessName: 'Company',
    calculationItem: 'Calculation Item',
    calculator: 'Calculator',
    categories: 'Categories',
    category: 'Category',
    chartType: 'Chart',
    chat: 'Chat',
    chatOperator: 'Client Messages',
    checklist: 'Checklist',
    client: 'Client',
    clientName: 'Client Name',
    companyName: 'Company Name',
    completed: 'Completed',
    contact: 'Contact',
    costMaterial: 'Total',
    created: 'Created At',
    createdAt: 'Date',
    credits: 'Credits',
    customerBalance: 'Customer Balance',
    customerDisplayName: 'Customer Name',
    customerEmail: 'Customer Email',
    date: 'Date',
    dateRange: 'Date range',
    delete: 'Delete',
    description: 'Description',
    detail: 'Detail',
    details: 'Details',
    documents: 'Documents',
    dueDate: 'Due date',
    email: 'Email',
    emptyState: 'No results found',
    firstName: 'First Name',
    hours: 'Hours',
    id: 'id',
    impersonate: 'Impersonate',
    infoInvoices: 'Invoice Information',
    invoiceNumber: 'Invoice No',
    invoices: 'Invoices',
    item: 'Item',
    jobTitle: 'Job Title',
    laborCost: 'Labor Cost',
    lastName: 'Last Name',
    leadName: 'Project Name',
    leadProjectEndDate: 'End Date',
    leadProjectStartDate: 'Start Date',
    list: 'Checklist',
    materialsAndSupplies: 'Extra Materials and Supplies',
    materialsFromTasks: 'Materials From Tasks',
    mediaRate: 'Median Rate',
    message: 'Message',
    minRequired: 'Min Required',
    misc: 'Miscelaneous',
    name: 'Name',
    nameInvoice: 'Invoice Name',
    notes: 'Notes',
    onboarding: 'Onboarding',
    other: 'Other',
    paidInvoices: 'Paid invoices',
    payrollTax: 'Payroll Tax',
    pendingInvoices: 'Pending invoices',
    phone: 'Phone',
    phoneNumber: 'Phone Number',
    priceSquareFeet: 'Price / Sq ft',
    priceUnit: 'Price / Unit',
    priceVariance: 'Price Variance',
    primaryDataType: 'Primary',
    projectDuration: 'Project date range',
    projectEndDate: 'Project end date',
    projectName: 'Project Name',
    projectStartDate: 'Project start date',
    projectType: 'Project Type',
    proposal: 'Proposal',
    quantity: 'Quantity',
    quantitySquareFeet: 'Square Feet',
    quantityUnits: 'Units',
    quotedProposal: 'Quoted Proposal',
    rate: 'Rate',
    recallProposal: 'Recall',
    recallStatus: 'Recall status',
    regularTime: 'Regular time',
    requestedAvailableDays: 'Available Days',
    requestedTimeEnd: 'Time End',
    requestedTimeStart: 'Time Start',
    resend: 'Resend',
    resource: 'Resource',
    resourcesQuantity: '# Users',
    roles: 'Roles',
    salesTax: 'Sales Tax',
    secondaryDataType: 'Secondary',
    seen: 'Seen',
    sendDate: 'Date Sent',
    serviceAndTask: 'Service and task',
    serviceAndTimes: 'Service times',
    serviceSetup: 'Services Setup',
    serviceTimes: 'service Times',
    serviceTitle: 'Scheduled services',
    show: 'show',
    showDetail: '',
    skills: 'Skills',
    status: 'Status',
    statusChip: 'Status',
    statusIcon: 'Status',
    subTotal: 'Sub total',
    task: 'Task',
    taskMaterials: 'Materials',
    taskName: 'Task',
    tasks: 'Tasks',
    taxItem: 'Sales Taxable Items',
    taxableItems: 'Sales taxable items',
    time: 'Total Billable Time',
    timeScheduled: 'Time Scheduled',
    title: 'Title',
    total: 'Total',
    totalCost: 'Total Cost',
    totalCostMaterial: 'Total',
    totalTime: 'Total Time',
    totalVisits: 'Total Visits',
    type: 'Type',
    workOrders: 'Work Orders'
  },
  LOGIN: {
    backToLogin: 'Back to login',
    clientLogin: 'Login as client',
    companyDescription: 'Description',
    confirmButton: 'Confirm',
    customerLogin: 'Login as customer',
    emailInvalid: 'email is invalid',
    errorAttempt:
      'Your account is locked due to too many failed attempts({attemptNumber}), please contact your admin to unlock your account.',
    errorMessage: 'Unable to log in, please try again or contact administrator',
    forgotPassword: 'Forgot Password',
    forgotPasswordSuccessButton: 'Back to log in',
    forgotPasswordSuccessDescription:
      'We sent you an email to reset your password',
    forgotPasswordSuccessTitle: 'Email Sent',
    inputRequired: '(*) is required',
    invalidToken: 'Invalid Token',
    invitationError: "Passwords don't match",
    invitationErrorEmpty: 'Password cannot be empty',
    invitationErrorEndpoint: 'Something went wrong, please try later',
    invitationInfoTitle: 'Confirm your information',
    invitationMfaCheckbox: 'MFA by Email',
    invitationTitle: 'Set your password',
    linkExpiredDescription: 'Please request the link invitation again',
    linkExpiredTitle: 'Token Expired',
    login: 'Log in',
    mfaCode: 'Verification Code',
    mfaConfirmationSubtitleEmail:
      'To enable multi-factor authentication please enter the code we sent to your email.',
    mfaConfirmationSubtitlePhone:
      'To enable multi-factor authentication please enter the code we sent you via text message.',
    mfaDescription:
      'Please enter the code generated by your favorite authenticator application in the field below.',
    mfaError:
      'Wrong verification code, make sure the code is entered correctly',
    mfaRegistrationSubtitle:
      'Select your favorite method to authenticate your account.',
    mfaRegistrationTitle: 'Two Factor Authentication',
    mfaResendCode: 'Resend code',
    mfaTitle: 'To keep your account secure vertify your indentity.',
    mfaUpdateEmailSubtitle: 'Please enter a valid email',
    mfaUpdatePhoneSubtitle: 'Please enter a valid phone number',
    mfaVerificationCode: 'Verification Code',
    password: 'Password',
    rememberDevice: 'Remember this device',
    rememberMe: 'Remember me',
    requestLogin: 'Request Login',
    resetPasswordButton: 'Change Password',
    resetPasswordConfirm: 'Confirm Password',
    resetPasswordErrorMessage:
      'Password does not meet security requirements: can’t be a previous password, must contain at least one uppercase letter and a number',
    resetPasswordNew: 'New Password',
    resetPasswordOld: 'Old Password',
    sendLinkAuthDescription:
      'We sent you an email with the authentication link',
    sendLinkAuthTitle: 'Email Sent',
    sendLinkButton: 'Send link to e-mail',
    successSubtitle:
      'Next time you sign in, you’ll need to enter the code we send you via text message',
    successTitle:
      'Your account is now protected with multi-factor authentication',
    userName: 'Username',
    validEmail: 'You should use a valid email',
    validPhone: 'You should use a valid phone number',
    welcome: 'Welcome to:'
  },
  MENU: {
    all: 'All',
    categories: 'Categories',
    checklists: 'Checklists',
    client: 'Clients',
    clients: 'Clients',
    companies: 'Companies',
    companyInvitations: 'Company Invitations',
    contact: 'Contacts',
    customers: 'Customers',
    invitations: 'Invitations',
    leads: 'Leads',
    materials: 'Materials',
    projects: 'Projects',
    proposalMessages: 'Proposal Messages',
    prospect: 'Prospects',
    resources: 'Resources',
    serviceCatalog: 'Service Catalog',
    services: 'Services',
    skills: 'Skills',
    tasks: 'Tasks',
    userInvitations: 'User Invitations',
    users: 'Users',
    widgets: 'Widgets'
  },
  MODAL: {
    Unit: 'Unit',
    activate: 'Do you want to activate this item?',
    add: 'Add',
    addMe: 'Add me',
    archiveModalProjectListDescription: 'Where the Technician was removed:',
    archiveModalProjectListTitle: 'List of Projects',
    archived: 'Do you want to archive this item?',
    block: 'Do you want to block this item?',
    cancel: 'Cancel',
    clientPhone: 'Client Phone: {0}',
    close: 'Close',
    confirModal: 'Are you sure you want to resend this proposal?',
    confirModal2: 'Are you sure you want to recall this proposal?',
    confirModal3:
      'When you recall a proposal, the client is notified and the proposal is no longer valid for comments, approval or rejection.',
    confirModal4: 'Recall reason or comments for your client (optional):',
    confirModalRecall: 'Recall',
    confirModalResend: 'Resend',
    confirModalResendInvoice: 'Are you sure to resend invoice?',
    confirModalStatusInvoice: 'Are you sure to change status to Paid?',
    confirModalYes: 'Yes',
    confirModalYesRecall: 'Yes,Re-call it',
    confirmCancelProject: 'Are you sure you want to cancel this project?',
    confirmModalExitTask:
      'Are you sure you want to exit before save the progress?',
    confirmModalNo: 'No',
    confirmModalUnassign: 'Are you sure to unassign the selected dates?',
    contactinformation: 'Contact Information',
    create: 'Create',
    delete: 'Delete',
    deleteModal: 'Are you sure you want to delete this item?',
    description: 'description',
    details: 'details',
    imageRequired: 'Image Required',
    match: 'Match',
    materials: 'Materials',
    measure: 'Measurement units',
    measurePerHour: 'Units per hour',
    mfaUpdateModalsMsg: 'Multi-Factor Authentication',
    minimumResources: 'Minimum Resources',
    name: 'name',
    officeNumber: 'Office Number: {0}',
    okButton: 'Ok',
    proposal: 'Proposal',
    reassignTechnicianDescription:
      'Select a technician from the list to re-assign all the future tasks to the new one!',
    reassignTechnicianTitle: 'Reassign Team',
    save: 'Save',
    send: 'Send',
    setupTime: 'Setup Time',
    supervisorNumber: 'Supervisor Number: {0}',
    teardownTime: 'Teardown Time',
    timePerMeasure: 'Hours per {0}',
    unarchived: 'Do you want to unarchive this item?',
    unblock: 'Do you want to unblock this item?',
    usePrimaryServicesAddress: 'Use as primary service address',
    watchers: 'Add or remove watchers'
  },
  NOTES: {
    mobileTaskTitle: 'View project notes',
    title: 'Leave a note here',
    titleMobile: 'Project notes'
  },
  NOTIFICATION: {
    TOAST: {
      addedSuccess: '{0} added successfully.',
      addingError: 'There is an issue adding {0}.',
      createCompany:
        'Your invitation has been successfully sent.\n\nOnce they join Blueberry, they’ll be able to schedule their cleaning services with ease.',
      createError: 'There is an issue creating {0}.',
      createServiceError:
        'There was an error creating the {service}. Please try again.',
      createServiceSuccess:
        'The {service} has been successfully created and is now ready for future projects.',
      createSuccess: '{0} created successfully.',
      customerCreated: 'Customer created successfully!',
      customerUpdated: 'Customer updated successfully!',
      deleteError: `Unable to delete this item, try it later.`,
      deleteSuccess: '{0} deleted successfully.',
      editError: 'There is an issue updating {0}.',
      editSuccess: '{0} edited successfully.',
      generalError: 'Something went wrong, please try it later.',
      messageEmpty: 'Message is empty',
      messageFail: 'Message fail to sent',
      messageSent: 'Message sent',
      projectUpdatedSuccess: 'Project updated successfully.',
      userActivated: 'User activated successfully.',
      userArchived: 'User archived successfully.',
      userUnarchived: 'User unarchived successfully.'
    }
  },
  ONBOARDING: {
    invitation: 'Onboarding invitation',
    success:
      '{0}, you have completed your onboarding process, you can continue to your client portal.'
  },
  PROFILE: {
    MENU: {
      availableDays: 'Available Days',
      changePassword: 'Change Password',
      profileInformation: 'Profile Information',
      twoFactorAuthentication: 'Two Factor Authentication'
    },
    availableDays: 'Available Days',
    availableTimeEnd: 'Available time end',
    availableTimeStart: 'Available time start',
    cancelRequest: 'Cancel Request',
    changeMfaMethod: 'Update MFA method',
    changeMfaMethodMessage:
      'You can use email or phone for Multi-Factor authentication (MFA).',
    changePassword: 'Change Password',
    companyName: 'Company Name',
    confirmPassword: 'Confirm Password',
    days: 'Days:',
    daysValidations: 'You must enter at least one day under Availale Days!',
    editInformation: 'Edit',
    emailAddress: 'Email Address',
    emailConfirmModal:
      'Are you sure that you want to change your current email to',
    firstName: 'First Name',
    forgotPassword: 'I forgot my password.',
    hoursValidations: 'You must enter valid hours format!',
    imageSizeMsg:
      'Images smaller than 500x500 may look stretched or pixelated.',
    jobTitle: 'Job Title',
    jpegAndPngAllowed: 'Only JPG and NPEG images are allowed.',
    lastName: 'Last Name',
    mfaConfiguration: 'Configured',
    mfaMessage:
      'You are using this data for MFA, you need to perform a verification in order to change it',
    mfaMessage1: 'You are currently using ',
    mfaMessage2: ' as MFA method.',
    mfaMethodsAvailable: 'MFA methods',
    mfaMethodsNotAvailable: 'MFA method is not enabled yet.',
    mfaTwoFactorMsg:
      'Multi-Factor Authentication (MFA) adds an additional layer of security to your account by requiring more than just a password to sign in.',
    mfaTwoFactorMsgThree:
      'This extra step, which can be completed via email or SMS, provides stronger protection against unauthorized access.',
    mfaTwoFactorMsgTwo:
      'Multi-Factor Authentication (MFA) enhances your account security by requiring additional verification beyond just a password.',
    settings: 'Settings',
    mfaUpdateModalsMsg: 'Multi-Factor Authentication',
    mfaUpdateSuccessMsg: 'MFA is updated successfully',
    newPassword: 'New Password',
    noSkills: 'You have no assigned skills',
    oldPassword: 'Old Password',
    passwordUpdate: 'Your password has been successfully updated!',
    passwordUpdated: 'Password updated!',
    phone: 'Phone Number',
    phoneNumber: 'Phone Number',
    picture: 'Profile Picture',
    profileInformation: 'Profile Information',
    profileSubtitle: 'Update general information and photo here',
    profileTitle: 'General Information',
    request: 'Request',
    requestChanges: 'Request changes',
    requestMessage: 'Your request is being processed.',
    requestPop: 'See request',
    rol: 'Roles',
    roles: 'Roles',
    saveInformation: 'Save',
    skills: 'Skills',
    successfulMsg: 'Profile updated!',
    successfulMsgPicture: 'Picture updated!',
    timeEnd: 'Time End',
    timeStart: 'Time Start',
    timeZone: 'Timezone',
    timezoneUpdated: 'Timezone Updated!',
    twoFactorAuthentication: 'Two Factor Authentication',
    uploadPicture: 'Upload picture',
    uploadTimezone: 'Update Timezone',
    userSubtitle: 'Update user information here',
    userTitle: 'User Information'
  },
  PROJECT: {
    ASSIGNMENTS: {
      medianRate: 'Median Rate',
      requiredResources: 'Resources Required'
    }
  },
  REQUEST: {
    approve: 'Approve',
    availableDays: 'Available Days',
    close: 'Close',
    current: 'Current',
    reject: 'Reject',
    requestDetail: 'Request Detail',
    requested: 'Requested',
    requests: 'Requests',
    timeEnd: 'Time End',
    timeStart: 'Time Start',
    timezone: 'Timezone'
  },
  ROLES: {
    Administrator: 'Administrator',
    BusinessDevelopment: 'Business Development',
    Finance: 'Finance',
    Operations: 'Operations',
    Sales: 'Sales',
    SuperUser: 'SuperUser',
    Supervisor: 'Supervisor',
    Technician: 'Technician'
  },
  SELECT_ICONS: {
    favorite: 'Favorite',
    home: 'Home',
    star: 'Star'
  },
  STATUS: {
    highPriority: 'High Priority',
    lowPriority: 'Low Priority',
    mediumPriority: 'Medium Priority',
    notRequired: 'Not Required'
  },
  SUMMARY_DRAWER: {
    createNewElementError: 'Error creating new'
  },
  TECHNICIAN_VIEW: {
    DASHBOARD: {
      title: 'Assignments'
    }
  },
  TEST: {
    name: 'Bluberry'
  },
  TIMESHEET: {
    actual: 'Actual',
    actualEnd: 'Actual End',
    actualStart: 'Actual Start',
    assignment: 'Assignment',
    client: 'Client',
    clientName: 'Client Name',
    companyName: 'Company Name',
    customer: 'Customer',
    customerName: 'Customer Name',
    date: 'Date',
    error: 'Something went wrong, try again later',
    exportCSV: 'Export CSV',
    hhmm: '(hh:mm)',
    hhmmss: '(hh:mm:ss)',
    hourScheduled: 'Hour Scheduled',
    hourlyRate: 'Hourly Rate ($)',
    item: 'Item',
    laborHourCost: 'Labor Hour Cost',
    noDataCard: 'No Time Entries Yet',
    noDataCardDescription: 'All tasks are being handled on time',
    paymentTotal: 'Payment Total ($)',
    project: 'Project',
    report: 'Report',
    review: 'Review',
    schedule: 'Scheduled',
    scheduleEnd: 'Scheduled End',
    scheduleStart: 'Scheduled Start',
    status: 'Status',
    taskName: 'Task Name',
    technician: 'Technician',
    technicianName: 'Technician Name',
    timesheetTitle: 'Timesheet',
    totalCost: 'Total Cost',
    totalHours: 'Total Hours',
    totalHoursScheduled: 'Total Hours Scheduled',
    totalHoursWorked: 'Total Hours Worked',
    totalSchedule: 'Total Schedule',
    totalTask: 'Total Task',
    totalTime: 'Total Time',
    totalWorked: 'Total Worked',
    travelTime: 'Travel Time',
    type: 'Type',
    variance: 'Variance',
    varianceAverage: 'Variance Average',
    variancePercentage: 'Variance %',
    varianceTotal: 'Variance Total',
    view: 'View'
  },
  TOOLTIP: {
    CUSTOMER: {
      onboardingCompleted: 'This client has completed the onboarding',
      resendOnboarding: 'Resend invitation for onboarding'
    },
    GENERAL: {
      activate: 'Activate this user',
      archive: 'Archive this item',
      unarchive: 'Unarchive this item',
      unblock: 'Unblock this user'
    },
    INSIGHTS: {
      map: 'View location'
    },
    INVOICE: {
      payment: 'Make your payment',
      paymentLinkErrorForNoReference:
        'Unable to generate the payment link because there is no reference available.',
      paymentLinkSuccessWithReference:
        'Reference available to generate payment link.'
    },
    PROJECT: {
      assignmentsPercent: "{0}% of the technician's skills match",
      edit: 'Edit',
      noMatchTimezone:
        'This technician is not located in the same service address timezone',
      notesTitle: 'Leave a note here',
      taskApprovalRequired:
        'This task requires approval, go to timesheet review'
    },
    PROPOSAL: {
      messages: 'Open messages',
      openDetailButton: 'Proposal details',
      proposal: 'Proposal',
      proposalSeen: 'Proposal seen',
      proposalUnseen: 'Proposal not seen',
      recallButton: 'Recall this proposal',
      resendButton: 'Resend proposal',
      watchers: 'Watch followers'
    },
    WIDGET: {
      addWidget: 'Add this widget',
      removeWidget: 'Remove this widget'
    }
  },
  WIDGETS: {
    EMPTY: {
      action: 'Add a wiget',
      description:
        'You can view the widget description here and click the arrow for more details.',
      name: 'Widget title'
    },
    ERROR: {
      action: 'Try again',
      description:
        'Unable to retrieve widget information. Reload or try again later.',
      name: 'Widget error'
    },
    OPTIONS: {
      CHART: {
        Area: 'Area',
        Bar: 'Bar',
        Donut: 'Donut',
        Line: 'Line',
        Pie: 'Pie',
        Radar: 'Radar',
        RadialBar: 'Radial Bar',
        null: 'N/A'
      },
      INTERVAL: {
        Annually: 'Annually',
        Biannually: 'Biannually',
        Biweekly: 'Biweekly',
        Historical: 'Historical',
        Monthly: 'Monthly',
        Quarterly: 'Quarterly',
        Weekly: 'Weekly',
        null: 'N/A'
      },
      PERIOD: {
        Historical: 'Historical',
        ThisMonth: 'This Month',
        ThisWeek: 'This Week',
        ThisYear: 'This Year',
        Today: 'Today',
        null: 'N/A'
      },
      PRIMARYDATATYPE: {
        Assignments: 'Assignments',
        CalendarEventIteration: 'Calendar visits',
        Clients: 'Clients',
        Invoices: 'Invoices',
        Leads: 'Leads',
        Projects: 'Projects',
        Proposals: 'Proposals',
        Tasks: 'Tasks',
        TasksIteration: 'Tasks in started visits',
        Technicians: 'Technicians',
        Timesheet: 'Timesheet',
        Users: 'Users',
        Visits: 'Visits',
        null: 'N/A'
      },
      SECONDARYDATATYPE: {
        Active: 'Active',
        Actual: 'Actual',
        Amount: 'Amount',
        Archived: 'Archived',
        Arrived: 'Arrived',
        Assigned: 'Assigned',
        AssignmentStatus: 'Assignment',
        Blocked: 'Blocked',
        Completed: 'Completed',
        CountOverdue: 'Count Overdue',
        CountPaid: 'Count Paid',
        CountPending: 'Count Pending',
        CounterOffer: 'Counter offer',
        Created: 'When was created',
        CreatedAt: 'Created At',
        EndDate: 'End date',
        HourlyRate: 'Hourly Rate',
        Invitations: 'Invitations',
        Invoices: 'Invoices',
        Lead: 'Lead',
        LocationConfirmed: 'Location Confirmed',
        MissingToday: 'Missing Today',
        OnRoad: 'On road',
        Overdue: 'Overdue',
        Overtime: 'Overtime',
        Paid: 'Paid',
        Pending: 'Pending',
        Project: 'Project',
        Proposal: 'Proposal',
        ProposalStatus: 'Proposal status',
        Requests: 'Requests',
        Review: 'Review',
        Roles: 'Roles',
        ScheduleVsActual: 'Schedule Vs Actual',
        Seen: 'Seen',
        Skills: 'Skills',
        StartDate: 'Start date',
        Started: 'Started',
        Status: 'Status',
        ThisWeek: 'This Week',
        Timezone: 'Timezone',
        TodayTasks: 'Today Tasks',
        Total: 'Total',
        TotalToday: 'Total Today',
        TravelStatus: 'Travel Status',
        TravelTime: 'Travel Time',
        Type: 'Type',
        UnAssigned: 'Unassigned',
        User: 'User',
        null: 'N/A'
      }
    },
    addWidget: 'Add widget',
    chartType: 'Chart',
    configuration: 'Configuration',
    description: '{0} your widgets here by playing with the selectors.',
    descriptionLabel: 'Description',
    itemCounter: 'Intervals',
    name: 'Name',
    periodConfig: 'Period',
    primaryDataType: 'Structure',
    secondaryDataType: 'Field',
    timeConfig: 'Interval',
    timeConfigTitle: 'Time configuration',
    title: '{0} a Widget',
    unprocessableChart: 'The chart configuration could not be processed',
    widget: 'Widget'
  }
};
