import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import ManualPricing from './services/ManualPricing';
import ServiceTime from './services/ServiceTime';
import useNavigationStore from '../../../store/zustand/navigation';
import { formatDate, getFrequencyDays } from '../../../utils/general';
import Api from '../../../api';
import { CreateLeadSWR, EditLeadSWR } from './components/LeadSWR';
import Banner from '../../../components/shared/Banner';
import { secondaryColors } from '../../../theme/themeConfig';

interface Props {
  saveStepper: ({ next }: any) => void;
  sendValues: React.Dispatch<React.SetStateAction<any>>;
  setServiceIndex: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const Services: React.FC<Props> = function Services({
  saveStepper,
  sendValues,
  setServiceIndex,
  setValues,
  values
}) {
  const theme = useTheme();
  const [step, setStep] = useState<number>(1);
  const [serviceTimeId, setServiceTimeId] = useState<number>(0);
  const [currentServiceTime, setCurrentServiceTime] = useState<number>(-1);
  const [showSummary, setShowSummary] = useState<boolean>(true);
  const [createServiceTime, setCreateServiceTime] = useState<boolean>(false);
  const [serviceTimeUpdate, setServiceTimeUpdate] = useState<boolean>(false);
  const [dataresult, setDataresult] = useState<any>(null);
  const [dataresultEdit, setDataresultEdit] = useState<any>(null);
  const [letDeleteServiceTimeId, setLetDeleteServiceTimeId] =
    useState<number>(-1);
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const { id } = values;
  const [serviceTimeApproved, setServiceTimeApproved] =
    useState<boolean>(false);

  useEffect(() => {
    setServiceTimeUpdate(false);
    setCreateServiceTime(false);
    if (values.manualPricing || values.projectType === 'Ad-Hoc') {
      setCurrentServiceTime(0);
      setServiceTimeId(values.serviceTimes[0]?.id);
      setStep(1);
    } else {
      setCurrentServiceTime(-1);
      if (
        !values.serviceTimes ||
        (values.serviceTimes &&
          (values.serviceTimes?.length === 0 || !values.serviceTimes[0]?.id))
      ) {
        setStep(1);
      }
    }
    if (step === 1 && currentServiceTime === -1) {
      if (values?.serviceTimes?.length > 0) {
        const cleanedServicesTimes = values?.serviceTimes?.filter(
          (item: any) => item?.id && typeof item?.id === 'number'
        );
        setValues({ ...values, serviceTimes: cleanedServicesTimes });
      }
    }
  }, []);

  useEffect(() => {
    if (dataresult && dataresult.id) {
      setCreateServiceTime(false);
      setServiceTimeUpdate(false);
      setServiceTimeId(dataresult.id);
      setCurrentServiceTime(dataresult.id);

      const newAuxServiceTimes = values.serviceTimes;

      newAuxServiceTimes[currentServiceTime] = dataresult;

      setValues({
        ...values,
        serviceTimes: newAuxServiceTimes
      });
      setDataresult(null);
      setCurrentServiceTime(-1);
      saveStepper({ next: 1 });
    }
  }, [dataresult]);

  useEffect(() => {
    if (dataresultEdit && dataresultEdit?.id) {
      setCreateServiceTime(false);
      setServiceTimeUpdate(false);
      // setServiceTimeId(values.serviceTimes[currentServiceTime].id);
      setCurrentServiceTime(-1);
      const newAuxServiceTimes = values.serviceTimes;
      newAuxServiceTimes[currentServiceTime] = dataresultEdit;

      setValues({
        ...values,
        serviceTimes: newAuxServiceTimes
      });

      setDataresultEdit(null);
      if (values.manualPricing || values.projectType === 'Ad-Hoc') {
        // setServiceTimeId(values.serviceTimes[0].id);
        saveStepper({ next: 2 });
      } else {
        saveStepper({ next: 1 });
      }
    }
  }, [dataresultEdit]);

  const { data: deleteResult } = useSWR(
    letDeleteServiceTimeId > -1
      ? { id, serviceTimeId: letDeleteServiceTimeId }
      : null,
    (...args) => Api.LeadServiceTimesApi.deleteLeadServicesTime(...args)
  );

  useEffect(() => {
    if (values?.serviceTimes?.length > 0) {
      const cleanedServicesTimes = values?.serviceTimes?.filter(
        (item: any) => item?.id && typeof item?.id === 'number'
      );
      setValues({ ...values, serviceTimes: cleanedServicesTimes });
    }
    setLetDeleteServiceTimeId(-1);
  }, [deleteResult]);

  function getManualPricing() {
    return (
      <ManualPricing
        saveStepper={saveStepper}
        setServiceTimeUpdate={setServiceTimeUpdate}
        setValues={setValues}
        values={values}
      />
    );
  }

  const getScreen = () => {
    if (currentServiceTime !== -1) {
      switch (step) {
        case 1:
          return (
            <ServiceTime
              indexServiceTime={currentServiceTime}
              projectEnd={values.projectEndDate}
              projectStart={values.projectStartDate}
              projectType={values.projectType || ''}
              saveStepper={saveStepper}
              serviceTimeId={serviceTimeId}
              setCreateServiceTime={setCreateServiceTime}
              setCurrentServiceTime={setCurrentServiceTime}
              setServiceTimeUpdate={setServiceTimeUpdate}
              setValues={(newValues) => {
                const { serviceTimes } = values;
                serviceTimes[currentServiceTime] = newValues;
                setValues({ ...values, serviceTimes });
              }}
              values={values}
            />
          );

        default:
          return 'Loading';
      }
    }
    return <Box />;
  };

  const getServices = (serviceTime: any) => {
    const { services } = serviceTime;

    if (services && services.length > 0) {
      let auxServices = '';
      services.map((service: any) => {
        if (service?.name && service?.name !== '') {
          if (auxServices === '') {
            auxServices = service.name;
          } else {
            auxServices = `${auxServices}, ${service.name}`;
          }
        }

        return null;
      });

      if (auxServices !== '') {
        return auxServices;
      }
    }

    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const getIndividualTasks = (serviceTime: any) => {
    const { individualTasks } = serviceTime || {};
    if (
      individualTasks &&
      individualTasks?.tasks &&
      individualTasks?.tasks?.length > 0
    ) {
      return `${individualTasks.tasks
        .map((task: any) => {
          return task.name;
        })
        .toString()
        .replaceAll(',', ', ')}`;
    }
    return t('LEAD.CREATE.SERVICES.SUMMARY.noData');
  };

  const deleteServiceTime = (index: number) => {
    setConfirmModal(
      () => {
        const { serviceTimes } = values;
        setLetDeleteServiceTimeId(serviceTimes[index].id);
        serviceTimes.splice(index, 1);
        setValues({ ...values, serviceTimes });
      },
      `${t('GENERAL.confirmButton')}`,
      () => {},
      `${t('GENERAL.cancelButton')}`,
      <Box display="flex" flexDirection="column" pl={8} pr={8}>
        <Box p={2}>
          <i className="custom-icon icon-info" style={{ fontSize: 48 }} />
        </Box>
        <Typography variant="primary">
          {t('LEAD.CREATE.SERVICES.SUMMARY.confirmDelete')}
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    let auxServiceTimeApproved = true;
    if (values?.serviceTimes && values?.serviceTimes?.length > 0) {
      values.serviceTimes.forEach((serviceTime: any) => {
        if (!serviceTime?.scheduled && !serviceTime?.isScheduled) {
          auxServiceTimeApproved = false;
        }
      });
    } else {
      auxServiceTimeApproved = false;
    }
    setServiceTimeApproved(auxServiceTimeApproved);
  }, [values]);

  const getStatusColor = (status: string) => {
    return values.status !== 'Converted'
      ? `${theme.palette.success.main}`
      : `${theme.palette.success[300]}`;
  };

  const getSummay = () => {
    return (
      <Box display="flex" flexDirection="column" gap="48px" width="100%">
        <Box display="flex" gap="40px" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="16px">
            <Typography color="primary.main" variant="heading_500">
              {t('LEAD.CREATE.SERVICES.SUMMARY.title')}
            </Typography>
            <Typography color="secondary.400" variant="body_200">
              {t('LEAD.CREATE.SERVICES.SUMMARY.subtitle')}
            </Typography>
          </Box>

          <Box alignItems="flex-end" display="flex" flexShrink={0}>
            <Button
              disabled={values?.readOnly}
              onClick={() => {
                const { serviceTimes } = values;
                if (
                  values &&
                  values?.serviceTimes[0] &&
                  values?.serviceTimes[0]?.id
                ) {
                  serviceTimes.push({});
                }
                setValues({ ...values, serviceTimes });
                setServiceTimeId(-1);
                setCurrentServiceTime(
                  values?.serviceTimes?.length > 0 &&
                    values?.serviceTimes[0]?.id
                    ? (values?.serviceTimes?.length || 0) - 1
                    : 0
                );
              }}
              sx={{ padding: '12px 16px 12px 16px' }}
              variant="primary"
            >
              <Box alignItems="center" display="flex" gap="10px">
                <i className="custom-icon icon-add" />
                <Typography variant="heading_328">
                  {t('LEAD.CREATE.SERVICES.SUMMARY.addServiceTimeButton')}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        {values?.serviceTimes && values?.serviceTimes.length > 0 && (
          <Box display="flex" flexDirection="column" gap="24px" width="100%">
            {values.serviceTimes
              .sort((a: any, b: any) => a.id - b.id)
              .map((serviceTime: any, index: number) => {
                if (serviceTime?.id && typeof serviceTime?.id === 'number') {
                  return (
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="24px"
                      key={`min-box-${index + 1}`}
                      padding="16px 24px 16px 24px"
                      sx={{
                        border: 1,
                        borderColor: theme.palette.secondary[400],
                        borderRadius: 1
                      }}
                      width="100%"
                    >
                      <Box
                        alignItems="center"
                        display="flex"
                        gap="16px"
                        justifyContent="space-between"
                      >
                        <Box
                          display="grid"
                          gridTemplateColumns="174px 1fr 1fr"
                          sx={{ gridColumnGap: '16px' }}
                        >
                          <Typography variant="secondary-semi">
                            {`${t(
                              'LEAD.CREATE.SERVICES.SUMMARY.serviceTime'
                            )} #${index + 1}`}
                          </Typography>

                          <Typography color="mainText.300" variant="body_300">
                            {getServices(serviceTime)}
                          </Typography>

                          <Typography color="mainText.300" variant="body_300">
                            {serviceTime.frequency}
                          </Typography>
                        </Box>

                        <Box display="flex" gap="24px">
                          <Button
                            disabled={values?.readOnly}
                            onClick={() => {
                              if (!values?.readOnly) {
                                setServiceIndex(index);
                                saveStepper({ next: 2 });
                              }
                            }}
                            variant="warning-text"
                          >
                            <Box alignItems="center" display="flex" gap="10px">
                              <i className="custom-icon icon-profile" />
                              <Typography variant="heading_328">
                                {serviceTime.scheduled ||
                                serviceTime.isScheduled
                                  ? t('LEAD.CREATE.SERVICES.SUMMARY.scheduled')
                                  : t('LEAD.CREATE.SERVICES.SUMMARY.pending')}
                              </Typography>
                            </Box>
                          </Button>

                          <Button
                            disabled={values?.readOnly}
                            onClick={() => {
                              setCreateServiceTime(false);
                              setCurrentServiceTime(index);
                              setServiceTimeId(serviceTime.id);
                              setServiceTimeUpdate(false);
                            }}
                            sx={{ minWidth: '114px' }}
                            variant="text"
                          >
                            <Box alignItems="center" display="flex" gap="10px">
                              <i className="custom-icon icon-edit" />
                              <Typography variant="heading_328">
                                {t('GENERAL.editButton')}
                              </Typography>
                            </Box>
                          </Button>

                          <Button
                            disabled={values?.readOnly}
                            onClick={() => {
                              deleteServiceTime(index);
                            }}
                            variant="cancel-text"
                          >
                            <Box alignItems="center" display="flex" gap="10px">
                              <i className="custom-icon icon-delete" />
                              <Typography variant="heading_328">
                                {t('GENERAL.deleteButton')}
                              </Typography>
                            </Box>
                          </Button>
                        </Box>
                      </Box>

                      <div
                        style={{
                          backgroundColor: secondaryColors.SECONDARY_1,
                          height: '1px',
                          width: '100%'
                        }}
                      />

                      <Box
                        display="grid"
                        gridTemplateColumns="190px 170px 110px 1fr"
                        sx={{ gridRowGap: '32px' }}
                      >
                        <Typography color="primary.main" variant="heading_300">
                          {values.projectType !== 'Ad-Hoc'
                            ? t('LEAD.CREATE.startDate')
                            : 'Service Day'}
                          :
                        </Typography>

                        <Typography color="mainText.300" variant="body_300">
                          {formatDate(serviceTime.startDate)}
                        </Typography>

                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.endDate')}:
                        </Typography>

                        <Typography color="mainText.300" variant="body_300">
                          {formatDate(serviceTime.endDate)}
                        </Typography>

                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.SERVICES.SelectedDays')}:
                        </Typography>

                        <Box
                          gridColumn="span 3"
                          style={{ fontSize: '16px', lineHeight: '20px' }}
                        >
                          <Typography color="mainText.300" variant="body_300">
                            {values.projectType !== 'Ad-Hoc'
                              ? getFrequencyDays(serviceTime)
                              : serviceTime.startDate}
                          </Typography>
                        </Box>

                        {/* Aditional Tasks */}
                        <Typography color="primary.main" variant="heading_300">
                          {t('LEAD.CREATE.SERVICES.SUMMARY.adhocTasks')}:
                        </Typography>

                        <Box
                          gridColumn="span 3"
                          style={{ fontSize: '16px', lineHeight: '20px' }}
                        >
                          <Typography color="mainText.300" variant="body_300">
                            {getIndividualTasks(serviceTime)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                }

                return null;
              })}
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          gap="16px"
          justifyContent="space-between"
        >
          {!serviceTimeApproved && (
            <Banner
              icon
              text={t('LEAD.CREATE.SERVICES.SUMMARY.warning')}
              textCustomStyle={{ fontSize: '1rem', fontWeight: '400' }}
              type="warning"
            />
          )}
          <Box>
            <Button
              disabled={!serviceTimeApproved || values?.readOnly}
              onClick={() => {
                saveStepper({ next: 3 });
              }}
              sx={{ width: 300 }}
              variant="primary"
            >
              <Typography variant="primary">
                {t('LEAD.CREATE.SERVICES.SUMMARY.continueButton')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (currentServiceTime === -1) {
      setShowSummary(true);
    } else {
      setShowSummary(false);
    }
  }, [currentServiceTime]);

  useEffect(() => {
    if (`${showSummary}` === 'true') {
      if (values?.serviceTimes?.length > 0) {
        const cleanedServicesTimes = values?.serviceTimes?.filter(
          (item: any) => item?.id && typeof item?.id === 'number'
        );
        setValues({ ...values, serviceTimes: cleanedServicesTimes });
      }
    }
  }, [showSummary]);

  return (
    <Box>
      {createServiceTime && (
        <CreateLeadSWR
          createServiceTime={createServiceTime}
          currentServiceTime={currentServiceTime}
          id={id}
          setDataresult={setDataresult}
          values={values}
        />
      )}
      {serviceTimeUpdate && (
        <EditLeadSWR
          currentServiceTime={currentServiceTime}
          editServiceTime={serviceTimeUpdate}
          leadId={id}
          serviceTimeId={serviceTimeId}
          setDataresultEdit={setDataresultEdit}
          values={values}
        />
      )}
      {values.manualPricing &&
      (values.projectType === 'adhoc' || values.projectType === 'Ad-Hoc') ? (
        getManualPricing()
      ) : (
        <Box>
          {showSummary ? (
            getSummay()
          ) : (
            <Box>{!values.manualPricing && getScreen()}</Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Services;
