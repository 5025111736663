/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useRef, useState } from 'react';
import Calendar from '@toast-ui/react-calendar';
import { isMobile } from 'react-device-detect';
import {
  Button,
  Container,
  Box,
  Typography,
  MenuItem,
  useTheme
} from '@mui/material';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { getMonthString } from '../../utils/general';
import SelectInput from '../../components/form/SelectInput';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import CalendarDetailPopup from '../../components/calendar/CalendarDetailPopup';
import useCalendarStore from '../../store/zustand/calendar';
import api from '../../api';
import { weekDays } from '../../helpers/weekDays';
import useAuthStore from '../../store/zustand/auth';
import { TimeFormatEnum } from '../../api-client/generated';

const MARGIN_Y = 20;
const MARGIN_X = 100;

const MobileStyle = {
  margin: '20px',
  width: 'auto'
};

const DesktopStyle = {
  marginX: `${MARGIN_X}px`,
  marginY: `${MARGIN_Y}px`,
  width: 'auto'
};

const CalendarView: React.FC = function CalendarView() {
  const monthArray = getMonthString('short');
  const [displayMonth, setDisplayMonth] = useState<string>();
  const [displayYear, setDisplayYear] = useState<number>();
  const [projectFilter, setProjectFilter] = useState('');
  const [customerFilter, setCustomerFilter] = useState('customer');
  const [taskFilter, setTaskFilter] = useState('task');
  const [typeFilter, setTypeFilter] = useState('type');
  const [priorityFilter, setPriorityFilter] = useState('priority');
  const [servicesFilter, setServicesFilter] = useState('services');
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [getCalendarEvents, setGetCalendarEvents] = useState(false);
  const [detailPopUpData, setDetailPopUpData] = useState<any>();
  const [calendarEventsList, setCalendarEventsList] = useState<any[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<any>();
  const [calendarEventsProjectsList, setCalendarEventsProjectsList] = useState<
    any[]
  >([]);
  const { selectedDate, setSelectedDate, selectedView, setSelectedView } =
    useCalendarStore((state) => state);

  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const calendar = useRef<Calendar>(null);
  const calendarContainer = useRef<HTMLElement>(null);
  const pageContainer = useRef<HTMLDivElement>(null);
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const { palette, spacing } = useTheme();
  const [selectedMonth, setSelectedMonth] = useState('');
  const userState = useAuthStore((state) => state);

  const theme = {
    common: {
      dayName: {
        color: palette.grey[500]
      },
      today: {
        color: 'red'
      }
    }
  };

  const template = {
    monthDayName(model: any) {
      return (
        <span
          style={{
            color: palette.grey[500],
            fontSize: spacing(1.5),
            fontWeight: 700
          }}
        >
          {weekDays[model.day].name}
        </span>
      );
    },
    monthGridHeader(model: any) {
      const date = parseInt(model.date.split('-')[2], 10);
      return (
        <span
          style={{
            color: model.isToday ? palette.alert?.[300] : palette.primary[500],
            fontSize: spacing(2),
            fontWeight: 700
          }}
        >
          {date}
        </span>
      );
    },
    monthGridHeaderExceed(hiddenEvents: any) {
      return (
        <span style={{ color: palette.informative[500] }}>
          +{hiddenEvents} more
        </span>
      );
    },
    monthMoreTitleDate(moreTitle: any) {
      const { date } = moreTitle;
      return <span>{date}</span>;
    },
    time(event: any) {
      return (
        <div
          style={{
            backgroundColor: event.backgroundColor,
            color: event.color,
            fontSize: spacing(2),
            fontWeight: 700,
            left: 3,
            margin: '0px 5px 0px 5px',
            overflow: 'hidden',
            padding: '5px 5px 0px 4px',
            position: 'absolute',
            width: '-webkit-fill-available',
            zIndex: 10
          }}
        >
          {event.title}
        </div>
      );
    },
    weekDayName(model: any) {
      return `
      <span style="font-size: 16px; font-weight: 700; color: ${
        palette.primary[400]
      }">${model.date}</span>
      &nbsp;
      <span style="font-weight: 700; font-size: 12px; color: ${
        palette.grey[400]
      }">${weekDays[model.day].name}</span>
      `;
    }
  };

  const { data: calendarEventsData, error: getCalendarEventsError } = useSWR(
    getCalendarEvents && selectedMonth
      ? {
          day: `${displayYear}-${selectedMonth}-01`,
          palette,
          timeFormat: userState?.timeFormat,
          url: 'api/CalendarEvents'
        }
      : null,
    api.CalendarApi.calendarGetProjectsCalendarEvents
  );

  //= ================= FUNCTIONS =============================

  const updateDisplayDate = () => {
    const calendarInstance = calendar.current?.getInstance();
    const date = calendarInstance?.getDate();
    const month = date?.getMonth() ?? 0;
    const year = date?.getFullYear() ?? 0;
    const cero = month < 9 ? '0' : '';
    setDisplayMonth(monthArray[month]);
    setSelectedMonth(`${cero}${String(Number(month) + 1)}`);
    setDisplayYear(year);
    setGetCalendarEvents(true);
  };

  //= ================ USEEFFECT ========================

  useEffect(() => {
    if (calendarEventsData && !getCalendarEventsError) {
      setSelectedMonth('');
      setProjectsList(calendarEventsData.projects);

      const eventsInLocalTimezone = calendarEventsData.events.map(
        (item: any) => {
          const auxStart = new Date(`${item.start}`);
          auxStart.setTime(
            auxStart.getTime() + calendarEventsData.timezone * 60000
          );
          const auxEnd = new Date(`${item.start}`);
          auxEnd.setTime(
            auxEnd.getTime() + calendarEventsData.timezone * 60000
          );

          return {
            ...item,
            end: dayjs(auxEnd).format('MM/DD/YYYY,  h:mm:ss A'),
            start: dayjs(auxStart).format('MM/DD/YYYY,  h:mm:ss A')
          };
        }
      );
      setCalendarEventsList(eventsInLocalTimezone);
      setCalendarEvents(calendarEventsData);
      setCalendarEventsProjectsList(calendarEventsData.eventsProjects);
      setGetCalendarEvents(false);
    }
  }, [calendarEventsData]);

  useEffect(() => {
    const calendarInstance = calendar.current?.getInstance();
    calendarInstance?.setDate(moment().toDate());
    setSelectedDate(moment().toDate());
    updateDisplayDate();
    setGetCalendarEvents(true);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      const calendarInstance = calendar.current?.getInstance();
      calendarInstance?.setDate(selectedDate);
    }
  }, [selectedDate]);

  const getClickPosition = (e: MouseEvent) => {
    let xPos = 0;
    let yPos = 0;

    if (pageContainer.current) {
      xPos = pageContainer.current?.getBoundingClientRect().x - MARGIN_X;
      yPos = pageContainer.current?.getBoundingClientRect().y - MARGIN_Y;
    }
    const parentPosition = {
      x: xPos,
      y: yPos
    };

    const xPosition = e.clientX - parentPosition.x;
    const yPosition = e.clientY - parentPosition.y;

    setClickPosition({
      x: xPosition,
      y: yPosition
    });
  };

  return (
    <Container
      id="calendar-page-container"
      ref={pageContainer}
      sx={isMobile ? MobileStyle : DesktopStyle}
    >
      <Box
        alignItems="center"
        display="flex"
        key="calendar-view-arrows-box"
        marginBottom="1.5rem"
      >
        <Button
          onClick={() => {
            const calendarInstance = calendar.current?.getInstance();
            calendarInstance?.prev();
            updateDisplayDate();
          }}
        >
          <ArrowBackIosIcon color="primary" />
        </Button>
        <Typography color="primary.main" fontSize="24px" fontWeight="bold">
          {displayMonth} {displayYear}
        </Typography>
        <Button
          onClick={() => {
            const calendarInstance = calendar.current?.getInstance();
            calendarInstance?.next();
            updateDisplayDate();
          }}
        >
          <ArrowForwardIosIcon color="primary" />
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        key="calendar-view-filters-box"
        marginBottom="1.5rem"
      >
        <Box display="flex" gap="0.5rem" key="calendar-view-filters-box-box">
          {/* <Box key="calendar-view-filters-projects-box-box" width="120px">
            <SelectInput
              fullWidth
              onChange={(event: any) =>
                setProjectFilter(event.target.value as string)
              }
              value={projectFilter}
            >
              <MenuItem disabled value="project">
                Project
              </MenuItem>
              {projectsList &&
                projectsList?.length > 0 &&
                projectsList.map((project) => (
                  <MenuItem key={`project-id-${project.id}`} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
            </SelectInput>
          </Box>
          <Box key="calendar-view-filters-customer-box-box" width="120px">
            <SelectInput
              fullWidth
              onChange={(event: any) =>
                setCustomerFilter(event.target.value as string)
              }
              value={customerFilter}
            >
              <MenuItem disabled value="customer">
                Customer
              </MenuItem>
            </SelectInput>
          </Box>
          <Box key="calendar-view-filters-task-box-box" width="120px">
            <SelectInput
              fullWidth
              onChange={(event: any) =>
                setTaskFilter(event.target.value as string)
              }
              value={taskFilter}
            >
              <MenuItem disabled value="task">
                Task
              </MenuItem>
            </SelectInput>
          </Box>
          <Box key="calendar-view-filters-type-box-box" width="120px">
            <SelectInput
              fullWidth
              onChange={(event: any) =>
                setTypeFilter(event.target.value as string)
              }
              value={typeFilter}
            >
              <MenuItem disabled value="type">
                Type
              </MenuItem>
            </SelectInput>
          </Box>
          <Box key="calendar-view-filters-priority-box-box" width="120px">
            <SelectInput
              fullWidth
              onChange={(event: any) =>
                setPriorityFilter(event.target.value as string)
              }
              value={priorityFilter}
            >
              <MenuItem disabled value="priority">
                Priority
              </MenuItem>
            </SelectInput>
          </Box>
          <Box key="calendar-view-filters-services-box-box" width="120px">
            <SelectInput
              fullWidth
              onChange={(event: any) =>
                setServicesFilter(event.target.value as string)
              }
              value={servicesFilter}
            >
              <MenuItem disabled value="services">
                Services
              </MenuItem>
            </SelectInput>
          </Box> */}
          {}
        </Box>
        <Box key="calendar-view-filters-view-box-box" width="115px">
          <SelectInput
            onChange={(event: any) => setSelectedView(event.target.value)}
            value={selectedView}
          >
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="day">Day</MenuItem>
          </SelectInput>
        </Box>
      </Box>

      {calendarEventsList && (
        <Box
          id="calendar-container"
          key="calendar-container-box"
          ref={calendarContainer}
        >
          <Calendar
            isReadOnly
            calendars={projectsList}
            events={
              selectedView === 'month'
                ? calendarEventsProjectsList
                : calendarEventsList
            }
            height="800px"
            onClickEvent={(e) => {
              getClickPosition(e.nativeEvent);
              setDetailPopUpData(e.event);
              if (e.event?.id === detailPopUpData?.id) {
                setOpenDetailPopup(!openDetailPopup);
              } else {
                setOpenDetailPopup(true);
              }
            }}
            ref={calendar}
            template={template}
            theme={theme}
            usageStatistics={false}
            view={selectedView}
            week={{
              taskView: false
            }}
          />
          {openDetailPopup && (
            <CalendarDetailPopup
              calendarEventsData={calendarEvents}
              detailPopUpData={detailPopUpData}
              isOpen={openDetailPopup}
              popUpPosition={clickPosition}
              selectedView={selectedView}
              setOpenDetailPopup={setOpenDetailPopup}
            />
          )}
        </Box>
      )}
    </Container>
  );
};

export default CalendarView;
