/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../../style/App.scss';
import { Autocomplete, Typography, Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface AutoProps {
  options: any;
  setOptions: (value: any) => void;
  setSearch: (value: React.SetStateAction<string>) => void;
  setValueSearched: (value: any) => void;
  value: any;
}

const AutoCompleteSearch: FC<AutoProps> = function AutoCompleteSearch({
  options,
  setOptions,
  setSearch,
  setValueSearched,
  value: valueSearch
}) {

  const [inputValue, setinputValue] = useState<any>(valueSearch || '');
  useEffect(() => {
    setinputValue(valueSearch);
  }, [valueSearch]);
  return (
    <Autocomplete
      autoComplete
      disableClearable
      disablePortal
      filterSelectedOptions
      freeSolo
      includeInputInList
      filterOptions={(x) => x}
      getOptionLabel={(option: any) => option?.name! ?? option}
      id="combo-box-demo"
      noOptionsText="No results"
      onChange={(event: any, newValue: any | null) => {
        setOptions(newValue ? [newValue, options] : options);
        setValueSearched(newValue);
      }}
      onInputChange={(event: any, newValue: any | null) => {
        setSearch(newValue);
      }}
      options={options}
      renderInput={(params) => (
        <Box display="flex" gap="0px">
          <SearchIcon
            fontSize="small"
            sx={{
              color: '#7D8090',
              marginLeft: '16px',
              marginTop: '12px'
            }}
          />
          <TextField
            {...params}
            autoFocus
            placeholder="search"
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                borderStyle: 'hidden'
              },
              backgroundColor: 'white !important',
              fontWeight: 'bold',
              height: '48px !important'
            }}
          />
        </Box>
      )}
      renderOption={(props, option: any) => (
        <li {...props}>
          <Typography variant="body_300">{option?.name}</Typography>
        </li>
      )}
      sx={{
        '& input': {
          fontSize: '16px',
          height: '16px'
        },
        backgroundColor: 'white !important',
        border: '1px solid #DDDDDD',
        borderRadius: '0.5em',
        width: 300
      }}
      value={inputValue}
    />
  );
};

export default AutoCompleteSearch;
