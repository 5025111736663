import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface EditButtonProp {
  customStyle?: object;
  disabled?: boolean;
  handleOpenDraw: any;
  tooltip?: string | undefined | null;
}

const EditButton: React.FC<EditButtonProp> = function EditButton({
  handleOpenDraw,
  tooltip,
  disabled,
  customStyle
}) {
  return (
    <Tooltip arrow title={tooltip}>
      <Button
        disabled={disabled}
        onClick={handleOpenDraw}
        style={{ minWidth: '24px', ...customStyle }}
        variant="table"
      >
        <Typography color="secondary.main" variant="primary-bold">
          <i className="custom-icon icon-edit" />
        </Typography>
      </Button>
    </Tooltip>
  );
};

EditButton.defaultProps = {
  customStyle: {},
  disabled: false,
  tooltip: ''
};

export default EditButton;
