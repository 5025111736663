import React from 'react';
import '../../style/App.scss';
import { Box, useTheme, Typography, PaletteColor } from '@mui/material';
import {
  generalIconStatus,
  getCustomColorBasedOnStatus
} from '../../utils/general';

interface BannerProps {
  icon?: boolean;
  text: string;
  textCustomStyle?: object;
  type: string;
}

const Banner: React.FC<BannerProps> = function Banner({
  type,
  text,
  icon,
  textCustomStyle
}) {
  const theme = useTheme();

  const currentPalette: PaletteColor = getCustomColorBasedOnStatus(type, theme);

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: `${currentPalette.light}`,
        border: `1px solid ${currentPalette.main}`,
        borderRadius: '8px',
        color: currentPalette.main,
        display: 'flex',
        gap: '16px',
        p: '16px'
      }}
    >
      {icon && (
        <i
          className={`custom-icon icon-${generalIconStatus[type]}`}
          style={{ alignItems: 'center', display: 'flex' }}
        />
      )}
      <Typography sx={{ ...textCustomStyle }} variant="banner-small">
        {text}
      </Typography>
    </Box>
  );
};

Banner.defaultProps = {
  icon: false,
  textCustomStyle: {}
};

export default Banner;
