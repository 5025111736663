/* eslint-disable sort-keys-fix/sort-keys-fix */
import dayjs from 'dayjs';
import moment from 'moment';
import {
  CalendarEventsApi,
  CalendarEventsProjectDetailResponseDTO,
  DatesToAssignDTO,
  GenerateScheduleDTO,
  ProjectAssignResourcesDTO,
  ProjectManagementApi,
  ProjectManagementApiProjectGetProjectsPaginatedRequest,
  ProjectResourcesDTO,
  UpdateCancellationRequestedProjectStatusDTOStatusEnum
} from '../api-client/generated';
import { formatTimesAndCalculateDifference } from '../utils/calendarHelper';
import { sortByPercentAndRate } from '../utils/general';

const apiCaller = new ProjectManagementApi();
const apiCallerCalendar = new CalendarEventsApi();

const getProjectsPaginated = async (
  params: ProjectManagementApiProjectGetProjectsPaginatedRequest
) => {
  if (!params) {
    return null;
  }
  const response = await apiCaller.projectGetProjectsPaginated(params);
  const { data } = response;
  return data;
};

const getProjectById = async (id: string) => {
  if (!id) {
    return null;
  }

  const response: any = await apiCaller.projectGetProjectById({ id });
  const { data } = response;
  return data;
};

function processScheduleResponse(response: any) {
  // Verificar si response es un array
  if (!Array.isArray(response)) {
    console.error('Response needs to be an array');
    return null;
  }

  // Convertir las fechas de formato UTC a formato D/M/YYYY para facilitar el manejo
  const processedResponse = response.map((item: any) => {
    const processedItem = {
      days: item?.days.map((utcDateString: string) => {
        const parts = utcDateString.split('/');
        const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
        return formattedDate;
      }),

      id: item.id,
      range: item?.range,
      // range: item?.range
      //   ?.split(' - ')
      //   ?.map((date: string) => {
      //     return new Date(date).toLocaleDateString(); // Convertir cada fecha del rango a formato local
      //   })
      //   .join(' - '), // Unir las fechas del rango con el separador "-"
      stateDatesList: item?.stateDatesList,
      status: item.status
    };
    return processedItem;
  });
  return processedResponse;
}

const generateSchedule = async (
  startDate: string,
  endDate: string,
  frequency: string,
  frequencyWeek: string,
  frequencyDays: Array<string>,
  projectId: string,
  serviceTimeId: number
): Promise<
  | {
      days: any;
      id: any;
      range: any;
      stateDatesList: any;
      status: any;
    }[]
  | null
  | undefined
  // eslint-disable-next-line consistent-return
> => {
  const startDateUTC = new Date(startDate).toISOString();
  const endDateUTC = new Date(endDate).toISOString();
  // Crear un objeto con las propiedades correctas
  const generateScheduleDTO: GenerateScheduleDTO = {
    startDate: startDateUTC,
    endDate: endDateUTC,
    frequency,
    frequencyWeek,
    frequencyDays,
    projectId,
    serviceTimeId
  };
  if (generateScheduleDTO.projectId !== undefined) {
    const response = await apiCaller.projectGenerateSchedule({
      generateScheduleDTO
    });
    // Extraer la propiedad 'data' del objeto de respuesta procesado
    const { data } = response;
    // Procesar la respuesta para convertir las fechas UTC a formato local
    const responseLocal = processScheduleResponse(data);
    return responseLocal;
  }
};

const getDetailByProject = async (
  projectId: string,
  serviceTimesId: number,
  currentDay: string,
  scheduledOn: any,
  daySelected: string,
  timeFormat?: string
  // eslint-disable-next-line consistent-return
): Promise<CalendarEventsProjectDetailResponseDTO | undefined> => {
  if (!currentDay || currentDay.length === 0) {
    return undefined;
  }

  const parts = currentDay.split('/');

  let setDay;

  // Verificar si parts tiene la longitud esperada (3 elementos)
  if (parts.length === 3) {
    // Construir la fecha con los elementos de parts
    setDay = `${parts[2]}-${(parts[1] || '').padStart(2, '0')}-${(
      parts[0] || ''
    ).padStart(2, '0')}`;
  }

  const daySelectedParts = daySelected.split('/');

  let calendarEvent;
  if (daySelectedParts.length === 3) {
    // Construir la fecha con los elementos de daySelectedParts
    calendarEvent = `${daySelectedParts[2]}-${(
      daySelectedParts[1] || ''
    ).padStart(2, '0')}-${(daySelectedParts[0] || '').padStart(2, '0')}`;
  }

  // Verificar si setDay está definido antes de usarlo
  let currentDaytst;
  if (setDay) {
    currentDaytst = setDay.concat(
      'T',
      scheduledOn === 'Date' ? '00:00:00' : scheduledOn
    );
  }

  const currentDayUTC = dayjs(currentDaytst)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  const currentDate = new Date(currentDayUTC);

  // eslint-disable-next-line no-restricted-globals
  if (calendarEvent && !isNaN(currentDate.getTime())) {
    const response =
      await apiCallerCalendar.calendarEventsFindByDateAndServiceTime({
        date: currentDayUTC,
        projectId,
        serviceTimeId: serviceTimesId,
        calendarEventDay: calendarEvent
      });
    const { data } = response;

    if (response.data?.serviceTime && response.data?.serviceTime?.services) {
      let serviceTimeHours = 0;
      const auxData = {
        ...data,
        serviceTime: {
          ...data.serviceTime,
          services: data.serviceTime.services.map((service: any) => {
            let serviceHours = 0;
            const auxTasks = service.tasks.map((task: any) => {
              let taskHours = 0;
              const auxTaskIte = task.taskIteration.map((taskIte: any) => {
                let checkListCompleted = false;

                if (
                  task?.checklist.length > 0 &&
                  taskIte?.checkList.length > 0 &&
                  taskIte.checkList.every((check: any) => check.checked)
                ) {
                  checkListCompleted = true;
                } else if (task?.checklist.length === 0) {
                  checkListCompleted = true;
                }

                const calendarEventIterationApprovalStatus =
                  data?.calendarEventIterations &&
                  data?.calendarEventIterations.length > 0
                    ? data?.calendarEventIterations.find(
                        (calendarEventIteration: any) =>
                          calendarEventIteration?.technician?.id ===
                          taskIte?.technician?.id
                      )?.approvalStatus
                    : '';

                if (taskIte?.status === 'created') {
                  return {
                    ...taskIte,
                    checkListCompleted,
                    status:
                      calendarEventIterationApprovalStatus !== 'completed'
                        ? taskIte?.status
                        : calendarEventIterationApprovalStatus,
                    workedTime: 0
                  };
                }

                if (taskIte?.startTime) {
                  const taskWorkedTimes = formatTimesAndCalculateDifference(
                    taskIte?.startTime,
                    taskIte?.endTime || new Date().toISOString(),
                    timeFormat
                  );

                  taskHours += taskWorkedTimes.differenceInMinutes;

                  return {
                    ...taskIte,
                    checkListCompleted,
                    status:
                      calendarEventIterationApprovalStatus !== 'completed'
                        ? taskIte?.status
                        : calendarEventIterationApprovalStatus,
                    workedTime: taskWorkedTimes.differenceInMinutes
                  };
                }

                return {
                  ...taskIte,
                  status:
                    calendarEventIterationApprovalStatus !== 'completed'
                      ? taskIte?.status
                      : calendarEventIterationApprovalStatus,
                  workedTime: 0
                };
              });
              serviceHours += taskHours;
              let auxTechnicians: any[] = [];

              if (
                response.data?.assignments &&
                response.data?.assignments.length > 0
              ) {
                auxTechnicians = response.data?.assignments
                  .filter(
                    (assignment: any) => assignment?.leadServiceTask === task.id
                  )
                  .map((assignment: any) => assignment.technician);
              }

              return {
                ...task,
                taskIteration: auxTaskIte,
                technicians:
                  auxTechnicians.length > 0
                    ? auxTechnicians
                    : task?.technicians,
                workedTime: taskHours
              };
            });
            serviceTimeHours += serviceHours;

            return {
              ...service,
              tasks: auxTasks,
              workedTime: serviceHours
            };
          }),
          workedTime: serviceTimeHours
        }
      };

      return auxData;
    }

    return data;
  }
  // }
};

const changeProjectName = async (params: any) => {
  const { proposal, id } = params;
  const { lead } = proposal;
  const { name } = lead;
  const response: any = await apiCaller.projectChangeProjectName({
    id,
    changeLeadNameDTO: { name }
  });

  return { ...response?.data, status: response?.status };
};

const cancelProject = async (params: any) => {
  const { id } = params;

  const payload = {
    id,
    projectUpdateProjectStatusRequest: {
      status:
        UpdateCancellationRequestedProjectStatusDTOStatusEnum.CancellationRequested,
      reason: 'Cancellation requested by operator'
    }
  };

  const response: any = await apiCaller.projectUpdateProjectStatus(payload);

  return { ...response?.data, status: response?.status };
};

const postNote = async (params: any) => {
  const { id, title, text } = params;
  const response: any = await apiCaller.projectCreateProjectNote({
    id,
    projectNoteDTO: { text, title }
  });
  return { ...response?.data, status: response?.status };
};

const getNotes = async (params: any) => {
  const { id } = params;
  const response: any = await apiCaller.projectGetProjectsNotes({ id });
  return response.data;
};

const deleteNotes = async (params: any) => {
  const { id } = params;
  const response: any = await apiCaller.projectDeleteProjectNote({ id });
  return response.data;
};

const patchChangeProjectEnforceGeofencing = async (params: any) => {
  const { id, enforceGeofencing } = params;
  const response: any = await apiCaller.projectChangeProjectEnforceGeofencing({
    id,
    projectEnforceGeofencingDTO: { enforceGeofencing }
  });
  return response.data;
};

const getAssigments = async (params: any) => {
  const { id } = params;
  const response: any = await apiCaller.projectGetProjectAssignments({ id });
  let contServiceTimes = 0;
  const groups: any[] = [];
  const items: any[] = [];

  response.data.proposal.lead.serviceTimes.forEach((serviceTime: any) => {
    contServiceTimes += 1;

    groups.push({
      // endDate: serviceTime.endDate,
      endTime: serviceTime.endTime,
      id: `${serviceTime.id}`,
      level: 0,
      parent: null,
      selected: false,
      serviceTimeId: serviceTime.id,
      // startDate: serviceTime.startDate,
      startTime: serviceTime.startTime,
      title: `Service Time #${contServiceTimes}`
    });

    if (serviceTime?.services.length > 0) {
      serviceTime?.services.forEach((service: any) => {
        groups.push({
          id: `${serviceTime.id}:${service.id}`,
          level: 1,
          parent: `${serviceTime.id}`,
          selected: false,
          serviceId: service.id,
          title: `${service.name}`
        });

        service.tasks.forEach((task: any) => {
          groups.push({
            details: ` Req: ${task.resourcesAssigned}`,
            id: `${serviceTime.id}:${service.id}:${task.id}`,
            level: 2,
            mediaRate: task.mediaRate || 0,
            parent: `${serviceTime.id}`,
            rate: task.mediaRate || 0,
            selected: false,
            taskId: task.id,
            title: task.name
          });

          const auxResources: any[] = [];
          service.calendarEvents.forEach((calendarEvent: any) => {
            const auxAssignments = calendarEvent.assignments.filter(
              (assignment: any) => assignment?.leadServiceTask?.id === task?.id
            );

            if (
              calendarEvent.assignments.length > 0 &&
              auxAssignments.length > 0
            ) {
              calendarEvent.assignments
                .filter(
                  (assignment: any) => assignment.leadServiceTask.id === task.id
                )
                .forEach((assignment: any) => {
                  const initials =
                    `${assignment.technician?.firstName} ${assignment.technician?.lastName}`
                      .toUpperCase()
                      .split(' ')
                      .slice(0, 2)
                      .map((word: string) => word[0]?.toUpperCase())
                      .join('');
                  if (
                    !groups.find(
                      (group) =>
                        group.id ===
                        `${serviceTime.id}:${service.id}:${task.id}:${assignment.technician.id}`
                    )
                  ) {
                    groups.push({
                      assignmentId: assignment.id,
                      details: `${assignment.technician.firstName} ${assignment.technician.lastName}`,
                      id: `${serviceTime.id}:${service.id}:${task.id}:${assignment.technician.id}`,
                      initials,
                      level: 3,
                      rate: assignment.technician.rate || 0,
                      parent: `${serviceTime.id}`,
                      selected: false,
                      technicianId: assignment.technician.id,
                      title: `${assignment.technician.firstName} ${assignment.technician.lastName}`
                    });

                    auxResources[assignment.technician.id] = {
                      id: assignment.technician.id,
                      name: `${assignment.technician.firstName} ${assignment.technician.lastName}`
                    };
                  }

                  items.push({
                    assignmentId: assignment.id,
                    canMove: false,
                    calendarEventId: calendarEvent.id,
                    calendarEventEnd: calendarEvent.end,
                    calendarEventStart: calendarEvent.start,
                    currentAssignments: calendarEvent?.currentAssignments || 0,
                    end_time: moment(
                      `${moment(calendarEvent.start).format(
                        'YYYY-MM-DD'
                      )} 23:59:59`
                    ),
                    group: `${serviceTime.id}:${service.id}:${task.id}:${assignment.technician.id}`,
                    id: `${calendarEvent.id}:${task.id}:${assignment.id}`,
                    initials,
                    requiredAssignments: calendarEvent.requiredAssignments || 0,
                    selected: false,
                    serviceTimeId: serviceTime.id,
                    skills: task.skills,
                    start_time: moment(
                      `${moment(calendarEvent.start).format(
                        'YYYY-MM-DD'
                      )} 00:00:00`
                    ),
                    status: calendarEvent?.state,
                    measure: task.measure,
                    mediaRate: task.mediaRate || 0,
                    quantity: task.quantity,
                    projectId: id,
                    measurePerHour: task.measurePerHour,
                    taskId: task.id,
                    name: task.name,
                    taskTitle: `Task: ${task.name} - Median rate: $${
                      task.mediaRate || 0
                    }`,
                    technicianId: assignment.technician.id,
                    title: `${assignment.technician.firstName} ${
                      assignment.technician.lastName
                    } Rate: ${assignment.technician.rate || '0'}`
                  });
                });
            }

            items.push({
              canMove: false,
              calendarEventId: calendarEvent.id,
              calendarEventEnd: calendarEvent.end,
              calendarEventStart: calendarEvent.start,
              currentAssignments: auxAssignments.length || 0,
              end_time: moment(
                `${moment(calendarEvent.start).format('YYYY-MM-DD')} 23:59:59`
              ),
              group: `${serviceTime.id}:${service.id}:${task.id}`,
              id: `${calendarEvent.id}:${task.id}`,
              measure: task.measure,
              mediaRate: task.mediaRate || 0,
              measurePerHour: task.measurePerHour,
              projectId: id,
              quantity: task.quantity,
              requiredAssignments: task.resourcesAssigned || 1,
              selected: false,
              serviceTimeId: serviceTime.id,
              skills: task.skills,
              start_time: moment(
                `${moment(calendarEvent.start).format('YYYY-MM-DD')} 00:00:00`
              ),
              status: calendarEvent?.state,
              name: task.name,
              taskId: task.id,
              title: `Task: ${task.name} - Median rate: $${task.mediaRate || 0}`
            });
          });
        });
      });
    }
  });

  if (groups.length > 0) {
    return { groups, items };
  }

  return null;
};

const getResources = async (params: any) => {
  const { itemsList, projectId } = params;
  const { skills, serviceTimeId, taskId: serviceTaskId } = itemsList[0];

  const auxDates = itemsList
    .map((item: any) => {
      return {
        date: item.start_time.format('YYYY-MM-DD'),
        start: item.calendarEventStart,
        end: item.calendarEventEnd,
        calendarEventId: item.calendarEventId,
        assignedId: item?.assignmentId || null
      };
    })
    .sort((a: any, b: any) =>
      a.start.localeCompare(b.start)
    ) as DatesToAssignDTO[];

  const payload = {
    dates: auxDates,
    skills,
    serviceTimeId,
    serviceTaskId
  } as ProjectResourcesDTO;

  const response: any = await apiCaller.projectGetResources({
    projectResourcesDTO: payload
  });
  let groups: any[] = [];
  const items: any[] = [];

  if (response.data) {
    response.data.forEach((resource: any) => {
      const initials = `${resource.firstName} ${resource.lastName}`
        .toUpperCase()
        .split(' ')
        .slice(0, 2)
        .map((word: string) => word[0]?.toUpperCase())
        .join('');

      let match = 0;
      resource.skills.forEach((skill: any) => {
        skills?.forEach((taskSkill: any) => {
          if (skill.id === taskSkill) {
            match += 1;
          }
        });
      });
      const taskNumber = skills?.length || 0;
      const percent = Math.floor((match / taskNumber) * 100);

      groups.push({
        matchTimezone: resource?.matchTimezone,
        id: `${resource.id}`,
        initials,
        level: 0,
        parent: null,
        rate: resource.rate || 0,
        selected: false,
        technicianId: resource.id,
        title: `${resource.firstName} ${resource.lastName}`,
        skills: resource?.skills || [],
        percent
      });

      groups = sortByPercentAndRate(groups);

      resource.calendar.forEach((calendar: any) => {
        const assignmentId =
          calendar?.assignmentId || calendar?.reassignmentId || null;
        items.push({
          assignmentId,
          available: calendar.available,
          canMove: false,
          calendarEventId: calendar?.calendarEventId,
          calendarEventEnd: calendar.end,
          calendarEventStart: calendar.start,
          currentAssignments: itemsList[0]?.currentAssignments || 0,
          end_time: moment(`${calendar.date} 23:59:59`),
          group: `${resource.id}`,
          id: `${resource.id}:${calendar?.calendarEventId}`,
          initials,
          measure: itemsList[0]?.measure,
          mediaRate: itemsList[0]?.mediaRate || 0,
          measurePerHour: itemsList[0]?.measurePerHour,
          projectId,
          quantity: itemsList[0]?.quantity,
          requiredAssignments: itemsList[0]?.requiredAssignments || 0,
          selected: false,
          serviceTimeId: payload.serviceTimeId,
          skills: payload.skills,
          start_time: moment(`${calendar.date} 00:00:00`),
          taskId: payload.serviceTaskId,
          title: `${calendar?.rason}`
        });
      });
    });

    return { groups, items };
  }

  return null;
};

const assignResources = async (params: any) => {
  const { datesList, projectId, reason, technicianId, serviceTaskId } = params;

  const auxDates = datesList
    .map((item: any) => {
      return {
        date: item.start_time.format('YYYY-MM-DD'),
        start: item.calendarEventStart,
        end: item.calendarEventEnd,
        calendarEventId: item.calendarEventId,
        assignedId: item?.assignmentId || null
      };
    })
    .sort((a: any, b: any) =>
      a.start.localeCompare(b.start)
    ) as DatesToAssignDTO[];
  const requestParameters = {
    projectAssignResourcesDTO: {
      leadServiceTaskId: serviceTaskId,
      datesToAssign: auxDates,
      reason: `${reason}`,
      technicianId
    } as ProjectAssignResourcesDTO
  };

  await apiCaller.projectAssignResources(requestParameters);

  const result = await getAssigments({
    id: projectId || datesList[0].projectId
  });

  return result;
};

const unassignResources = async (params: any) => {
  const assignmentsIds = params?.assignmentsIdsList.map(
    (assignment: any) => assignment.assignmentId
  );

  const response = await apiCaller.projectUnassignResources({
    projectUnassignmentParamsDTO: { assignmentsIds }
  });

  const result = await getAssigments({
    id: params.projectId
  });

  return result;
};

const projectGetMessages = async (params: { id: string }) => {
  const { id } = params;
  if (!id) {
    return null;
  }
  const response = await apiCaller.projectGetProposalChatById({ id });
  const { data } = response;
  return data;
};

const postMessageProjectClientView = async (params: any) => {
  const { id, message } = params;
  const response = await apiCaller.projectCreateClientMessageInProposal({
    createMessageInProjectDTO: { message },
    id
  });
  return response.data;
};

const postMessageProjectOperator = async (params: any) => {
  const { id, message } = params;
  const response = await apiCaller.projectCreateMessageInProposal({
    createMessageInProjectDTO: { message },
    id
  });
  return response.data;
};

export default {
  assignResources,
  cancelProject,
  changeProjectName,
  getAssigments,
  generateSchedule,
  getDetailByProject,
  getProjectById,
  projectGetMessages,
  getProjectsPaginated,
  getResources,
  postNote,
  getNotes,
  deleteNotes,
  unassignResources,
  patchChangeProjectEnforceGeofencing,
  postMessageProjectClientView,
  postMessageProjectOperator
};
