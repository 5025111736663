import { RolesEnum } from '../api-client/generated';

export const ROLES = {
  ADMINISTRATOR: RolesEnum.Administrator,
  BUSINESS_DEVELOPMENT: RolesEnum.BusinessDevelopment,
  CUSTOMER: 'Client',
  FINANCE: RolesEnum.Finance,
  OPERATIONS: RolesEnum.Operations,
  SALES: RolesEnum.Sales,
  SUPER_USER: RolesEnum.SuperUser,
  SUPERVISOR: RolesEnum.Supervisor,
  TECHNICIAN: RolesEnum.Technician
};

export default {
  ROLES
};

export const priorityRoleLogin = (
  roles: Array<String>,
  needAdmin: boolean = false
) => {
  if (needAdmin && roles.includes(ROLES.ADMINISTRATOR)) {
    return [ROLES.ADMINISTRATOR];
  }
  if (roles.includes(ROLES.TECHNICIAN)) {
    return [ROLES.TECHNICIAN];
  }
  if (roles.includes(ROLES.OPERATIONS)) {
    return [ROLES.OPERATIONS];
  }
  return roles[0];
};
