/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { RefObject, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Icon,
  useTheme,
  Collapse,
  Chip,
  Button,
  Modal,
  styled,
  SliderProps,
  SlideProps,
  Tooltip
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from '../../../components/input/RegularInput';
import { getCurrency, statusStyle } from '../../../utils/general';
import { TitlesRow } from './TimesheetDashboard';
import Tile from '../../../components/dashboard/tile';
import {
  decimalsToHours,
  formatSumTime,
  hoursToDecimals
} from '../../../utils/calendarHelper';
import { ReactComponent as ChevronUpIcon } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import CustomTooltip from '../../../components/shared/CustomTooltip';

const ButtonContainerModal = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: theme.spacing(30)
}));

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24px',
  display: 'flex',
  flexDirection: 'column',
  height: '280px',
  justifyContent: 'center',
  padding: '20px',
  position: 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: '420px'
}));

const DrawerButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: 'white',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  width: '100%'
}));

interface DataList {
  actualEnd: string;
  actualStart: string;
  actualTotal: number;
  clientName: string;
  companyName: string;
  day: string;
  id: number;
  laborHourCost: string;
  laborTotalCost: string;
  millisecondsWorked: string;
  projectName: string;
  scheduledEnd: string;
  scheduledStart: string;
  scheduledTotal: number;
  status: string;
  taskName: string;
  technician: string;
  travelTime: number;
  travelTimeInMilliseconds: string;
  type: string;
  typeText: string;
  variance: number;
  varianceActual: string;
  variancePercentage: string;
}

interface Props {
  data: {
    dataList: DataList[];
    email: string;
    hourlyRate: string;
    id: string;
    phoneNumber: string;
    technician: string;
    technicianPaymentTotal: string;
    totalHoursScheduled: number;
    totalHoursWorked: number;
  };
  isLoading: boolean;
  isTechnician?: boolean;
  setUpdateDecisionData: React.Dispatch<any>;
}

const smallCell = '1%';

function CustomTable(props: {
  boxHeight: number;
  confirmModal: (val: any) => void;
  isTechnician: boolean;
  rows: DataList[];
  tableLabel: string;
}) {
  const { rows, tableLabel, confirmModal, boxHeight, isTechnician } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const [openRow, setOpenRow] = useState<number | null>(null);
  const [inputValues, setInputValues] = useState<{
    [key: number]: number | string;
  }>({});

  const toggleRow = (index: number) => {
    if (openRow === index) {
      setOpenRow(null);
      return;
    }
    setOpenRow(index);
  };

  const handleInputChange = (index: number, value: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [index]: value
    }));
  };

  useEffect(() => {
    if (rows) {
      rows.map((item, index) => handleInputChange(index, item.millisecondsWorked));
    }
  }, [rows]);

  function ListServiceAndTasks({ dataRow }: { dataRow: DataList[] }) {
    return (
      <Box display="flex" flexDirection="column" padding="0px 10px 10px 10px">
        {dataRow.map((item: any, index: number) => (
          <Typography fontSize={15} variant="body1">
            <strong>Service: </strong>{' '}
            {`${item.serviceName || 'No data to display'}`} <br />
            <strong>Task: </strong>{' '}
            {`${
              item.projectType === 'Regular'
                ? item.originalTask
                    .map((element: any) => element.name)
                    ?.join(', ') || 'No data to display'
                : item.originalTask || 'No data to display'
            }`}
          </Typography>
        ))}
      </Box>
    );
  }

  const handleValueInput = (value: any) => {
    let millisecondsWorked = value;
    if (typeof millisecondsWorked === 'string') {
      if (!millisecondsWorked.includes(':')) {
        millisecondsWorked = decimalsToHours(millisecondsWorked);
      }
    }
    if (typeof millisecondsWorked === 'number') {
      millisecondsWorked = decimalsToHours(millisecondsWorked);
    }
    return millisecondsWorked;
  };

  return (
    <TableContainer
      style={{
        marginTop: 20,
        maxHeight: '100vh',
        overflowX: isMobile ? 'scroll' : 'initial'
      }}
    >
      <Typography mb="10px" paddingLeft="18px" variant="h6">
        {tableLabel}
      </Typography>
      <Table stickyHeader>
        <TableHead
          sx={[
            !isMobile && {
              position: 'sticky',
              top: `${boxHeight + 40}px`,
              zIndex: 1
            }
          ]}
        >
          <TableRow>
            <TableCell width={smallCell} />
            <TableCell align="center"> {`${t('TIMESHEET.date')}`}</TableCell>
            <TableCell align="center">
              {' '}
              {`${t('TIMESHEET.assignment')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.travelTime')}`}
              <br />
              {`${t('TIMESHEET.hhmmss')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.schedule')}`}
              <br />
              {`${t('TIMESHEET.hhmm')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.actual')}`}
              <br />
              {`${t('TIMESHEET.hhmmss')}`}
            </TableCell>
            <TableCell align="center">
              {`${t('TIMESHEET.variance')}`}
              <br />
              {`${t('TIMESHEET.hhmm')}`}
            </TableCell>
            {!isTechnician && <TableCell width={smallCell} />}
            {!isTechnician && <TableCell width={smallCell} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => {
            return (
              <>
                <TableRow
                  sx={{
                    '& > *': { borderBottom: 'unset' },
                    cursor: 'pointer'
                  }}
                >
                  <TableCell align="center" onClick={() => toggleRow(index)}>
                    {openRow === index ? (
                      <ChevronUpIcon />
                    ) : (
                      <ChevronDownIcon />
                    )}
                  </TableCell>
                  <TableCell align="center" onClick={() => toggleRow(index)}>
                    {item?.day}
                  </TableCell>

                  <TableCell align="center" onClick={() => toggleRow(index)}>
                    {`${item?.clientName}  ${item?.projectName}   `}
                    <CustomTooltip
                      backgroundColor={theme.palette.grey[600]}
                      borderColor={theme.palette.grey[600]}
                      component={<ListServiceAndTasks dataRow={[item]} />}
                    >
                      <div
                        style={{
                          display: 'inline-flex'
                        }}
                      >
                        <i
                          className="custom-icon icon-info"
                          style={{
                            color: '#58aacf',
                            fontSize: 21,
                            position: 'relative',

                            top: '3px'
                          }}
                        />
                      </div>
                    </CustomTooltip>
                  </TableCell>

                  <TableCell align="center" onClick={() => toggleRow(index)}>
                    {(item?.travelTimeInMilliseconds)}
                  </TableCell>
                  <TableCell align="center" onClick={() => toggleRow(index)}>
                    {decimalsToHours(item?.scheduledTotal)}
                  </TableCell>
                  <TableCell align="center">
                    {isTechnician ? (
                      // decimalsToHours(item?.actualTotal)
                      (item?.millisecondsWorked)
                    ) : (
                      <RegularInput
                        customStyle={{ width: '100px' }}
                        onChange={(e) => handleInputChange(index, e)}
                        type="text"
                        value={handleValueInput(inputValues[index]) || ''}
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" onClick={() => toggleRow(index)}>
                    {formatSumTime(item?.variance)}
                  </TableCell>

                  {!isTechnician && (
                    <TableCell align="center">
                      <Tooltip arrow title="Accept Actual">
                        <Button
                          onClick={() => {
                            setOpenRow(null);
                            let actualTotal = inputValues[index];
                            if (typeof actualTotal === 'string') {
                              if (actualTotal.includes(':')) {
                                actualTotal = hoursToDecimals(actualTotal);
                              }
                              actualTotal = Number(actualTotal);
                            }
                            if (actualTotal < 0) {
                              actualTotal *= -1;
                            }
                            actualTotal = Number(actualTotal.toFixed(2));
                            confirmModal({
                              actualTotal,
                              approvalStatus: 'Approved',
                              taskIterationId: item?.id,
                              type: item?.type
                            });
                          }}
                        >
                          <Typography
                            color="success.main"
                            variant="primary-bold"
                          >
                            <i className="custom-icon icon-active" />
                          </Typography>
                        </Button>
                      </Tooltip>
                    </TableCell>
                  )}

                  {!isTechnician && (
                    <TableCell align="center">
                      <Tooltip arrow title="Reject Actual">
                        <Button
                          onClick={() => {
                            setOpenRow(null);
                            let actualTotal = item.scheduledTotal;
                            if (actualTotal < 0) {
                              actualTotal *= -1;
                            }
                            actualTotal = Number(actualTotal.toFixed(2));
                            confirmModal({
                              actualTotal,
                              approvalStatus: 'Denied',
                              taskIterationId: item?.id,
                              type: item?.type
                            });
                          }}
                        >
                          <Typography color="alert.main" variant="primary-bold">
                            <i className="custom-icon icon-clear" />
                          </Typography>
                        </Button>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={9}
                    style={{
                      backgroundColor: theme.palette.surface[200],
                      marginBottom: 10,
                      paddingBottom: 0,
                      paddingTop: 0
                    }}
                  >
                    <Collapse
                      unmountOnExit
                      in={openRow === index}
                      timeout="auto"
                    >
                      <Box>
                        <Box sx={{ borderBottom: '1px solid darkGrey' }} />
                        <Table style={{ tableLayout: 'fixed' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                {`${t('TIMESHEET.taskName')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.customerName')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.companyName')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.laborHourCost')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.totalCost')}`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                                {item?.taskName}
                              </TableCell>
                              <TableCell align="center">
                                {item?.clientName}
                              </TableCell>
                              <TableCell align="center">
                                {item?.companyName}
                              </TableCell>
                              <TableCell align="center">
                                {getCurrency(item?.laborHourCost)}
                              </TableCell>
                              <TableCell align="center">
                                {getCurrency(item?.laborTotalCost)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Box sx={{ borderBottom: '1px solid darkGrey' }} />
                        <Table style={{ tableLayout: 'fixed' }}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                {`${t('TIMESHEET.actualStart')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.actualEnd')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.scheduleStart')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.scheduleEnd')}`}
                              </TableCell>
                              <TableCell align="center">
                                {`${t('TIMESHEET.status')}`}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="center">
                                {item?.actualStart}
                              </TableCell>
                              <TableCell align="center">
                                {item?.actualEnd}
                              </TableCell>
                              <TableCell align="center">
                                {item?.scheduledStart}
                              </TableCell>
                              <TableCell align="center">
                                {item?.scheduledEnd}
                              </TableCell>
                              <TableCell align="center">
                                <Chip
                                  label={
                                    <Box
                                      sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <i
                                        className={`custom-icon icon-${item?.status}`}
                                      />
                                      <Typography variant="button_100">
                                        {item?.status}
                                      </Typography>
                                    </Box>
                                  }
                                  sx={{ ...statusStyle(item?.status) }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Box sx={{ borderBottom: '1px solid darkGrey' }} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const TimesheetReviewTable: React.FC<Props> = function TimesheetReviewTable({
  data,
  setUpdateDecisionData,
  isLoading,
  isTechnician = false
}) {
  const boxRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const { t } = useTranslation();
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [decisionData, setDecisionData] = useState<any>({});
  const [boxHeight, setBoxHeight] = useState<number>(0);

  const adhocs = data?.dataList?.filter(
    (item) => item.type === 'taskIteration'
  );
  const reOcurrings = data?.dataList?.filter(
    (item) => item.type === 'calendarEventIteration'
  );
  const variances = data?.dataList?.map((item) =>
    item.varianceActual ? Number(item.varianceActual) : 0
  );
  const totalVariance = variances?.reduce((acc, variance) => {
    if (variance <= 0) {
      return acc + -1 * variance;
    }
    return acc + variance;
  }, 0);
  const varianceAverage = totalVariance / (data?.dataList?.length || 0);

  const handleOpenModal = (data: any) => {
    setConfirmModal(true);
    setDecisionData(data);
  };

  useEffect(() => {
    const updateBoxHeight = () => {
      if (boxRef.current) {
        setBoxHeight(boxRef?.current?.clientHeight);
      }
    };
    updateBoxHeight();
    window.addEventListener('resize', updateBoxHeight);
    return () => {
      window.removeEventListener('resize', updateBoxHeight);
    };
  }, []);

  return (
    <Box>
      {isTechnician ? (
        <Box
          alignItems="center"
          display="flex"
          height="54px"
          justifyContent="flex-start"
          padding="20px"
          sx={{
            backgroundColor: theme.palette.surface[200],
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
          width="100%"
        >
          <Typography variant="h5">{data.technician}</Typography>
        </Box>
      ) : (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          padding="10px"
          sx={{
            backgroundColor: theme.palette.surface[200],
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
          width="100%"
        >
          <Button href={`tel:${data.phoneNumber}`}>
            <Typography color="secondary.500" variant="primary-bold">
              <i className="custom-icon icon-classic-phone" />
            </Typography>
          </Button>
          <Button href={`mailto:${data.email}`}>
            <Typography color="secondary.500" variant="primary-bold">
              <i className="custom-icon icon-email" />
            </Typography>
          </Button>
        </Box>
      )}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="space-around"
        padding="20px"
        ref={boxRef}
        sx={[
          {
            backgroundColor: theme.palette.surface[200],
            flexFlow: 'wrap',
            gap: theme.spacing(1)
          },
          !isMobile && {
            position: 'sticky',
            top: '54px',
            zIndex: 2
          }
        ]}
        width="100%"
      >
        <Tile
          icon={
            <AccessTimeIcon
              sx={{
                color: 'white',
                fontSize: '28px'
              }}
            />
          }
          isLoading={isLoading}
          key="dashboard-tile-hoursSheduled"
          sameWidth={boxHeight > 500}
          title="Total Task"
          type="Counter"
          value={data.dataList.length.toString()}
        />
        <Tile
          icon={
            <AccessTimeIcon
              sx={{
                color: 'white',
                fontSize: '28px'
              }}
            />
          }
          isLoading={isLoading}
          key="dashboard-tile-hoursSheduled"
          sameWidth={boxHeight > 500}
          title={t('TIMESHEET.totalTask')}
          type="Counter"
          value={decimalsToHours(data.totalHoursScheduled)}
        />
        <Tile
          isLoading={isLoading}
          key="dashboard-tile-hoursSheduled"
          sameWidth={boxHeight > 500}
          title={t('TIMESHEET.totalWorked')}
          type="Counter"
          value={decimalsToHours(data.totalHoursWorked)}
          variation={
            data.totalHoursWorked < data.totalHoursScheduled ? 'down' : 'up'
          }
        />
        <Tile
          icon={
            <AccessTimeIcon
              sx={{
                color: 'white',
                fontSize: '28px'
              }}
            />
          }
          isLoading={isLoading}
          key="dashboard-tile-hoursWorked"
          sameWidth={boxHeight > 500}
          title={t('TIMESHEET.varianceAverage')}
          type="Counter"
          value={formatSumTime(varianceAverage)}
        />

        <Tile
          icon={
            <AccessTimeIcon
              sx={{
                color: 'white',
                fontSize: '28px'
              }}
            />
          }
          isLoading={isLoading}
          key="dashboard-tile-hoursWorked"
          sameWidth={boxHeight > 500}
          title={t('TIMESHEET.varianceTotal')}
          type="Counter"
          value={formatSumTime(totalVariance)}
        />
      </Box>
      {reOcurrings.length > 0 && (
        <CustomTable
          boxHeight={boxHeight}
          confirmModal={(val: any) => handleOpenModal(val)}
          isTechnician={isTechnician}
          rows={reOcurrings}
          tableLabel="Regular"
        />
      )}
      {adhocs.length > 0 && (
        <CustomTable
          boxHeight={boxHeight}
          confirmModal={(val: any) => handleOpenModal(val)}
          isTechnician={isTechnician}
          rows={adhocs}
          tableLabel="Adhoc"
        />
      )}
      <Modal onClose={() => setConfirmModal(false)} open={confirmModal}>
        <ModalContainer>
          <Typography color="secondary.400" variant="modalTitle">
            <i
              className="custom-icon icon-info"
              style={{
                color:
                  decisionData.approvalStatus === 'Approved'
                    ? theme.palette.primary.main
                    : theme.palette.error.main,
                fontSize: 40
              }}
            />
            <br />
            <br />
            {`
            ${decimalsToHours(
              decisionData.actualTotal
            )} will set as actual time`}
          </Typography>
          <br />
          <ButtonContainerModal>
            <>
              <DrawerButton
                onClick={() => {
                  setConfirmModal(false);
                  setDecisionData({});
                }}
                sx={{ height: 46, width: 114 }}
                variant={
                  decisionData.approvalStatus === 'Approved'
                    ? 'primary-outlined'
                    : 'destructive-outlined'
                }
              >
                <Typography
                  color={
                    decisionData.approvalStatus === 'Approved'
                      ? 'primary'
                      : 'error'
                  }
                >
                  {t('MODAL.cancel')}
                </Typography>
              </DrawerButton>
              <DrawerButton
                onClick={() => {
                  setUpdateDecisionData(decisionData);
                  setConfirmModal(false);
                  setDecisionData({});
                }}
                sx={{ height: 46, width: 114 }}
                variant={
                  decisionData.approvalStatus === 'Approved'
                    ? 'primary'
                    : 'destructive'
                }
              >
                <Typography>
                  {decisionData.approvalStatus === 'Approved'
                    ? 'Confirm'
                    : 'Reject'}
                </Typography>
              </DrawerButton>
            </>
          </ButtonContainerModal>
        </ModalContainer>
      </Modal>
    </Box>
  );
};

export default TimesheetReviewTable;
