import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  styled,
  SliderProps,
  Modal,
  Button,
  Container
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { CheckCircle, Cancel } from '@mui/icons-material';
import RegularInput from '../input/RegularInput';
import TextAreaInput from '../form/TextAreaInput';
import { CompanyResponseDTO } from '../../api-client/generated/api';
import api from '../../api';
import { warningColors, accentColors } from '../../theme/themeConfig';
import { COMPANY_INFORMATION_FIELDS } from '../../helpers/company-information-data';
import {
  FormLabel,
  HeaderFormContainer,
  TitleButtonsBox,
  TitleFormBox,
  WhiteBoxContainer
} from '../form/parts';
import ButtonFormGroup from '../form/ButtonFormGroup';
import PhotoUploader from '../form/PhotoUploader';

interface Props {
  companyInfo: CompanyResponseDTO;
}

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 500
};

interface FormValues {
  companyDescription: string;
  companyName: string;
}

export const MsgOnboard = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  display: 'inline-flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
  padding: '8px 24px'
}));

function CompanyInformation({ companyInfo }: Props) {
  const { companyName, companyDescription } = companyInfo;
  const { t } = useTranslation();
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    companyDescription: '',
    companyName: ''
  });
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<any>([]);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] =
    useState<string>('Something go wrong');

  // =========================== API ================================
  const { data: updatedCompany, error: updateError } = useSWR(
    actionUpdate ? ['update/currentCompany', formValues] : null,
    api.CompanyApi.updateCurrentCompany
  );

  const companyInformationFields = COMPANY_INFORMATION_FIELDS({
    companyDescriptionLabel: t('COMPANY_SETTINGS.companyDescription'),
    companyNameLabel: t('COMPANY_SETTINGS.companyName'),
    companyNameValidation: t('COMPANY_SETTINGS_VALIDATIONS.companyName')
  });

  // ===========================FUNCTIONS================================

  const onChange = (name: string, value: string) => {
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = () => {
    const temporalErrorArray: string[] = [];
    companyInformationFields.forEach((value) => {
      const fieldName: string = value.name ? value.name : '';
      if (!value.pattern.test(formValues[fieldName as keyof FormValues])) {
        temporalErrorArray.push(value?.name);
      }
    });

    setErrorFields(temporalErrorArray);

    if (temporalErrorArray.length === 0) {
      setErrorFields([]);
      setActionUpdate(true);
    }
  };

  const handleCancel = () => {
    setFormValues({
      companyDescription: companyDescription ?? '',
      companyName
    });
    setErrorFields([]);
    setIsEditActive(false);
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ marginLeft: '60px', mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  // ============================ USE_EFFECT ==================================

  useEffect(() => {
    setFormValues({
      companyDescription: companyDescription ?? '',
      companyName
    });
  }, [companyName, companyDescription]);

  useEffect(() => {
    if (updateError) {
      const { response } = updateError;
      setError(true);
      setErrorMessage(`${response.data.message}`);
      setActionUpdate(false);
    }
  }, [updateError]);

  useEffect(() => {
    if (updatedCompany) {
      setOpenSuccessful(true);
      setActionUpdate(false);
      setIsEditActive(false);
      setError(false);
    }
  }, [updatedCompany]);

  return (
    <Box>
      <HeaderFormContainer isMobile={isMobile}>
        <TitleFormBox isMobile={isMobile} />
        <TitleButtonsBox isEditActive={isEditActive} isMobile={isMobile}>
          <ButtonFormGroup
            actionButtonMsg={t('PROFILE.saveInformation')}
            cancelAction={() => handleCancel()}
            enableEditAction={() => setIsEditActive(true)}
            enableEditButtonMsg={t('PROFILE.editInformation')}
            isMobile={isMobile}
            mainAction={() => {
              handleSubmit();
            }}
            updateFlag={isEditActive}
          />
        </TitleButtonsBox>
      </HeaderFormContainer>

      <WhiteBoxContainer isMobile={isMobile}>
        <Box>
          <Box marginBottom="24px">
            <Typography color="mainText.main" variant="secondary">
              {t('COMPANY_SETTINGS.companyProfile')}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'left'
            }}
          >
            <PhotoUploader photoType="company" />
          </Box>
          {companyInfo.onboardingCompleted ? (
            <MsgOnboard
              sx={{
                border: `2px solid ${accentColors.ACCENT_1}`,
                color: `${accentColors.ACCENT}`
              }}
            >
              <CheckCircle sx={{ color: `${accentColors.ACCENT}` }} />
              <Typography variant="body_300">
                {t('COMPANY_SETTINGS.onboardingCompleted')}
              </Typography>
            </MsgOnboard>
          ) : (
            <MsgOnboard
              sx={{
                border: `2px solid ${warningColors.ERROR_1}`,
                color: `${warningColors.ERROR}`
              }}
            >
              <Cancel sx={{ color: `${warningColors.ERROR}` }} />
              <Typography variant="body_300">
                {t('COMPANY_SETTINGS.onboardingIncomplete')}
              </Typography>
            </MsgOnboard>
          )}

          {companyInformationFields.map((value) => {
            if (value.type === 'textarea') {
              return (
                <Box sx={{ marginTop: '10px' }}>
                  <FormLabel error label={value.label} />
                  <TextAreaInput
                    disabled={!isEditActive}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(value.name, event.target.value);
                    }}
                    rows={5}
                    sx={{ my: '16px', width: '100%' }}
                    value={formValues[value.name as keyof FormValues]}
                  />
                </Box>
              );
            }
            return (
              <Box key={value.name} sx={{ marginTop: '10px' }}>
                <FormLabel
                  error={errorFields.includes(value.name)}
                  label={value.label}
                />
                <RegularInput
                  customStyle={{ width: '100%' }}
                  disabled={!isEditActive}
                  onChange={(event: any) => {
                    onChange(value.name, event);
                  }}
                  value={formValues[value.name as keyof FormValues]}
                />
              </Box>
            );
          })}
        </Box>
        {getError()}
      </WhiteBoxContainer>
      <Modal
        aria-describedby="modal-modal-description-profile"
        aria-labelledby="modal-modal-title-profile"
        onClose={() => setOpenSuccessful(false)}
        open={openSuccessful}
      >
        <Box sx={style}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                color="success.main"
                sx={{ fontSize: 24 }}
                variant="primary-bold"
              >
                {t('COMPANY_SETTINGS.companyUpdateSuccesful')}
              </Typography>
            </Box>
            <br />
            <Button
              onClick={() => {
                setOpenSuccessful(false);
              }}
              sx={{ width: isMobile ? '100%' : '50%' }}
              variant="contained"
            >
              <Typography variant="primary-bold">
                {t('GENERAL.done')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default CompanyInformation;
