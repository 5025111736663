import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  SliderProps,
  styled,
  Typography,
  Container,
  Modal,
  Button
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import useSWR, { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
import ImageCropper from '../input/CropImage';
import api from '../../api';
import useNavigationStore from '../../store/zustand/navigation';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 70px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 500
};

interface Props {
  edit?: boolean;
  id?: string;
  isClient?: boolean;
  photoType: 'profile' | 'company';
  size?: number;
}

export const AvatarContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  position: 'relative',
  width: 'fit-content'
}));

function PhotoUploader({ photoType, edit = true, id, size, isClient }: Props) {
  const { t } = useTranslation();
  const navigation = useNavigationStore((state) => state);
  const [actionUpdatePhoto, setActionUpdatePhoto] = useState<boolean>(false);
  const [openSuccessful, setOpenSuccessful] = useState<boolean>(false);
  const [actionUpdateLogo, setActionUpdateLogo] = useState<boolean>(false);
  const [fireLogoAPI, setFireLogoAPI] = useState<boolean>(false);
  const [firePhotoAPI, setFirePhotoAPI] = useState<boolean>(false);
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [content, setContent] = useState<Array<any>>([]);
  const [avatarPhoto, setAvatarPhoto] = useState<string>('');
  const [filePhoto, setFilePhoto] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] =
    useState<string>('Something go wrong');

  const avatarStyle = {
    borderRadius: '50%',
    height: size,
    width: size,
    zIndex: 1
  };

  // ============================ API ==================================

  const { data: photoResponse, error: photoError } = useSWR(
    firePhotoAPI ? { id, url: 'photo/profile' } : null,
    id ? api.StorageApi.getProfilePhotoByID : api.StorageApi.getProfilePhoto
  );

  const { data: logoResponse, error: logoError } = useSWR(
    fireLogoAPI ? 'photo/company' : null,
    api.StorageApi.getCopmanyLogo
  );

  const { data: updatePhotoResponse, error: updatePhotoError } = useSWR(
    actionUpdatePhoto ? { file: filePhoto } : null,
    api.StorageApi.uploadProfilePhoto
  );

  const { data: updateLogoResponse, error: updateLogoError } = useSWR(
    actionUpdateLogo ? { file: filePhoto } : null,
    api.StorageApi.uploadCompanyLogo
  );

  // =========================== FUNCTIONS ================================

  const { mutate } = useSWRConfig();

  const handleClose = () => {
    setShowCropper(false);
    setContent([]);
  };

  const fireAPI = () => {
    if (photoType === 'profile') {
      setActionUpdatePhoto(true);
    } else {
      setActionUpdateLogo(true);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const photo = e.target.files[0];
      const img = new Image();

      img.onload = () => {
        if (img.width < 500 && img.height < 500) {
          // eslint-disable-next-line no-alert
          alert(t('PROFILE.imageSizeMsg'));
          setShowCropper(true);
          const result = window.URL.createObjectURL(photo);
          setContent([result]);
        }
      };
      img.src = URL.createObjectURL(photo);
      if (photo.type === 'image/jpeg' || photo.type === 'image/png') {
        setShowCropper(true);
        const result = window.URL.createObjectURL(e.target.files[0]);
        setContent([result]);
      } else {
        // eslint-disable-next-line no-alert
        alert(t('PROFILE.jpegAndPngAllowed'));
        e.target.value = '';
      }
    }
    e.target.value = '';
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ marginLeft: '60px', mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMessage}
        </Typography>
      </Container>
    );

  // ============================ USE_EFFECT ==================================

  useEffect(() => {
    if (!isClient) {
      if (photoType === 'profile' && id) {
        setFirePhotoAPI(true);
      } else if (photoType === 'profile') {
        mutate({ url: 'photo/profile' }, null, true);
        setFirePhotoAPI(true);
      } else {
        setFireLogoAPI(true);
      }
    }
  }, []);

  useEffect(() => {
    if (photoType === 'profile' && photoResponse?.data) {
      const { data } = photoResponse;
      const photo: any = data[1];
      setAvatarPhoto(photo.fileUrl);
      setFirePhotoAPI(false);
    } else if (photoType === 'company' && logoResponse?.data) {
      const { data } = logoResponse;
      const photo: any = data[1];
      setAvatarPhoto(photo.fileUrl);
      setFireLogoAPI(false);
    }
  }, [photoResponse, logoResponse]);

  useEffect(() => {
    if (updatePhotoResponse) {
      setOpenSuccessful(true);
      setShowCropper(false);
      setActionUpdatePhoto(false);
      mutate({ url: 'photo/profile' }, null, true);
      setFirePhotoAPI(true);
      navigation.setUpdatePhotoProfile(true);
    }
  }, [updatePhotoResponse]);

  useEffect(() => {
    if (updateLogoResponse) {
      setOpenSuccessful(true);
      setShowCropper(false);
      setActionUpdateLogo(false);
      mutate('photo/company', null, true);
      setFireLogoAPI(true);
    }
  }, [updateLogoResponse]);

  useEffect(() => {
    if (updatePhotoError) {
      const { response } = updatePhotoError;
      setError(true);
      setErrorMessage(`${response.data.message}`);
      setShowCropper(false);
      setActionUpdatePhoto(false);
    }
    if (updateLogoError) {
      const { response } = updateLogoError;
      setError(true);
      setErrorMessage(`${response.data.message}`);
      setShowCropper(false);
      setActionUpdateLogo(false);
    }
  }, [updatePhotoError, updateLogoError]);

  useEffect(() => {
    if (photoError || logoError) {
      setFirePhotoAPI(false);
    }
  }, [photoError, logoError]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <AvatarContainer>
        <Avatar alt="Logo" src={avatarPhoto} sx={avatarStyle} />
        {edit && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              zIndex: 100
            }}
          >
            <input
              accept="image/png, image/jpeg"
              className={`custom-file-input ${size && size > 120 ? 'big' : ''}`}
              id="files"
              onChange={handleFileChange}
              style={{ color: 'transparent', zIndex: 100 }}
              type="file"
            />
          </Box>
        )}
        {showCropper && (
          <ImageCropper
            hideCropper={handleClose}
            image={content[0]}
            onCropCancel={() => {
              setContent([]);
            }}
            onCropDone={(newFile: any) => {
              setFilePhoto(newFile);
              fireAPI();
            }}
          />
        )}
      </AvatarContainer>
      <Modal
        aria-describedby="modal-modal-description-profile"
        aria-labelledby="modal-modal-title-profile"
        onClose={() => setOpenSuccessful(false)}
        open={openSuccessful}
      >
        <Box sx={style}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography
                color="success.main"
                sx={{ fontSize: 24 }}
                variant="primary-bold"
              >
                {error ? getError() : t('PROFILE.successfulMsg')}
              </Typography>
            </Box>
            <br />
            <Button
              onClick={() => {
                setOpenSuccessful(false);
              }}
              sx={{ width: isMobile ? '100%' : '50%' }}
              variant="contained"
            >
              <Typography variant="primary-bold">
                {t('GENERAL.done')}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
PhotoUploader.defaultProps = {
  edit: true,
  id: undefined,
  isClient: false,
  size: 100
};
export default PhotoUploader;
