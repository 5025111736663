import { Box, Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ServiceTimeCalculator from '../../../components/calculator/ServiceTimeCalculator';
import TableComponentEditable from '../../../components/shared/TableComponentEditable';
import DeleteButton from '../../../components/list/DeleteButton';
import { getCurrency, getSalesTaxableItems } from '../../../utils/general';
import {
  getCalculatedMarkup,
  getMargin,
  getMarkup,
  getTotalProject
} from '../../../utils/calculatorHelper';

const styleAssignedResources = {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  mt: '16px',
  width: '100%'
};
interface Props {
  // availability: any;
  saveStepper: ({ next }: any) => void;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const CostCalculator: React.FC<Props> = function CostCalculator({
  values,
  setValues,
  saveStepper
  // availability
}) {
  const [totalServiceTimeCost, setTotalServiceTimeCost] = useState<number>(0);

  const { t } = useTranslation();
  const [taxes, setTaxes] = useState<number>(0.0);
  const { palette } = useTheme();
  const buyPrice = getTotalProject(values, t);
  const sellPrice = values?.profit
    ? (parseFloat(values.profit || 0) / 100) * buyPrice + buyPrice
    : buyPrice;
  const margin = getMargin(buyPrice, sellPrice);
  const markup = getMarkup(buyPrice, sellPrice);

  const updatedValues = {
    ...values,
    serviceTimes: values?.serviceTimes?.map((serviceTime: any) => {
      if (!serviceTime.startDate) {
        // eslint-disable-next-line no-param-reassign
        serviceTime.startDate = dayjs(values.projectStartDate);
      }
      return serviceTime;
    })
  };

  const getMiscCost = () => {
    let total = 0.0;
    if (values?.Misc?.length > 0) {
      values.Misc.forEach((misc: any) => {
        total += parseFloat(misc.total || 0);
      });
    }
    return total;
  };

  const getTotalInvoiced = () => {
    return sellPrice + getSalesTaxableItems('tax', values?.taxableItems) || 0;
  };

  const marginAsPercentage = () => {
    return margin.percent;
  };

  const markupAsPercentage = () => {
    return markup.percent || 0;
  };

  const summaryData = [
    {
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryDesireMargin')}
        </Typography>
      ),
      summaryCost: margin.percent || 0.0,
      readOnly: values?.readOnly
    },
    {
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryMarkup')}
        </Typography>
      ),
      summaryCost: getCurrency(markup.amount)
    },
    {
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryMarkupPercent')}
        </Typography>
      ),
      summaryCost: `${markupAsPercentage()}%`
    },
    {
      name: (
        <Typography variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summarySuggestedPrice')}
        </Typography>
      ),
      summaryCost: getCurrency(sellPrice)
    }
    // {
    //   name: (
    //     <Typography variant="primary-bold">
    //       {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summarySalesTax')}
    //     </Typography>
    //   ),
    //   summaryCost: getCurrency(
    //     getSalesTaxableItems('tax', values?.taxableItems)
    //   )
    // },
    // {
    //   name: (
    //     <Typography variant="primary-bold">
    //       {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summarytotal')}
    //     </Typography>
    //   ),
    //   summaryCost: getCurrency(getTotalInvoiced())
    // }
  ];

  const getServiceTimes = () => {
    if (values?.serviceTimes && values.serviceTimes.length > 0) {
      return (
        <Box sx={styleAssignedResources}>
          {values.serviceTimes.map((servTime: any, index: number) => {
            if (servTime?.services?.length > 0) {
              const auxServices =
                servTime?.services.map((service: any) => {
                  return {
                    ...service,
                    readOnly: values.readOnly
                  };
                }) || [];

              return (
                <ServiceTimeCalculator
                  index={index}
                  onServiceTimeCostChange={(newCost: number) => {
                    setTotalServiceTimeCost((prevTotal) => prevTotal + newCost);
                  }}
                  setValues={(newValue: any) => {
                    // This applies for extra items added
                    const { serviceTimes } = values;
                    serviceTimes[index] = newValue;
                    updatedValues.serviceTimes[index] = newValue;
                    setValues(updatedValues);
                  }}
                  totalServiceTimeCost={totalServiceTimeCost}
                  values={{
                    company: values.company,
                    ...servTime,
                    services: auxServices,
                    readOnly: values.readOnly,
                  }}
                />
              );
            }

            return null;
          })}
        </Box>
      );
    }
    return false;
  };

  const getHeader = () => {
    return (
      <Box>
        <Typography variant="title">Cost Calculator</Typography>
      </Box>
    );
  };

  const getMiscelaneous = () => {
    return (
      <TableComponentEditable
        action={() => {}}
        addType="add"
        editableFields={['misc', 'total']}
        handleOpenDraw={() => {
          const Misc = values.Misc || [];
          Misc.push({
            misc: 'edit miscelaneous here',
            total: ''
          });
          setValues({ ...values, Misc });
        }}
        headKeys={['misc', 'total', 'delete']}
        onChange={(value, key, index) => {
          const Misc = values.Misc || [];
          Misc[index] = {
            ...Misc[index],
            [key]: value
          };
          setValues({ ...values, Misc });
        }}
        valuesArray={{
          data:
            values?.Misc?.length > 0
              ? values.Misc.map((misc: any, index: number) => {
                  return {
                    ...misc,
                    delete: (
                      <DeleteButton
                        deleteFn={() => {
                          const Misc = values.Misc || [];
                          Misc.splice(index, 1);
                          setValues({ ...values, Misc });
                        }}
                      />
                    )
                  };
                })
              : []
        }}
      />
    );
  };

  const getTaxableItems = () => {
    return (
      <Box display="flex" flexDirection="column" pt={2}>
        <TableComponentEditable
          action={() => {}}
          addType="add"
          editableFields={['taxableItems', 'subTotal']}
          handleOpenDraw={() => {
            const taxableItems = values.taxableItems || [];
            taxableItems.push({
              salesTax: 0,
              subtotal: 0,
              taxableItems: 'edit tax item here',
              total: 0
            });
            setValues({ ...values, taxableItems });
          }}
          headKeys={['taxableItems', 'subTotal', 'salesTax', 'total', 'delete']}
          onChange={(value, key, index) => {
            const taxableItems = values.taxableItems || [];
            taxableItems[index] = {
              ...taxableItems[index],
              [key]: value
            };

            taxableItems[index] = {
              ...taxableItems[index],
              salesTax: getCurrency(
                (taxes / 100) * (taxableItems[index].subTotal || 0)
              ),
              subTotal: taxableItems[index].subTotal || 0,
              total:
                (taxes / 100) * (taxableItems[index].subTotal || 0) +
                (taxableItems[index].subTotal || 0)
            };
            setValues({ ...values, taxableItems });
          }}
          valuesArray={{
            data:
              values?.taxableItems?.length > 0
                ? values.taxableItems.map(
                    (taxableItems: any, index: number) => {
                      return {
                        ...taxableItems,
                        company: values.company,
                        delete: (
                          <DeleteButton
                            deleteFn={() => {
                              const taxableItems = values.taxableItems || [];
                              taxableItems.splice(index, 1);
                              setValues({ ...values, taxableItems });
                            }}
                          />
                        )
                      };
                    }
                  )
                : []
          }}
        />
      </Box>
    );
  };

  const getSummary = () => {
    return (
      <Box p={5}>
        <Typography variant="primary-bold">
          <Typography variant="body_500">
            {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryHeader')}
          </Typography>
          <br />
        </Typography>
        <Typography style={{ marginLeft: '160px' }} variant="primary-bold">
          {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryCOGS')}
          <br />
        </Typography>
        <Typography style={{ marginLeft: '215px' }} variant="primary-bold">
          {`${getCurrency(getTotalProject(values, t))}`}
        </Typography>
        <Box display="flex" flexDirection="row">
          <Box display="flex" width={1000}>
            <TableComponentEditable
              disableAdd
              hideTableHeader
              action={() => {}}
              alignRightFields={['summaryCost']}
              editableFields={['profit']}
              handleOpenDraw={() => {}}
              headKeys={['name', 'summaryCost']}
              onChange={(value, key, index) => {
                if (key || index) {
                  const { profit } = getCalculatedMarkup(value);
                  setValues({
                    ...values,
                    profit
                  });
                }
              }}
              successRows={[5]}
              valuesArray={{
                data: summaryData
              }}
              warningRows={[0]}
            />
          </Box>
          <Box
            alignItems="start"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            pl={5}
          >
            <Typography variant="primary-bold">
              <Typography variant="body_400">
                {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryTitle')}
              </Typography>
            </Typography>
            <Typography variant="primary" whiteSpace="break-spaces">
              {t('LEAD.CREATE.COST_CALCULATOR.SUMMARY.summaryDescription')
                .split('\n')
                .map((element: any) => {
                  return (
                    <Box pb={1} pt={1}>
                      {element}
                    </Box>
                  );
                })}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const getErrors = () => {
    const toBack = [];
    const { serviceTimes } = values;
    if (serviceTimes || (serviceTimes && !serviceTimes.services)) {
      toBack.push('No Services Added');
    }
    return toBack;
  };

  const getWarnings = () => {
    return (
      <Box>
        <Box
          border={2}
          borderColor={palette.warning[400]}
          borderRadius={1}
          display="flex"
          flexDirection="column"
          p={2}
          sx={{ background: palette.warning[100] }}
          width={500}
        >
          <Box>
            <Typography variant="body_400">
              <Typography variant="primary-bold">Warnings</Typography>
            </Typography>
          </Box>
          <Box>
            {getErrors().map((error) => {
              return (
                <Typography variant="primary">
                  <li>{error}</li>
                </Typography>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    const { company } = values || {};
    const { taxes } = company || {};
    let sales = 0.0;
    if (taxes?.length > 0) {
      taxes.forEach((tax: any) => {
        if (tax.type === 'sales') {
          sales += tax.satax;
        }
      });
    }
    setTaxes(sales);
    if (false) {
      saveStepper(1);
      setValues(values);
    }
  }, []);

  return (
    <Box>
      {getHeader()}
      {false && getWarnings()}
      {getServiceTimes()}
      <Box display="flex" flexDirection="column" gap={2} pt={5}>
        <Typography variant="body_500">
          {/* {t('LEAD.CREATE.COST_CALCULATOR.otherCostTitle')} */}
        </Typography>
        {/* {getMiscelaneous()} */}
        {/* {getTaxableItems()} */}
      </Box>
      {getSummary()}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%'
        }}
      >
        <Button
          onClick={() => saveStepper({ next: 4 })}
          sx={{ width: 200 }}
          variant="contained"
        >
          <Typography variant="primary">
            {t('LEAD.CREATE.COST_CALCULATOR.goToProposalButton')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CostCalculator;
