/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Collapse,
  Paper,
  SliderProps,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import SummaryDrawer from '../../../../components/SummaryDrawer';
import ServiceDeleteButton from '../../../../components/list/ServiceDeleteButton';
import DeleteButton from '../../../../components/list/DeleteButton';
import TableComponentEditable from '../../../../components/shared/TableComponentEditable';
import useNavigationStore from '../../../../store/zustand/navigation';
import TimeComponent from '../../../../components/input/TimeComponent';
import AddTaskModal from '../../../../components/modals/AddTaskModal';
import AddMaterialModal from '../../../../components/modals/AddMaterialModal';
import { getCurrency } from '../../../../utils/general';
import { getServiceCost } from '../../../../utils/calculatorHelper';
import api from '../../../../api';
import { MaterialResponseDTO } from '../../../../api-client/generated';
import OpenDetailButton from '../../../../components/list/OpenDetailButton';
import EditButton from '../../../../components/list/EditButton';
import { secondaryColors, textColors } from '../../../../theme/themeConfig';

const RowElement = styled(TableCell)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  borderBottom: 'none',
  color: theme.palette.primary.main,
  display: 'flex',
  fontSize: '18px',
  fontWeight: 'bold',
  textOverflow: 'ellipsis',
  width: '100%'
}));

interface SCollapse {
  editableFields: Array<string>;
  headKeys: Array<string>;
  indexST?: number;
  manualPricing: boolean;
  openInit?: boolean;
  screen?: string;
  service: any;
  setService: (newValue: any) => void;
  totalServices?: number;
}

export const ServiceCollapse: React.FC<SCollapse> = function ServiceCollapse({
  service,
  totalServices,
  screen,
  setService,
  headKeys,
  manualPricing,
  editableFields,
  openInit,
  indexST
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(openInit || false);
  const { setConfirmModal } = useNavigationStore((state) => state);
  const [taskFromModal, setTaskFromModal] = useState<any>();
  const [openDraw, setOpenDraw] = useState<boolean>(false);
  const [addTask, setAddTask] = useState<boolean>(false);
  const [materialFromModal, setMaterialFromModal] = useState<any>();
  const [addMaterial, setAddMaterial] = useState<boolean>(false);
  const [totalTaskTime, setTotalTaskTime] = useState<any>([]);
  const [newTaskArray, setNewTaskArray] = useState<any>([]);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteItem, setDelete] = useState<string | null>(null);
  const [serviceInfo, setServiceInfo] = useState<any>();
  const [valueDrawer, setValueDrawer] = useState<any>('');
  const [skills, setSkills] = useState<Array<any> | null>(null);
  const [fireSkills, setFireSkills] = useState<boolean>(true);
  const [fireMaterialsSkills, setFireMaterials] = useState<boolean>(true);
  const [materials, setMaterials] = useState<MaterialResponseDTO[]>([]);
  const [addToCatalog, setAddToCatalog] = useState<boolean>(false);
  const [drawOnlyView, setDrawOnlyView] = useState<boolean>(false);
  const [fireTaskCreate, setFireTaskCreate] = useState<boolean>(false);
  const [drawerIndex, setDrawerIndex] = useState<number>(0);

  const { createSkill } = api.skillApi;
  const { createMaterial } = api.MaterialApi;

  const {
    data: skillResponse,
    error: skillError,
    mutate
  } = useSWR(fireSkills ? 'api/getSkills' : null, api.skillApi.getAllSkills);

  const {
    data: materialResponse,
    error: materialError,
    mutate: mutateMaterial
  } = useSWR(
    fireMaterialsSkills ? 'api/getMaterials' : null,
    api.MaterialApi.getAllMaterials
  );

  const valuesForCreateTask = () => {
    const value = {
      ...taskFromModal,
      measure: taskFromModal?.measureId,
      skills: taskFromModal?.skills?.map((item: any) => item.id) || [],
      taskChecklist: taskFromModal?.checklist,
      taskMaterials: taskFromModal?.materials
    };
    delete value?.measureId;
    delete value?.checklist;
    return value;
  };

  const { data: createData, error: createError } = useSWR(
    fireTaskCreate ? valuesForCreateTask() : null,
    api.taskApi.createTaskReturnList
  );

  const getHeadEnding = () => {
    switch (screen) {
      case 'calculator':
        return <Box>{`${getCurrency(getServiceCost(service))}`}</Box>;
      case 'services':
        return (
          <ServiceDeleteButton
            customStyle={{ backgroundColor: 'transparent' }}
            deleteFn={() => {
              setDelete(service.id);
              setShowDelete(true);
            }}
            disabled={serviceInfo?.readOnly}
          >
            {t('GENERAL.deleteButton')}
          </ServiceDeleteButton>
        );
      case 'schedule':
        return (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '8px',
              position: 'static',
              right: '15%'
            }}
          >
            <Typography>Total time for service:</Typography>
            <TimeComponent
              readOnly
              setTime={() => {}}
              time={totalTaskTime?.reduce((a: any, b: any) => a + b, 0) || 0.0}
            />
          </Box>
        );
      default:
        return false;
    }
  };

  const getSetupAndTeardownTime = () => {
    switch (screen) {
      case 'services':
        return (
          <Box alignItems="center" display="flex" gap="150px">
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography
                color={secondaryColors.SECONDARY_5}
                variant="body_300"
              >
                {t('LEAD.CREATE.SERVICES.setupTime')}
              </Typography>
              <TimeComponent
                reverseOrder
                disabled={serviceInfo?.readOnly}
                setTime={(setupTime) => {
                  setService({ ...service, setupTime: setupTime.toString() });
                }}
                time={service?.setupTime || '0'}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography
                color={secondaryColors.SECONDARY_5}
                variant="body_300"
              >
                {t('LEAD.CREATE.SERVICES.teardownTime')}
              </Typography>
              <TimeComponent
                reverseOrder
                disabled={serviceInfo?.readOnly}
                setTime={(teardownTime) =>
                  setService({
                    ...service,
                    teardownTime
                  })
                }
                time={service?.teardownTime || 0}
              />
            </Box>
          </Box>
        );

      default:
        return false;
    }
  };

  const getChecklistOrMaterials = () => {
    switch (screen) {
      case 'calculator': {
        const materials: Array<any> = [];
        if (service?.tasks) {
          service.tasks.forEach((task: any) => {
            if (task?.materials?.length > 0) {
              task?.materials.forEach((material: any) => {
                if (material?.materialId) {
                  materials.push({
                    ...material,
                    task,
                    taskName: task.name
                  });
                }
              });
            }
          });
        }
        const extraMaterials: Array<any> = [];
        if (service.materialsAndSupplies?.length > 0) {
          service.materialsAndSupplies.forEach((item: any, index: number) => {
            extraMaterials.push({
              ...item,
              delete: (
                <DeleteButton
                  deleteFn={() => {
                    const { materialsAndSupplies } = service;
                    materialsAndSupplies.splice(index, 1);
                    setService({ ...service, materialsAndSupplies });
                  }}
                  disabled={serviceInfo?.readOnly}
                />
              ),
              readOnly: serviceInfo?.readOnly
            });
          });
        }
        return (
          <Box display="flex" flexDirection="column" gap={1} pt={5}>
            <TableComponentEditable
              disableAdd
              action={() => {}}
              editableFields={editableFields}
              handleOpenDraw={() => {}}
              headKeys={[
                'materialsFromTasks',
                'totalCostMaterial',
                'taskName',
                'calculator'
              ]}
              onChange={() => {}}
              valuesArray={{
                data: materials
              }}
            />
            <TableComponentEditable
              action={() => {}}
              disableAdd={serviceInfo?.readOnly}
              editableFields={editableFields}
              handleOpenDraw={() => {
                setConfirmModal(
                  () => {
                    setAddMaterial(true);
                  },
                  `${t('GENERAL.addButton')}`,
                  () => {},
                  `${t('GENERAL.cancelButton')}`,
                  <AddMaterialModal
                    setValue={(value) => setMaterialFromModal(value)}
                  />
                );
              }}
              headKeys={['materialsAndSupplies', 'costMaterial', 'delete']}
              onChange={(value, key, index) => {
                const materialsAndSupplies = service.materialsAndSupplies || [];
                materialsAndSupplies[index] = {
                  ...materialsAndSupplies[index],
                  cost: parseFloat(value)
                };
                setService({ ...service, materialsAndSupplies });
              }}
              valuesArray={{
                data: extraMaterials
              }}
            />
          </Box>
        );
      }
      case 'services':
        return (
          <>
            {' '}
            {/* <Box pt={2}>
                <Typography variant="primary">
                  {t('LEAD.CREATE.SERVICES.checklist')}
                </Typography>
              </Box> */}
            {/* <Box
                className="scrollbar"
                sx={{ m: 0, marginTop: '16px', maxHeight: 200 }}
              >
                {/* ----------------- CHECKLIST ----------------- */}
            {/* <SortableTable
                  addManagement
                  onChange={(checklist) => {
                    setService({ ...service, checklist });
                  }}
                  type="input"
                  values={
                    service?.checklist?.[0]
                      ? service?.checklist.map((checkItem: { name: string }) => {
                          return checkItem;
                        })
                      : []
                  }
                /> */}
            {/* </Box> */}
          </>
        );

      default:
        return false;
    }
  };

  useEffect(() => {
    if (addTask) {
      if (addToCatalog) {
        setFireTaskCreate(true);
      }
      setOpen(false);
      setAddTask(false);
      const valuesFromModal = {
        ...taskFromModal,
        checklist: taskFromModal.taskChecklist || taskFromModal.checklist
      };
      delete valuesFromModal.measureId;
      setService({
        ...service,
        tasks: service.tasks
          ? [...service.tasks, valuesFromModal]
          : [valuesFromModal]
      });
      if (!addToCatalog) {
        setTaskFromModal(null);
      }
      // setOpen(true);
    }
  }, [addTask]);

  useEffect(() => {
    if (createData) {
      setFireTaskCreate(false);
      setAddToCatalog(false);
      setTaskFromModal(null);
    }
  }, [createData]);

  useEffect(() => {
    if (addMaterial) {
      setOpen(false);
      setAddMaterial(false);
      const materialsAndSupplies = service.materialsAndSupplies
        ? [...service.materialsAndSupplies, materialFromModal]
        : [materialFromModal];
      setService({
        ...service,
        materialsAndSupplies
      });
      setTaskFromModal(null);
      setOpen(true);
    }
  }, [addMaterial]);

  const handlerTask = (newTaskArray: any) => {
    if (newTaskArray?.length > 0) {
      const newTaskArrayValue = [...newTaskArray];
      const newArray = [...totalTaskTime];
      newTaskArray?.forEach((tasks: any, indexTA: number) => {
        const measurePerTime = Number(tasks?.measurePerHour) ?? 0;
        const minRequired = Number(tasks?.minimumResources) ?? 0;
        const quantity = tasks?.quantity || 0;
        const time =
          tasks?.measure?.name !== 'Unit'
            ? quantity / measurePerTime
            : quantity * measurePerTime;

        const resources =
          tasks?.resources?.length > 0 ? tasks?.resources?.length : 1;
        const result = (time * minRequired) / resources;

        newArray[indexTA] = result;
        setTotalTaskTime(newArray);

        newTaskArrayValue[indexTA] = {
          ...newTaskArrayValue[indexTA],
          technicians: tasks?.resources?.length > 0 ? tasks?.resources : [],
          totalTime: result
        };
        setService({ ...service, tasks: newTaskArrayValue });
        setServiceInfo({ ...service, tasks: newTaskArrayValue });
        setNewTaskArray(tasks);
      });
    }
  };

  useEffect(() => {
    if (service?.name) {
      setServiceInfo(service);
      // setNewTaskArray(service.tasks);
      // handlerTask(service.tasks);
    }
  }, [service]);

  useEffect(() => {
    if (newTaskArray.length > 0) {
      const newTaskArrayValue = [...newTaskArray];
      const newArray = [...totalTaskTime];
      newTaskArray?.forEach((tasks: any, indexTA: number) => {
        const measurePerTime = Number(tasks?.measurePerHour) ?? 0;
        const minRequired = Number(tasks?.minimumResources) ?? 0;
        const quantity = tasks?.quantity || 0;
        const measure = tasks?.measure?.name
          ? tasks.measure.name
          : tasks.measure;
        const time =
          measure === 'Unit'
            ? quantity * measurePerTime
            : quantity / measurePerTime;

        const resources =
          tasks?.resources && tasks.resources?.length > 0
            ? tasks.resources.length
            : tasks?.technicians?.length || 1;
        const result = (time * minRequired) / resources;

        newArray[indexTA] = result;
        setTotalTaskTime(newArray);
        newTaskArrayValue[indexTA] = {
          ...newTaskArrayValue[indexTA],
          totalTime: result
        };

        setService({ ...service, tasks: newTaskArrayValue });
      });
    }
    // handlerTask(serviceInfo?.tasks);
  }, [newTaskArray]);

  const handleOpenDraw = (id: any) => {
    const filter = serviceInfo.tasks.filter((item: any) => item?.id === id);
    const valueDrawer = filter?.map((item: any) => {
      const skillsValues: any = [];
      item.skills.forEach((value: any) => {
        if (value.id) {
          skillsValues.push(value);
        } else {
          skills?.forEach((obj: any) => {
            if (value.includes(obj.id)) {
              skillsValues.push(obj);
            }
          });
        }
      });

      const materialsValues: any = [];
      if (item.taskMaterials) {
        item.taskMaterials.forEach((obj: any) => {
          if (obj !== null) {
            materialsValues.push(obj);
          }
        });
      }
      // if (item.materials) {
      //   item.materials.forEach((obj: any) => {
      //     if (obj !== null) {
      //       materialsValues.push(obj);
      //     }
      //   });
      // }

      return {
        ...item,
        skills: skillsValues,
        taskChecklist: item.checklist || item.taskChecklist,
        taskMaterials: materialsValues
      };
    });
    setValueDrawer(valueDrawer[0]);
    setOpenDraw(true);
  };

  const handleCloseDraw = () => {
    setValueDrawer({});
    setOpenDraw(false);
  };

  const handleDeleteTask = (index: number) => {
    const { tasks } = serviceInfo;
    tasks.splice(index, 1);
    setService({ ...serviceInfo, tasks });
  };

  const handleUpdateTask = (item: any, index: number) => {
    const { tasks } = serviceInfo;
    const tasksEdit = tasks;
    tasksEdit[index] = {
      ...tasksEdit[index],
      ...item,
      checklist: item.taskChecklist
    };
    setService({ ...serviceInfo, tasks: tasksEdit });
    setServiceInfo({ ...serviceInfo, tasks: tasksEdit });
  };

  useEffect(() => {
    setFireSkills(true);
    setFireMaterials(true);
    setTotalTaskTime(service?.tasks?.map((task: any) => task.totalTime));
  }, []);

  useEffect(() => {
    if (service?.id === deleteItem && showDelete) {
      setService(null);
      setShowDelete(false);
    }
  }, [showDelete]);

  useEffect(() => {
    if (materialResponse) {
      setFireMaterials(false);
      setMaterials(materialResponse);
    }
  }, [materialResponse]);

  useEffect(() => {
    if (skillResponse) {
      setFireSkills(false);
      setSkills(skillResponse);
    }
  }, [skillResponse]);

  return (
    <Paper
      sx={{
        border: `1px solid ${secondaryColors.SECONDARY_1}`,
        borderRadius: '8px',
        boxShadow: 'none'
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={`${!open ? '24px' : '0px'}`}
        padding="16px 24px 16px 24px"
      >
        {/* ----------------- TABLE COMPLETE ----------------- */}
        <TableHead
          sx={{
            display: 'flex',
            width: '100%'
          }}
        >
          {serviceInfo?.name && (
            <TableRow
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: '56px',
                width: '100%'
              }}
            >
              <RowElement>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography color={textColors.BLACK} variant="secondary-semi">
                    {serviceInfo.name || 'Adhoc task'}
                  </Typography>

                  <Box alignItems="center" display="flex" gap="48px">
                    {getHeadEnding()}
                    <Button onClick={() => setOpen(!open)}>
                      <i
                        className="custom-icon icon-select"
                        style={{
                          color: secondaryColors.SECONDARY_4,
                          fontSize: '14px',
                          transform: `rotate(${open ? '180deg' : '0deg'})`,
                          transitionDuration: '300ms'
                        }}
                      />
                    </Button>
                  </Box>
                </Box>
              </RowElement>
            </TableRow>
          )}
        </TableHead>

        {!open ? (
          <div
            style={{
              backgroundColor: secondaryColors.SECONDARY_1,
              height: '1px',
              width: '100%'
            }}
          />
        ) : null}

        {/* ----------------- COLLAPSE BODY ----------------- */}
        <Collapse
          in={
            typeof totalServices !== 'undefined' && totalServices === 1
              ? !open
              : open
          }
        >
          <Box display="flex" flexDirection="column" gap="24px">
            {getSetupAndTeardownTime()}
            <Box>
              <Typography variant="body_300">
                {t('LEAD.CREATE.SERVICES.tasks')}
              </Typography>
            </Box>
            {valueDrawer && openDraw && (
              <SummaryDrawer
                arrayType="skills"
                createNestedAction={createSkill}
                createSecondaryNestedAction={createMaterial}
                deleteAction={() => handleDeleteTask(drawerIndex)}
                handleClose={handleCloseDraw}
                itemsArray={
                  skills as { description: string; id: string; name: string }[]
                }
                mutateAction={mutate}
                mutateNestedAction={mutate}
                mutateSecondaryNestedAction={mutateMaterial}
                onlyView={drawOnlyView}
                open={openDraw}
                secondaryArrayType="material"
                secondaryItemsArray={
                  materials as { id: string; name: string }[]
                }
                type="task-lead"
                updateAction={(item: any) => {
                  handleUpdateTask(item, drawerIndex);
                }}
                value={valueDrawer}
              />
            )}
            {/* ----------------- TASK TABLE ----------------- */}
            {serviceInfo && (
              <TableComponentEditable
                action={() => {
                  // for edit
                }}
                addType="add"
                disableAdd={
                  serviceInfo?.readOnly ||
                  (screen ? !['services'].includes(screen) : true)
                }
                editableFields={manualPricing ? editableFields : []}
                handleOpenDraw={() => {
                  if (manualPricing) {
                    const defaultTask = {
                      description: t('LEAD.CREATE.SERVICES.lineItemTask'),
                      materials: [],
                      measure: 'Unit',
                      name: t('LEAD.CREATE.SERVICES.lineItemTask')
                    };
                    setNewTaskArray(
                      serviceInfo?.tasks
                        ? [...serviceInfo.tasks, defaultTask]
                        : [defaultTask]
                    );
                  } else {
                    setConfirmModal(
                      async () => {
                        setAddTask(true);
                      },
                      `${t('GENERAL.saveButton')}`,
                      () => {},
                      `${t('GENERAL.cancelButton')}`,
                      <AddTaskModal
                        setAddToCatalog={(value: boolean) =>
                          setAddToCatalog(value)
                        }
                        setValue={(value) => setTaskFromModal(value)}
                      />
                    );
                  }
                }}
                headKeys={headKeys}
                indexST={indexST}
                manualPricing={manualPricing}
                onChange={(value, key, index) => {
                  const incomingIndex = index;
                  const { tasks } = serviceInfo;
                  if (!manualPricing) {
                    tasks[incomingIndex] = {
                      ...tasks[incomingIndex],
                      description:
                        tasks[incomingIndex]?.description ||
                        tasks[incomingIndex]?.name ||
                        '',
                      [key]: value
                    };
                  } else if (key === 'time') {
                    tasks[incomingIndex] = {
                      ...tasks[incomingIndex],
                      checklist: tasks[incomingIndex].checklist || [],
                      isImageRequired: false,
                      [key]: value,
                      materials: [],
                      measure: 'Unit',
                      measurePerHour: 1,
                      quantity: parseFloat(value.toFixed(2)),
                      resources: tasks[incomingIndex].resources || [],
                      serviceId: serviceInfo.id,
                      skills: [],
                      technicians: tasks[incomingIndex].technicians || [],
                      total: parseFloat(value.toFixed(2)),
                      totalTime: parseFloat(value.toFixed(2))
                    };
                  } else {
                    tasks[incomingIndex] = {
                      ...tasks[incomingIndex],
                      [key]: value
                    };
                  }
                  setNewTaskArray(tasks);
                }}
                valuesArray={{
                  data:
                    serviceInfo?.tasks &&
                    serviceInfo?.tasks.length > 0 &&
                    serviceInfo?.tasks.map((item: any, index: number) => {
                      return {
                        ...item,
                        company: serviceInfo.company,
                        delete: (
                          <DeleteButton
                            customStyle={{ padding: 0 }}
                            deleteFn={() => {
                              handleDeleteTask(index);
                            }}
                            disabled={serviceInfo?.readOnly}
                            tooltip="Delete"
                          />
                        ),
                        edit: (
                          <EditButton
                            customStyle={{ padding: 0 }}
                            disabled={serviceInfo?.readOnly}
                            handleOpenDraw={() => {
                              handleOpenDraw(item.id);
                              setDrawerIndex(index);
                              setDrawOnlyView(false);
                            }}
                            tooltip="Edit"
                          />
                        ),
                        readOnly: serviceInfo?.readOnly,
                        showDetail: (
                          <OpenDetailButton
                            customStyle={{ padding: 0 }}
                            handleOpenDraw={() => {
                              handleOpenDraw(item.id);
                              setDrawerIndex(index);
                              setDrawOnlyView(true);
                            }}
                            tooltip="View"
                          />
                        )
                      };
                    })
                }}
              />
            )}
            {getChecklistOrMaterials()}
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
};

ServiceCollapse.defaultProps = {
  indexST: undefined,
  openInit: false,
  screen: undefined,
  totalServices: 0
};

export default ServiceCollapse;
