import {
  Button,
  Box,
  Container,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  styled,
  SlideProps
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';

import { ConstructionRounded } from '@mui/icons-material';
import RegularInput from '../input/RegularInput';
import PasswordInput from '../input/PasswordInput';
import useAuthStore from '../../store/zustand/auth';
import Api from '../../api';
import {
  clearCacheData,
  getLocalStorage,
  setLocalStorage
} from '../../utils/authHelper';
import { FormLabel } from '../form/parts';
import Loader from '../shared/Loader';
import { loginLandingByRole } from '../../helpers/routesConstants';
import { roleName } from '../../utils/general';
import { priorityRoleLogin, ROLES } from '../../helpers/roles';
import appConfig from '../../config';
import { getUserLocation } from '../../utils/geofencingHelper';

interface Props {
  isMobile: boolean;
  setCurrentProcess: React.Dispatch<React.SetStateAction<string>>;
}

const LoginButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: theme.palette.mainText?.['100']
}));

const Login: React.FC<Props> = function Login({ isMobile, setCurrentProcess }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { cache } = useSWRConfig();
  const userState = useAuthStore((state) => state);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [shouldAuth, setShouldAuth] = useState<boolean>(false);
  const [shouldProfile, setShouldProfile] = useState<boolean>(false);
  const [shouldUpdateTimezone, setShouldUpdateTimezone] =
    useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(
    getLocalStorage('rememberMe') || false
  );
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const autoLogin = getLocalStorage('keepSessionOpen');
  const { mutate } = useSWRConfig();

  const underMaintenance = appConfig?.underMaintenance === 'true';

  // === ATTEMPT

  // ======================================== API

  // eslint-disable-next-line max-len
  const {
    data: authentication,
    isValidating: isValidatingAuthentication,
    error: errorAuth
  } = useSWR(
    shouldAuth ? { email, password, rememberMe } : null,
    Api.authApi.getAuthenticationUser
  );

  const {
    data: updateTimezoneForCurrentUserData,
    error: updateTimezoneForCurrentUserError
  } = useSWR(
    shouldUpdateTimezone ? 'api/profile/timezone/current' : null,
    Api.authApi.updateTimezoneForCurrentUser
  );

  const {
    data: profile,
    isValidating: isValidatingProfile,
    error: errorProfile
  } = useSWR(shouldProfile ? 'api/profile' : null, Api.authApi.getProfile);

  // Clear cache when set Profile, & re-login with same User.
  const clearCache = () => mutate({ email, password, rememberMe }, null, true);
  const clearProfile = () => mutate('api/profile', null, true);
  const clearCompany = () => mutate('api/currentCompany', null, true);

  const handleLogin = () => {
    if (email !== '' && password !== '') {
      clearCache();
      setShouldAuth(true);
    } else {
      setError(true);
      setErrorMessage(`${t('GENERAL.inputRequired')}`);
    }
  };

  const checkBoxChecked = (event: any) => {
    setRememberMe(event.target.checked);
    setLocalStorage('rememberMe', event.target.checked);
  };

  const forgotPassword = () => {
    navigate('/forgotPassword');
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {`${errorMessage}`}
        </Typography>
      </Container>
    );

  const loginValidation = (profile: any) => {
    const role = priorityRoleLogin(
      profile?.roles?.map((item: any) => item.name),
      true
    );
    if (profile && profile.email) {
      const autoLogin = getLocalStorage('keepSessionOpen');
      if (false || profile.block) {
        // if (!profile.active || profile.block) { // validation to be defined
        // attempt
        setError(true);
        setShouldAuth(false);
        setShouldProfile(false);
      } else if (autoLogin) {
        userState.login();
        navigate(loginLandingByRole[role as keyof typeof loginLandingByRole], {
          replace: true
        });
      } else {
        userState.login();
        if (profile.mfa) {
          // should come in the token
          setCurrentProcess('mfa');
        } else {
          navigate(
            loginLandingByRole[role as keyof typeof loginLandingByRole],
            {
              replace: true
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    if (errorAuth) {
      const { data } = errorAuth;
      setError(true);
      setShouldAuth(false);
      if (data) {
        setErrorMessage(data?.message);
      } else {
        setErrorMessage(errorAuth?.message);
      }
    }
  }, [errorAuth]);

  useEffect(() => {
    if (authentication) {
      if (authentication.accessToken) {
        if (authentication.requestTwoFactor) {
          setCurrentProcess('mfa');
        } else {
          setShouldProfile(true);
          setShouldUpdateTimezone(true);
        }
      } else {
        setShouldAuth(false);
        setError(true);
        setErrorMessage(authentication?.message);
        if (authentication?.requestChangePassword) {
          setTimeout(() => {
            navigate('/changePassword');
          }, 2000);
        }
      }
    }
  }, [authentication]);

  useEffect(() => {
    // Aqui vamos a agregar una condicion mas para esperar el token
    if (profile && authentication?.accessToken) {
      const {
        roles,
        email,
        mfa,
        block,
        active,
        firstName,
        lastName,
        skills,
        phoneNumber,
        id,
        timeFormat
      } = profile;
      const rolesName = roles.map((ele: any) => ele.name);
      const skillsName = skills.map((ele: any) => ele.name);
      userState.setUser({
        active,
        block,
        email,
        firstName,
        id,
        lastName,
        mfa,
        phoneNumber,
        roles: rolesName,
        skills: skillsName,
        timeFormat
      });
      loginValidation(profile);
    }
  }, [profile, authentication]);

  useEffect(() => {
    clearCache();
    clearProfile();
    clearCompany();
    clearCacheData();
    // autologin
    if (autoLogin) {
      setShouldProfile(true);
      setShouldUpdateTimezone(true);
    }
  }, []);

  useEffect(() => {
    if (errorProfile) {
      setError(true);
    }
  }, [errorProfile]);

  useEffect(() => {
    if (profile && authentication?.accessToken) {
      const askLocation = profile.roles.some(
        (role: any) => role.name === ROLES.TECHNICIAN
      );

      if (askLocation) {
        getUserLocation();
      }
    }
  }, [profile, authentication]);

  if (underMaintenance && !getLocalStorage('allowMaintenance')) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Typography color="primary.300" fontSize={28} variant="primary-bold">
          {t('GENERAL.underMaintenance')}
        </Typography>
        <Typography color="primary.400" variant="primary-bold">
          <ConstructionRounded fontSize="large" />
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <FormLabel error={false} label={t('LOGIN.userName')} />
      <RegularInput
        autofill
        customStyle={{ borderStyle: 'none', width: '100%' }}
        error={error}
        onChange={(user) => setEmail(user)}
        type="email"
      />
      <FormLabel error={false} label={t('LOGIN.password')} />
      <PasswordInput
        customStyle={{}}
        error={error}
        onChange={(pass) => setPassword(pass)}
        submitEvent={() => {
          handleLogin();
        }}
      />
      {getError()}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <FormGroup sx={{ mb: 1, mt: 1 }}>
          <FormControlLabel
            control={<Checkbox defaultChecked={rememberMe} />}
            defaultChecked={rememberMe}
            label={
              <Typography color="secondary.main" variant="primary-bold">
                {t('LOGIN.rememberMe')}
              </Typography>
            }
            onChange={checkBoxChecked}
          />
        </FormGroup>
        {!isMobile && (
          <Typography
            color="informative.main"
            onClick={forgotPassword}
            sx={{ ':hover': { cursor: 'pointer' } }}
            variant="primary-bold"
          >
            {t('LOGIN.forgotPassword')}
          </Typography>
        )}
      </Box>
      <LoginButton
        onClick={() => {
          handleLogin();
        }}
        variant="primary"
      >
        {isValidatingAuthentication || isValidatingProfile ? (
          <Loader />
        ) : (
          <Typography color="mainText.100" variant="primary-bold">
            {t('LOGIN.login')}
          </Typography>
        )}
      </LoginButton>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px'
        }}
      >
        <Typography
          color="informative.main"
          onClick={() => setCurrentProcess('clientLogin')}
          sx={{ ':hover': { cursor: 'pointer' } }}
          variant="primary-bold"
        >
          {t('LOGIN.customerLogin')}
        </Typography>
      </Box>
      {isMobile && (
        <Typography
          color="informative.main"
          onClick={forgotPassword}
          sx={{ m: 'auto', pt: 2 }}
          variant="primary-bold"
        >
          {t('LOGIN.forgotPassword')}
        </Typography>
      )}
    </>
  );
};

export default Login;
