import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ServiceCollapse } from './ServiceCollapse';

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const AssignResources: React.FC<Props> = function AssignResources({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values
}) {
  const { t } = useTranslation();
  const services =
    index >= 0 && values?.serviceTimes?.[index]
      ? values?.serviceTimes?.[index]?.services
      : values?.serviceTimes?.[0]?.services;

  const serviceTimeValue =
    index >= 0 && values?.serviceTimes?.[index]
      ? values?.serviceTimes?.[index]
      : [];

  const adHoc = values?.manualPricing;
  const auxKey = `assign-resource-serviceTime-${index}`;

  return (
    <Box display="flex" flexDirection="column" gap="32px" key={`${auxKey}`}>
      <Typography variant="secondary-semi">
        {t('LEAD.CREATE.SERVICES.SCHEDULE.assingResources')}
      </Typography>
      <Typography variant="primary">
        <Box display="flex" flexDirection="column" gap="16px">
          {!adHoc &&
            services &&
            services.length > 0 &&
            services.map((service: any, indexi: number) => {
              return (
                <ServiceCollapse
                  editableFields={['minRequired']}
                  headKeys={['task', 'mediaRate', 'assigned', 'minRequired']}
                  indexST={index}
                  key={`serv-key-${service.id}`}
                  manualPricing={values.manualPricing}
                  screen="schedule"
                  service={{ ...service, readOnly: values?.readOnly }}
                  setService={(newValue: any) => {
                    let newService = services;
                    newService[indexi] = newValue;
                    newService = { ...newService };

                    let newServiceTime = values.serviceTimes;
                    newServiceTime[index] = serviceTimeValue;
                    newServiceTime = [...newServiceTime];

                    setValues({
                      ...values,
                      serviceTimes: newServiceTime
                    });
                  }}
                />
              );
            })}
        </Box>
        {/* TODO: condicion para crear uno nuevo */}
        {adHoc ? (
          <ServiceCollapse
            editableFields={['minRequired']}
            headKeys={['task', 'mediaRate', 'assigned', 'minRequired']}
            indexST={0}
            key="serv-key-adhoc"
            manualPricing={values.manualPricing}
            screen="schedule"
            service={{
              ...(values?.serviceTimes[0]?.services[0] || adHoc),
              readOnly: values?.readOnly
            }}
            setService={(newValue: any) => {
              setValues({
                ...values,
                serviceTimes: [
                  {
                    ...values?.serviceTimes[0],
                    services: [newValue]
                  }
                ]
              });
            }}
          />
        ) : null}
      </Typography>
    </Box>
  );
};

export default AssignResources;
