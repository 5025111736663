import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { getFrequencyWithDays } from '../../../../utils/general';
import TimeComponent from '../../../../components/input/TimeComponent';

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceTimeDuration: React.FC<Props> = function ServiceTimeDuration({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [scheduleTimeError, setScheduleTimeError] = useState<boolean>(false);
  const [totalServiceTime, setTotalServiceTime] = useState<any>(0);
  const adHoc = values?.serviceTimes?.[index]?.adhocTasks;

  const services =
    index >= 0 && values?.serviceTimes?.[index]
      ? values?.serviceTimes?.[index]?.services
      : values?.serviceTimes?.[0]?.services;

  const [timeStart, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );
  const [timeEnd, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(9).minute(0)
  );

  const finalHour = () => {
    const hours = Math.floor(totalServiceTime);
    const minutes = Math.round((totalServiceTime - hours) * 60);
    const finalTime = timeStart
      ? timeStart.add(hours, 'hour').add(minutes, 'minute')
      : null;
    const newDate = finalTime ? finalTime.format('h:mm A') : '';
    return newDate;
  };

  useEffect(() => {
    setTimeStart(
      dayjs()
        .hour(parseFloat(services?.[index]?.startTime?.split(':')[0]))
        .minute(parseFloat(services?.[index]?.startTime?.split(':')[1]))
    );
    setTimeEnd(
      dayjs()
        .hour(parseFloat(services?.[index]?.endTime?.split(':')[0]))
        .minute(parseFloat(services?.[index]?.endTime?.split(':')[1]))
    );

    const totalValue = services
      ? services
          .flatMap((array: any) => array.tasks)
          .reduce(
            (accumulator: any, item: any) =>
              accumulator + (item?.totalTime || 0),
            0
          )
      : 0;

    const adHocValueTime = adHoc?.totalTime ? adHoc.totalTime : 0;
    const totalTimeValue = totalValue + adHocValueTime;

    setTotalServiceTime(totalTimeValue);
  }, [values]);

  useEffect(() => {
    if (typeof values.serviceTimes[index].startTime === 'string') {
      const startTime = dayjs()
        .hour(parseFloat(values.serviceTimes[index]?.startTime?.split(':')[0]))
        .minute(
          parseFloat(values.serviceTimes[index]?.startTime?.split(':')[1])
        );
      const endTime = dayjs()
        .hour(parseFloat(values.serviceTimes[index]?.endTime?.split(':')[0]))
        .minute(parseFloat(values.serviceTimes[index]?.endTime?.split(':')[1]));
      setTimeStart(startTime);
      setTimeEnd(endTime);
    }
  }, [values]);

  useEffect(() => {
    // This is to show the user a message indicating that the service time is greater than the time window
    const hours = Math.floor(totalServiceTime);
    const minutes = Math.round((totalServiceTime - hours) * 60);
    const finalTime = timeStart
      ? timeStart.add(hours, 'hour').add(minutes, 'minute')
      : null;
    if (finalTime && timeEnd) {
      const finalArriveTime = timeEnd.hour() + timeEnd.minute() / 100;
      const finalCalculatedTime = finalTime.hour() + finalTime.minute() / 100;
      if (finalCalculatedTime > finalArriveTime) {
        setScheduleTimeError(true);
      } else {
        setScheduleTimeError(false);
      }
    }
  }, [values, timeStart, timeEnd]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="32px"
      padding="32px 24px 32px 24px"
      sx={{
        border: scheduleTimeError ? 2 : 1,
        borderColor: scheduleTimeError
          ? theme.palette.error.main
          : theme.palette.secondary[400],
        borderRadius: 1
      }}
    >
      <Typography variant="secondary-semi">
        {t('LEAD.CREATE.SERVICES.SCHEDULE.serviceTimeTitle')}
      </Typography>

      <Box display="flex" flexDirection="column" gap="16px">
        <Typography variant="primary">
          {t('LEAD.CREATE.SERVICES.SCHEDULE.totalDurationMsg1')}
        </Typography>

        <Box display="flex" gap="8px">
          <TimeComponent
            readOnly
            setTime={() => {}}
            time={totalServiceTime || 0.0}
          />

          {timeStart && timeStart.isValid() && timeEnd && timeEnd.isValid() && (
            <Box>
              <Typography fontSize="1rem" variant="primary">
                {values.projectType === 'Regular'
                  ? 'Starting'
                  : 'Starting between'}
              </Typography>{' '}
              <Typography fontSize="1rem" variant="primary">
                {timeStart.format('h:mm A')}{' '}
                {t('LEAD.CREATE.SERVICES.SCHEDULE.arriveMsg2')}
                {values.projectType === 'Regular' ? 'Finishing ' : ''}
                {false && finalHour()}
                {timeEnd.format('h:mm A')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {values &&
      values.projectType === 'adhoc' &&
      values.projectType === 'Ad-Hoc' ? null : (
        <Box display="flex">
          <Typography fontSize="1rem" variant="primary">
            {' '}
            {values?.serviceTimes
              ? getFrequencyWithDays(values?.serviceTimes[index], t)
              : 'frequency'}
          </Typography>
        </Box>
      )}
      {scheduleTimeError ? (
        <Box display="flex">
          <Typography
            color={theme.palette.error.main}
            fontSize="1rem"
            variant="body_300"
          >
            * {t('LEAD.CREATE.SERVICES.SCHEDULE.moreHoursMsg')}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ServiceTimeDuration;
