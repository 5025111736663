import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useSWR from 'swr';
import api from '../api';
import NavigationWrapper from '../components/shared/NavigationWrapper';
import {
  AUTHORIZED_VIEW_NAMES,
  GLOBAL,
  loginLandingByRole
} from '../helpers/routesConstants';
import useAuthStore from '../store/zustand/auth';
import { getLocalStorage } from '../utils/authHelper';
import AppContainer from '../views/AppContainer';
import ProtectedRoute from './ProtectedRoute';
import useNavigationStore from '../store/zustand/navigation';
import GlobalConfirmModal from '../components/modals/GlobalConfirmModal';
import ClientProposalView from '../views/leads/steps/ClientProposalView';
import { priorityRoleLogin } from '../helpers/roles';
import ClientContainer from '../views/ClientContainerUnauthenticated';
import ClientProcess from '../views/ClientProcess';
import ClientContainerAuthenticated from '../views/ClientContainerAuthenticated';

function AuthenticatedApp() {
  // This component will be for authentication checks & redirection flags.

  const userState = useAuthStore((state) => state);
  const { logout, roles } = userState;
  const roleName = priorityRoleLogin(roles) || '';
  const defaultURL =
    loginLandingByRole[roleName as keyof typeof loginLandingByRole];
  const { confirmModalStatus, setCurrentStepScreen } = useNavigationStore(
    (state) => state
  );
  const [checkToken, setCheckToken] = useState<boolean>(false);

  let time: any;
  let resetToken: any;
  const { data } = useSWR(
    checkToken ? '/validToken' : null,
    api.authApi.tokenValid
  );

  const token = getLocalStorage('accessToken');
  if (!token) {
    setCheckToken(true);
  }

  const resetTimer = () => {
    clearTimeout(time);
    if (getLocalStorage('rememberMe')) {
      return;
    } // autologout only available when the user disable remember me
    time = setTimeout(() => {
      logout();
    }, 3600000); // 1 hr min Idle
  };

  const resetTokenFunc = () => {
    const timeNow = new Date();
    const tokenTimeLeft = new Date(
      (JSON.parse(atob(token.split('.')[1])).exp - 120) * 1000
    );
    const difference = tokenTimeLeft.getTime() - timeNow.getTime();
    if (difference > 0) {
      clearTimeout(resetToken);
      resetToken = setTimeout(() => {
        setCheckToken(true);
      }, difference);
    } else {
      setCheckToken(true);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
    resetTokenFunc();
    setCurrentStepScreen('app');
    return () => {
      document.removeEventListener('mousemove', resetTimer);
      clearTimeout(resetToken);
      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setCheckToken(false);
      resetTimer();
      resetTokenFunc();
    }
  }, [data]);

  return (
    <>
      <NavigationWrapper>
        <Routes>
          <Route element={<ProtectedRoute />} path="/">
            <Route element={<AppContainer />} path="app/*" />
            <Route element={<Navigate to={defaultURL} />} />
            <Route
              element={<ClientProposalView />}
              path={`${GLOBAL.CUSTOMER_PROPOSAL}/:token`}
            />
            <Route
              element={<ClientContainerAuthenticated />}
              path={`${GLOBAL.CUSTOMER_PAGE}/*`}
            />
          </Route>
        </Routes>
      </NavigationWrapper>
      {confirmModalStatus && <GlobalConfirmModal />}
    </>
  );
}

export default AuthenticatedApp;
