import { CreateCompanyInvitationDTOPlanEnum } from '../api-client/generated';
import {
  ASSIGNMENTS_TAB,
  DASHBOARD_TAB,
  CALENDAR_TAB,
  PROJECT_TAB,
  CUSTOMER_TAB,
  LEAD_TAB,
  CHAT_TAB,
  TIMESHEET,
  DOCUMENTS_TAB,
  CATALOG_TAB,
  RESOURCES_TAB,
  RESOURCES_OPT_TAB,
  COMPANIES_TAB,
  RESOURCES_ADMIN_TAB,
  CUSTOMER_INVOICES_TAB,
  CUSTOMER_INFORMATION_TAB,
  CUSTOMER_PROPOSAL_TAB,
  CUSTOMER_DASHBOARD_TAB,
  CUSTOMER_PROJECT_TAB,
  CATALOG_SUPERUSER_TAB
} from '../helpers/navItems';
import { ROLES } from '../helpers/roles';

interface menuTabsProps {
  children?: {
    route: string;
    text: string;
  }[];
  icon: string;
  position: number;
  route: string;
  text: string;
}

interface UserState {
  active: boolean;
  block: boolean;
  email: string | undefined;
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
  mfa: boolean;
  roles: string[] | [];
  setUser: (data: any) => void;
  timeFormat?: string;
}

function filterUniquePositionsWithMaxLength(
  menuTabs: menuTabsProps[]
): menuTabsProps[] {
  const positions: {
    [position: number]: { childrenLength: number; tab: menuTabsProps };
  } = {};
  const result: menuTabsProps[] = [];
  menuTabs.forEach((tab) => {
    const { position } = tab;
    const childrenLength = tab.children ? tab.children.length : 0;
    if (
      !positions[position] ||
      childrenLength > positions[position].childrenLength
    ) {
      positions[position] = { childrenLength, tab };
    }
  });
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const position in positions) {
    result.push(positions[position].tab);
  }

  return result;
}

export default function useOrderTabByRole(userState: UserState, company?: any) {
  if (!userState.roles) {
    return [];
  }
  const userRoles = [...userState.roles];
  const menuTabs: menuTabsProps[] = [];
  //  We add the tabs according to the company restrictions
  if (company?.plan === CreateCompanyInvitationDTOPlanEnum.Calculator) {
    userRoles.forEach((el) => {
      switch (el) {
        case ROLES.OPERATIONS:
          menuTabs.push(CUSTOMER_TAB, LEAD_TAB, CATALOG_TAB, RESOURCES_OPT_TAB);
          break;
        case ROLES.ADMINISTRATOR:
          menuTabs.push(RESOURCES_ADMIN_TAB, CATALOG_TAB);
          break;
        case ROLES.SUPER_USER:
          menuTabs.push(
            COMPANIES_TAB,
            RESOURCES_TAB,
            CATALOG_SUPERUSER_TAB
            // CALENDAR_TAB
          );
          break;
        default:
          break;
      }
    });
  } else {
    // We Add the tabs according to the role. User can have more tha one role.
    userRoles.forEach((el) => {
      switch (el) {
        case ROLES.OPERATIONS:
          menuTabs.push(
            DASHBOARD_TAB,
            CALENDAR_TAB,
            PROJECT_TAB,
            CUSTOMER_TAB,
            LEAD_TAB,
            TIMESHEET,
            CATALOG_TAB,
            RESOURCES_OPT_TAB
          );
          break;
        case ROLES.SALES:
          menuTabs.push(CUSTOMER_TAB, LEAD_TAB, CATALOG_TAB);
          break;
        case ROLES.BUSINESS_DEVELOPMENT:
          menuTabs.push(CUSTOMER_TAB);
          break;
        case ROLES.TECHNICIAN:
          menuTabs.push(DASHBOARD_TAB, CALENDAR_TAB, TIMESHEET);
          break;
        case ROLES.ADMINISTRATOR:
          menuTabs.push(RESOURCES_ADMIN_TAB, CATALOG_TAB);
          break;
        case ROLES.FINANCE:
          menuTabs.push(PROJECT_TAB, CUSTOMER_TAB, TIMESHEET);
          break;
        case ROLES.SUPER_USER:
          menuTabs.push(COMPANIES_TAB, RESOURCES_TAB, CATALOG_SUPERUSER_TAB);
          break;
        case ROLES.SUPERVISOR:
          menuTabs.push(PROJECT_TAB);
          break;
        case ROLES.CUSTOMER:
          menuTabs.push(
            CUSTOMER_PROJECT_TAB,
            CUSTOMER_INVOICES_TAB,
            CUSTOMER_INFORMATION_TAB,
            CUSTOMER_PROPOSAL_TAB
          );
          break;
        default:
          break;
      }
    });
  }

  const filteredMenuTabs = filterUniquePositionsWithMaxLength(menuTabs);
  return filteredMenuTabs.sort((a, b) => a.position - b.position);
}
