import { Box, Typography, styled, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const GeneralInfoContainer = styled(Box)<{ clientView?: boolean }>(
  ({ theme, clientView }) => ({
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(2),
    marginBottom: clientView ? 0 : theme.spacing(4),
    marginTop: clientView ? 0 : theme.spacing(4),
    padding: theme.spacing(2)
  })
);

interface Props {
  clientView?: boolean;
  values: any;
}
interface InfoRowProps {
  label: string;
  value: string;
}

function InfoRow({ label, value }: InfoRowProps) {
  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Typography
        color="primary"
        fontWeight={700}
        sx={{ width: '30%' }}
        variant="subtitle2"
      >
        {label}
      </Typography>
      <Typography
        color="secondary.main"
        sx={{ textAlign: 'left', width: '70%' }}
        variant="body_300"
      >
        {value}
      </Typography>
    </Box>
  );
}

const ProposalGeneralInformation: React.FC<Props> =
  function ProposalGeneralInformation({ values, clientView }) {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <GeneralInfoContainer clientView={clientView}>
        <Typography sx={{ fontSize: clientView ? 20 : 26 }} variant="h6">
          {t('LEAD.CREATE.PROPOSAL.generalInfoTitle')}
        </Typography>
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.secondary[100]}`,
            marginTop: 1,
            width: '100%'
          }}
        />
        <Box display="flex" flexDirection="column" gap={1} mt={2}>
          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoClient')}
            value={values?.client?.name ?? 'N/A'}
          />
          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoContact')}
            value={`${values?.contact?.name ?? 'N/A'} ( ${
              values?.contact?.phoneNumber ?? 'N/A'
            } )`}
          />
          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoServiceAddress')}
            value={values?.address?.address ?? 'N/A'}
          />
          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoProjectName')}
            value={values?.name ?? 'N/A'}
          />
          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoDuration')}
            value={`${
              values?.startDate
                ? dayjs(values.startDate).format('MM/DD/YYYY')
                : 'N/A'
            } - ${
              values?.endDate
                ? dayjs(values.endDate).format('MM/DD/YYYY')
                : 'N/A'
            }`}
          />
          <InfoRow
            label={t('LEAD.CREATE.PROPOSAL.generalInfoDescription')}
            value={values?.description ?? 'N/A'}
          />
        </Box>
      </GeneralInfoContainer>
    );
  };

ProposalGeneralInformation.defaultProps = {
  clientView: false
};

export default ProposalGeneralInformation;
