import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  SliderProps,
  styled,
  useTheme,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
  Button,
  Collapse,
  TableHead,
  Paper
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const TimePickerFileld = styled(TextField)(() => ({
  '.MuiOutlinedInput-notchedOutline': {
    borderStyle: 'hidden'
  },
  borderStyle: 'hidden',
  fontSize: '15px',
  marginTop: '10px',
  width: '100%'
}));

const style = {
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '20px'
};

interface Props {
  saveStepper: ({ next }: any) => void;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const ServiceWindow: React.FC<Props> = function ServiceWindow({
  saveStepper,
  serviceIndex: index,
  setValues,
  setStep,
  values
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [servicesForm, setServicesForm] = useState<any>({});

  const [startTime, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );

  const [endTime, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(18).minute(0)
  );

  const services =
    index >= 0 && values?.serviceTimes?.[index]
      ? values?.serviceTimes?.[index]?.services
      : values?.serviceTimes?.[0]?.services;

  const formatTime = (time: Dayjs | null) => {
    const timeUTC = time;
    if (
      timeUTC?.hour().toString().length === 1 &&
      timeUTC?.minute().toString().length === 1
    ) {
      return `0${timeUTC?.hour()}:0${timeUTC?.minute()}`;
    }
    if (timeUTC?.hour().toString().length === 1) {
      return `0${timeUTC?.hour()}:${timeUTC?.minute()}`;
    }
    if (timeUTC?.minute().toString().length === 1) {
      return `${timeUTC?.hour()}:0${timeUTC?.minute()}`;
    }
    return `${timeUTC?.hour()}:${timeUTC?.minute()}`;
  };

  const getServiceTimeWarning = () => {
    const startHour = startTime?.hour() ?? 0;
    const endHour = endTime?.hour() ?? 0;
    if (endHour < startHour) {
      return (
        <Box>
          <Typography color="warning.main" variant="primary">
            {t('LEAD.CREATE.SERVICES.nextDayWarning')}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  useEffect(() => {
    if (typeof values.serviceTimes[index].startTime === 'string') {
      const startTime = dayjs()
        .hour(parseFloat(values.serviceTimes[index]?.startTime?.split(':')[0]))
        .minute(
          parseFloat(values.serviceTimes[index]?.startTime?.split(':')[1])
        );
      const endTime = dayjs()
        .hour(parseFloat(values.serviceTimes[index]?.endTime?.split(':')[0]))
        .minute(parseFloat(values.serviceTimes[index]?.endTime?.split(':')[1]));
      setTimeStart(startTime);
      setTimeEnd(endTime);
    }
  }, []);

  return (
    <Box alignItems="center" display="flex" gap="40px">
      <Typography variant="secondary-semi">
        {t('LEAD.CREATE.SERVICES.SCHEDULE.serviceWindowTitle')}
      </Typography>

      <Box>{getServiceTimeWarning()}</Box>
      <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
        <Typography variant="body_400">
          {values.projectType === 'Regular'
            ? t('LEAD.CREATE.SERVICES.SCHEDULE.starting')
            : t('LEAD.CREATE.SERVICES.SCHEDULE.arriveMsg1')}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box>
            <DesktopTimePicker
              disabled={values?.readOnly}
              onChange={(newValue) => {
                setTimeStart(newValue);
                const newService = services;
                const newSrt = values?.serviceTimes;
                if (newSrt) {
                  newSrt[index] = {
                    ...newSrt[index],
                    startTime: formatTime(newValue)
                  };
                  setValues({ ...values, serviceTimes: newSrt });
                }
                if (newService && newService[index]) {
                  newService[index].startTime = formatTime(newValue);
                }
                setServicesForm({ ...servicesForm, services: newService });
              }}
              renderInput={(params) => {
                /* eslint-disable react/jsx-props-no-spreading */
                return <TimePickerFileld InputProps={{ style }} {...params} />;
              }}
              value={startTime}
            />
          </Box>
          <Typography variant="body_400">
            {values.projectType === 'Regular'
              ? t('LEAD.CREATE.SERVICES.SCHEDULE.finishing')
              : t('LEAD.CREATE.SERVICES.SCHEDULE.arriveMsg2')}
          </Typography>
          <Box>
            <DesktopTimePicker
              disabled={values?.readOnly}
              onChange={(newValue) => {
                setTimeEnd(newValue);
                const newService = services;
                const newSrt = values?.serviceTimes;
                if (newSrt) {
                  newSrt[index] = {
                    ...newSrt[index],
                    endTime: formatTime(newValue)
                  };
                  setValues({ ...values, serviceTimes: newSrt });
                }
                if (newService && newService[index]) {
                  newService[index].endTime = formatTime(newValue);
                }
                setServicesForm({ ...servicesForm, services: newService });
              }}
              renderInput={(params) => (
                <TimePickerFileld InputProps={{ style }} {...params} />
              )}
              value={endTime}
            />
          </Box>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default ServiceWindow;
