import { LocationHistoryApi } from '../api-client/generated';
import { getUserLocation } from '../utils/geofencingHelper';

const apiCaller = new LocationHistoryApi();

const getLocationHistory = async (params: any, date: string) => {
  // console.log({ userId, projectId, calendarEventId });
  // TODO: Mutate should filter by user and calendarEventId and taskIterationId
  const { data } = await apiCaller.locationHistoryFind({
    calendarEventId: params.calendarEventId,
    date,
    projectId: params.projectId,
    userId: params.userId
  });
  return data;
};

const createLocationHistory = async (params: any) => {
  const { lat, long } = await getUserLocation();
  const { data } = await apiCaller.locationHistoryCreate({
    locationHistoryCreationDTO: {
      action: params.action,
      calendarEventId: params.calendarEventId,
      latitude: lat,
      longitude: long,
      projectId: params.projectId,
      taskIterationId: params.taskIterationId
    }
  });
  return data;
};

export default {
  createLocationHistory,
  getLocationHistory
};
