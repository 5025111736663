/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line react/no-unstable-nested-components
import React, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProjectsTimeList from './ProjectsTimeList';
import InvoiceServiceTimes from './serviceTimes/InvoiceServiceTimes';
import InvoicesList from './invoices/InvoicesList';
import Notes from './Notes';
import Assignments from './Assignments';
import Chat from '../../components/client/Chat';
import api from '../../api';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  padding?: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, padding = 3, ...other } = props;
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box className="tab-panel-box" sx={{ p: padding, width: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

interface StepDetailProjectsProps {
  dayChecked: string;
  detailProject: any;
  isClient?: boolean;
  projectType: string;
  refresh: () => void;
  typeView: string;
  utcUpadtedAt: string;
}

const StepDetailProjects: React.FC<StepDetailProjectsProps> =
  function StepProjects({
    detailProject,
    utcUpadtedAt,
    typeView,
    dayChecked,
    projectType,
    refresh,
    isClient = false
  }) {
    const [step, setStep] = useState<number>(0);
    const [countServices, setCountServices] = useState<number>(0);
    const { t } = useTranslation();

    useEffect(() => {
      if (
        detailProject &&
        detailProject?.proposal?.lead?.serviceTimes &&
        detailProject?.proposal?.lead?.serviceTimes.lenght > 0 &&
        detailProject?.proposal?.lead?.serviceTimes[0]?.services
      ) {
        setCountServices(
          detailProject?.proposal?.lead?.serviceTimes[0]?.services?.lenght
        );
      }
    }, [detailProject]);

    useEffect(() => {
      if (typeView && projectType === 'Regular') {
        setStep(1);
      }
    }, [typeView, projectType]);

    const headerProject = () => {
      return (
        <Box style={{ width: '100%' }}>
          <Tabs
            aria-label="basic tabs example"
            onChange={(_: React.SyntheticEvent, newValue: number) => {
              setStep(newValue);
            }}
            style={{
              backgroundColor: 'white',
              height: '100hv'
            }}
            value={step}
          >
            {projectType === 'Regular' && !isClient && (
              <Tab
                label={t('LISTING_PAGES.serviceSetup')}
                style={{ fontSize: '13px' }}
              />
            )}

            {projectType === 'Regular' && (
              <Tab
                label={t(
                  isClient
                    ? 'LISTING_PAGES.serviceAndTimes'
                    : 'LISTING_PAGES.serviceAndTask'
                )}
                style={{ fontSize: '13px' }}
              />
            )}

            {projectType === 'Ad-Hoc' && (
              <Tab
                label={
                  <Box
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '5px'
                    }}
                  >
                    <Typography
                      style={{
                        color: '#005DA2',
                        fontSize: '13px'
                      }}
                      variant="primary-bold"
                    >
                      {t('LISTING_PAGES.serviceAndTask')}
                    </Typography>

                    <Box
                      style={{
                        alignItems: 'center',
                        backgroundColor: '#005DA2',
                        borderRadius: '12px',
                        display: 'flex',
                        height: '20px',
                        justifyContent: 'center',
                        width: '30px'
                      }}
                    >
                      <Typography align="center" color="#FFFFFF" fontSize={12}>
                        {countServices}
                      </Typography>
                    </Box>
                  </Box>
                }
                style={{ fontSize: '13px' }}
              />
            )}
            <Tab
              label={t('LISTING_PAGES.invoices')}
              style={{ fontSize: '13px' }}
            />
            {isClient && (
              <Tab
                label={t('LISTING_PAGES.chat')}
                style={{ fontSize: '13px' }}
              />
            )}
            {!isClient && (
              <Tab
                disabled
                label={t('LISTING_PAGES.documents')}
                style={{ fontSize: '13px' }}
              />
            )}
            {!isClient && (
              <Tab
                label={t('LISTING_PAGES.notes')}
                style={{ fontSize: '13px' }}
              />
            )}
            {!isClient && (
              <Tab
                label={t('GENERAL.assignments')}
                style={{ fontSize: '13px' }}
              />
            )}
            {!isClient && (
              <Tab
                label={t('LISTING_PAGES.chatOperator')}
                style={{ fontSize: '13px' }}
              />
            )}
          </Tabs>

          <Grid
            md={12}
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'row',
              height: '100hv'
            }}
            xl={12}
            xs={12}
          >
            {projectType === 'Regular' ? (
              <>
                {!isClient && (
                  <TabPanel index={step} value={0}>
                    <ProjectsTimeList
                      detailProject={detailProject}
                      projectType={projectType}
                    />
                  </TabPanel>
                )}
                <TabPanel index={step} value={isClient ? 0 : 1}>
                  <InvoiceServiceTimes
                    dayChecked={dayChecked}
                    detailProject={detailProject}
                    projectType={projectType}
                    typeView={typeView}
                    utcUpadtedAt={utcUpadtedAt}
                  />
                </TabPanel>
                <TabPanel index={step} value={isClient ? 1 : 2}>
                  <InvoicesList
                    detailProject={detailProject}
                    isClient={isClient}
                  />
                </TabPanel>
              </>
            ) : (
              <>
                <TabPanel index={step} value={0}>
                  <InvoiceServiceTimes
                    dayChecked={dayChecked}
                    detailProject={detailProject}
                    projectType={projectType}
                    typeView={typeView}
                    utcUpadtedAt={utcUpadtedAt}
                  />
                </TabPanel>
                <TabPanel index={step} value={1}>
                  <InvoicesList
                    detailProject={detailProject}
                    isClient={isClient}
                  />
                </TabPanel>
              </>
            )}

            {!isClient && (
              <>
                <TabPanel
                  index={step}
                  value={projectType === 'Regular' ? 4 : 3}
                >
                  <Notes
                    id={detailProject?.id}
                    messages={detailProject?.notes}
                    updateMessages={() => {
                      refresh();
                    }}
                  />
                </TabPanel>
                <TabPanel
                  index={step}
                  padding={1}
                  value={projectType === 'Regular' ? 5 : 4}
                >
                  <Assignments
                    id={detailProject?.id}
                    projectEndDate={
                      detailProject?.proposal?.lead?.projectEndDate
                    }
                    projectStartDate={
                      detailProject?.proposal?.lead?.projectStartDate
                    }
                    projectStatus={detailProject?.status}
                    refresh={refresh}
                  />
                </TabPanel>
              </>
            )}

            <TabPanel
              index={step}
              padding={1}
              // eslint-disable-next-line no-nested-ternary
              value={isClient ? 2 : projectType === 'Regular' ? 6 : 5}
            >
              <Box sx={{ height: 520, overflow: 'scroll' }}>
                <Chat
                  apiToGetMessage={api.ProjectApi.projectGetMessages}
                  apiToPostMessage={
                    isClient
                      ? api.ProjectApi.postMessageProjectClientView
                      : api.ProjectApi.postMessageProjectOperator
                  }
                  clientView={isClient}
                  id={detailProject.id}
                />
              </Box>
            </TabPanel>
          </Grid>
        </Box>
      );
    };

    return (
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: 'white',
          borderLeft: '1px solid #ddd',
          height: '100%',
          marginTop: '-16px',
          padding: '2px'
        }}
        xs={12}
      >
        <Grid md={12} xl={12} xs={12}>
          {headerProject()}
        </Grid>
      </Grid>
    );
  };

export default StepDetailProjects;
