import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import {
  colorsParser,
  customWidthChart,
  keyParser,
  seriesParser
} from '../../../utils/widgetHelper';

// ======================================================================== INTERFACES
interface Props {
  customOptions?: ApexOptions;
  data: any;
  hideXLabels?: boolean;
  name?: string;
  nestedData?: boolean;
  showTitle?: boolean;
  type: string;
}

const ColumnChart: React.FC<Props> = function ColumnChart({
  data,
  nestedData,
  name,
  type,
  customOptions,
  hideXLabels,
  showTitle
}) {
  // ======================================================================== STATES

  const [categories, setCategories] = useState<Array<any>>();
  const [values, setValues] = useState<Array<any>>();
  const [colors, setColors] = useState<Array<any>>();

  // ======================================================================== CONST

  const options: ApexOptions = {
    chart: {
      id: name
    },
    colors,
    plotOptions: nestedData
      ? {}
      : {
          bar: {
            distributed: true
          }
        },
    title: { text: showTitle ? name : '' },
    xaxis: {
      categories,
      labels: { show: !hideXLabels }
    },
    ...customOptions
  };

  // ======================================================================== USEEFFECTS

  useEffect(() => {
    if (nestedData !== undefined) {
      const keys: any = Object.keys(data);
      setValues(seriesParser(data, nestedData));
      setColors(colorsParser(data, nestedData));
      setCategories(keyParser(keys, type, nestedData));
    }
  }, []);

  // ======================================================================== RENDER

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {values && options && (
            <Chart
              height={300}
              options={options}
              series={nestedData ? values : [{ data: values, name }]}
              type="bar"
              width={customWidthChart(data, !!nestedData) * 40}
            />
          )}
        </div>
      </div>
    </div>
  );
};

// ======================================================================== DEFAULT

ColumnChart.defaultProps = {
  customOptions: {},
  hideXLabels: false,
  name: 'chart',
  nestedData: false,
  showTitle: false
};

export default ColumnChart;
