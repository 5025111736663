/* eslint-disable no-nested-ternary */
/* eslint-disable no-unreachable */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
import {
  Box,
  Button,
  Container,
  LinearProgress,
  MenuItem,
  Modal,
  SliderProps,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';
import useSWR, { mutate } from 'swr';
import { isMobile } from 'react-device-detect';
import DomainIcon from '@mui/icons-material/Domain';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useNavigate } from 'react-router-dom';
import { linearProgressClasses } from '@mui/material/LinearProgress';
import timezone from 'dayjs/plugin/timezone';
import { getLocationTimeZone, validatePhoneNumber } from '../../utils/general';
import SelectInput from '../../components/form/SelectInput';
import api from '../../api';
import {
  FlexContainer,
  FormLabel,
  GridBoxInput,
  GridContainer,
  HeaderFormContainer,
  TitleFormBox,
  WhiteBoxContainer
} from '../../components/form/parts';
import {
  ACCOUNT_B2B_FIELDS,
  ACCOUNT_INFO_FIELDS,
  IMPORT_FIELDS
} from '../../helpers/client-data';
import { CreateClientDTORelationshipEnum } from '../../api-client/generated';
import { customerCommunicationMethods } from '../../utils/customerHelper';

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  left: '50%',
  padding: isMobile ? '40px 30px' : '40px 40px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile ? '90%' : 350
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 5,
  height: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.informative[200]
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.informative[500],
    borderRadius: 5
  }
}));

const clientStyle = {
  alignItems: 'flex-start !important',
  display: 'flex',
  flexDirection: 'row !important',
  flexWrap: 'wrap',
  justifyContent: 'flex-start !important',
  marginBottom: '20px',
  width: '100% !important'
};

const customStyles = {
  margin: '20px 0px',
  padding: '20px',
  width: '100%'
};

interface ClickableBoxProps {
  icon: React.ElementType;
  isSelected: boolean;
  onClick: () => void;
  subTitle: string;
  title: string;
}

const ClickableBox: React.FC<ClickableBoxProps> = function ({
  onClick,
  isSelected,
  icon: Icon,
  title,
  subTitle
}) {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        alignItems: 'center',
        background: `${theme.palette.background.paper}`,
        border: `1px solid ${isSelected ? '#59A9CF' : '#E0E0E0'}`,
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'flex',
        gap: 4,
        height: '224px',
        justifyContent: 'center',
        width: '580px'
      }}
    >
      <Box>
        <Icon style={{ color: '#005DA2', height: 80, width: 80 }} />
      </Box>
      <Box>
        <Typography color="primary" variant="primary-bold">
          {title}
        </Typography>
        <Typography color="secondary" variant="body2">
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

interface GridBoxInputSectionProps {
  csvKeys: string[];
  formValue: any;
  isMobile: boolean;
  label: string;
  name: string;
  required?: boolean;
  setFormValue: (value: any) => void;
}

function GridBoxInputSection({
  isMobile,
  name,
  formValue,
  setFormValue,
  csvKeys,
  required = false,
  label
}: GridBoxInputSectionProps) {
  const inputStyle = {
    width: '100%'
  };

  return (
    <GridBoxInput isMobile={isMobile} key={name} sx={{ width: '100%' }}>
      <FormLabel label={required ? `${label}*` : label} />
      <SelectInput
        onChange={(event) => {
          setFormValue({
            ...formValue,
            [name]: event.target.value
          });
        }}
        selectvariant="secondary"
        sx={inputStyle}
        value={formValue[name] || ''}
      >
        {csvKeys.map((item: any) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </SelectInput>
    </GridBoxInput>
  );
}

GridBoxInputSection.defaultProps = {
  required: false
};

function ImportClients() {
  const theme = useTheme();
  const [csvFile, setCsvFile] = useState(null);
  const [csvKeys, setCsvKeys] = useState<string[]>([]);
  const [json, setJson] = useState<string[]>([]);
  const [jsonError, setJsonErrors] = useState<string[]>([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorMsgs, setErrorMsgs] = useState<string[]>(['']);
  const [formValue, setFormValue] = useState<any>({});
  const [isFormView, setFormView] = useState<boolean>(false);
  const [values, setValues] = useState<any>({});
  const [error, setError] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [fireAPICreateClient, setFireAPICreateClient] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: dataCreatedClient, error: createdClientError } = useSWR(
    fireAPICreateClient ? [values, true] : null,
    api.ClientApi.createClient
  );

  useEffect(() => {
    if (dataCreatedClient) {
      setError(false);
      setFireAPICreateClient(false);
      setValues({});
      setIndex(index + 1);
    }
  }, [dataCreatedClient]);

  useEffect(() => {
    if (csvFile) {
      Papa?.parse(csvFile, {
        complete: function (results: any) {
          setCsvKeys(Object.keys(results.data[0]));
          setJson(results.data);
        },
        header: true
      });
    }
  }, [csvFile]);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setCsvFile(file);
  };

  const downloadCSV = () => {
    if (jsonError.length === 0) {
      return;
    }
    const csv = Papa?.unparse({
      data: jsonError,
      fields: csvKeys
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'CSV Export File';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (createdClientError) {
      const error = [createdClientError].find((param) => param !== undefined);
      const response = error?.response?.data?.message || '';
      const errors = error?.response?.data?.errors || [];
      const errorArray =
        typeof response === 'string'
          ? [response, ...errors]
          : response.push(errors);
      setErrorMsgs(errorArray);

      setJsonErrors([...jsonError, json[index]]);
      setFireAPICreateClient(false);
      setValues({});
      setIndex(index + 1);
    }
  }, [createdClientError]);

  const getAddressTimezone = async (address: string) => {
    if (!address || address === '') {
      return {
        timezone: new Date().getTimezoneOffset(),
        timezoneIANA: 'America/Chicago'
      };
    }
    const addresses = await api.MapsApi.searchDirection(address);
    const coords = addresses.features[0]?.center;
    const timezone = await getLocationTimeZone(coords[1], coords[0]);
    if (!timezone) {
      return {
        timezone: new Date().getTimezoneOffset(),
        timezoneIANA: 'America/Chicago'
      };
    }
    return timezone;
  };

  const checkErrorFields = (client: any) => {
    const temporalErrorArray: string[] = [];
    const labelErrorArray: string[] = [];
    if (client.email === '' || !client.email) {
      temporalErrorArray.push('email');
      labelErrorArray.push(t(`ERROR.inputFieldRequired`, ['Email']));
    }

    if (client.firstName === '' || !client.firstName) {
      temporalErrorArray.push('firstName');
      labelErrorArray.push(t(`ERROR.inputFieldRequired`, ['First Name']));
    }
    setErrorFields(temporalErrorArray);
    // Remove duplicated error values.
    const newLabelErrorArray = labelErrorArray.filter((ele, index) => {
      return labelErrorArray.indexOf(ele) === index;
    });
    setErrorMsgs(newLabelErrorArray);
    return newLabelErrorArray;
  };

  const handleClient = async () => {
    setIsInProgress(true);
    if (index < json.length && !error) {
      const client = {
        billingAddress: json[index][formValue.billingAddress],
        billingEmail: json[index][formValue.billingEmail] && [
          json[index][formValue.billingEmail]
        ],
        businessName: json[index][formValue.businessName],
        email: json[index][formValue.email],
        firstName: json[index][formValue.firstName],
        jobTitle: json[index][formValue.jobTitle],
        lastName: json[index][formValue.lastName],
        phone: validatePhoneNumber(json[index][formValue.phone]),
        relationship: formValue.relationship,
        workOrderEmails: json[index][formValue.workOrderEmails] && [
          json[index][formValue.workOrderEmails]
        ]
      };

      const timezoneObj = await getAddressTimezone(
        json[index][formValue.addressesAddress]
      );
      const addresses = {
        address: json[index][formValue.addressesAddress],
        name: json[index][formValue.addressesName],
        phone: validatePhoneNumber(json[index][formValue.addressesPhone]),
        timezone: timezoneObj.timezone,
        timezoneIANA: timezoneObj.timezoneIANA
      };
      const contacts = {
        name: json[index][formValue.contactsName],
        phoneNumber: validatePhoneNumber(json[index][formValue.contactsPhone])
      };
      const communicationMethods = {
        name: json[index][formValue.communicationMethodsName],
        type: formValue.communicationMethodsType,
        value: json[index][formValue.communicationMethodsValue]
      };
      const labelError = checkErrorFields(client);
      const values = {
        ...client,
        addresses: [addresses],
        communicationMethods: [communicationMethods],
        contacts: [contacts]
      };
      if (labelError.length === 0) {
        setValues(values);
        setFireAPICreateClient(true);
      } else {
        setJsonErrors([...jsonError, json[index]]);
        setValues({});
        setIndex(index + 1);
      }
      return;
    }
    setIsInProgress(false);
    downloadCSV();
  };

  const handleImport = async () => {
    setOpenModal(true);

    if (!csvFile) {
      setErrorMsgs([t(`CUSTOMER.errorUploadCsv`)]);
      setError(true);
      return;
    }
    handleClient();
  };

  useEffect(() => {
    if (index > 0) {
      handleClient();
    }
  }, [index]);

  const getBodyModal = () => {
    if (json.length - jsonError.length === 0) {
      return (
        <>
          <Typography
            color="mainText.main"
            sx={{ fontSize: 22 }}
            variant="primary-bold"
          >
            {t(`ERROR.errorTitle`)}
          </Typography>

          <Container sx={{ mt: 1 }}>
            <Typography
              color="mainText.main"
              sx={{
                display: 'inline',
                marginTop: '10px'
              }}
              variant="primary-bold"
            >
              {t(`CUSTOMER.errorAnyFieldProcessed`)}
            </Typography>
            <br />
            <Typography
              color="alert.main"
              sx={{
                display: 'inline',
                marginTop: '10px'
              }}
              variant="body_100"
            >
              {errorMsgs.map((error, index) => (
                <span key={`error-${index + 1}`}>
                  &nbsp; &bull; &nbsp;
                  {error}
                  <br />
                </span>
              ))}
            </Typography>
          </Container>
        </>
      );
    }
    if (isInProgress) {
      const calc = (index * 100) / json.length;
      const progress = Math.round(calc);
      return (
        <>
          <Typography
            color="mainText.main"
            sx={{ fontSize: 22 }}
            variant="primary-bold"
          >
            {t(`CUSTOMER.processingImport`, [index + 1, json.length])}
          </Typography>
          <BorderLinearProgress
            color="primary"
            style={{
              marginBottom: '10px',
              marginTop: '10px',
              width: '220px'
            }}
            value={progress}
            variant="determinate"
          />
          <Typography
            color="mainText.main"
            sx={{ fontSize: 22 }}
            variant="primary-bold"
          >
            {`${progress}%`}
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          color="success.main"
          sx={{ fontSize: 22 }}
          variant="primary-bold"
        >
          {t('CUSTOMER.modalMsgSuccesfuly')}
        </Typography>
        <br />
        <Typography
          color="mainText.main"
          sx={{
            display: 'inline',
            marginTop: '10px'
          }}
          variant="primary-bold"
        >
          {t(`CUSTOMER.successfullyProcessed`, [
            json.length - jsonError.length
          ])}
          {jsonError.length > 0 && t(`CUSTOMER.downloadCSV`)}
        </Typography>
        <br />
        {errorMsgs.length > 0 && (
          <Typography
            color="alert.main"
            sx={{
              display: 'inline',
              marginTop: '10px'
            }}
            variant="body_100"
          >
            {errorMsgs.map((error, index) => (
              <span key={`error-${index + 1}`}>
                &nbsp; &bull; &nbsp;
                {error}
                <br />
              </span>
            ))}
          </Typography>
        )}
      </>
    );
  };

  interface HandleClickProps {
    relationship: CreateClientDTORelationshipEnum;
  }

  const handleClick = ({ relationship }: HandleClickProps) => {
    setFormValue({
      relationship
    });
  };

  return (
    <Container sx={{ width: '90%' }}>
      <Box sx={{ display: 'flex', padding: '40px 0px 20px' }}>
        {/* ---------------- Header ---------------- */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width={isMobile ? '80%' : '100%'}
        >
          <TitleFormBox isMobile={isMobile}>
            <Typography sx={{ marginBottom: '4px' }} variant="title">
              {t(`CUSTOMER.titleImportCustomers`)}
            </Typography>
            <Typography color="secondary.400" variant="body_300">
              {t(`CUSTOMER.subtitleImportCustomers`)}
            </Typography>
          </TitleFormBox>
          <Button
            component="label"
            onClick={() => {
              setFormValue({ relationship: formValue.relationship });
              setFormView(false);
            }}
            sx={{
              lineHeight: 'normal',
              width: '160px'
            }}
            variant="contained"
          >
            {t(`CUSTOMER.uploadCSV`)}
            <input
              hidden
              accept=".csv"
              onChange={handleFileChange}
              type="file"
            />
          </Button>
        </Box>
      </Box>

      {!isFormView ? (
        <>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: '24px',
              justifyContent: 'space-between',
              marginTop: '24px',
              ...customStyles
            }}
          >
            <ClickableBox
              icon={DomainIcon}
              isSelected={
                formValue.relationship === CreateClientDTORelationshipEnum.B2B
              }
              onClick={() =>
                handleClick({
                  relationship: CreateClientDTORelationshipEnum.B2B
                })
              }
              subTitle={t('CUSTOMER.businessToBusinessSubTitle')}
              title={t('CUSTOMER.businessToBusinessTitle')}
            />
            <ClickableBox
              icon={PersonOutlineIcon}
              isSelected={
                formValue.relationship === CreateClientDTORelationshipEnum.B2C
              }
              onClick={() =>
                handleClick({
                  relationship: CreateClientDTORelationshipEnum.B2C
                })
              }
              subTitle={t('CUSTOMER.businessToConsumerSubTitle')}
              title={t('CUSTOMER.businessToConsumerTitle')}
            />
          </Box>
          {csvFile && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                component="label"
                onClick={() => setFormView(true)}
                sx={{
                  lineHeight: 'normal',
                  width: '160px'
                }}
                variant="contained"
              >
                Continue
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Container sx={clientStyle}>
          {formValue.relationship === CreateClientDTORelationshipEnum.B2B && (
            <WhiteBoxContainer
              customStyles={{ ...customStyles }}
              isMobile={isMobile}
            >
              <Box margin="20px 0px">
                <Typography color="primary" variant="heading_500">
                  {`${t('CUSTOMER.STEPS.companyInfo.title')}`}
                </Typography>
              </Box>
              <GridContainer
                isMobile={isMobile}
                sx={{
                  gap: '24px',
                  gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                  width: '100%'
                }}
              >
                {ACCOUNT_B2B_FIELDS.map((value, index) => (
                  <GridBoxInputSection
                    csvKeys={csvKeys}
                    formValue={formValue}
                    isMobile={isMobile}
                    label={value.label}
                    name={value.name}
                    required={value.required}
                    setFormValue={setFormValue}
                  />
                ))}
              </GridContainer>
            </WhiteBoxContainer>
          )}
          <WhiteBoxContainer
            customStyles={{ ...customStyles }}
            isMobile={isMobile}
          >
            <Box margin="20px 0px">
              <Typography color="primary" variant="heading_500">
                {formValue.relationship === CreateClientDTORelationshipEnum.B2B
                  ? `${t('CUSTOMER.STEPS.b2bMainContact.title')}`
                  : `${t('CUSTOMER.STEPS.b2cMainContact.title')}`}
              </Typography>
            </Box>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                width: '100%'
              }}
            >
              {ACCOUNT_INFO_FIELDS.map((value, index) => (
                <GridBoxInputSection
                  csvKeys={csvKeys}
                  formValue={formValue}
                  isMobile={isMobile}
                  label={value.label}
                  name={value.name}
                  required={value.required}
                  setFormValue={setFormValue}
                />
              ))}
            </GridContainer>
          </WhiteBoxContainer>
          <WhiteBoxContainer
            customStyles={{ ...customStyles }}
            isMobile={isMobile}
          >
            <Box margin="20px 0px">
              <Typography color="primary" variant="heading_500">
                {`${t('CUSTOMER.STEPS.communicationMethods.title')}`}
              </Typography>
            </Box>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr 1fr',
                width: '100%'
              }}
            >
              <GridBoxInput isMobile={isMobile} sx={{ width: '100%' }}>
                <FormLabel label={`${t('CUSTOMER.type')}`} />
                <SelectInput
                  onChange={(event) => {
                    setFormValue({
                      ...formValue,
                      communicationMethodsType: event.target.value
                    });
                  }}
                  selectvariant="secondary"
                  sx={{ width: '100%' }}
                  value={formValue.communicationMethodsType || ''}
                >
                  {customerCommunicationMethods.map((item: any) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </SelectInput>
              </GridBoxInput>

              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.value')}`}
                name="communicationMethodsValue"
                setFormValue={setFormValue}
              />
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('COMPANY_SETTINGS.name')}`}
                name="communicationMethodsName"
                setFormValue={setFormValue}
              />
            </GridContainer>
          </WhiteBoxContainer>
          <WhiteBoxContainer
            customStyles={{ ...customStyles }}
            isMobile={isMobile}
          >
            <Box margin="20px 0px">
              <Typography color="primary" variant="heading_500">
                {`${t('CUSTOMER.STEPS.billingPreferences.title')}`}
              </Typography>
            </Box>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: '100%',
                width: '100%'
              }}
            >
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.billingAddress')}`}
                name="billingAddress"
                setFormValue={setFormValue}
              />
            </GridContainer>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                width: '100%'
              }}
            >
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.validWorkOrderLabel')}`}
                name="workOrderEmails"
                setFormValue={setFormValue}
              />
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.billingEmail')}`}
                name="billingEmail"
                setFormValue={setFormValue}
              />
            </GridContainer>
          </WhiteBoxContainer>
          <WhiteBoxContainer
            customStyles={{ ...customStyles }}
            isMobile={isMobile}
          >
            <Box margin="20px 0px">
              <Typography color="primary" variant="heading_500">
                {`${t('CUSTOMER.STEPS.serviceAddress.title')}`}
              </Typography>
            </Box>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                width: '100%'
              }}
            >
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.nickname')}`}
                name="addressesName"
                setFormValue={setFormValue}
              />
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.phoneNumber')}`}
                name="addressesPhone"
                setFormValue={setFormValue}
              />
            </GridContainer>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: isMobile ? '100%' : '1fr',
                width: '100%'
              }}
            >
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.address')}`}
                name="addressesAddress"
                setFormValue={setFormValue}
              />
            </GridContainer>
          </WhiteBoxContainer>
          <WhiteBoxContainer
            customStyles={{ ...customStyles }}
            isMobile={isMobile}
          >
            <Box margin="20px 0px">
              <Typography color="primary" variant="heading_500">
                {`${t('CUSTOMER.STEPS.pointOfContact.title')}`}
              </Typography>
            </Box>
            <GridContainer
              isMobile={isMobile}
              sx={{
                gap: '24px',
                gridTemplateColumns: isMobile ? '100%' : '1fr 1fr',
                width: '100%'
              }}
            >
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('COMPANY_SETTINGS.name')}`}
                name="contactsName"
                setFormValue={setFormValue}
              />
              <GridBoxInputSection
                csvKeys={csvKeys}
                formValue={formValue}
                isMobile={isMobile}
                label={`${t('CUSTOMER.phoneNumber')}`}
                name="contactsPhone"
                setFormValue={setFormValue}
              />
            </GridContainer>
            <Box
              sx={{
                alignItems: 'center',
                display: isMobile ? 'block' : 'flex',
                marginTop: '10px'
              }}
            >
              <Button
                onClick={() => handleImport()}
                sx={{
                  lineHeight: 'normal',
                  marginLeft: '10px',
                  width: '22%'
                }}
                variant="primary"
              >
                {t('CUSTOMER.importCustomersButton')}
              </Button>
            </Box>
          </WhiteBoxContainer>

          <Modal
            aria-describedby="modal-modal-description-profile"
            aria-labelledby="modal-modal-title-profile"
            onClose={() => setOpenModal(false)}
            open={openModal}
          >
            <Box sx={style}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center'
                  }}
                >
                  {getBodyModal()}
                </Box>
                <br />
                <Button
                  onClick={() => {
                    if (isInProgress) {
                      setFireAPICreateClient(false);
                      setOpenModal(false);
                      setError(false);
                      setIndex(0);
                      setIsInProgress(false);
                      setValues({});
                    } else {
                      setOpenModal(false);
                      setCsvFile(null);
                      setCsvKeys([]);
                      setJson([]);
                      setJsonErrors([]);
                      setErrorMsgs(['']);
                      setIndex(0);
                      if (jsonError.length === 0) {
                        navigate('/app/clients/listing-page', {
                          replace: true
                        });
                      }
                    }
                  }}
                  sx={{ width: isMobile ? '100%' : '50%' }}
                  variant="contained"
                >
                  <Typography variant="primary-bold">
                    {isInProgress
                      ? t('GENERAL.cancelButton')
                      : t('GENERAL.done')}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Modal>
        </Container>
      )}
    </Container>
  );
}

export default ImportClients;
