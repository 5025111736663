/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';
import { Select } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

interface VariantProps extends ComponentProps<typeof Select> {
  height?: string;
  // eslint-disable-next-line react/require-default-props
  selectvariant?: 'secondary' | 'default';
}

const SelectInput: React.FC<VariantProps> = function SelectInput({
  children,
  height,
  ...props
}) {
  return (
    <Select
      {...props}
      IconComponent={ExpandMoreOutlinedIcon}
      sx={{
        '.MuiSelect-icon': {
          color: '#7D8090'
        },
        backgroundColor: 'white',
        borderRadius: '0.5em',
        borderWidth: 0,
        height: height || '48px !important',
        textAlign: 'left',
        width: '100%'
      }}
    >
      {children}
    </Select>
  );
};

SelectInput.defaultProps = {
  height: undefined
};

export default SelectInput;
