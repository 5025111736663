import { WidgetsApi } from '../../api-client/generated';

const apiCaller = new WidgetsApi();

const getAllWidgets = async () => {
  const response = await apiCaller.widgetGetAllWidgets();
  return response.data as any;
};

const getWidgetById = async (params: any) => {
  const { id } = params;
  const response = await apiCaller.widgetGetWidgetById({ id });
  return response.data;
};

const createWidget = async (params: any) => {
  const {
    chartType,
    description,
    itemCounter,
    name,
    periodConfig,
    primaryDataType,
    secondaryDataType,
    timeConfig
  } = params;
  const response = await apiCaller.widgetCreateNewWidget({
    createWidgetDataDTO: {
      chartType,
      description,
      itemCounter,
      name,
      periodConfig,
      primaryDataType,
      secondaryDataType,
      timeConfig
    }
  });
  return response.data;
};

const updateWidget = async (params: any) => {
  const {
    chartType,
    description,
    itemCounter,
    name,
    periodConfig,
    primaryDataType,
    secondaryDataType,
    timeConfig,
    id,
    isInterval
  } = params;
  const dataIntervalPeriod = isInterval
    ? {
        itemCounter,
        timeConfig
      }
    : {
        periodConfig
      };
  const response = await apiCaller.widgetUpdateWidget({
    id,
    updateWidgetDTO: {
      ...dataIntervalPeriod,
      chartType,
      description,
      name,
      primaryDataType,
      secondaryDataType
    }
  });
  return response.data;
};

const deleteWidget = async (params: { id: string; url: string }) => {
  if (!params.id) {
    return null;
  }
  const response = await apiCaller.widgetDeleteWidgetById({
    id: Number(params.id)
  });
  const { status, statusText } = response;
  return { status, statusText };
};

const getCategories = async (params: any) => {
  const { primaryDataType: primaryData } = params;
  const category = primaryData ? 'secondaryDataTypes' : 'presets';
  const response = await apiCaller.widgetGetCategories({
    category,
    primaryData
  });
  return response.data;
};

export default {
  createWidget,
  deleteWidget,
  getAllWidgets,
  getCategories,
  getWidgetById,
  updateWidget
};
