import { SwitchProps, styled, Switch } from '@mui/material';

export const IOSSwitch = styled((props: SwitchProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Switch disableRipple focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor:
          theme.palette.mode === 'dark' ? 'primary.100' : 'primary.100',
        border: 0,
        opacity: 1
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      },
      color: '#fff',
      transform: 'translateX(16px)'
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: '6px solid #fff',
      color: '#33cf4d'
    },
    margin: 2,
    padding: 0,
    transitionDuration: '300ms'
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    height: 22,
    width: 22
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    borderRadius: 26 / 2,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  },
  height: 26,
  padding: 0,
  width: 42
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#1890ff',
        opacity: 1,
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc'
        })
      },
      color: '#fff',
      transform: 'translateX(12px)'
    },
    padding: 2
  },
  '& .MuiSwitch-thumb': {
    borderRadius: 6,
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    height: 12,
    transition: theme.transitions.create(['width'], {
      duration: 200
    }),
    width: 12
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'rgba(0,0,0,.25)',
    borderRadius: 16 / 2,
    boxSizing: 'border-box',
    opacity: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)'
    })
  },
  '&:active': {
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    },
    '& .MuiSwitch-thumb': {
      width: 15
    }
  },
  display: 'flex',
  height: 16,
  padding: 0,
  width: 28
}));

export default IOSSwitch;
