import {
    ServiceEndpointsApi,
    CreateServiceDTO,
    ServiceEndpointsApiServiceGetAllPaginatedRequest,
    ServiceEndpointsApiServiceGetOneByIdRequest,
    ChecklistApi
  } from '../api-client/generated/api';
  
  const apiCaller = new ChecklistApi();
  
  const getChecklists = async () => {
    const response:any = await apiCaller.checklistGetChecklists();
    return response;
  };
  
  const getAllChecklists = async () => {
    const response:any = await apiCaller.checklistGetChecklists();
    const { data } = response;
    return data;
  };
  
  const getChecklist = async (
    param: ServiceEndpointsApiServiceGetOneByIdRequest
  ) => {
    if (param && param.id) {
      const response:any = await apiCaller.checklistGetChecklistById(param);
      const { data } = response;
      return data;
    }
    return null;
  };

  const createChecklist = async (params: any) => {    
    const { categories, description, name, taskChecklist } = params;
    const list = taskChecklist.map((task: any) => task.name);
    const response = await apiCaller.checklistCreate({
      createChecklistDTO: { categories, description, name, list }
    });
    const { data } = response;
    return { data };
  };
  
  const updateChecklist = async (params: any) => {
    const { categories, description, name, taskChecklist } = params;
    const list = taskChecklist.map((task: any) => task.name);
    const response = await apiCaller.checklistUpdate({
      id: params.id,
      updateChecklistDTO: { categories, description, name, list }
    });
    const { data } = response;
    return { data };
  };
  
  const deleteChecklist = async (params: { id: string; url: string }) => {
    if (!params.id) {
      return null;
    }
    const response = await apiCaller.checklistDelete({ id: params.id });
    const { status, statusText } = response;
    return { status, statusText };
  };
  
  export default {
    createChecklist,
    getChecklists,
    deleteChecklist,
    getAllChecklists,
    getChecklist,
    updateChecklist
  };
  