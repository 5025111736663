/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  styled
} from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { isMobile } from 'react-device-detect';
import ReportIcon from '@mui/icons-material/Report';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { height, width } from '@mui/system';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PdfViewerProps {
  file: string;
  title?: string;
}

const PDFDocumentWrapper = styled('div')(() => ({
  canvas: {
    height: 'auto !important',
    width: '100% !important'
  }
}));

const PdfViewerComponent: React.FC<PdfViewerProps> =
  function PdfViewerComponent({ file, title }) {
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [error, setError] = useState<boolean>(false);
    const { t } = useTranslation();

    const onDocumentLoadSuccess = ({
      numPages
    }: {
      numPages: number;
    }): void => {
      setNumPages(numPages);
      setPageNumber(1);
    };

    const onDocumentError = (event: any): void => {
      console.error(event);
      setError(true);
    };

    const changePage = (offset: number) =>
      setPageNumber((prevPageNumber) => prevPageNumber + offset);

    const previousPage = () => changePage(-1);
    const nextPage = () => changePage(1);

    if (error) {
      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginY: isMobile ? 2 : 0,
            width: '100%'
          }}
        >
          <Typography>{t('LEAD.CREATE.PROPOSAL.pdfErrorMessage')}</Typography>
          <ReportIcon sx={{ color: '#d3455b', fontSize: '200px' }} />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          marginY: isMobile ? 2 : 0,
          width: '100%'
        }}
      >
        <Paper>
          {title && (
            <Typography
              alignSelf="flex-start"
              color="primary"
              sx={{ fontSize: 24, paddingLeft: 4, paddingTop: 2 }}
              variant="h4"
            >
              {title}
            </Typography>
          )}
          <PDFDocumentWrapper>
            <Document
              file={file}
              onLoadError={onDocumentError}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </Document>
          </PDFDocumentWrapper>
          {numPages !== 0 && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                height: '40px',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <IconButton
                color="inherit"
                disabled={pageNumber <= 1}
                onClick={previousPage}
                sx={{
                  border: '1px solid',
                  borderRadius: '50%',
                  height: '28px',
                  width: '28px'
                }}
              >
                <ChevronLeft sx={{ fontSize: '14px' }} />
              </IconButton>
              <Typography
                sx={{ alignContent: 'center', marginX: 2 }}
                variant="body2"
              >
                {`${pageNumber || (numPages ? 1 : '--')} / ${numPages || '--'}`}
              </Typography>
              <IconButton
                color="inherit"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                sx={{
                  border: '1px solid',
                  borderRadius: '50%',
                  height: '28px',
                  width: '28px'
                }}
              >
                <ChevronRight sx={{ fontSize: '14px' }} />
              </IconButton>
            </Box>
          )}
        </Paper>
      </Box>
    );
  };

export default PdfViewerComponent;
