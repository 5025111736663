/* eslint-disable no-restricted-syntax */

import { mutate } from 'swr';
import { localCache } from './cache';

/* eslint-disable import/prefer-default-export */
export function setLocalStorage(key: string, data: any) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorage(key: string) {
  let toBack;
  if (key && localStorage.getItem(key)) {
    toBack = JSON.parse(localStorage.getItem(key) || '');
  }
  return toBack;
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export const rolesToEditUser = ['Administrator', 'Operations', 'SuperUser'];

export const clearCacheData = async () => {
  sessionStorage.clear();
  localStorage.clear();
  localCache.clear();
  mutate(() => true, undefined, { revalidate: false });
  mutate(() => true, null, { revalidate: false });
  if ('caches' in window) {
    caches.keys().then(function (names) {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  document.cookie = '';
};
