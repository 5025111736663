/* eslint-disable import/prefer-default-export */

const patternEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const patternPhone =
  /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export function clientOnboardingValidation(values: any, activeStep: number) {
  if (activeStep === 1) {
    if (values?.firstName === '' || !values?.firstName) {
      return {
        error: true,
        field: 'firstName',
        text: 'First name is Required'
      };
    }
    if (values?.lastName === '' || !values?.lastName) {
      return { error: true, field: 'lastName', text: 'Last name is Required' };
    }
  }
  if (activeStep === 2) {
    if (values?.email === '' || !values?.email) {
      return { error: true, field: 'email', text: 'Email is Required' };
    }
    if (!patternEmail.test(values?.email)) {
      return {
        error: true,
        field: 'email',
        text: 'Email should have the format example@mail.com'
      };
    }
    if (values?.phone === '' || !values?.phone) {
      return { error: true, field: 'phone', text: 'Phone Number is Required' };
    }
    if (!patternPhone.test(values?.phone)) {
      return {
        error: true,
        field: 'phone',
        text: 'Phone Number should have the format +(1) 323-133-2222'
      };
    }
    if (values?.businessName === '' || !values?.businessName) {
      return {
        error: true,
        field: 'businessName',
        text: 'Company Name is Required'
      };
    }
    if (values?.jobTitle === '' || !values?.jobTitle) {
      return { error: true, field: 'jobTitle', text: 'Job title is Required' };
    }
  }
  if (activeStep === 3) {
    if (values.billingAddress === '' || !values?.billingAddress) {
      return {
        error: true,
        field: 'billingAddress',
        text: 'Billing Address is required and must be selected from the list of options'
      };
    }
    if (values.billingEmail.length <= 0 || !values?.billingEmail) {
      return {
        error: true,
        field: 'billingEmail',
        text: 'Billing Email is required'
      };
    }
    let value: { error?: boolean; field?: string; text?: string } = {};
    values.billingEmail.forEach((email: string) => {
      if (!patternEmail.test(email) || !email) {
        value = {
          error: true,
          field: 'billingEmail',
          text: 'Billing Email should have the format example@mail.com'
        };
        return;
      }
      value = { error: false };
    });
    return value;
  }
  if (activeStep === 4) {
    if (values.addresses.length === 0) {
      return {
        error: true,
        field: 'addresses',
        text: 'Service Address is required'
      };
    }
    let val: { error?: boolean; field?: string; text?: string } = {};
    values.addresses.forEach((value: any) => {
      if (value?.phone === '' || !value?.phone) {
        val = {
          error: true,
          field: 'addresses.phone',
          text: 'Phone in address is required'
        };
        return;
      }
      if (!patternPhone.test(value?.phone)) {
        val = {
          error: true,
          field: 'addresses.phone',
          text: 'Phone Number should have the format +(1) 323-133-2222'
        };
        return;
      }
      if (value?.name === '' || !value?.name) {
        val = {
          error: true,
          field: 'addresses.name',
          text: 'Nickname in address is required'
        };
        return;
      }
      if (value?.address === '' || !value?.address) {
        val = {
          error: true,
          field: 'addresses.address',
          text: 'Address is required  and must be selected from the list of options'
        };
      }
    });
    return val;
  }
  if (activeStep === 5) {
    if (values.contacts.length === 0) {
      return {
        error: true,
        field: 'contacts',
        text: 'Contacts is required'
      };
    }
    let val: { error?: boolean; field?: string; text?: string } = {};
    values.contacts.forEach((value: any) => {
      if (value?.phoneNumber === '' || !value?.phoneNumber) {
        val = {
          error: true,
          field: 'contacts.phoneNumber',
          text: 'Phone number in contacts is required'
        };
      }
      if (!patternPhone.test(value?.phoneNumber)) {
        val = {
          error: true,
          field: 'contacts.phone',
          text: 'Phone Number should have the format +(1) 323-133-2222'
        };
        return;
      }
      if (value?.name === '' || !value?.name) {
        val = {
          error: true,
          field: 'contacts.name',
          text: 'Name in contacts is required'
        };
      }
    });
    return val;
  }

  return { error: false };
}
