export const AUTHORIZED_VIEW_NAMES = {
  ADMIN_PROCESS: 'admin-process',
  ADVANCED_DASHBOARDS: 'advanced-dashboards',
  ALL: 'all',
  APP: 'app/*',
  ASSIGNMENTS: 'assignments',
  AVAILABLE_DAYS: 'available-days',
  CALENDAR: 'calendar',
  CATALOG: 'catalog/*',
  CATEGORIES: 'categories',
  CHANGE_PASSWORD: 'change-password',
  CHAT_CONVERSATION: 'conversation',
  CHATS: 'chats/*',
  CHATS_LIST: 'list',
  CHATS_PROJECTS: 'projects/*',
  CHECKLISTS: 'checklists',
  CLIENTS: 'clients',
  COMPANIES: 'companies/*',
  COMPANIES_LIST: 'list',
  COMPANY_ONBOARDING: 'company-onboarding',
  COMPANY_SETTINGS: 'company-settings',
  COMPANY_SETTINGS_INTEGRATION: 'company-settings-integration',
  CONTACTS: 'contacts',
  CUSTOMER_PROJECTS: 'projects',
  CUSTOMERS: 'crm/*',
  DASHBOARD: 'dashboard',
  DEFAULT: '/',
  EDIT_CUSTOMER: 'edit',
  EDIT_LEAD: 'edit-lead',
  EDIT_USER: 'edit-user',
  IMPORT_CUSTOMERS: 'import-customers',
  INFORMATION: 'information',
  INSIGHTS: 'insights/*',
  INSIGHTS_ROUTES: 'insights-routes/*',
  INVALID: '*',
  INVITATIONS: 'invitations',
  INVOICES: 'invoices',
  LANDING: 'landing',
  LEADS: 'leads/*',
  LIST: 'listing-page',
  MATERIALS: 'materials',
  MENU: 'menu',
  MFA: 'mfa',
  NEW: 'new',
  ONBOARDING: 'onboarding/*',
  PROFILE: 'profile/*',
  PROJECTS: 'projects/*',
  PROJECTS_DETAIL: 'detail/*',
  PROJECTS_LIST: 'list',
  PROPOSAL_MESSAGES: 'proposal-messages',
  PROPOSALS: 'proposals',
  PROSPECTS: 'prospects',
  REQUESTS: 'requests',
  RESOURCES: 'resources/*',
  SERVICES: 'services',
  SETTINGS: 'settings',
  SKILLS: 'skills',
  TASKS: 'tasks',
  TECHNICIAN_TASK_DETAIL: 'technician-task-detail/*',
  TIMESHEET: 'timesheet',
  USERS: 'users/*',
  WIDGETS: 'widgets'
};

export const CATALOG_TABS = {
  CATEGORIES: '/app/catalog/categories',
  CHECKLISTS: '/app/catalog/checklists',
  MAIN: '/app/catalog/',
  MATERIALS: '/app/catalog/materials',
  PROPOSAL_MESSAGES: '/app/catalog/proposal-messages',
  SERVICE: '/app/catalog/services',
  SKILLS: '/app/catalog/skills',
  TASK: '/app/catalog/tasks',
  WIDGETS: 'app/catalog/widgets'
};

export const RESOURCES_TABS = {
  EDIT_USER: 'app/resources/edit-user',
  INVITATIONS: 'app/resources/invitations',
  REQUESTS: 'app/resources/requests',
  USERS: 'app/resources/users'
};

export const COMPANIES_TABS = {
  COMPANIES: 'app/companies/list',
  INVITATIONS: 'app/companies/invitations',
  USERS: 'app/companies/companies'
};

export const CUSTOMER_TABS = {
  ALL: '/app/crm/all',
  CLIENTS: '/app/crm/clients',
  CONTACTS: '/app/crm/contacts',
  EDIT_CUSTOMER: '/app/crm/edit',
  IMPORT_CUSTOMERS: '/app/crm/import-customers',
  NEW_CUSTOMER: '/app/crm/new',
  PROSPECTS: '/app/crm/prospects'
};

export const DASHBOARD_TABS = {
  DASHBOARD: '/app/dashboard',
  TECHNICIAN_TASK_DETAIL: '/app/dashboard/technician-task-detail'
};

export const LEAD_TABS = {
  EDIT_LEAD: '/app/leads/edit-lead',
  LEADS: '/app/leads/listing-page',
  NEW: '/app/leads/new'
};

export const DEFAULT_SCREENS = {
  ADMIN_PROCESS: '/app/admin-process',
  ASSIGNMENTS: '/app/assignments/',
  CHATS: 'chats/list',
  COMPANIES: '/app/companies/companies',
  COMPANY_ONBOARDING: '/app/company-onboarding',
  CUSTOMERS: '/app/crm/contacts',
  DASHBOARD: '/app/dashboard/',
  LANDING: '/app/landing/',
  LEADS: '/app/leads/listing-page',
  PROFILE: '/app/profile/',
  PROJECTS: 'projects/list',
  RESOURCES: '/app/resources/users',
  SERVICE_CATALOG: '/app/catalog/services',
  TIMESHEET: '/app/timesheet'
};

export const CUSTOMER_SCREENS = {
  DASHBOARD: '/customer-page/dashboard',
  INFORMATION: '/customer-page/information',
  INVOICES: '/customer-page/invoices',
  LANDING: '/customer-page/landing',
  PROJECTS: '/customer-page/projects/list',
  PROJECTS_DETAIL: 'customer-page-detail/*',
  PROPOSALS: '/customer-page/proposals'
};

export const loginLandingByRole = {
  Administrator: DEFAULT_SCREENS.ADMIN_PROCESS,
  BusinessDevelopment: DEFAULT_SCREENS.CUSTOMERS,
  Finance: DEFAULT_SCREENS.LANDING,
  Operations: DEFAULT_SCREENS.DASHBOARD,
  Sales: DEFAULT_SCREENS.CUSTOMERS,
  SuperUser: DEFAULT_SCREENS.LANDING,
  Supervisor: DEFAULT_SCREENS.LANDING,
  Technician: DEFAULT_SCREENS.DASHBOARD,
  default: DEFAULT_SCREENS.LANDING
};

export const UNAUTHORIZED_VIEW_NAMES = {
  CHANGE_PASSWORD: 'changePassword',
  DEFAULT: '/',
  FORGOT_PASSWORD: 'forgotPassword',
  INVALID: '*',
  INVITATION: 'invitation/accept-invitation',
  LOGIN: 'login',
  RESET_PASSWORD: 'reset-password'
};

export const GLOBAL = {
  ADMIN_INVITATION: 'company/invitation/accept-invitation',
  APP_LINK: 'app-link',
  CHANGE_PASSWORD: 'changePassword',
  CUSTOMER_INVOICE: 'customer-invoice',
  CUSTOMER_PAGE: 'customer-page',
  CUSTOMER_PROPOSAL: 'customer-proposal',
  DEFAULT: '/',
  FORGOT_PASSWORD: 'forgotPassword',
  IMPERSONATE: 'impersonate',
  INVITATION: 'invitation/accept-invitation',
  RESET_PASSWORD: 'reset-password'
};

export default { AUTHORIZED_VIEW_NAMES, GLOBAL, UNAUTHORIZED_VIEW_NAMES };
