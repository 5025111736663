import { NoteApi } from '../api-client/generated/api';

const apiCaller = new NoteApi();

const postNote = async (params: any) => {
  const { entity, entityId, noteText } = params;
  const response: any = await apiCaller.noteCreate({
    createNoteDTO: {
      entity,
      entityId,
      noteText
    }
  });
  return { ...response?.data, status: response?.status };
};

const getAllByEntity = async (params: any) => {
  const { entity, entityId } = params;
  const response: any = await apiCaller.noteGetAllByEntity({
    entity,
    entityId
  });
  return response.data;
};
export default {
  getAllByEntity,
  postNote
};
