import React, { useEffect, useState } from 'react';
import { Box, Container, useTheme } from '@mui/system';
import { Button, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HandshakeIcon from '@mui/icons-material/Handshake';
import blueBerryLogoLeters from '../../../../../assets/images/bluberry.png';
import blueBerryLogoMini from '../../../../../assets/images/bluberry_logo_mini.png';

interface Props {
  counterOfferSubmitted?: boolean;
  proposalId: string;
}

const AcceptedProposal: React.FC<Props> = function AcceptedProposal({
  proposalId,
  counterOfferSubmitted = false
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container
      sx={{ alignCenter: 'center', display: 'flex', justifyContent: 'center' }}
    >
      <Box
        mt={6}
        padding={6}
        sx={{
          alignSelf: 'center',
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[300]}`,
          maxWidth: '600px'
        }}
      >
        <Box
          justifyContent="center"
          sx={{
            display: 'flex',
            mb: 4,
            width: '100%'
          }}
        >
          <Box>
            <img
              alt="BlueBerry Logo"
              className="logo-image-mini"
              src={blueBerryLogoMini}
            />
            <img
              alt="BlueBerry leters"
              className="logo-image-letters"
              src={blueBerryLogoLeters}
            />
          </Box>
        </Box>
        <Box
          mb={4}
          mt={2}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {counterOfferSubmitted ? (
            <Typography variant="title">New proposal Requested</Typography>
          ) : (
            <Typography variant="title">
              {t('LEAD.CREATE.PROPOSAL.acceptedProposalTitle')}
            </Typography>
          )}
        </Box>
        <Box
          borderColor={theme.palette.grey[300]}
          mt={2}
          sx={{
            alignItems: 'center',
            display: 'block',
            justifyContent: 'center',
            justifyItems: 'center',
            padding: '0 4rem',
            textAlign: 'center'
          }}
        >
          {counterOfferSubmitted ? (
            <Typography variant="subtitle2">
              We’re working on your new proposal, and you’ll receive an email
              with a link to review it soon. <br />
              <br /> Please note that the previous proposal is no longer
              available.
            </Typography>
          ) : (
            <Typography variant="subtitle2">
              {t('LEAD.CREATE.PROPOSAL.acceptedProposalText')}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

AcceptedProposal.defaultProps = {
  counterOfferSubmitted: false
};

export default AcceptedProposal;
