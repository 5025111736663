import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  SliderProps,
  Typography,
  styled
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import api from '../api';
import SelectInput from '../components/form/SelectInput';
import { setLocalStorage, removeLocalStorage } from '../utils/authHelper';
import useAuthStore from '../store/zustand/auth';
import { parseJwt } from '../utils/general';

const getProportion = (baseSize: number, proportion: number) =>
  isMobile ? baseSize * proportion : baseSize;

const AuthProcessContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.mainText?.['100'],
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  marginTop: isMobile ? theme.spacing(-25) : theme.spacing(-18),
  minHeight: 277,
  paddingTop: isMobile ? theme.spacing(1) : theme.spacing(3),
  width: getProportion(500, 0.61)
}));

const FormContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'left',
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile ? theme.spacing(4) : theme.spacing(6),
  width: '100%'
}));

function ClientProcess() {
  const navigate = useNavigate();
  const userState = useAuthStore((state) => state);
  const { token: paramToken } = useParams<{ token: string }>();
  const [checkCompanies, setCheckCompanies] = useState<boolean>(false);
  const [checkTokenWithCompany, setCheckTokenWithCompany] =
    useState<boolean>(false);
  const [getProfile, setGetProfile] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [companiesList, setCompaniesList] = useState<any[]>([]);
  const [companySelected, setCompanySelected] = useState<string>('');

  const { data, error } = useSWR(
    checkCompanies ? { token: paramToken } : null,
    api.ClientPageApi.getCompanies
  );

  const { data: newToken, error: newTokenError } = useSWR(
    checkTokenWithCompany ? { id: companySelected, token: paramToken } : null,
    api.ClientPageApi.getTokenWithCompany
  );

  const { data: profile, error: errorProfile } = useSWR(
    getProfile ? '/clients-portal/information' : null,
    api.ClientPageApi.getClientInformation
  );

  useEffect(() => {
    if (paramToken) {
      removeLocalStorage('accessToken');
      const { companyId } = parseJwt(paramToken);
      if (companyId) {
        setCompanySelected(companyId);
        setCheckTokenWithCompany(true);
      } else {
        setCheckCompanies(true);
      }
    }
  }, []);

  useEffect(() => {
    if (error || newTokenError || errorProfile) {
      removeLocalStorage('accessToken');
      setCheckCompanies(false);
      setCheckTokenWithCompany(false);
      setGetProfile(false);
      navigate('/login', { state: { tokenExpired: true } });
    }
  }, [error, newTokenError, errorProfile]);

  useEffect(() => {
    if (data) {
      setCheckCompanies(false);
      if (data.length === 1) {
        setCompanySelected(data[0].companyId);
        setCheckTokenWithCompany(true);
      } else {
        setCompaniesList(data);
        setShowOptions(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (newToken) {
      setCheckTokenWithCompany(false);
      setLocalStorage('accessToken', newToken?.accessToken);
      setShowOptions(false);
      setGetProfile(true);
    }
  }, [newToken]);

  useEffect(() => {
    if (profile) {
      setGetProfile(false);
      if (profile.onboardingComplete) {
        const { email, firstName, lastName, phone, id } = profile;
        userState.setUser({
          active: true,
          block: false,
          email,
          firstName,
          id,
          lastName,
          mfa: false,
          phoneNumber: phone,
          roles: ['Client']
        });
        userState.login();
        navigate('/customer-page/landing');
      } else {
        navigate('/customer-page/onboarding', { state: profile });
      }
    }
  }, [profile]);

  return (
    <Container
      sx={{
        alignItems: 'center',
        backgroundColor: 'background',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Container
        className="TopFrame"
        sx={{ backgroundColor: 'primary.main', height: 334 }}
      />
      <AuthProcessContainer>
        {showOptions ? (
          <FormContainer>
            <Typography color="primary.main" variant="primary-bold">
              Select company
            </Typography>
            <SelectInput
              height="36px !important"
              onChange={(event: any) => {
                setCompanySelected(event.target.value);
              }}
              selectvariant="secondary"
            >
              {companiesList.map(
                (company: { companyId: string; companyName: string }) => (
                  <MenuItem key={company.companyId} value={company.companyId}>
                    {company.companyName}
                  </MenuItem>
                )
              )}
            </SelectInput>
            <Button
              color="primary"
              disabled={companySelected === ''}
              onClick={() => setCheckTokenWithCompany(true)}
              sx={{ width: '100%' }}
              variant="contained"
            >
              <Typography variant="primary-bold">Next</Typography>
            </Button>
          </FormContainer>
        ) : (
          <CircularProgress size={40} />
        )}
      </AuthProcessContainer>
    </Container>
  );
}

export default ClientProcess;
