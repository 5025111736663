import { Tabs, Tab, Box, Typography } from '@mui/material';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CompanyInformation from '../../components/companySettings/CompanyInformation';
import CompanySettings from '../../components/companySettings/CompanySettings';
import CompanyFinancialIntegration from '../../components/companySettings/CompanyFinancialIntegration';
import api from '../../api';
import ViewLoader from '../../components/shared/ViewLoader';
import { FormMainContainer } from '../../components/shared/container/containerParts';
import { TitleFormBox } from '../../components/form/parts';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      // eslint-disable-next-line
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '24px 24px 24px 0' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CompanySettingsPage() {
  const [value, setValue] = useState(0);
  const { state: params } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof params?.tab === 'number') {
      setValue(params?.tab);
    }
  }, [params]);

  const { data: currentCompany } = useSWR(
    'api/currentCompany',
    api.CompanyApi.getCurrentCompany
  );

  if (!currentCompany) {
    return <ViewLoader />;
  }

  return (
    <FormMainContainer isMobile={isMobile}>
      <TitleFormBox isMobile={isMobile}>
        <Typography sx={{ marginBottom: '4px' }} variant="title">
          {t('COMPANY_SETTINGS.company')}
        </Typography>
        {value !== 2 && (
          <Typography color="secondary.400" variant="body_300">
            {t('COMPANY_SETTINGS.pageSubtitle')}
          </Typography>
        )}
      </TitleFormBox>
      <Tabs
        aria-label="basic tabs example"
        onChange={(_: React.SyntheticEvent, newValue: number) =>
          setValue(newValue)
        }
        sx={{ marginLeft: '0px', paddingLeft: '0px' }}
        value={value}
      >
        <Tab label="Information" />
        <Tab label="Settings" />
        <Tab label="Integrations" />
      </Tabs>
      <TabPanel index={0} value={value}>
        <CompanyInformation companyInfo={currentCompany} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <CompanySettings />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <CompanyFinancialIntegration />
      </TabPanel>
    </FormMainContainer>
  );
}

TabPanel.defaultProps = {
  children: null
};

export default CompanySettingsPage;
