/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import '../../style/App.scss';
import useSWR from 'swr';
import api from '../../api';
import Notes from '../../views/projects/Notes';
import Chat from '../../views/leads/steps/components/Chat';

interface Props {
  id: string;
}

const NotesClient: React.FC<Props> = function NotesClient({ id }) {
  const [data, setData] = useState<any>();
  const [fireNotesApi, setNotesApi] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars

  // ================================================================================ SWR
  const {
    data: notesData,
    mutate: notesMutate,
    error: notesError
  } = useSWR(
    fireNotesApi ? { entity: 'customer', entityId: id, url: 'getNotes' } : null,
    api.NotesApi.getAllByEntity
  );

  // ================================================================================ LIFECYCLE

  useEffect(() => {
    if (notesData) {
      setData(notesData);
      setNotesApi(false);
    }
  }, [notesData]);

  useEffect(() => {
    if (notesError) {
      setData([]);
      setNotesApi(false);
    }
  }, [notesError]);

  // ================================================================================ RENDER

  return (
    <Chat
      notesView
      entity="customer"
      id={id}
      messages={data}
      updateMessages={() => {
        notesMutate(null, true);
        setNotesApi(true);
      }}
    />
  );
};

export default NotesClient;
