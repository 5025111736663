/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Typography,
  SliderProps,
  styled,
  useTheme,
  TextField,
  Button
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import ResourceServiceTimeList from './components/ResourceServiceTimeList';
import Api from '../../../api';

const style = {
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '20px'
};

interface Props {
  saveStepper: ({ next }: any) => void;
  // eslint-disable-next-line react/require-default-props
  sendValues?: React.Dispatch<React.SetStateAction<any>>;
  serviceIndex: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  values: any;
}

const styleCheckedIcon = {
  color: '#19ae9f',
  position: 'absolute',
  right: '4px',
  top: '-4px'
};

export const ServicesDots = styled('span')<SliderProps>(() => ({
  alignItems: 'center',
  borderRadius: '50%',
  color: 'white',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  width: '40px'
}));

const Schedule: React.FC<Props> = function Schedule({
  saveStepper,
  values,
  setValues,
  sendValues,
  serviceIndex: index,
  setStep
}) {
  const [serviceTimesList, setServiceTimesList] = useState<any>([]);
  const [serviceTimeUpdate, setServiceTimeUpdate] = useState<boolean>(false);

  const { t } = useTranslation();
  const theme = useTheme();
  const { data } = useSWR(
    serviceTimeUpdate === true && values?.serviceTimes[0]?.id
      ? { idLead: values.id, serviceTimes: values.serviceTimes }
      : null,
    (...argsUpdate) =>
      Api.LeadServiceTimesApi.updateMultipleLeadServicesTime(...argsUpdate)
  );

  useEffect(() => {
    if (data) {
      const auxSL = serviceTimesList.filter(
        (serviceTime: any) =>
          !serviceTime?.scheduled && !serviceTime?.isScheduled
      );

      setServiceTimeUpdate(false);

      if (
        (`${values?.manualPricing}` === 'true' && auxSL.length === 0) ||
        values?.projectType === 'Ad-Hoc'
      ) {
        setStep(3);
        saveStepper({ next: 3 });
      } else {
        saveStepper({ next: 1 });
        setStep(1);
      }
    }
  }, [data]);

  useEffect(() => {
    if (serviceTimeUpdate) {
      const auxSL = serviceTimesList.filter(
        (serviceTime: any) =>
          !serviceTime?.scheduled && !serviceTime?.isScheduled
      );
      setServiceTimeUpdate(false);
      if (`${values?.manualPricing}` === 'true' && auxSL.length === 0) {
        setStep(3);
        saveStepper({ next: 3 });
      } else {
        saveStepper({ next: 1 });
        setStep(1);
      }
    }
  }, [serviceTimeUpdate]);

  const colorsRef = useRef<any>([]);
  function randomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgba(${red}, ${green}, ${blue}, 0.3)`;
  }

  if (!colorsRef.current.length) {
    for (let i = 0; i < serviceTimesList?.length; i += 1) {
      colorsRef.current.push(randomColor());
    }
  }

  useEffect(() => {
    if (values?.serviceTimes?.length > 0) {
      setServiceTimesList(values?.serviceTimes);
    }
  }, [values?.serviceTimes]);

  function scheduleServices() {
    return (
      <Box display="flex" gap="20px">
        <Box
          alignItems="center"
          display="flex"
          gap="12px"
          justifyContent="center"
        >
          <EventAvailableIcon fontSize="large" sx={{ color: '#19ae9f' }} />
          <Typography color="primary.main" variant="body_500">
            {t('LEAD.CREATE.SERVICES.SCHEDULE.scheduledTitle')}
          </Typography>
        </Box>
        <Box display="flex" gap="8px">
          {serviceTimesList &&
            serviceTimesList?.length > 0 &&
            serviceTimesList.map(
              ({ name, scheduled, isScheduled }: any, index2: any) => {
                const auxKey = `schedule-serviceTime-${index2}`;
                if (name !== '') {
                  return (
                    <Box key={`${auxKey}`}>
                      {scheduled || isScheduled ? (
                        <CheckCircleIcon
                          fontSize="small"
                          sx={styleCheckedIcon}
                        />
                      ) : null}
                      <ServicesDots
                        sx={{ backgroundColor: colorsRef.current[index2] }}
                      >
                        <Typography color="mainText.main" variant="body_500">
                          {index2 + 1}
                        </Typography>
                      </ServicesDots>
                    </Box>
                  );
                }
                return null;
              }
            )}
        </Box>
      </Box>
    );
  }

  function buttons() {
    return (
      <Box
        sx={{
          alignSelf: 'flex-end',
          display: 'flex',
          width: 300
        }}
      >
        <Button
          fullWidth
          disabled={values?.readOnly}
          onClick={() => {
            const newServicesTimes = values.serviceTimes.map(
              (serviceTime: any) => {
                if (
                  typeof serviceTime?.id === 'number' &&
                  serviceTime?.services?.length > 0
                ) {
                  let validSchedule = true;
                  serviceTime.services.map((service: any) => {
                    const { tasks } = service;
                    if (tasks?.length > 0) {
                      tasks.forEach((task: any) => {
                        if (
                          (task && !task.resources) ||
                          (task &&
                            task.resources &&
                            task.resources.length === 0) ||
                          task.minimumResources > task.resources.length
                        ) {
                          validSchedule = false;
                        }
                      });
                    } else {
                      validSchedule = false;
                    }
                    return null;
                  });
                  return {
                    projectType: values.projectType,
                    ...serviceTime,
                    scheduled: validSchedule,
                    supervisor: serviceTime?.supervisor?.id
                      ? serviceTime?.supervisor
                      : null
                  };
                }

                return null;
              }
            );
            setValues({ ...values, serviceTimes: newServicesTimes });

            setServiceTimeUpdate(true);
          }}
          sx={{ m: 1 }}
          variant="primary"
        >
          {t('GENERAL.saveScheduled')}
        </Button>
      </Box>
    );
  }

  function serviceTimeList() {
    return (
      <Box sx={{ width: '100%' }} width="100%">
        {ResourceServiceTimeList({
          saveStepper,
          sendValues,
          setStep,
          setValues,
          values
        })}
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="48px">
      {values &&
      (values.projectType === 'adhoc' || values.projectType === 'Ad-Hoc')
        ? null
        : scheduleServices()}
      <Box display="flex" flexDirection="column" gap="24px">
        {serviceTimeList()}
        {buttons()}
      </Box>
    </Box>
  );
};

export default Schedule;
