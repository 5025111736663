import {
  ProfileApi,
  UpdateRequestStatusDTO
} from '../../api-client/generated/api';
import { sortWeekDays } from '../../utils/general';

const profileApi = new ProfileApi();

const getUserRequests = async (params: any) => {
  const filters:any = {}

  if(params.query) {
    filters.userName = params.query
  }
  if(params.status) {
    filters.status = params.status
  }
  
  const response = await profileApi
    .profileGetAllAvailableTimeRequest(filters)
    .then((res: any) => {
      if (res?.data?.length > 0) {
        res.data = res.data.map((item: any) => {
          return {
            ...item,
            requestedAvailableDays: sortWeekDays(item.requestedAvailableDays)
          };
        });
      }
      return res;
    });
  const { data } = response;
  return data as unknown as Array<any>;
};

const reviewRequest = async (requestId: string, status: string) => {
  const response = await profileApi.profileUpdateRequestStatus({
    updateRequestStatusDTO: { requestId, status }
  });
  const { data } = response;
  return { data };
};

export default {
  getUserRequests,
  reviewRequest
};
