import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../style/App.scss';
import {
  Container,
  Box,
  Button,
  Typography,
  styled,
  SlideProps,
  SliderProps,
  Modal,
  Backdrop
} from '@mui/material';
import useSWR from 'swr';
import ToastNotification from '../shared/ToastNotification';
import { toastNotification } from '../../utils/notificationHelper';

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 254,
  padding: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 407
}));

const ButtonContainerModal = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '216px'
}));

const DrawerButton = styled(Button)<SlideProps>(() => ({
  color: 'white',
  marginBottom: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  width: '100%'
}));

interface Props {
  action: (params: any) => Promise<any>;
  cleanRow?: () => void;
  id: any | null;
  mutate: () => void;
  setShouldMutate?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteModal: React.FC<Props> = function DeleteModal({
  setShowModal,
  mutate,
  id,
  action,
  cleanRow,
  setShouldMutate
}) {
  const { t } = useTranslation();
  const [deleteAction, setDeleteAction] = useState<boolean>(false);
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();
  const { data: deleteData, error: deleteError } = useSWR(
    deleteAction ? id : null,
    action
  );

  useEffect(() => {
    if (deleteData) {
      setToastNotification({
        text: t('NOTIFICATION.TOAST.deleteSuccess',['Item']),
        type: 'info'
      });
      setDeleteAction(false);
      // This is to force the mutate of the list of clients with the useEffect
      if (setShouldMutate) setShouldMutate(true);
      if (cleanRow) cleanRow();
      // This mutate won't work because most of the SWR calls use a ternary operator with a state variable
      mutate();
    }
  }, [deleteData]);

  useEffect(() => {
    if (deleteError) {
      setDeleteAction(false);
      setToastNotification({
        text: t('NOTIFICATION.TOAST.deleteError'),
        type: 'error'
      });
    }
  }, [deleteError]);

  useEffect(() => {}, []);

  return (
    <>
      <Container sx={{ margin: '40px', width: 'auto' }}>
        <Modal
          open
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => {
            setShowModal(false);
          }}
        >
          <ModalContainer>
            <Typography color="secondary.400" variant="modalTitle">
              <i
                className="custom-icon icon-delete"
                style={{ fontSize: 40, marginBottom: 24 }}
              />
              <br />
              <br />
              {t('MODAL.deleteModal')}
            </Typography>
            <br />
            <ButtonContainerModal>
              <>
                <DrawerButton
                  onClick={() => {
                    setShowModal(false);
                  }}
                  sx={{ borderColor: 'primary.main', height: 46, width: 114 }}
                  variant="outlined"
                >
                  <Typography color="primary.main">
                    {t('MODAL.cancel')}
                  </Typography>
                </DrawerButton>
                <DrawerButton
                  color="error"
                  onClick={() => {
                    setDeleteAction(true);
                  }}
                  sx={{ height: 46, width: 114 }}
                  variant="contained"
                >
                  <Typography>{t('MODAL.delete')}</Typography>
                </DrawerButton>
              </>
            </ButtonContainerModal>
          </ModalContainer>
        </Modal>
      </Container>
      {toastNotification && (
        <ToastNotification
          onClose={() => {
            setShowModal(false);
            setToastNotification(undefined);
          }}
          text={toastNotification.text}
          type={toastNotification.type}
        />
      )}
    </>
  );
};

DeleteModal.defaultProps = {
  cleanRow: () => {},
  setShouldMutate: () => {}
};

export default DeleteModal;
