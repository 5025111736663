import { CompanyInvitationApi } from '../../api-client/generated/api';

const apiCaller = new CompanyInvitationApi();

interface Pagination {
  items: number;
  page: number;
}

const getCompanyInvitations = async () => {
  const response = await apiCaller.companyInvitationGetAll();
  const { data } = response;
  return Array.isArray(data) ? data : [];
};

const getCompanyInvitationsPaginated = async (params: Pagination) => {
  const response = await apiCaller.companyInvitationGetAllPaginated(params);
  const { data } = response;
  return Array.isArray(data.data) ? data.data : [];
};

const resendCompanyInvitation = async (email: string) => {
  const response = await apiCaller.companyInvitationResendInvitation({ email });
  const { status, statusText } = response;
  return { status, statusText };
};

const deleteCompanyInvitation = async (id: string) => {
  const response = await apiCaller.companyInvitationDeleteInvitation({ id });
  const { status, statusText } = response;
  return { status, statusText };
};

const sendInvitation = async (params: any) => {
  const {
    companyName,
    companyDescription,
    administratorEmail,
    administratorFirstName,
    administratorLastName,
    plan
  } = params;
  const response = await apiCaller.companyInvitationCreate({
    createCompanyInvitationDTO: {
      administratorEmail,
      administratorFirstName,
      administratorLastName,
      companyDescription,
      companyName,
      plan
    }
  });
  const { status, statusText } = response;
  return { status, statusText };
};

export default {
  deleteCompanyInvitation,
  getCompanyInvitations,
  getCompanyInvitationsPaginated,
  resendCompanyInvitation,
  sendInvitation
};
