/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useRef, useState } from 'react';

import { Container, Box, Button } from '@mui/material';

import useSWR from 'swr';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Api from '../../api';
import projects from '../../utils/dummy/projects';
import FilterSelect from '../../components/filters/FilterSelect';
import ListingPage from '../../components/list/ListingPage';
import AutoCompleteSearch from '../../components/input/AutoCompleteSearch';

function modifiedData(dataProject: any[]) {
  return dataProject && dataProject.length > 0
    ? dataProject?.map((item: any) => {
        return {
          ...item,
          leadName: item.proposal.lead.name,
          leadProjectEndDate: dayjs(
            item?.proposal?.lead?.projectEndDate
          ).format('MM-DD-YYYY'),
          leadProjectStartDate: dayjs(
            item?.proposal?.lead?.projectStartDate
          ).format('MM-DD-YYYY')
        };
      })
    : [];
}

const ListingClientProjects: React.FC = function ListingClientProjects() {
  const [fireProjectApi, setFireProjectApi] = useState<boolean>(false);
  const [lock, setLock] = useState<boolean>(false);
  const [emptyState, setEmptyState] = useState<boolean>(false);
  const [dataProject, setDataProject] = useState<any>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const pageNumber = useRef<number>(1);
  const navigate = useNavigate();

  const getAction = Api.ClientPageApi.getClientProjects;

  const {
    data: mainResponse,
    error: mainError,
    mutate
  } = useSWR(fireProjectApi ? 'clients-portal/projects' : null, getAction);

  const handleOpen = () => {
    console.log('handleOpen');
  };

  const isItemLoaded = (index: any) => !!dataProject[index];

  const loadMoreItems = () => {
    if (dataProject && dataProject.length < (totalItems || 0) && !lock) {
      pageNumber.current += 1;
      setLock(true);
      setFireProjectApi(true);
    }
  };

  useEffect(() => {
    if (mainResponse && mainResponse.data && mainResponse.data.length > 0) {
      setEmptyState(mainResponse.data.length === 0);
      setTotalItems(mainResponse?.pagination?.totalItems || 0);
      setFireProjectApi(false);
      const dataPaginated = dataProject?.length > 0 ? [...dataProject] : [];
      mainResponse.data.forEach((item: any) => {
        dataPaginated.push(item);
      });
      setDataProject(dataPaginated);
      setLock(false);
    }
  }, [mainResponse]);

  useEffect(() => {
    if (dataProject && dataProject.length === 0 && !emptyState) {
      mutate();
    }
  }, [dataProject]);

  useEffect(() => {
    setFireProjectApi(true);
  }, []);

  useEffect(() => {
    if (mainError) {
      setDataProject([]);
      setFireProjectApi(false);
    }
  }, [mainError]);

  return (
    <Box>
      <ListingPage
        fullSize
        data={modifiedData(dataProject || [])}
        handleOpen={handleOpen}
        handleOpenDraw={() => {}}
        headKeys={[
          'leadName',
          'leadProjectStartDate',
          'leadProjectEndDate',
          'status'
        ]}
        isItemLoaded={isItemLoaded}
        itemCount={mainResponse?.pagination?.totalItems || 0}
        loadMoreItems={loadMoreItems}
        nameButton="deleteButton"
        orderByManual="createdAt"
        orderManual="desc"
        setValueDrawer={(value: any) => {
          navigate(`/customer-page/projects/detail/${value.id}`, {
            state: { id: value.id }
          });
        }}
        title="projects"
      />
    </Box>
  );
};

export default ListingClientProjects;
