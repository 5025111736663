import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import blueBerryLogoMini from '../../assets/images/bluberry_logo_mini.png';

interface Props {
  clientView?: boolean;
  counterOfferMode?: boolean;
  previewFlag?: boolean;
  proposalFormValue?: any;
  values: any;
}

const ProposalTitle: React.FC<Props> = function ProposalTitle({
  clientView,
  previewFlag,
  values,
  proposalFormValue,
  counterOfferMode
}) {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap="16px" mt={2}>
      <Box>
        {clientView ? (
          <Box display="flex" flexDirection="row">
            <img
              alt="BlueBerry Logo"
              className="logo-image-mini"
              src={blueBerryLogoMini}
            />
            <Box display="flex" flexDirection="column" marginLeft="10px">
              <Typography
                color="secondary"
                sx={{ fontSize: 22 }}
                variant="heading_300"
              >
                {t('LEAD.CREATE.PROPOSAL.titleProposal')}
              </Typography>
              <Typography
                color="secondary.200"
                sx={{ fontSize: 16 }}
                variant="body_500"
              >
                {values?.client.businessName}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{ fontSize: clientView ? 24 : 30 }}
            variant="body_500"
          >
            {t('LEAD.CREATE.PROPOSAL.titleCreateProposal')}
            {previewFlag
              ? t('LEAD.CREATE.PROPOSAL.titlePreview')
              : t('LEAD.CREATE.PROPOSAL.titlePricing')}
          </Typography>
        )}
      </Box>
      {previewFlag ? null : (
        <Box width="80%">
          <Typography variant="body_400">{`${t(
            'LEAD.CREATE.PROPOSAL.subtitlePart1'
          )} ${values?.profit || values?.profitPercentage || 0}% ${t(
            'LEAD.CREATE.PROPOSAL.subtitlePart2'
          )}`}</Typography>
        </Box>
      )}
      {previewFlag &&
      proposalFormValue?.personalizedMessage &&
      `${clientView}` !== 'true' ? (
        <Box>
          <TextField
            disabled
            multiline
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'black !important'
              },
              '& fieldset': { border: 'none' },
              backgroundColor: 'white !important',
              minHeight: '112px',
              width: '100%'
            }}
            value={proposalFormValue?.personalizedMessage || ''}
          />
        </Box>
      ) : null}
    </Box>
  );
};

ProposalTitle.defaultProps = {
  clientView: false,
  counterOfferMode: false,
  previewFlag: false,
  proposalFormValue: {}
};

export default ProposalTitle;
