import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface DeleteButtonProp {
  customStyle?: object;
  deleteFn: any;
  disabled?: boolean;
  icon?: string;
  tooltip?: string;
}

const DeleteButton: React.FC<DeleteButtonProp> = function DeleteButton({
  deleteFn,
  icon,
  tooltip,
  disabled,
  customStyle
}) {
  return (
    <Tooltip title={tooltip}>
      <Button
        disabled={disabled}
        onClick={() => deleteFn()}
        style={{ minWidth: '24px', padding: 0, ...customStyle }}
        variant="table"
      >
        <Typography color="secondary.main" variant="primary-bold">
          <i className={`custom-icon icon-${icon}`} />
        </Typography>
      </Button>
    </Tooltip>
  );
};

DeleteButton.defaultProps = {
  customStyle: {},
  disabled: false,
  icon: 'delete',
  tooltip: 'delete'
};

export default DeleteButton;
