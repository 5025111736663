/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  styled,
  SliderProps,
  SlideProps,
  TextField,
  Container,
  Modal,
  Backdrop,
  MenuItem,
  Checkbox
} from '@mui/material';

import useSWR from 'swr';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import RegularInput from './input/RegularInput';
import AutoCompleteInput from './input/AutoCompleteInput';
import { capitalizeString, getCurrentMeasure } from '../utils/general';
import SortableTable from './list/sortableTable';
import SelectInput from './form/SelectInput';
import api from '../api';
import { MeasureResponseDTO } from '../api-client/generated/api';
import { taskValidation } from '../utils/taskHelper';
import TimeComponent from './input/TimeComponent';
import { HeaderContainer } from './form/parts';
import { type toastNotification } from '../utils/notificationHelper';
import AddChecklistModal from './modals/AddChecklistModal';
import useNavigationStore from '../store/zustand/navigation';

const ButtonContainer = styled(Box)<SlideProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
}));

const ButtonContainerModal = styled(Box)<SlideProps>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: theme.spacing(27)
}));

const DrawerButton = styled(Button)<SlideProps>(({ theme }) => ({
  color: 'white',
  marginBottom: theme.spacing(4),
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  width: '100%'
}));

const ModalContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '0.5em',
  boxShadow: '24',
  display: 'flex',
  flexDirection: 'column',
  height: 254,
  p: '40px 70px',
  position: 'absolute' as 'absolute',
  right: '50%',
  textAlign: 'center',
  top: '50%',
  transform: 'translate(50%, -50%)',
  width: 407
}));

const MainTitle = styled(Typography)<SliderProps>(() => ({
  fontSize: '24px',
  fontWeight: 'bolder',
  variant: 'title'
}));

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary[400],
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-12px'
}));

const ContentContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(4)
}));

const TaskContainer = styled(Box)<SliderProps>(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  marginTop: theme.spacing(2)
}));

type summaryProps = {
  arrayDisabled?: boolean;
  arrayType:
    | 'service'
    | 'skills'
    | 'tasks'
    | 'category'
    | 'categories'
    | 'material'
    | 'lead'
    | 'proposal-messages'
    | 'widgets';
  createNestedAction: (params: any) => Promise<any>;
  createSecondaryNestedAction?: (params: any) => Promise<any>;
  deleteAction: ((params: any) => Promise<any>) | ((params: any) => void);
  handleClose: () => void;
  itemsArray: {
    id: string;
    message?: string;
    name?: string;
    title?: string;
  }[];
  mutateAction: () => void;
  mutateNestedAction: () => void;
  mutateSecondaryNestedAction?: () => void;
  onlyView?: boolean;
  open: boolean;
  renderChecklistsComponent?: boolean;
  secondaryArrayType?:
    | 'service'
    | 'skills'
    | 'tasks'
    | 'category'
    | 'material'
    | 'lead';
  secondaryItemsArray?: { id: string; name: string }[];
  setToastNotification?: React.Dispatch<
    React.SetStateAction<toastNotification | undefined>
  >;
  setUpdateItem?: any;
  type:
    | 'service'
    | 'task'
    | 'skill'
    | 'category'
    | 'material'
    | 'lead'
    | 'task-lead'
    | 'message'
    | 'title'
    | 'proposal-messages'
    | 'widgets'
    | 'checklist';
  updateAction: ((params: any) => Promise<any>) | ((params: any) => void);
  value: any;
};

const SummaryDrawer: React.FC<summaryProps> = function SummaryDrawer({
  open,
  handleClose,
  value,
  arrayType,
  createNestedAction,
  mutateNestedAction,
  itemsArray,
  updateAction,
  deleteAction,
  arrayDisabled,
  mutateAction,
  type,
  secondaryItemsArray,
  createSecondaryNestedAction,
  mutateSecondaryNestedAction,
  secondaryArrayType,
  setUpdateItem,
  onlyView,
  setToastNotification,
  renderChecklistsComponent
}) {
  const { t } = useTranslation();
  const { setConfirmModal } = useNavigationStore((state) => state);
  const checklistRequired = arrayType === 'skills' || renderChecklistsComponent;
  const [error, setError] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [deleteIdValue, setDeleteIdValue] = useState<string>('');
  const [formValue, setFormValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [actionUpdate, setActionUpdate] = useState<boolean>(false);
  const [actionDelete, setActionDelete] = useState<boolean>(false);
  const [createNested, setCreateNested] = useState<boolean>(false);
  const [fireGetFiles, setFireGetFiles] = useState<boolean>(false);
  const [firePostFiles, setFirePostFiles] = useState<boolean>(false);
  const [fireTaskFiles, setFireTaskFiles] = useState<boolean>(false);
  const [fireDeleteApi, setFireDeleteApi] = useState<boolean>(false);
  const [fireMeasures, setFireMeasures] = useState<boolean>(false);
  const [termFile2, setTermFile2] = useState<any>([]);
  const [formFiles, setFormFiles] = useState<any>(null);
  const [filesToCreate, setFilesToCreate] = useState<any>([]);
  const [deleteID, setDeleteId] = useState<string>('');
  const [deleteIds, setDeleteIds] = useState<Array<string>>([]);
  const [termFile, setTermFile] = useState<any>([]);
  const [finalFileForm, setFinalFileForm] = useState<any>([]);
  const [dataObtained, setDataObtained] = useState<boolean>(false);
  const [createSecondaryNested, setCreateSecondaryNested] =
    useState<boolean>(false);
  const [taskChecklist, setTaskChecklist] = useState<Array<any>>([]);
  const [items, setItems] = useState<Array<any> | null>(itemsArray);
  const [secondaryItems, setSecondaryItems] = useState<Array<any>>(
    secondaryItemsArray || []
  );
  const [addChecklist, setAddChecklist] = useState<boolean>(false);
  const [checklistFromModal, setChecklistFromModal] = useState<any>();

  const [materialList, setMaterialList] = useState<Array<any>>([]);

  const [valuesAutocomplete, setValuesAutocomplete] = useState<any>(
    value[arrayType]
  );

  const [secondaryValuesAutocomplete, setSecondaryValuesAutocomplete] =
    useState<any>('');

  const [nestedValue, setNestedValue] = useState<{ name: string }>({
    name: ''
  });

  const [secondaryNestedValue, setSecondaryNestedValue] = useState<{
    name: string;
  }>({ name: '' });

  const [measureList, setMeasureList] = useState<any>([]);

  // =================================================== SWR

  const { data: updateData, error: updateError } = useSWR(
    actionUpdate ? { ...formValue, url: `/update/${type}-${value.id}` } : null,
    updateAction,
    { revalidateOnMount: true }
  );

  const { data: deleteData, error: deleteError } = useSWR(
    actionDelete
      ? { id: deleteIdValue, url: `/delete/${type}-${value.id}` }
      : null,
    deleteAction
  );

  const { data: createNestedElement, error: nestedElementError } = useSWR(
    createNested ? nestedValue : null,
    createNestedAction
  );

  const checkIfSecondaryNestedAction =
    createSecondaryNestedAction || (() => {});

  const { data: createSecondaryElement, error: secondaryNestedElementError } =
    useSWR(
      createSecondaryNested ? secondaryNestedValue : null,
      checkIfSecondaryNestedAction
    );

  const { data: measure } = useSWR(
    fireMeasures && (type === 'task' || type === 'task-lead')
      ? 'api/getMeasure'
      : null,
    api.MeasureApi.getMeasure
  );

  const {
    data: files,
    error: filesError,
    isValidating: isValidatingGetFiles
  } = useSWR(
    fireGetFiles && !dataObtained && value.id
      ? { entityId: value.id, relatedEntity: type }
      : null,
    api.StorageApi.getEntityFiles
  );

  const {
    data: deleteFileResponse,
    error: errorDeleteFile,
    isValidating: isValidatingDeleteFiles
  } = useSWR(
    fireDeleteApi && deleteIds.length > 0 ? { fileIds: deleteIds } : null,
    api.StorageApi.deleteFiles
  );

  const { data: postTaskFile, error: postFileTaskError } = useSWR(
    fireTaskFiles && filesToCreate.length > 0
      ? {
          entityId: value.id,
          files: filesToCreate
        }
      : null,
    api.StorageApi.uploadTaskFiles
  );

  const { data: postFile, error: postFileError } = useSWR(
    firePostFiles && filesToCreate.length > 0
      ? {
          entityId: value.id,
          files: filesToCreate,
          relatedEntity: type
        }
      : null,
    api.StorageApi.uploadEntityFiles
  );

  //= ================================================== FUNCTIONS

  const isUnit = () => {
    return getCurrentMeasure(measureList, value).name === 'Unit';
  };

  const getMeasureName = () => {
    return getCurrentMeasure(measureList, value).name;
  };

  const getMeasureNameFromForm = () => {
    return getCurrentMeasure(measureList, { measure: formValue.measure }).name;
  };

  const resetValues = () => {
    setValuesAutocomplete(value[arrayType]);
    setTaskChecklist([]);
  };

  const handleUpdate = () => {
    if (value.name === '' && type !== 'proposal-messages') {
      setErrorMsg('All fields required');
      setError(true);
    }

    if (type === 'task' || type === 'task-lead') {
      const formValidation = taskValidation(
        isUnit,
        formValue,
        getMeasureName,
        t
      );

      if (formValidation) {
        setErrorMsg(formValidation);
        setError(true);
        return;
      }
      if (type === 'task-lead') {
        updateAction({ ...formValue, measure: getMeasureNameFromForm() });
        setFirePostFiles(false);
        setFireTaskFiles(false);
        setTimeout(() => {
          handleClose();
        }, 500);
        return;
      }
    }

    if (
      type === 'proposal-messages' &&
      value?.title === '' &&
      value?.message === ''
    ) {
      setErrorMsg('All fields required');
      setError(true);

      return;
    }
    setError(false);
    setActionUpdate(true);
  };

  const handleDelete = () => {
    if (type === 'task-lead') {
      deleteAction(formValue.id);
      setActionUpdate(false);
      setDeleteModal(false);
      setFirePostFiles(false);
      setFireTaskFiles(false);
      setTimeout(() => {
        handleClose();
        resetValues();
      }, 500);
      return;
    }

    if (deleteIdValue !== '') {
      setActionDelete(true);
    }
  };

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 2 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {errorMsg}
        </Typography>
      </Container>
    );

  const createNewNestedElement = (newValue: any) => {
    setNestedValue({ name: newValue.createValue });
  };

  const createNewSecondaryNestedElement = (newValue: any) => {
    setSecondaryNestedValue({ name: newValue.createValue });
  };

  const getServiceExtraItems = () => {
    if (type !== 'service') {
      return false;
    }
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box
          gap={2}
          sx={{
            alignItems: 'start',
            display: 'flex',
            flexDirection: 'column',
            width: '40% !important'
          }}
        >
          <SubTitle>{capitalizeString(t('MODAL.setupTime'))}*</SubTitle>
          <TimeComponent
            reverseOrder
            setTime={(v) => {
              setFormValue({
                ...formValue,
                setupTime: v
              });
            }}
            time={formValue.setupTime || 0.0}
          />
        </Box>
        <Box
          gap={2}
          sx={{
            alignItems: 'start',
            display: 'flex',
            flexDirection: 'column',
            width: '40% !important'
          }}
        >
          <SubTitle>{capitalizeString(t('MODAL.teardownTime'))}*</SubTitle>
          <TimeComponent
            reverseOrder
            setTime={(v) => {
              setFormValue({ ...formValue, teardownTime: v });
            }}
            time={formValue.teardownTime || 0.0}
          />
        </Box>
      </Box>
    );
  };

  const getDocumentItem = () => {
    if ((type !== 'service' && type !== 'task') || onlyView) {
      return null;
    }

    return (
      <Box display="flex" flexDirection="column" gap={2} width="100%">
        <SubTitle>Files</SubTitle>
        {type === 'task' ? (
          <SortableTable
            addManagement
            disableSort
            bodyKeys={['files']}
            handleDelete={(value) => {
              const newArray = termFile2;

              if (termFile2[value]?.fileId) {
                setDeleteIds([...deleteIds, termFile2[value]?.fileId]);
              }

              newArray.splice(value, 1);
              setTermFile2(newArray);
            }}
            handleFileChange={(value) => {
              setTermFile2([...termFile2, value.target.files[0]]);
            }}
            onChange={(newTermList) => {
              setTermFile2(newTermList);
              setFinalFileForm(newTermList);
            }}
            type="input"
            values={termFile2 ?? []}
          />
        ) : (
          <SortableTable
            addManagement
            disableSort
            bodyKeys={['files']}
            handleDelete={(value) => {
              const newArray = termFile;
              const newArray2 = termFile2;

              if (termFile2[value]?.fileId) {
                setDeleteIds([...deleteIds, termFile2[value]?.fileId]);
              }

              newArray.splice(value, 1);
              setTermFile2(newArray2);

              newArray.splice(value, 1);
              setTermFile(newArray);
            }}
            handleFileChange={(value) => {
              setTermFile([...termFile, value.target.files[0]]);
              setTermFile2([...termFile2, value.target.files[0]]);
            }}
            onChange={(newTermList) => {
              setTermFile(newTermList);
              setTermFile2(newTermList);
              setFinalFileForm(newTermList);
            }}
            type="input"
            values={termFile2 ?? []}
          />
        )}
      </Box>
    );
  };

  //= ================================================== USEEFFECTS

  useEffect(() => {
    if (deleteError || updateError) {
      setActionUpdate(false);
      setActionDelete(false);
      setError(true);
      setErrorMsg(`Error ${deleteError ? 'deleting' : 'updating'} item`);
    }
  }, [deleteError, updateError]);

  useEffect(() => {
    if (deleteFileResponse) {
      setError(false);
      // setSnackMsgFlag(false);
      setFireDeleteApi(false);
      // setOpenSnackFile(true);
      // clearClient();
    }
  }, [deleteFileResponse]);

  useEffect(() => {
    if (postFile) {
      setFilesToCreate([]);
      setFirePostFiles(false);
      setDataObtained(true);
      if (type === 'task-lead') {
        setFireGetFiles(true);
      }
    }
  }, [postFile]);

  useEffect(() => {
    if (postTaskFile && !postFileTaskError) {
      setFilesToCreate([]);
      setFireTaskFiles(false);
    }
  }, [postTaskFile]);

  useEffect(() => {
    if (deleteID) {
      // setFireDeleteApi(true);
    }
  }, [deleteID]);

  useEffect(() => {
    if (filesError || errorDeleteFile || postFileError) {
      setActionUpdate(false);
      setActionDelete(false);
      setFireGetFiles(false);
      setError(true);
      setErrorMsg(`Error getting files`);
    } else {
      setErrorMsg('');
      setError(false);
    }
  }, [filesError, errorDeleteFile, postFileError]);

  useEffect(() => {
    if (nestedElementError) {
      setCreateNested(false);
      setError(true);
      setErrorMsg(
        `${t('SUMMARY_DRAWER.createNewElementError')} ${secondaryArrayType}`
      );
    }
  }, [nestedElementError]);

  useEffect(() => {
    if (secondaryNestedElementError) {
      setCreateSecondaryNested(false);
      setError(true);
      setErrorMsg(
        `${t('SUMMARY_DRAWER.createNewElementError')} ${secondaryArrayType}`
      );
    }
  }, [secondaryNestedElementError]);

  useEffect(() => {
    if (nestedValue && nestedValue.name !== '') {
      setCreateNested(true);
    }
  }, [nestedValue]);

  useEffect(() => {
    if (termFile?.target) {
      setFirePostFiles(true);
    }
  }, [termFile]);

  useEffect(() => {
    if (secondaryNestedValue && secondaryNestedValue.name !== '') {
      setCreateSecondaryNested(true);
    }
  }, [secondaryNestedValue]);

  useEffect(() => {
    if (createNestedElement) {
      setCreateNested(false);
      mutateNestedAction();
      switch (arrayType) {
        case 'material':
        case 'proposal-messages':
        case 'category':
          setItems(
            items ? [...items, createNestedElement] : [createNestedElement]
          );
          setValuesAutocomplete(createNestedElement);
          break;
        case 'tasks':
        case 'skills': {
          setValuesAutocomplete([...valuesAutocomplete, createNestedElement]);
          setItems(
            items ? [...items, createNestedElement] : [createNestedElement]
          );
          break;
        }
        case 'categories': {
          const { data } = createNestedElement;
          setValuesAutocomplete([...valuesAutocomplete, data]);
          setItems(items ? [...items, data] : [data]);
          break;
        }
        default:
          break;
      }
    }
  }, [createNestedElement]);

  useEffect(() => {
    if (createSecondaryElement) {
      setCreateSecondaryNested(false);
      if (mutateSecondaryNestedAction) {
        mutateSecondaryNestedAction();
      }
      const newValue = createSecondaryElement;
      switch (secondaryArrayType) {
        case 'material':
        case 'category':
          setSecondaryItems([...secondaryItems, newValue]);
          setSecondaryValuesAutocomplete(newValue);
          break;
        case 'tasks':
        case 'skills': {
          const { data } = createSecondaryElement;
          setSecondaryValuesAutocomplete([...valuesAutocomplete, data]);
          setSecondaryItems(items ? [...items, data] : [data]);
          break;
        }
        default:
          break;
      }
    }
  }, [createSecondaryElement]);

  useEffect(() => {
    if (valuesAutocomplete) {
      switch (arrayType) {
        case 'material':
        case 'proposal-messages':
        case 'category':
          setFormValue({
            ...formValue,
            [arrayType]:
              valuesAutocomplete && valuesAutocomplete.id
                ? valuesAutocomplete.id
                : undefined
          });
          break;
        case 'tasks':
        case 'skills':
        case 'categories': {
          setFormValue({
            ...formValue,
            [arrayType]: valuesAutocomplete.map((item: any) => item.id)
          });
          break;
        }
        default:
          break;
      }
    }
  }, [valuesAutocomplete]);

  useEffect(() => {
    if (secondaryValuesAutocomplete) {
      switch (secondaryArrayType) {
        case 'material':
        case 'category':
          setSecondaryNestedValue({
            ...value,
            [secondaryArrayType]:
              secondaryValuesAutocomplete && secondaryValuesAutocomplete.id
                ? secondaryValuesAutocomplete.id
                : undefined
          });
          break;
        case 'tasks':
        case 'skills': {
          setSecondaryNestedValue({
            ...value,
            [arrayType]: valuesAutocomplete.map((item: any) => item.id)
          });
          break;
        }
        default:
          break;
      }
    }
  }, [secondaryValuesAutocomplete]);

  useEffect(() => {
    setItems(itemsArray);
  }, [itemsArray]);

  useEffect(() => {
    if (files && !isValidatingGetFiles) {
      const actualFiles = (files?.data as unknown as any) ?? [];
      const serviceFile = actualFiles
        .filter(
          (file: any) =>
            file.metaDescription === 'serviceDocument' ||
            file.metaDescription === 'taskDocument'
        )
        .map((values: any) => {
          return {
            description: values.description,
            fileId: values.id,
            fileName: values.fileOriginalName,
            name: values.fileOriginalName
          };
        });

      setDataObtained(true);
      setFinalFileForm(serviceFile);
      setFormFiles(serviceFile);
      setTermFile2(serviceFile);
    }
  }, [files]);

  useEffect(() => {
    if (value) {
      setLoading(true);

      switch (type) {
        case 'category':
          setFormValue({
            [arrayType]: !arrayDisabled ? value[arrayType]?.id : undefined,
            description: value.description || '',
            id: value.id || '',
            name: value.name || ''
          });
          break;
        case 'proposal-messages':
          setFormValue({
            [arrayType]: !arrayDisabled ? value[arrayType]?.id : undefined,
            description: value.message || '',
            id: value.id || '',
            message: value.message || '',
            name: value.title || '',
            title: value.title || ''
          });
          break;
        case 'material':
          setFormValue({
            description: value.description || '',
            id: value.id || '',
            name: value.name || ''
          });
          break;
        case 'service':
          setFireGetFiles(true);
          setFirePostFiles(true);
          setFormValue({
            [arrayType]:
              !arrayDisabled && value[arrayType] && value[arrayType].length > 0
                ? value[arrayType].map((value: any) => value.id) || []
                : [],
            description: value.description || '',
            id: value.id || '',
            name: value.name || '',
            setupTime: value.setupTime,
            teardownTime: value.teardownTime
          });
          setValuesAutocomplete(value[arrayType]);
          break;
        case 'skill':
        case 'checklist':
          setFormValue({
            [arrayType]: !arrayDisabled ? value[arrayType]?.id : undefined,
            description: value.description || '',
            id: value.id || '',
            name: value.name || ''
          });
          setValuesAutocomplete(value[arrayType]);

          if (value?.list) {
            const checklist = value.list.map((item: any) => {
              return { completed: false, name: item };
            });
            setTaskChecklist([...taskChecklist, ...checklist]);
          }

          break;
        case 'task':
        case 'task-lead':
          {
            setFireGetFiles(true);

            if (type === 'task') {
              setFireTaskFiles(true);
            }

            const formatSkills = value.skills.map((item: any) => item?.id);

            const formatMaterials = value.taskMaterials.map((item: any) => ({
              cost: item.cost || 0,
              materialId: item.id
            }));

            setFormValue({
              description: value.description || '',
              id: value.id || '',
              imageRequired: value.imageRequired,
              materials: formatMaterials,
              measure: value.measure?.id || '',
              measurePerHour: value.measurePerHour || 0.0,
              minimumResources: value.minimumResources || 0,
              name: value.name || '',
              skills: formatSkills
            });

            setValuesAutocomplete(value[arrayType]);

            if (secondaryArrayType) {
              setSecondaryNestedValue(value[secondaryArrayType]);
            }

            if (arrayType === 'skills') {
              setTaskChecklist(value.taskChecklist || value.checklist || []);
            }

            const formatMaterialsList = value.taskMaterials.map(
              (item: any) => ({
                ...item,
                ...item.material,
                cost: Number(item.cost || '0')
              })
            );

            setMaterialList(formatMaterialsList);
          }

          break;
        default:
          break;
      }
    }

    if (open) {
      setDeleteIdValue(value.id);
    }
  }, [value]);

  useEffect(() => {
    if (formValue && formValue.name) {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [formValue]);

  useEffect(() => {
    if (deleteData || updateData) {
      setActionDelete(false);
      setActionUpdate(false);
      setDeleteModal(false);
      setFirePostFiles(false);
      setFireTaskFiles(false);
      if (setUpdateItem) {
        setUpdateItem(value.id);
      }

      if (deleteIds.length > 0 && updateData) {
        setFireDeleteApi(true);
      }

      const auxFilesToCreate = termFile2.filter((item: any) => !item.fileId);

      if (auxFilesToCreate.length > 0 && updateData) {
        setFilesToCreate(auxFilesToCreate);

        if (type === 'service') {
          setFirePostFiles(true);
        }

        if (type === 'task') {
          setFireTaskFiles(true);
        }
      }

      mutateAction();

      if (updateData && setToastNotification) {
        setToastNotification({
          text: t('NOTIFICATION.TOAST.editSuccess', ['Item']),
          type: 'info'
        });
      }

      setTimeout(() => {
        setTermFile2([]);
        setFinalFileForm([]);
        setFormFiles(null);
        setDataObtained(false);
        handleClose();
        resetValues();
        if (updateData && setToastNotification) {
          setToastNotification({
            text: t('NOTIFICATION.TOAST.editSuccess', ['Item']),
            type: 'info'
          });
        }
      }, 500);
    }
  }, [deleteData, updateData]);

  useEffect(() => {
    if (measure) {
      setFireMeasures(false);
      setMeasureList(measure);
      if (type === 'task-lead') {
        const currentMeasure = measure.find((item: any) => {
          if (value?.measure?.name) {
            return item.name === value?.measure?.name;
          }
          return item.name === value?.measure;
        });

        setFormValue({
          ...formValue,
          measure: currentMeasure?.id
        });
      }
    }
  }, [measure]);

  useEffect(() => {
    setFireMeasures(true);
    setError(false);
  }, []);

  useEffect(() => {
    if (addChecklist && checklistFromModal) {
      const checklist = checklistFromModal.list.map((item: any) => {
        return { completed: false, name: item };
      });
      setTaskChecklist([...taskChecklist, ...checklist]);
      setFormValue({
        ...value,
        taskChecklist: checklist
      });
      setAddChecklist(false);
      setChecklistFromModal(null);
    }
  }, [addChecklist]);

  return open && formValue ? (
    <Box
      className="sumary-sidebar scrollbar"
      sx={{
        display: open ? '' : 'none',
        overflow: 'scroll',
        width: isMobile ? ' 100%' : '500px'
      }}
    >
      <HeaderContainer sticky={false} style={{ padding: '20px 24px' }}>
        <MainTitle>{`${capitalizeString(type)} ${t(
          'MODAL.details'
        )}`}</MainTitle>
        <HighlightOffOutlinedIcon
          onClick={() => {
            handleClose();
            setError(false);
            resetValues();
          }}
          sx={{ color: 'mainText.main', cursor: 'pointer' }}
        />
      </HeaderContainer>
      {!loading ? (
        <ContentContainer style={{ padding: '20px 20px' }}>
          <Box>
            {type !== 'proposal-messages' ? (
              <SubTitle>{capitalizeString(t('MODAL.name'))}</SubTitle>
            ) : (
              <SubTitle>Title</SubTitle>
            )}
            {/* PASS VALUE ON THE INPUT */}
            {type !== 'proposal-messages' ? (
              <RegularInput
                customStyle={{ fontSize: '15px', width: '100%' }}
                disabled={onlyView}
                onChange={(event: any) =>
                  setFormValue({ ...formValue, name: event })
                }
                value={formValue.name}
              />
            ) : (
              <RegularInput
                customStyle={{ fontSize: '15px', width: '100%' }}
                disabled={onlyView}
                onChange={(event: any) =>
                  setFormValue({ ...formValue, title: event })
                }
                value={formValue.title}
              />
            )}
          </Box>

          <Box>
            {type !== 'proposal-messages' ? (
              <SubTitle>{capitalizeString(t('MODAL.description'))}</SubTitle>
            ) : (
              <SubTitle>Message</SubTitle>
            )}
            {/* PASS VALUE ON THE INPUT */}
            {type !== 'proposal-messages' ? (
              <RegularInput
                multiline
                customStyle={{ fontSize: '15px', width: '100%' }}
                disabled={onlyView}
                onChange={(event: any) =>
                  setFormValue({ ...formValue, description: event })
                }
                value={formValue.description}
              />
            ) : (
              <RegularInput
                customStyle={{ fontSize: '15px', width: '100%' }}
                disabled={onlyView}
                onChange={(event: any) =>
                  setFormValue({ ...formValue, message: event })
                }
                value={formValue.message}
              />
            )}
          </Box>
          {/* Nested Items */}
          {!arrayDisabled && items && type !== 'proposal-messages' && (
            <>
              <Box>
                <Box marginBottom="8px">
                  <SubTitle>{capitalizeString(`${arrayType}`)}</SubTitle>
                  <AutoCompleteInput
                    closeOnSelect={arrayType === 'category'}
                    defaultValue={value[arrayType]}
                    disabled={onlyView}
                    multiple={
                      arrayType === 'tasks' ||
                      arrayType === 'skills' ||
                      arrayType === 'categories'
                    }
                    onChange={(event: any, newValue: any | null) => {
                      switch (arrayType) {
                        case 'material':
                        case 'proposal-messages':
                        case 'category':
                          if (newValue && newValue.createValue) {
                            createNewNestedElement(newValue);
                          } else {
                            setValuesAutocomplete(newValue);
                          }
                          break;
                        case 'tasks':
                        case 'skills':
                          if (
                            newValue.length > 0 &&
                            newValue[newValue.length - 1].createValue
                          ) {
                            createNewNestedElement(
                              newValue[newValue.length - 1]
                            );
                          } else {
                            setValuesAutocomplete(newValue);
                          }
                          break;
                        case 'categories':
                          if (
                            newValue.length > 0 &&
                            newValue[newValue.length - 1].createValue
                          ) {
                            createNewNestedElement(
                              newValue[newValue.length - 1]
                            );
                          } else {
                            setValuesAutocomplete(newValue);
                          }
                          break;
                        default:
                          break;
                      }
                    }}
                    // default option should be an array
                    options={items || []}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            borderStyle: 'hidden'
                          },
                          borderStyle: 'hidden',
                          fontSize: '15px'
                        }}
                        // eslint-disable-next-line
                        {...params}
                      />
                    )}
                    value={valuesAutocomplete}
                  />
                </Box>

                {checklistRequired && (
                  <>
                    <Box display="flex" justifyContent="space-between">
                      <SubTitle>{capitalizeString('checklist')}</SubTitle>
                    </Box>
                    <Box className="scrollbar" sx={{ maxHeight: 200, mt: 2 }}>
                      <SortableTable
                        addManagement
                        bodyKeys={['name']}
                        disabledTable={onlyView}
                        onChange={(newChecklist) => {
                          const checklist = newChecklist.map((item: any) => {
                            const value = item;
                            delete value?.id;
                            return { ...value, completed: false };
                          });
                          setTaskChecklist(checklist);
                          setFormValue({
                            ...formValue,
                            taskChecklist: checklist
                          });
                        }}
                        type="generic-input"
                        values={taskChecklist}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="end"
                      mb={4}
                      mt={1}
                      sx={{ cursor: 'pointer' }}
                    >
                      <SubTitle
                        fontSize="16px"
                        onClick={() => {
                          setConfirmModal(
                            () => {
                              setAddChecklist(true);
                            },
                            `${t('GENERAL.addChecklistButton')}`,
                            () => {},
                            `${t('GENERAL.cancelButton')}`,
                            <AddChecklistModal
                              setValue={(value) => {
                                setChecklistFromModal(value);
                              }}
                            />
                          );
                        }}
                      >
                        <Box alignItems="center" display="flex" fontSize="16px">
                          <i
                            className="custom-icon icon-add"
                            style={{ fontSize: 18 }}
                          />
                          {capitalizeString('Add Checklist from catalog')}
                        </Box>
                      </SubTitle>
                    </Box>
                  </>
                )}
              </Box>

              {(type === 'task' || type === 'task-lead') && (
                <>
                  <TaskContainer>
                    <Box width="100%">
                      <SubTitle>
                        {capitalizeString(t('MODAL.measure'))}
                      </SubTitle>
                      <SelectInput
                        disabled={onlyView}
                        onChange={(event) => {
                          setFormValue({
                            ...formValue,
                            measure: event.target.value
                          });
                        }}
                        selectvariant="secondary"
                        value={formValue.measure}
                      >
                        {measureList.map((measure: MeasureResponseDTO) => (
                          <MenuItem key={measure.id} value={measure.id}>
                            {measure.name}
                          </MenuItem>
                        ))}
                      </SelectInput>
                    </Box>
                    <Box width="100%">
                      <SubTitle>
                        {getCurrentMeasure(measureList, formValue).name ===
                        'Unit'
                          ? capitalizeString(
                              t('MODAL.timePerMeasure', ['Unit'])
                            )
                          : capitalizeString(t('MODAL.measurePerHour'))}
                      </SubTitle>
                      <RegularInput
                        customStyle={{ fontSize: '15px', width: '100%' }}
                        disabled={onlyView}
                        onChange={(event: any) =>
                          setFormValue({
                            ...formValue,
                            measurePerHour: Number(event)
                          })
                        }
                        type="number"
                        value={formValue.measurePerHour}
                      />
                    </Box>
                  </TaskContainer>
                  <Box>
                    <SubTitle>
                      {capitalizeString(t('MODAL.materials'))}
                    </SubTitle>
                    <Box
                      className="scrollbar"
                      sx={{
                        maxHeight: 200,
                        mt: 1
                      }}
                    >
                      <SortableTable
                        addManagement
                        disableSort
                        createNestedElement={createNewSecondaryNestedElement}
                        disabledTable={onlyView}
                        nestedOptions={secondaryItems}
                        onChange={(newChecklist) => {
                          const formatMaterials = newChecklist.map(
                            (material: any) => {
                              return {
                                cost: parseFloat(material.cost || '0'),
                                materialId: material.id
                              };
                            }
                          );
                          setMaterialList(newChecklist);
                          setFormValue({
                            ...formValue,
                            materials: formatMaterials || null
                          });
                        }}
                        type="material-input"
                        values={materialList}
                      />
                    </Box>
                  </Box>

                  <Box mt={2}>
                    <SubTitle>
                      {capitalizeString(t('MODAL.minimumResources'))}
                    </SubTitle>

                    <RegularInput
                      customStyle={{ fontSize: '15px', width: '100%' }}
                      disabled={onlyView}
                      onChange={(event: any) =>
                        setFormValue({
                          ...formValue,
                          minimumResources: Number(event)
                        })
                      }
                      type="number"
                      value={formValue.minimumResources}
                    />
                  </Box>
                  {type !== 'task-lead' && (
                    <Box alignItems="center" display="flex" marginLeft="-8px">
                      <Checkbox
                        checked={formValue.imageRequired}
                        disabled={onlyView}
                        onChange={(event) => {
                          setFormValue({
                            ...formValue,
                            imageRequired: event.target.checked
                          });
                        }}
                      />
                      <SubTitle sx={{ marginTop: '-8px' }}>
                        {capitalizeString(t('MODAL.imageRequired'))}
                      </SubTitle>
                    </Box>
                  )}
                </>
              )}
              {getError()}
            </>
          )}
          {getServiceExtraItems()}
          {getDocumentItem()}
          <ButtonContainer sx={{ mt: 3 }}>
            {onlyView ? (
              <DrawerButton
                color="primary"
                onClick={() => {
                  handleClose();
                }}
                variant="contained"
              >
                <Typography>{t('MODAL.close')}</Typography>
              </DrawerButton>
            ) : (
              <>
                <DrawerButton
                  color="error"
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                  variant="contained"
                >
                  <Typography>{t('MODAL.delete')}</Typography>
                </DrawerButton>
                <DrawerButton
                  color="success"
                  onClick={handleUpdate}
                  variant="contained"
                >
                  <Typography>{t('MODAL.save')}</Typography>
                </DrawerButton>
              </>
            )}
          </ButtonContainer>
        </ContentContainer>
      ) : null}
      {deleteModal && (
        <Modal
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          onClose={() => setDeleteModal(false)}
          open={deleteModal}
        >
          <ModalContainer>
            <Typography color="secondary.400" variant="modalTitle">
              <i
                className="custom-icon icon-delete"
                style={{ fontSize: 40, marginBottom: 24 }}
              />
              <br />
              <br />
              {t('MODAL.deleteModal')}
            </Typography>
            <br />
            <ButtonContainerModal>
              <>
                <DrawerButton
                  onClick={() => setDeleteModal(false)}
                  sx={{ borderColor: 'primary.main', height: 46, width: 114 }}
                  variant="outlined"
                >
                  <Typography color="primary.main">
                    {t('MODAL.cancel')}
                  </Typography>
                </DrawerButton>
                <DrawerButton
                  color="error"
                  onClick={() => {
                    handleDelete();
                    setError(false);
                  }}
                  sx={{ height: 46, width: 114 }}
                  variant="contained"
                >
                  <Typography>{t('MODAL.delete')}</Typography>
                </DrawerButton>
              </>
            </ButtonContainerModal>
          </ModalContainer>
        </Modal>
      )}
    </Box>
  ) : (
    <div>{}</div>
  );
};

SummaryDrawer.defaultProps = {
  arrayDisabled: false,
  createSecondaryNestedAction: async () => {},
  mutateSecondaryNestedAction: () => {},
  onlyView: false,
  renderChecklistsComponent: false,
  secondaryArrayType: undefined,
  secondaryItemsArray: [],
  setToastNotification: () => {},
  setUpdateItem: () => {}
};

export default SummaryDrawer;
