import React from 'react';
import '../../style/App.scss';
import { Button, Tooltip, Typography } from '@mui/material';

interface OpenDetailButtonProps {
  customStyle?: object;
  handleOpenDraw: any;
  icon?: string;
  tooltip?: string;
}

const OpenDetailButton: React.FC<OpenDetailButtonProps> =
  function OpenDetailButton({ handleOpenDraw, tooltip, icon, customStyle }) {
    return (
      <Tooltip arrow title={tooltip}>
        <Button
          onClick={handleOpenDraw}
          style={{ minWidth: '24px', ...customStyle }}
          variant="table"
        >
          <Typography color="secondary.main" variant="primary-bold">
            <i className={`custom-icon icon-${icon}`} />
          </Typography>
        </Button>
      </Tooltip>
    );
  };

OpenDetailButton.defaultProps = {
  customStyle: {},
  icon: 'eye',
  tooltip: ''
};

export default OpenDetailButton;
