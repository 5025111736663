interface weekDaysProps {
  abr: string;
  name: string;
  short: string;
}
interface weekDaysIndexProps {
  [key: string]: number;
}

interface weekNumberIndexProps {
  [key: string]: number;
}

export const weekDays: weekDaysProps[] = [
  { abr: 'S', name: 'Sunday', short: 'Sun' },
  { abr: 'M', name: 'Monday', short: 'Mon' },
  { abr: 'T', name: 'Tuesday', short: 'Tue' },
  { abr: 'W', name: 'Wednesday', short: 'Wed' },
  { abr: 'T', name: 'Thursday', short: 'Thu' },
  { abr: 'F', name: 'Friday', short: 'Fri' },
  { abr: 'S', name: 'Saturday', short: 'Sat' }
];

export const weekDaysIndex: weekDaysIndexProps = {
  Friday: 5,
  Monday: 1,
  Saturday: 6,
  Sunday: 0,
  Thursday: 4,
  Tuesday: 2,
  Wednesday: 3
};

export const weekNumberIndex: weekNumberIndexProps = {
  First: 0,
  Fourth: 3,
  Second: 1,
  Third: 2
};

export default {
  weekDays
};
