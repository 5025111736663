import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

type NavigationProps = {
  children: JSX.Element;
  isClient: boolean;
  // eslint-disable-next-line react/require-default-props
  route?: string;
};

const Navigation: React.FC<NavigationProps> = function Navigation({
  children,
  isClient,
  route = ''
}) {
  if (!route) {
    return children;
  }

  /* ----- IF DOES NOT HAVE CHILDREN THEN DIRECT NAVLINK ------*/
  return (
    <NavLink
      style={{
        textDecoration: 'none'
      }}
      to={isClient ? `${route}` : `/app/${route}`}
    >
      {children}
    </NavLink>
  );
};

export default memo(Navigation);
