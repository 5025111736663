/* eslint-disable react/jsx-props-no-spreading */
import {
    Box,
    Paper,
    SliderProps,
    TextField,
    Typography,
    styled
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import useSWR from 'swr';
  import { useTranslation } from 'react-i18next';
  import api from '../../api';
  import AutoCompleteInput from '../input/AutoCompleteInput';
  import ArrayChips from '../list/ArrayChips';
  
  const rolesStyle = {
    backgroundColor: '#D9F2F0',
    border: '1px solid #68C0BA',
    borderRadius: '24px !important',
    color: '#0F5C56'
  };
  
  const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '17px',
    fontWeight: 'bolder',
    marginBottom: '-12px'
  }));
  
  interface Props {
    setValue: (item: any) => void;
  }
  
  const AddChecklistModal: React.FC<Props> = function AddChecklistModal({
    setValue
  }) {
    const { t } = useTranslation();
    const [getData, setData] = useState<boolean>(false);
    const [checklists, setChecklists] = useState<Array<any>>();
    const [checklistSelected, setChecklistSelected] = useState<any>();
    const [askChecklist, setAskChecklist] = useState<boolean>();
  
    //  ================================================================================= SWR
    //  ================================================================================= SWR
    const { data } = useSWR(
      getData ? { url: 'api/all-checklists' } : null,
      api.checklistApi.getAllChecklists
    );
  
    const { data: checklistData } = useSWR(
      askChecklist
        ? {
            id: checklistSelected.id,
            url: 'api/get-checklist'
          }
        : null,
      api.checklistApi.getChecklist
    );
  
    //  ================================================================================= USEEFFECTS
  
    useEffect(() => {
      if (data) {
        setData(false);
        setChecklists(data);
      }
    }, [data]);
  
    useEffect(() => {
      if (checklistData) {
        setAskChecklist(false);
        setValue({
          description: checklistData.description,
          id: checklistData.id,
          name: checklistData.name,
          list: checklistData.list,
        });
      }
    }, [checklistData]);
  
    useEffect(() => {
      if (checklistSelected) {
        setAskChecklist(true);
      }
    }, [checklistSelected]);
  
    useEffect(() => {
      setData(true);
    }, []);
  
    //  ================================================================================= RENDER
  
    return (
      <Box
        alignItems="start"
        display="flex"
        flexDirection="column"
        justifyContent="start"
        width={500}
      >
        <Box pb={2} pt={4}>
          <Typography variant="title">
            {t('LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.title')}
          </Typography>
        </Box>
        <SubTitle>{t('LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.name')}</SubTitle>
        {checklists && checklists?.length > 0 && (
          <Box width="100%">
            <AutoCompleteInput
              closeOnSelect
              disableCreate
              onChange={(event: any, newValue: any | null) => {
                setChecklistSelected(newValue);
              }}
              options={checklists}
              renderInput={(params) => (
                <TextField
                  placeholder={`${t(
                    'LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.autoCompletePlaceholder'
                  )}`}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderStyle: 'hidden'
                    },
                    borderStyle: 'hidden',
                    fontSize: '15px'
                  }}
                  {...params}
                />
              )}
              value={checklistSelected}
            />
          </Box>
        )}
        {checklistSelected && (
          <Box
            alignItems="start"
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <SubTitle>
              {t('LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.description')}
            </SubTitle>
            <Paper
              sx={{
                alignItems: 'start',
                display: 'flex',
                mb: 2,
                mt: 2,
                p: 1,
                width: '100%'
              }}
            >
              <Typography>
                {checklistSelected.description && checklistSelected.description !== ''
                  ? checklistSelected.description
                  : `${t('LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.noData')}`}
              </Typography>
            </Paper>
            <SubTitle>
              {t('LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.tasks')}
            </SubTitle>
            <Box sx={{ mt: 2, p: 1 }}>
              {checklistSelected.list?.length === 0 && (
                <SubTitle>
                  {t('LEAD.CREATE.SERVICES.ADD_CHECKLIST_MODAL.noData')}
                </SubTitle>
              )}
              <ArrayChips
                unlimited
                colorFont="#0F5C56"
                elementsArray={
                  checklistSelected.list?.map((item: any) => item) || []
                }
                style={rolesStyle}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  
  export default AddChecklistModal;
  